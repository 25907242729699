import {
  ListRequest,
  RequestParamsModelDefault
} from "../models/requestParams.model";
import DomainService from "../services/domain.service";
import { domainTypes } from "./enumerators";

interface IProps {
  domain: string;
  table?: string;
  column?: string;
  value?: any;
  setList?: React.Dispatch<React.SetStateAction<any[]>>;
}

export const getDomainListValues = async (props: IProps, token: any = null) => {
  const { domain, table, column, value, setList } = props;

  if (value !== 0) {
    let requestParams: ListRequest = RequestParamsModelDefault;
    requestParams.filter.field = [{ id: domain, value: domain }];

    if (domain === domainTypes.FLAGLIST) {
      requestParams.filter.field.push(
        { id: "NM_TABLE", value: table },
        { id: "NM_FIELD", value: column }
      );
    } else if (!table && value) {
      requestParams.filter.field.push({ id: column, value: value });
    }

    if (token) {
      requestParams.parameter.IdEnterprise = token.idEnterprise;
      requestParams.parameter.IdUser = token.idUser;
    }

    const response: any = await DomainService.getDomainList(requestParams);

    setList(response?.result?.dados?.length > 0 ? response?.result?.dados : []);

    return response?.result?.dados?.length > 0 ? response?.result?.dados : [];
  } else {
    setList([]);
  }
};

export const loadDropDowns = async (domainConfigurations, token = null) => {
  const loadPromises = domainConfigurations.map((config) =>
    getDomainListValues(config, token)
  );
  await Promise.all(loadPromises);
};
