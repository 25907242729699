import { getDomainListValues } from "./domainFunctions";
import { domainTypes } from "./enumerators";

export const onUfChange = async (idUF: any, setCities) => {
  await getDomainListValues({
    domain: domainTypes.CITY,
    column: "NR_UF_ID",
    value: idUF,
    setList: setCities
  });
};
