import { fieldTypes } from '../util/enumerators';
import { Col, Form } from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { t } from 'i18next';
import { ErrorMessage } from 'formik';
import CurrencyInput from './CurrencyInput';

export const DynamicForm = ({
  form,
  fieldList,
  touched = null,
  errors = null,
  handleChange = null,
  setFieldValue = null,
  handleBlur = null,
  values = null,
}) => {
  return (
    <>
      {fieldList.map((field, idx) => {
        const {
          type,
          lgSize,
          style,
          label,
          mask,
          required,
          disabled,
          maxLength,
          field: fieldName,
          styleField,
          styleLabel,
        } = field;

        const formId = form + '.' + fieldName; //Concatena o nome do FORM com um "." e o nome do CAMPO
        const fieldValue = values?.[form]?.[fieldName]; //Pega o valor do campo dinamicamente
        const isInvalid = touched?.[form]?.[fieldName] && !!errors?.[form]?.[fieldName]; //Verifica se o campo está inválido

        let renderedField;

        switch (type) {
          case fieldTypes.MASKED:
            renderedField = (
              <>
                <MaskedFormControl
                  type="text"
                  id={formId}
                  name={formId}
                  mask={mask}
                  size="sm"
                  required={required ?? true}
                  onChange={(e) => {
                    field.onChange
                      ? field.onChange(e, handleChange, setFieldValue, values)
                      : handleChange(e);
                  }}
                  onBlur={(e) => {
                    field.onBlur
                      ? field.onBlur(e, handleChange, setFieldValue, values)
                      : handleBlur(e);
                  }}
                  disabled={disabled}
                  isInvalid={isInvalid}
                  value={fieldValue}
                />
                <Form.Label className="label-input-form">{t(label)}</Form.Label>
              </>
            );
            break;
          case fieldTypes.SELECT:
            renderedField = (
              <>
                <Form.Select
                  id={formId}
                  name={formId}
                  required={required ?? true}
                  isInvalid={isInvalid}
                  disabled={disabled}
                  onChange={(e) => {
                    field.onChange
                      ? field.onChange(e, handleChange, setFieldValue, values)
                      : handleChange(e);
                  }}
                  value={fieldValue}
                >
                  {field.optionsList ? (
                    <>
                      {field.includeSelectOption !== false && (
                        <option value="">{t('Selecione...')}</option>
                      )}
                      {field.optionsList.map((item) => {
                        return (
                          <option key={item[field.value]} value={item[field.value]}>
                            {item[field.displayText]}
                          </option>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <option value={0}>NÃO</option>
                      <option value={1}>SIM</option>
                    </>
                  )}
                </Form.Select>
                <Form.Label
                  className={`label-input-form ${!disabled ? 'enabled ' : ''}${
                    !fieldValue ? '' : 'selected'
                  }`}
                >
                  {t(label)}
                </Form.Label>
              </>
            );
            break;
          case fieldTypes.CHECKBOX:
            renderedField = (
              <>
                <Form.Check
                  type="checkbox"
                  id={formId}
                  name={formId}
                  className="label-checkbox-form"
                  style={styleField}
                >
                  <Form.Check.Input
                    type="checkbox"
                    required={required ?? true}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    onChange={(e) => {
                      field.onChange
                        ? field.onChange(e, handleChange, setFieldValue, values)
                        : handleChange(e);
                    }}
                    value={fieldValue}
                    checked={fieldValue}
                  />
                  <Form.Check.Label style={styleLabel ?? {}}>{label}</Form.Check.Label>
                </Form.Check>
              </>
            );
            break;
          case fieldTypes.CURRENCY:
            renderedField = (
              <>
                <CurrencyInput
                  id={formId}
                  name={formId}
                  required={required ?? true}
                  size="sm"
                  onChange={(e) => {
                    field.onChange
                      ? field.onChange(e, handleChange, setFieldValue, values)
                      : handleChange(e);
                  }}
                  onBlur={(e) => {
                    field.onBlur
                      ? field.onBlur(e, handleChange, setFieldValue, values)
                      : handleBlur(e);
                  }}
                  disabled={disabled}
                  isInvalid={isInvalid}
                  value={fieldValue}
                />
                <Form.Label className="label-input-form">{t(label)}</Form.Label>
              </>
            );
            break;
          default:
            renderedField = (
              <>
                <Form.Control
                  type={type ?? 'text'}
                  id={formId}
                  name={formId}
                  size="sm"
                  required={required ?? true}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    if (type === 'number') {
                      if (inputValue.length > maxLength) {
                        e.target.value = inputValue.slice(0, maxLength);
                      } else if (parseFloat(inputValue) < 0 || inputValue.includes('e')) {
                        e.target.value = null;
                      }
                    }

                    field.onChange
                      ? field.onChange(e, handleChange, setFieldValue, values)
                      : handleChange(e);
                  }}
                  onInput={(e) => {
                    if (type === 'number' && e.nativeEvent['data'] === 'e')
                      e.target['value'] = null;
                  }}
                  onBlur={(e) => {
                    field.onBlur
                      ? field.onBlur(e, handleChange, setFieldValue, values)
                      : handleBlur(e);
                  }}
                  disabled={disabled}
                  isInvalid={isInvalid}
                  value={fieldValue}
                  maxLength={maxLength}
                  placeholder={field.placeholder ?? label}
                  step={field.step ?? 'any'}
                  min={field.type === 'number' ? 0 : null}
                  style={styleField}
                />
                <Form.Label className="label-input-form" style={styleLabel}>
                  {t(label)}
                </Form.Label>
              </>
            );
        }

        return (
          <Col sm={12} md={6} lg={lgSize} style={style} key={idx}>
            <Form.Group
              className={`${
                type !== fieldTypes.CHECKBOX
                  ? `form-group ${type === fieldTypes.SELECT && fieldValue ? 'selected' : ''}`
                  : ''
              }`}
            >
              {renderedField}

              {touched && (
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage name={formId} />
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        );
      })}
    </>
  );
};
