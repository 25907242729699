import { serviceControllers } from "../util/enumerators";
import HttpService from "./http.service";
const basePath = serviceControllers.PROFILE;

export const ProfileService = {
  insert,
  update
};

async function insert(data: any) {
  return HttpService.post(`${basePath}/addProfile`, data);
}

async function update(data: any) {
  return HttpService.post(`${basePath}/editProfile`, data);
}

export default ProfileService;
