import { useState, useEffect } from 'react';
import { Button, Col } from 'react-bootstrap';
import logo from '../../assets/images/logo.png';
import imgOutsourcing from '../../assets/images/menu-banner-02.png';
import imgOne from '../../assets/images/menu-banner-01.png';
import connectLogo from '../../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import PageLogin from './login';

import '../../assets/css/bootstrap.min.css';
import '../../assets/css/style.css';
import '../../assets/css/swiper.min.css';
import '../../assets/css/font-awesome-pro.css';
import '../../assets/css/bootsnav.css';
import '../../assets/css/themify-icons.css';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/justified-gallery.min.css';
import '../../assets/css/responsive.css';
import '../../assets/css/et-line-icons.css';
import '../../assets/css/animate.css';

import './Header.css';
import ResetPassword from './resetPassword';

function Navbar(props) {
  const { isResetingPassword } = props;
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(isResetingPassword);
  const [activeItem, setActiveItem] = useState('Início');
  const [activeItemDropDown, setActiveItemDropDown] = useState();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0 && !isSticky) {
        setIsSticky(true);
      } else if (scrollTop === 0 && isSticky) {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky]);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  const handleItemDropDownClick = (itemName) => {
    setActiveItemDropDown(itemName);
  };

  const handleOptionHover = () => {
    setIsMenuOpen(true);
  };

  const handleMenuLeave = () => {
    setIsMenuOpen(false);
  };

  const handleSidebarOpen = () => {
    setIsSidebarOpened(true);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpened(false);
  };

  return (
    <>
      <header>
        <nav
          className={`navbar navbar-default bootsnav navbar-fixed-top header-dark bg-transparent on no-full ${
            isSticky ? 'sticky' : ''
          }`}
        >
          <div className="progress-bar">
            <div className="progress-bar-inner" style={{ left: 0 }}></div>
          </div>
          <div
            className="container nav-header-container"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <Col md={2} xs={5} style={{ paddingLeft: 0 }}>
              <Link className="logo" to={'/Home'}>
                <img src={logo} data-rjs={logo} className="logo-dark default" alt="logo" />
              </Link>
            </Col>
            <Col
              md={9}
              xs={2}
              className="pull-right accordion-menu xs-no-padding-right"
              style={{
                borderRadius: 0,
                paddingRight: 0,
              }}
            >
              <button
                type="button"
                className="navbar-toggle collapsed pull-right"
                data-toggle="collapse"
                data-target="#navbar-collapse-toggle-1"
              >
                <span className="sr-only">toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <div className="navbar-collapse collapse pull-right" id="navbar-collapse-toggle-1">
                <ul
                  id="accordion"
                  className="navbar__options nav navbar-left no-margin alt-font text-normal"
                  data-in="fadeIn"
                  data-out="fadeOut"
                >
                  <li
                    className={`navbar__option ${activeItem === 'Início' ? 'active' : ''}`}
                    onClick={() => {
                      handleItemClick('Início');
                    }}
                  >
                    INÍCIO
                  </li>
                  <li
                    className={`navbar__option ${activeItem === 'A Global' ? 'active' : ''}`}
                    onClick={() => handleItemClick('A Global')}
                  >
                    A GLOBAL
                  </li>
                  <li
                    className={`navbar__option ${
                      activeItem === 'Serviços e Soluções' ? 'active' : ''
                    }`}
                    onMouseEnter={handleOptionHover}
                    onMouseLeave={handleMenuLeave}
                  >
                    SERVIÇOS & SOLUÇÕES
                  </li>
                  <li
                    className={`navbar__option ${activeItem === 'Clientes' ? 'active' : ''}`}
                    onClick={() => handleItemClick('Clientes')}
                  >
                    CLIENTES
                  </li>
                  <li
                    className={`navbar__option ${activeItem === 'Vagas' ? 'active' : ''}`}
                    onClick={() => handleItemClick('Vagas')}
                  >
                    VAGAS
                  </li>
                  <li
                    className={`navbar__option ${activeItem === 'Contato' ? 'active' : ''}`}
                    onClick={() => handleItemClick('Contato')}
                  >
                    CONTATO
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={1} xs={5} style={{ width: '60px' }}>
              <div className="header-searchbar">
                <div className="header-menu-button sm-display-none">
                  <Button
                    className="navbar-toggle mobile-toggle right-menu-button"
                    type="button"
                    id="showRightPush"
                    style={{ border: 'none' }}
                    onClick={handleSidebarOpen}
                  >
                    <i
                      className="fal fa-user"
                      style={{
                        fontWeight: '300',
                        fontSize: '18px',
                        color: '#cf468d',
                      }}
                    ></i>
                  </Button>
                </div>
              </div>
            </Col>
          </div>
        </nav>
        {isMenuOpen && (
          <div onMouseEnter={handleOptionHover} onMouseLeave={handleMenuLeave}>
            <ul className="div-menu-back-servicos">
              <Col lg={3} md={3} xs={12} sm={12} className="menu-servicos">
                <ul
                  style={{
                    listStyle: 'none',
                    paddingLeft: 0,
                  }}
                >
                  <li className="header-menu-servicos">Para a sua empresa</li>
                  <li
                    className={`item-menu-servicos ${activeItem === 'RPA' ? 'active' : ''}`}
                    onClick={() => handleItemClick('RPA')}
                  >
                    RPA - Robotic Process Automation
                  </li>
                  <li
                    className={`item-menu-servicos ${activeItem === 'Outsourcing' ? 'active' : ''}`}
                    onClick={() => handleItemClick('Outsourcing')}
                  >
                    Outsourcing e Hunting
                  </li>
                  <li
                    className={`item-menu-servicos ${activeItem === 'Fabrica' ? 'active' : ''}`}
                    onClick={() => handleItemClick('Fabrica')}
                  >
                    Fábrica de Software
                  </li>
                  <li
                    className={`item-menu-servicos ${activeItem === 'BPO' ? 'active' : ''}`}
                    onClick={() => handleItemClick('BPO')}
                  >
                    BPO - Business Process Outsourcing
                  </li>
                  <li
                    className={`item-menu-servicos ${activeItem === 'Elearning' ? 'active' : ''}`}
                    onClick={() => handleItemClick('Elearning')}
                  >
                    E-learning
                  </li>
                </ul>
              </Col>
              <Col lg={3} md={3} xs={12} sm={12}>
                <ul
                  style={{
                    listStyle: 'none',
                    paddingLeft: 0,
                  }}
                >
                  <li
                    className={`item-menu-servicos ${activeItem === 'Aplicativos' ? 'active' : ''}`}
                    onClick={() => handleItemClick('Aplicativos')}
                  >
                    Aplicativos
                  </li>
                  <li
                    className={`item-menu-servicos ${activeItem === 'UXUI' ? 'active' : ''}`}
                    onClick={() => handleItemClick('UXUI')}
                  >
                    UX / UI
                  </li>
                  <li
                    className={`item-menu-servicos ${
                      activeItem === 'SuiteSolucoes' ? 'active' : ''
                    }`}
                    onClick={() => handleItemClick('SuiteSolucoes')}
                  >
                    Suite de Soluções
                  </li>
                  <li
                    className={`item-menu-servicos ${activeItem === 'IA' ? 'active' : ''}`}
                    onClick={() => handleItemClick('IA')}
                  >
                    IA - inteligência Artifial
                  </li>
                  <li
                    className={`item-menu-servicos ${activeItem === 'BIBA' ? 'active' : ''}`}
                    onClick={() => handleItemClick('BIBA')}
                  >
                    BI & BA - Business Intelligence
                  </li>
                  <li
                    className={`item-menu-servicos ${activeItem === 'Blockchain' ? 'active' : ''}`}
                    onClick={() => handleItemClick('Blockchain')}
                  >
                    Blockchain
                  </li>
                </ul>
              </Col>
              <Col lg={3} md={3} xs={12} sm={12} style={{ height: 285, padding: 0 }}>
                <ul
                  style={{
                    listStyle: 'none',
                    paddingLeft: 0,
                  }}
                >
                  <a>
                    <li
                      className="item-menu-servicos"
                      onClick={() => {
                        // handleItemClick("Aplicativos")
                      }}
                    >
                      <img src={imgOutsourcing} alt="Tecnologia e Pessoas" data-no-retina />
                    </li>
                  </a>
                </ul>
              </Col>
              <Col lg={3} md={3} xs={12} sm={12} style={{ height: 285, padding: 0 }}>
                <ul style={{ listStyle: 'none' }}>
                  <a>
                    <li
                      className="item-menu-servicos"
                      onClick={() => {
                        // handleItemClick("Aplicativos")
                      }}
                    >
                      <img src={imgOne} alt="One" data-no-retina style={{ paddingLeft: '22px' }} />
                    </li>
                  </a>
                </ul>
              </Col>
            </ul>
          </div>
        )}
      </header>

      {isSidebarOpened && (
        <>
          <div
            className="cbp-spmenu-vertical cbp-spmenu-right cbp-spmenu-open"
            style={{ zIndex: 999, position: 'fixed' }}
          >
            <button
              className="close-button-menu side-menu-close"
              id="close-pushmenu"
              onClick={handleSidebarClose}
            ></button>
            <div
              className="display-table padding-twelve-all height-100 width-100"
              style={{ backgroundColor: '#000' }}
            >
              <div
                className="display-table-cell vertical-align-top padding-70px-top position-relative"
                style={{ fontSize: '14px' }}
              >
                <Col lg={12}>
                  <img src={connectLogo} alt="Connect Logo" />
                </Col>
                <Col lg={12}>
                  <h5>Bem-vindo(a) ao Connect</h5>

                  {!isResetingPassword ? <PageLogin /> : <ResetPassword />}
                </Col>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Navbar;
