import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonModel } from "../../models/components/button.model";
import {
  FaArrowLeft,
  FaCalendarTimes,
  FaRegCalendarTimes,
  FaRegSave,
  FaSave
} from "react-icons/fa";
import { ToastService } from "../../services/toast.service";
import { useEffect, useRef, useState } from "react";
import { VacationModel } from "../../models/vacation/vacation.model";
import { Header } from "../../components/Header";
import { Formik } from "formik";
import Fieldset from "../../components/Fieldset";
import { Form, Row } from "react-bootstrap";
import BaseService from "../../services/base.service";
import moment from "moment";
import * as Yup from "yup";
import {
  fieldTypes,
  formMode,
  serviceControllers
} from "../../util/enumerators";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { DynamicForm } from "../../components/DynamicForm";
import { formValidateDate, formatCpfCnpj, formatDate } from "../../util";
import { CPF_MASK, DATE_MASK } from "../../util/consts";

export const VacationRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { id, mode } = useParams<{ id?: string; mode?: string }>();

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(-1);
      },
      text: "Voltar",
      variant: "outline-primary",
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: "Voltar para a página anterior"
    },
    {
      onClick: () => {
        setShowModal(true);
      },
      text: "Excluir",
      icon: (
        <>
          <FaRegCalendarTimes className="fa-icon" />
          <FaCalendarTimes className="fa-icon-hover fa-icon-color-red" />
        </>
      ),
      tooltip: "Excluir férias",
      hide: !id || mode === formMode.VIEW || mode === formMode.REGISTER
    },
    {
      onClick: () => {
        submitFormik();
      },
      text: "Salvar",
      icon: (
        <>
          <FaRegSave className="fa-icon" /> <FaSave className="fa-icon-hover" />
        </>
      ),
      tooltip: "Salvar registro",
      hide: mode === formMode.VIEW
    }
  ];

  const [initialValues, setInitialValues] = useState<VacationModel>({
    vacation: {
      cpf: "",
      name: "",
      startDate: "",
      finishDate: "",
      observation: ""
    }
  });

  const fieldList = [
    {
      type: fieldTypes.MASKED,
      field: "cpf",
      label: "CPF",
      mask: CPF_MASK,
      onChange: async (event, handleChange, setFieldValue) => {
        handleChange(event);

        let cpf = event.target.value.replace(/\D/g, "");
        if (cpf.length === 11) {
          let collaboratorName = await getName(cpf);
          setFieldValue("vacation.name", collaboratorName);
        }
      },
      lgSize: 2,
      maxLength: 14,
      disabled: mode === formMode.EDIT || mode === formMode.VIEW || isLoading
    },
    {
      field: "name",
      label: "Nome do Colaborador",
      placeholder: "Escreva o nome do Colaborador",
      lgSize: 3,
      maxLength: 60,
      disabled: true
    },
    {
      type: fieldTypes.MASKED,
      field: "startDate",
      label: "Data de Início",
      mask: DATE_MASK,
      lgSize: 2,
      maxLength: 10,
      disabled: mode === formMode.VIEW || isLoading
    },
    {
      type: fieldTypes.MASKED,
      field: "finishDate",
      label: "Data de Término",
      mask: DATE_MASK,
      lgSize: 2,
      maxLength: 10,
      disabled: mode === formMode.VIEW || isLoading
    },

    {
      field: "observation",
      label: "Observação",
      placeholder: "Escreva alguma observação (se houver)",
      lgSize: 3,
      maxLength: 100,
      required: false,
      disabled: mode === formMode.VIEW || isLoading
    }
  ];

  const initialize = async () => {
    setLoading(true);

    if (id !== undefined || id != null) {
      if (mode === formMode.REGISTER) {
        setInitialValues({
          vacation: {
            cpf: formatCpfCnpj(id),
            name: await getName(id),
            startDate: "",
            finishDate: "",
            observation: ""
          }
        });
      } else {
        const apiResponse: any = await BaseService.getById(
          serviceControllers.VACATION,
          "NR_FER_ID",
          id,
          "",
          ["FERIAS"]
        );

        if (apiResponse.result != null) {
          setInitialValues({
            vacation: {
              cpf: formatCpfCnpj(apiResponse.result.dados[0]["NR_CLB_ID"]),
              name: apiResponse.result.dados[0]["RH_COLABORADOR_NM_CLB_DS"],
              startDate: formatDate(apiResponse.result.dados[0]["DT_INI_PRO"]),
              finishDate: formatDate(apiResponse.result.dados[0]["DT_FIM_PRO"]),
              observation: apiResponse.result.dados[0]["DS_FER_OBS"]
            }
          });
        }
      }
    }

    setLoading(false);
  };

  const onSubmit = async (data) => {
    if (!data.vacation.name) {
      ToastService.error("Colaborador não cadastrado!");
      return;
    }

    setLoading(true);

    const insertParams = {
      id: mode === formMode.EDIT ? id : 0,
      cpf: data.vacation.cpf.replace(/\D/g, ""),
      startDate: moment(data.vacation.startDate, "DD/MM/YYYY").toDate(),
      finishDate: moment(data.vacation.finishDate, "DD/MM/YYYY").toDate(),
      observation: data.vacation.observation
    };

    BaseService.insert(serviceControllers.VACATION, insertParams).then(
      (response: any) => {
        setLoading(false);
        if (response.return.success === true) {
          const successMessage = formMode.EDIT
            ? "Férias atualizada com sucesso!"
            : "Férias cadastrada com sucesso!";
          ToastService.success(successMessage);
          navigate(`/Ferias/`);
        } else {
          const errorMessage = formMode.EDIT
            ? "Erro ao atualizar férias!"
            : "Erro ao cadastrar férias!";
          ToastService.error(errorMessage);
        }
      }
    );
  };

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  const handleConfirmModal = async () => {
    var delParams = {
      id: id
    };

    await BaseService.del(serviceControllers.VACATION, delParams).then(
      (response: any) => {
        if (response.return.success === true) {
          ToastService.success("Férias removidas com sucesso!");
          navigate("/Ferias");
        } else {
          ToastService.error("Erro ao excluir férias!");
        }
        setShowModal(false);
      }
    );
  };

  const validationSchema = Yup.object().shape({
    vacation: Yup.object().shape({
      startDate: Yup.string()
        .required("Campo obrigatório")
        .test("valid-start-date", "Data inválida", formValidateDate),
      finishDate: Yup.string()
        .required("Campo obrigatório")
        .test("valid-finish-date", "Data inválida", formValidateDate)
        .test(
          "valid-finish-date",
          "Data Término menor que Data Início",
          function (value, { parent }) {
            const parsedStartDate = moment(
              parent.startDate,
              "DD/MM/YYYY",
              true
            );
            const parsedFinishDate = moment(value, "DD/MM/YYYY", true);

            return (
              parsedFinishDate.isValid() &&
              parsedFinishDate.isSameOrAfter(parsedStartDate)
            );
          }
        ),
      cpf: Yup.string()
        .required("Campo obrigatório")
        .test("valid-cpf", "CPF inválido", function (value) {
          const numericValue = value?.replace(/\D/g, "");
          return numericValue?.length === 11;
        })
    })
  });

  const getName = async (cpf) => {
    const apiResponse: any = await BaseService.getById(
      serviceControllers.COLLABORATOR,
      "NR_CLB_ID",
      cpf,
      "",
      ["FERIAS"]
    );

    if (apiResponse?.return?.success)
      return apiResponse.result.dados[0]["NM_CLB_DS"];
  };

  useEffect(() => {
    dispatch({
      type: "LOAD_FILTERS",
      filtersList: []
    });

    dispatch({
      type: "SEARCH_FILTERS",
      filtersToSearch: []
    });
    initialize();
  }, []);

  return (
    <>
      <Header
        title="Cadastro de Férias"
        buttons={buttons}
        previousPage="Listagem de Férias"
        previousUrl={() => {
          navigate(`/Ferias/`);
        }}
      />
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            onSubmit(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Fieldset legend="Dados das Férias">
                <Row className="align-items-center" style={{ paddingTop: 10 }}>
                  <DynamicForm
                    form="vacation"
                    fieldList={fieldList}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    values={values}
                  />
                </Row>
              </Fieldset>
            </Form>
          )}
        </Formik>
      </div>
      <ConfirmationModal
        title={"Deseja realmente excluir este registro?"}
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        handleConfirm={handleConfirmModal}
        children={""}
      />
    </>
  );
};
