interface IProps {
  current: number;
  total: number;
  selectedCount?: number;
}

export const Footer = (props: IProps) => {
  const { current, total, selectedCount } = props;

  //Define se vai usar a palavra "item" ou "itens" de acordo com a quantidade selecionada
  const itemPlural = selectedCount !== 1 ? "itens" : "item";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      <div>{`Exibindo ${current} de ${total} ${itemPlural}`}</div>
      <div>
        {selectedCount > 0 && (
          <>{`${selectedCount} ${itemPlural} ${
            selectedCount === 1 ? "selecionado" : "selecionados"
          }`}</>
        )}
      </div>
    </div>
  );
};
