import { Breadcrumb, Button } from 'react-bootstrap';
import { ButtonModel } from '../models/components/button.model';
import '../scss/custom.css';
import { useNavigate } from 'react-router-dom';
import { adjustIdName } from '../util';

interface IProps {
  title: string;
  previousPage?: string;
  previousUrl?: any;
  buttons?: ButtonModel[];
  middleButtons?: ButtonModel[];
  showBread?: boolean;
}

export const Header = ({
  title,
  previousPage,
  previousUrl,
  buttons,
  middleButtons,
  showBread = true,
}: IProps) => {
  let navigate = useNavigate();

  return (
    <header
      className="header"
      style={{
        display: 'flex',
        justifyContent: showBread ? 'space-between' : 'flex-end',
        position: 'sticky',
        zIndex: 7,
        top: 0,
        backgroundColor: showBread ? '#f2f3f8' : 'transparent',
        paddingTop: 3,
      }}
    >
      <div
        className="header-container"
        style={{
          justifyContent: showBread ? 'space-between' : 'flex-end',
        }}
      >
        {showBread && (
          <Breadcrumb
            style={{
              width: 'unset',
            }}
          >
            <Breadcrumb.Item onClick={() => navigate(`/HomeList`)} id="home-link">
              Home
            </Breadcrumb.Item>
            {previousPage != null && (
              <Breadcrumb.Item onClick={previousUrl}>{previousPage}</Breadcrumb.Item>
            )}
            <Breadcrumb.Item active>{title}</Breadcrumb.Item>
          </Breadcrumb>
        )}

        {middleButtons && (
          <div className="header-middle-buttons">
            <div>
              {middleButtons?.map((button: ButtonModel, index: any) => {
                return (
                  <>
                    {button.icon != null ? (
                      <>
                        <Button
                          key={index}
                          size="sm"
                          variant={button.variant ? button.variant : 'primary'}
                          disabled={button.disabled}
                          onClick={button.onClick}
                          onAuxClick={(event) => {
                            if (event.button !== 2) {
                              event.preventDefault();
                              event.stopPropagation();
                              button.onAuxClick();
                            }
                          }}
                          onContextMenu={(event) => {
                            if (typeof button.onContextMenu !== 'undefined') {
                              event.preventDefault();
                              event.stopPropagation();
                              button.onContextMenu();
                            }
                          }}
                          className="icon-button"
                          title={button.tooltip}
                          style={{ display: button.hide ? 'none' : '' }}
                        >
                          <div className="div-button">
                            <span className="fa-icon-button">{button.icon}</span>
                            <span className="text-button"> {button.text}</span>
                          </div>
                        </Button>
                        &nbsp;&nbsp;
                      </>
                    ) : (
                      <>
                        <Button
                          key={index}
                          size="sm"
                          variant={button.variant}
                          disabled={button.disabled}
                          onClick={button.onClick}
                          title={button.tooltip}
                        >
                          {button.text}
                        </Button>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            {buttons?.map((button: ButtonModel, index: any) => {
              return (
                <>
                  {button.icon != null ? (
                    <>
                      <Button
                        id={'btn-' + adjustIdName(button.text)}
                        key={index}
                        size="sm"
                        variant={button.variant ? button.variant : 'primary'}
                        disabled={button.disabled}
                        onClick={button.onClick}
                        onAuxClick={(event) => {
                          if (event.button !== 2) {
                            event.preventDefault();
                            event.stopPropagation();
                            button.onAuxClick();
                          }
                        }}
                        onContextMenu={(event) => {
                          if (typeof button.onContextMenu !== 'undefined') {
                            event.preventDefault();
                            event.stopPropagation();
                            button.onContextMenu();
                          }
                        }}
                        className="icon-button"
                        title={button.tooltip}
                        style={{ display: button.hide ? 'none' : '' }}
                      >
                        <div className="div-button">
                          <span className="fa-icon-button">{button.icon}</span>{' '}
                          {button.text?.length !== 0 && (
                            <span className="text-button">{button.text}</span>
                          )}
                        </div>
                      </Button>
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    <>
                      <Button
                        key={index}
                        id={'btn-' + adjustIdName(button.text)}
                        size="sm"
                        variant={button.variant}
                        disabled={button.disabled}
                        onClick={button.onClick}
                        title={button.tooltip}
                      >
                        {button.text}
                      </Button>
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </header>
  );
};
