import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonModel } from '../../../models/components/button.model';
import {
  FaArrowLeft,
  FaCalendarTimes,
  FaRegCalendarTimes,
  FaRegSave,
  FaSave,
  FaListAlt,
  FaRegListAlt,
} from 'react-icons/fa';
import { ToastService } from '../../../services/toast.service';
import { useEffect, useRef, useState } from 'react';
import { Header } from '../../../components/Header';
import { Formik } from 'formik';
import { t } from 'i18next';
import Fieldset from '../../../components/Fieldset';
import { Col, Form, Row } from 'react-bootstrap';
import BaseService from '../../../services/base.service';
import moment from 'moment';
import * as Yup from 'yup';
import { domainTypes, fieldTypes, serviceControllers } from '../../../util/enumerators';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { SalesOrderModel } from '../../../models/salesOrder/salesOrder.model';
import { UFModel } from '../../../models/UF.model';
import { getDomainListValues, loadDropDowns } from '../../../util/domainFunctions';
import { Loading } from '../../../components/Loading';
import { SalesOrderDataModelDefault } from '../../../models/salesOrder/salesOrderData.model';
import {
  ValuesDataModel,
  ValuesDataModelDefault,
} from '../../../models/salesOrder/valuesData.model';
import { editingMap, saveMap, valueFieldsMap } from '../form/columnMapping';
import { formValidateDate, formatDate } from '../../../util';
import { RequestParamsModelDefault } from '../../../models/requestParams.model';
import { DynamicForm } from '../../../components/DynamicForm';
import {
  CNPJ_MASK,
  CPF_MASK,
  DATE_MASK,
  TABELA_COLABORADOR,
  TABELA_REGISTRO,
} from '../../../util/consts';
import { onUfChange } from '../../../util/formFunctions';

export const SalesOrderRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams<{ id?: string }>();
  const [UF, setUF] = useState<UFModel[]>();
  const [cities, setCities] = useState([]);

  const [modalidades, setModalidades] = useState([]);
  const [horaTrabalho, setHoraTrabalho] = useState([]);
  const [captacao, setCaptacao] = useState([]);
  const [remuneracao, setRemuneracao] = useState([]);
  const [timesheet, setTimesheet] = useState([]);
  const [tipoFechamento, setTipoFechamento] = useState([]);
  const [vendedor, setVendedor] = useState([]);
  const [consultor, setConsultor] = useState([]);
  const [salesValidity, setSalesValidity] = useState([]);
  const [canaisVenda, setCanaisVenda] = useState([]);
  const [domainLists, setDomainLists] = useState({});

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/PedidoVenda/`);
      },
      text: 'Voltar',
      variant: 'outline-primary',
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: 'Voltar para a página anterior',
    },
    {
      onClick: () => {
        setShowModal(true);
      },
      text: 'Cancelar',
      icon: (
        <>
          <FaRegCalendarTimes className="fa-icon" />
          <FaCalendarTimes className="fa-icon-hover fa-icon-color-red" />
        </>
      ),
      tooltip: 'Cancelar pedido',
    },
    {
      onClick: () => {
        const newSale: ValuesDataModel = ValuesDataModelDefault;
        setInitialValues((prevValues) => ({
          ...prevValues,
          salesList: [...prevValues.salesList, newSale],
        }));
      },
      text: 'Nova Venda',
      icon: (
        <>
          <FaRegListAlt className="fa-icon" /> <FaListAlt className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Adicionar nova tabela de venda',
    },
    {
      onClick: () => {
        submitFormik();
      },
      text: 'Salvar',
      icon: (
        <>
          <FaRegSave className="fa-icon" /> <FaSave className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Salvar registro',
    },
  ];

  const [initialValues, setInitialValues] = useState<SalesOrderModel>({
    salesOrder: SalesOrderDataModelDefault,
    salesList: [ValuesDataModelDefault],
    // TODO: APAGAR APOS DEFINIÇÃO DE FUNCIONAMENTO
    salesValues: ValuesDataModelDefault,
  });

  const domainConfigurations = [
    {
      domain: domainTypes.STATE,
      setList: setUF,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_COLABORADOR,
      column: 'IND_BPF_TP',
      setList: setTipoFechamento,
    },
    {
      domain: domainTypes.MODALITY,
      setList: setModalidades,
    },
    {
      domain: domainTypes.WORK_SCHEDULE,
      setList: setHoraTrabalho,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_REGISTRO,
      column: 'CD_CNL_CPT',
      setList: setCaptacao,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_REGISTRO,
      column: 'IND_TIP_RMN',
      setList: setRemuneracao,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_REGISTRO,
      column: 'IND_PTO_TIP',
      setList: setTimesheet,
    },
    {
      domain: domainTypes.CONSULTANT,
      setList: setConsultor,
    },
    {
      domain: domainTypes.SALE,
      setList: setVendedor,
    },
  ];

  const fieldList = [
    {
      field: 'client',
      label: 'Cliente',
      placeholder: 'Escreva o nome do cliente',
      lgSize: 3,
      maxLength: 60,
      disabled: true,
    },
    {
      type: fieldTypes.MASKED,
      field: 'cnpj',
      label: 'CNPJ',
      mask: CNPJ_MASK,
      onChange: async (event, handleChange, setFieldValue) => {
        handleChange(event);

        let cnpj = event.target.value.replace(/\D/g, '');
        if (cnpj.length === 14) {
          let clientName = await getClient(cnpj);
          setFieldValue('salesOrder.client', clientName);
        }
      },
      lgSize: 2,
      disabled: id != null || isLoading,
    },
    {
      type: fieldTypes.SELECT,
      field: 'seller',
      label: 'Vendedor',
      optionsList: vendedor,
      lgSize: 3,
      disabled: id != null || isLoading,
      value: 'NR_VEN_ID',
      displayText: 'NM_VEN_DS',
    },
    {
      field: 'status',
      label: 'Status',
      placeholder: 'Escreva o status do pedido',
      lgSize: 2,
      maxLength: 60,
      disabled: true,
    },
    {
      field: 'number',
      label: 'Nº Pedido',
      placeholder: 'Escreva o número do pedido',
      lgSize: 2,
      maxLength: 60,
      disabled: id || isLoading ? true : false,
    },
    {
      field: 'area',
      label: 'Área/Projeto',
      placeholder: 'Escreva a área/projeto do pedido',
      lgSize: 3,
      maxLength: 60,
      disabled: id || isLoading ? true : false,
    },
    {
      type: fieldTypes.MASKED,
      field: 'date',
      label: 'Data',
      mask: DATE_MASK,
      lgSize: 2,
      disabled: id != null || isLoading,
    },
    {
      field: 'manager',
      label: 'Gestor',
      placeholder: 'Escreva o nome do gestor',
      lgSize: 3,
      maxLength: 60,
      disabled: id || isLoading ? true : false,
    },
    {
      type: fieldTypes.SELECT,
      field: 'invoicingBusinessDays',
      label: 'Fat. Dias Úteis',
      lgSize: 2,
      disabled: id != null || isLoading,
    },
    {
      type: fieldTypes.SELECT,
      field: 'closingTypeBilling',
      label: 'Fat. Tipo Fech',
      optionsList: tipoFechamento,
      lgSize: 2,
      disabled: id || isLoading ? true : false,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      type: fieldTypes.SELECT,
      field: 'consultant',
      label: 'Consultor',
      optionsList: consultor,
      lgSize: 3,
      disabled: id || isLoading ? true : false,
      value: 'NM_CLB_DS',
      displayText: 'NM_CLB_DS',
    },
    {
      type: fieldTypes.MASKED,
      field: 'cpf',
      label: 'CPF do Colaborador',
      mask: CPF_MASK,
      lgSize: 2,
      disabled: id != null || isLoading,
    },
    {
      type: fieldTypes.SELECT,
      field: 'modality',
      label: 'Modalidade',
      optionsList: modalidades,
      lgSize: 3,
      disabled: id || isLoading ? true : false,
      value: 'ID_MOD_CTR',
      displayText: 'DS_MOD_CTR',
    },
    {
      field: 'from',
      label: 'Corte:(De)',
      placeholder: 'Escreva o corte',
      lgSize: 2,
      maxLength: 4,
      disabled: id || isLoading ? true : false,
    },
    {
      field: 'to',
      label: 'Corte:(Até)',
      placeholder: 'Escreva o corte',
      lgSize: 2,
      maxLength: 4,
      disabled: id || isLoading ? true : false,
    },
    {
      type: fieldTypes.SELECT,
      field: 'schedule',
      label: 'Horário de Trabaho',
      optionsList: horaTrabalho,
      lgSize: 3,
      disabled: id || isLoading ? true : false,
      value: 'NM_HTR_DS',
      displayText: 'NM_HTR_DS',
    },
    {
      type: fieldTypes.SELECT,
      field: 'state',
      label: 'UF',
      onChange: (event, handleChange, setFieldValue) => {
        handleChange(event);
        onUfChange(event.target.value, setCities);
        setFieldValue('salesOrder.city', null);
      },
      optionsList: UF,
      lgSize: 2,
      disabled: id || isLoading ? true : false,
      value: 'NR_UF_ID',
      displayText: 'NR_UF_CD',
    },
    {
      type: fieldTypes.SELECT,
      field: 'city',
      label: 'Cidade',
      optionsList: cities,
      lgSize: 3,
      disabled: isLoading || cities?.length === 0 || id != null,
      value: 'NR_CID_ID',
      displayText: 'NM_CID_DS',
    },
    {
      type: fieldTypes.MASKED,
      field: 'startDate',
      label: 'Data Início',
      mask: '11/11/1111',
      lgSize: 2,
      disabled: id != null || isLoading,
    },
    {
      type: fieldTypes.MASKED,
      field: 'endDate',
      label: 'Data Fim',
      mask: DATE_MASK,
      lgSize: 2,
      disabled: id != null || isLoading,
    },
    {
      field: 'expertise',
      label: 'Especialidade',
      placeholder: 'Escreva o tipo do especialidade',
      lgSize: 3,
      maxLength: 60,
      disabled: id || isLoading ? true : false,
    },
    {
      field: 'monthlyHours',
      label: 'Qt. Horas Mês',
      placeholder: 'Escreva a quantidade de horas',
      lgSize: 2,
      maxLength: 60,
      disabled: id || isLoading ? true : false,
    },
    {
      field: 'workPlace',
      label: 'Local de Trabalho',
      placeholder: 'Escreva o local de trabalho',
      lgSize: 3,
      maxLength: 60,
      disabled: id || isLoading ? true : false,
    },
    {
      type: fieldTypes.SELECT,
      field: 'remuneration',
      label: 'Remuneração',
      optionsList: remuneracao,
      lgSize: 2,
      disabled: id || isLoading ? true : false,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      type: fieldTypes.SELECT,
      field: 'timesheet',
      label: 'Timesheet',
      optionsList: timesheet,
      lgSize: 2,
      disabled: id || isLoading ? true : false,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      //  TODO: Conferir origem dos dados
      type: fieldTypes.SELECT,
      field: 'salesChannel',
      label: 'Canal de Venda',
      optionsList: canaisVenda,
      lgSize: 3,
      disabled: id || isLoading ? true : false,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      //  TODO: Conferir nome da coluna e id
      type: fieldTypes.SELECT,
      field: 'poll',
      label: 'C. Captação',
      optionsList: captacao,
      lgSize: 2,
      disabled: id || isLoading ? true : false,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      field: 'observation',
      label: 'Observação',
      placeholder: 'Escreva alguma observação (se houver)',
      lgSize: 7,
      maxLength: 60,
      disabled: id || isLoading ? true : false,
    },
  ];

  const buyFieldList = [
    {
      field: 'purchasePriceInput',
      label: 'Valor de Compra',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'purchasePriceCalc',
      label: 'Valor de Compra',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'cltInput',
      label: 'CLT',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'cltCalc',
      label: 'CLT',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'daInput',
      label: 'DA',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'daCalc',
      label: 'DA',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'pjInput',
      label: 'PJ',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'pjCalc',
      label: 'PJ',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'flexInput',
      label: 'FLEX',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'flexCalc',
      label: 'FLEX',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'cooperInput',
      label: 'COOPER',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'cooperCalc',
      label: 'COOPER',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'subsistenceAllowanceInput',
      label: 'Ajuda de Custo',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'totalSaleValueInput',
      label: 'Total',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'totalSaleValueCalc',
      label: 'Total',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
  ];

  const chargesFieldList = [
    {
      field: 'cltChargesInput',
      label: 'Encargos CLT',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'cltChargesCalc',
      label: 'Encargos CLT',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'quotasInput',
      label: 'Custo Cotas',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'quotasCalc',
      label: 'Custo Cotas',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'rhInput',
      label: 'Custo RH',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'rhCalc',
      label: 'Custo RH',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'equipmentInput',
      label: 'Equipamentos',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'equipmentCalc',
      label: 'Equipamentos',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'indirectCostInput',
      label: 'Custos Indiretos',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'indirectCostCalc',
      label: 'Custos Indiretos',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'overhaedInput',
      label: 'Overhead',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'overhaedCalc',
      label: 'Overhead',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'cooperCostInput',
      label: 'Custo Cooper',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'cooperCostCalc',
      label: 'Custo Cooper',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'lifeInsuranceInput',
      label: 'Seguro Vida',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'lifeInsuranceCalc',
      label: 'Seguro Vida',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'totalTaxesValueInput',
      label: 'Total',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'totalTaxesValueCalc',
      label: 'Total',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
  ];

  const benefitsFieldList = [
    {
      field: 'healthCareInput',
      label: 'A. Médica',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'healthCareCalc',
      label: 'A. Médica',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'dentalCareInput',
      label: 'A. Ondontológica',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'dentalCareCalc',
      label: 'A. Ondontológica',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'mealTicketInput',
      label: 'V. Refeição',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'mealTicketCalc',
      label: 'V. Refeição',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'foodStampsInput',
      label: 'V. Alimentação',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'foodStampsCalc',
      label: 'V. Alimentação',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'fuelVoucherInput',
      label: 'V. Combustível',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'fuelVoucherCalc',
      label: 'V. Combustível',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'charteredInput',
      label: 'Fretado/VT',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'charteredCalc',
      label: 'Fretado/VT',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'plrInput',
      label: 'PLR',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'plrCalc',
      label: 'PLR',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'otherBenefitsInput',
      label: 'Outros Benefícios',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'otherBenefitsCalc',
      label: 'Outros Benefícios',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'totalBenefitslValueInput',
      label: 'Total',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'totalBenefitslValueCalc',
      label: 'Total',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
  ];

  const sellFieldList = [
    {
      field: 'sellPriceInput',
      label: 'R$ Venda',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'sellPriceCalc',
      label: 'R$ Venda',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'sellValueInput',
      label: 'Valor Venda',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'sellValueCalc',
      label: 'Valor Venda',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'equipmentSellInput',
      label: 'Equipamentos',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'equipmentSellCalc',
      label: 'Equipamentos',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'taxesInput',
      label: 'Imposto',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'taxesCalc',
      label: 'Imposto',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'profitInput',
      label: 'Lucro Operacional',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'profitCalc',
      label: 'Lucro Operacional',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'comissionInput',
      label: 'Comissão',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'comissionCalc',
      label: 'Comissão',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
    {
      field: 'presumedProfitInput',
      label: 'Lucro/Presumido',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: id != null || isLoading,
    },
    {
      field: 'presumedProfitCalc',
      label: 'Lucro/Presumido',
      placeholder: 'Escreva o valor',
      lgSize: 6,
      maxLength: 10,
      disabled: true,
    },
  ];

  const observationFieldList = [
    {
      field: 'observation',
      label: 'Observação',
      placeholder: 'Escreva alguma observação (se houver)',
      lgSize: 12,
      maxLength: 10,
      required: false,
      disabled: id != null || isLoading,
    },
  ];

  const initialize = async () => {
    setLoading(true);

    await loadDropDowns(domainConfigurations);

    if (id) {
      const apiResponse: any = await BaseService.getById(
        serviceControllers.SALES_ORDER,
        'NR_TMV_NU',
        id
      );

      const data = apiResponse.result?.dados[0];

      if (data) {
        const validityList: any = await BaseService.getById(
          serviceControllers.SALES_ORDER,
          'NR_TMV_NU',
          id,
          'GetValidity'
        );

        setSalesValidity(validityList.result?.dados);
        onUfChange(data['NR_UF_ID_LTR'], setCities);
        editingMap(data, setInitialValues);
      }
    }

    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);

    var insertParams = saveMap(data);

    BaseService.insert(serviceControllers.SALES_ORDER, insertParams).then((response: any) => {
      setLoading(false);
      if (response.return.success === true) {
        ToastService.success('Pedido de Venda salvo com sucesso!');
        navigate(`/Pedido/`);
      } else {
        ToastService.error('Erro ao salvar o pedido de venda!');
      }
    });
  };

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmModal = async () => {
    var delParams = {
      id: id,
    };
    await BaseService.del(serviceControllers.SALES_ORDER, delParams).finally(() => {
      setShowModal(false);
      ToastService.success('Pedido de venda removido com sucesso!');
      navigate('/Pedido');
    });
  };

  const getClient = async (cnpj) => {
    setLoading(true);

    const apiResponse: any = await BaseService.getById(
      serviceControllers.Client,
      'NR_CLI_ID',
      cnpj
    );

    setLoading(false);

    if (apiResponse?.return?.success) {
      return apiResponse.result.dados[0]['NM_CLI_DS'];
    }
  };

  const getValueFields = async (date) => {
    setLoading(true);

    var params = RequestParamsModelDefault;

    params.filter.field = [
      {
        id: 'NR_TMV_NU',
        value: id,
      },
      {
        id: 'DT_TMV_VIG',
        value: date,
      },
    ];

    const apiResponse: any = await BaseService.getList(
      serviceControllers.SALES_ORDER,
      params,
      'GetValueFields'
    );

    setLoading(false);

    if (apiResponse?.return?.success) {
      setInitialValues({
        ...initialValues,
        salesValues: valueFieldsMap(apiResponse.result.dados[0]),
      });
    }
  };

  const validationSchema = Yup.object().shape({
    salesOrder: Yup.object().shape({
      startDate: Yup.string()
        .required('Campo obrigatório')
        .test('valid-date', 'Data inválida', formValidateDate),
      endDate: Yup.string()
        .required('Campo obrigatório')
        .test('valid-date', 'Data inválida', formValidateDate),
      date: Yup.string()
        .required('Campo obrigatório')
        .test('valid-date', 'Data inválida', formValidateDate),
      name: Yup.string().required('Campo obrigatório'),
      cnpj: Yup.string().required('Campo obrigatório'),
    }),
  });

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });

    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });

    initialize();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <Header
        title="Cadastro de Pedido de Venda"
        buttons={buttons}
        previousPage="Listagem de Pedido de Venda"
        previousUrl={() => {
          navigate(`/PedidoVenda/`);
        }}
      />
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            onSubmit(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setValues,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Fieldset legend="Pedido">
                <Row className="align-items-center" style={{ paddingTop: 10 }}>
                  <DynamicForm
                    form="salesOrder"
                    fieldList={fieldList}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    values={values}
                  />
                </Row>
              </Fieldset>

              <Fieldset legend="Valores">
                {id && (
                  <Row style={{ paddingTop: 10 }}>
                    <Col lg={3}>
                      <Form.Group className="form-group" style={{ marginBottom: 0 }}>
                        <Form.Select
                          id="salesValues.listValues"
                          name="salesValues.listValues"
                          disabled={isLoading}
                          onChange={(event) => {
                            getValueFields(event.target.value);
                          }}
                          style={{ marginBottom: 0 }}
                        >
                          <option value="">{t('Selecione...')}</option>
                          {salesValidity?.map((item, index, array) => {
                            return (
                              <option key={item.DT_TMV_VIG} value={item.DT_TMV_VIG}>
                                {formatDate(item.DT_TMV_VIG) + ' - ' + item.DS_TMV_SIT}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Label className={`label-input-form ${!isLoading ? 'enabled ' : ''}`}>
                          {t('Vigência')}
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row style={{ paddingTop: 10 }}>
                  <Col sm={12} md={6} lg={3}>
                    <legend style={{ paddingLeft: 0, paddingBottom: 10 }}>Compra</legend>
                    <DynamicForm
                      form="salesValues"
                      fieldList={buyFieldList}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      values={values}
                    />
                  </Col>
                  <Col sm={12} md={6} lg={3}>
                    <legend style={{ paddingLeft: 0, paddingBottom: 10 }}>Encargos/ Custos</legend>
                    <DynamicForm
                      form="salesValues"
                      fieldList={chargesFieldList}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      values={values}
                    />
                  </Col>
                  <Col sm={12} md={6} lg={3}>
                    <legend style={{ paddingLeft: 0, paddingBottom: 10 }}>Benefícios</legend>
                    <DynamicForm
                      form="salesValues"
                      fieldList={benefitsFieldList}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      values={values}
                    />
                  </Col>
                  <Col sm={12} md={6} lg={3}>
                    <legend style={{ paddingLeft: 0, paddingBottom: 10 }}>Valor de Venda</legend>
                    <DynamicForm
                      form="salesValues"
                      fieldList={sellFieldList}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      values={values}
                    />
                  </Col>
                </Row>

                <div className="crossing-line"></div>

                <Row>
                  <DynamicForm
                    form="salesValues"
                    fieldList={observationFieldList}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    values={values}
                  />
                </Row>
              </Fieldset>
            </Form>
          )}
        </Formik>
      </div>
      <ConfirmationModal
        title={'Deseja realmente excluir este registro?'}
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
        children={''}
      />
    </>
  );
};
