import { useState } from "react";
import { Form, Button, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

import { ToastService } from "../../services/toast.service";
import AuthService from "../../services/auth.service";
import { ResultRequestModel } from "../../models/resultRequest.model";

const ForgotPassword = ({ setForgotPassword }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  const forgotPasswordRequest = (login: string) => {
    setLoading(true);
    AuthService.forgotPassword(login)
      .then((response: ResultRequestModel) => {
        if (response['return']['success']) {           
          ToastService.success(t("SUCCESS_FORGOT_PASSWORD"));
          setForgotPassword(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const initialValues: any = {
    username: ""
  };

  const schema = yup.object().shape({
    username: yup.string().required(t("REQUIRED"))
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values) => {
        forgotPasswordRequest(values.username);
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>{t("LOGIN")}</Form.Label>
            <Form.Control
              id="username"
              name="username"
              type="text"
              placeholder="exemplo@email.com"
              value={values.username}
              isInvalid={!!touched.username && !!errors.username}
              onChange={handleChange}
              disabled={isLoading}
            />
            <Form.Control.Feedback type="invalid">
              {errors.username}
            </Form.Control.Feedback>
          </Form.Group>
          <Row>
            <Col xs={4} sm={4}>
              <Button
                variant="link"
                disabled={isLoading}
                onClick={() => {
                  setForgotPassword();
                }}
              >
                {t("BACK")}
              </Button>
            </Col>
            <Col xs={8} sm={8}>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("RECOVER_PASSWORD")}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
export default ForgotPassword;
