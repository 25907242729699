import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonModel } from '../../models/components/button.model';
import { FaArrowLeft, FaRegSave, FaSave } from 'react-icons/fa';
import { ToastService } from '../../services/toast.service';
import { useEffect, useRef, useState } from 'react';
import { HolidayModel } from '../../models/holiday/holiday.model';
import { Header } from '../../components/Header';
import { Formik } from 'formik';
import Fieldset from '../../components/Fieldset';
import { Form, Row } from 'react-bootstrap';
import BaseService from '../../services/base.service';
import { UFModel } from '../../models/UF.model';
import moment from 'moment';
import * as Yup from 'yup';
import { getDomainListValues } from '../../util/domainFunctions';
import {
  domainTypes,
  fieldTypes,
  serviceControllers,
} from '../../util/enumerators';
import { DynamicForm } from '../../components/DynamicForm';
import { DATE_MASK } from '../../util/consts';
import { formValidateDate } from '../../util';
import { onUfChange } from '../../util/formFunctions';

export const FeriadoRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();

  const [UF, setUF] = useState<UFModel[]>();
  const [isLoading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/Feriado/`);
      },
      text: 'Voltar',
      variant: 'outline-primary',
      disabled: false,
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: 'Voltar para a página anterior',
    },
    {
      onClick: () => {
        submitFormik();
      },
      text: 'Salvar',
      disabled: false,
      icon: (
        <>
          <FaRegSave className="fa-icon" /> <FaSave className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Salvar registro',
    },
  ];

  const [initialValues, setInitialValues] = useState<HolidayModel>({
    holiday: {
      state: '',
      city: '',
      date: '',
      name: '',
    },
  });

  const fieldList = [
    {
      type: fieldTypes.SELECT,
      field: 'state',
      label: 'UF',
      onChange: (event, handleChange, setFieldValue) => {
        handleChange(event);
        onUfChange(event.target.value, setCities);
        setFieldValue('holiday.city', null);
      },
      optionsList: UF,
      lgSize: 2,
      disabled: isLoading ? true : false,
      value: 'NR_UF_ID',
      displayText: 'NR_UF_CD',
    },
    {
      type: fieldTypes.SELECT,
      field: 'city',
      label: 'Cidade',
      optionsList: cities,
      lgSize: 3,
      disabled: isLoading || cities?.length === 0,
      value: 'NR_CID_ID',
      displayText: 'NM_CID_DS',
    },
    {
      type: fieldTypes.MASKED,
      field: 'date',
      label: 'Data do Feriado',
      mask: DATE_MASK,
      lgSize: 2,
      maxLength: 10,
      disabled: isLoading,
    },
    {
      field: 'name',
      label: 'Nome do Feriado',
      placeholder: 'Escreva o nome do feriado',
      lgSize: 3,
      maxLength: 100,
      required: false,
      disabled: isLoading,
    },
  ];

  const initialize = async () => {
    setLoading(true);

    await getDomainListValues({
      domain: domainTypes.STATE,
      setList: setUF,
    });

    setLoading(false);
  };

  const onSubmit = async (data) => {
    await create(data);
  };

  const create = async (data: any) => {
    setLoading(true);

    var insertParams = {
      date: moment(data.holiday.date, 'DD/MM/YYYY').toDate(),
      city: data.holiday.city,
      state: data.holiday.state,
      name: data.holiday.name,
    };

    BaseService.insert(serviceControllers.HOLIDAY, insertParams).then(
      (response: any) => {
        setLoading(false);
        if (response.return.success === true) {
          ToastService.success('Feriado cadastrado com sucesso!');
          navigate(`/Feriado/`);
        } else {
          ToastService.error('Erro ao cadastrar feriado!');
        }
      }
    );
  };

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  const validationSchema = Yup.object().shape({
    holiday: Yup.object().shape({
      date: Yup.string()
        .required('Campo obrigatório')
        .test('valid-start-date', 'Data inválida', formValidateDate),
      name: Yup.string().required('Campo obrigatório'),
    }),
  });

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });

    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });
    initialize();
  }, []);

  return (
    <>
      <Header
        title="Cadastro de Feriado"
        buttons={buttons}
        previousPage="Listagem de Feriado"
        previousUrl={() => {
          navigate(`/Feriado/`);
        }}
      />
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            onSubmit(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Fieldset legend="Dados do Feriado">
                <Row className="align-items-center" style={{ paddingTop: 10 }}>
                  <DynamicForm
                    form="holiday"
                    fieldList={fieldList}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    values={values}
                  />
                </Row>
              </Fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
