import { serviceControllers } from "../util/enumerators";
import HttpService from "./http.service";

const basePath = serviceControllers.COLLABORATOR_TOKEN;

export const CollaboratorTokenService = {
  validToken,
  sendToken
};

async function validToken(data: string) {
  return HttpService.post(`${basePath}/ValidToken`, data);
}

async function sendToken(data) {
  return HttpService.post(`${basePath}/SendToken`, data);
}

export default CollaboratorTokenService;
