import moment from 'moment';

export interface ClientModel {
  basic: BasicClientModel;
  retention: RetentionModel;
  department: DepartmentModel[];
  manager: ManagerModel;
}
export interface BasicClientModel {
  id: number;
  cnpj: string;
  type: number;
  corporateName: string;
  alias: string;
  situation: number;
  clientChoice: number;
  supplierChoice: number;
  seller: string;
  startRange1: string;
  endRange1: string;
  startRange2: string;
  endRange2: string;
  kickback: string;
  partCLT: string;
  indPartCLT: number;
}

export interface RetentionModel {
  id: number;
  cnpj: string;
  type: number;
  effective: Date | string;
  pis: number;
  csll: number;
  confins: number;
  inss: number;
  indINSS: number;
  ir: number;
  iss: number;
  observation: string;
}

export interface ManagerModel {
  id: number;
  cnpj: string;
  name: string;
  alias: string;
  email: string;
  phone: string;
  status: string;
  //approveTS: string | boolean;
  contactName: string;
  contactEmail: string;
}
export interface DepartmentModel {
  id: number;
  cnpj: string;
  name: string;
  alias: string;
  status: string;
}

export interface DepartManagerModel {
  id: number;
  cnpj: number;
  department: number;
  manager: number;
  status: number;
}

export const BasicClientInitial: BasicClientModel = {
  id: 0,
  cnpj: '',
  type: 1,
  corporateName: '',
  alias: '',
  situation: 1,
  clientChoice: 0,
  supplierChoice: 0,
  seller: '',
  startRange1: '',
  endRange1: '',
  startRange2: '',
  endRange2: '',
  kickback: '',
  partCLT: '',
  indPartCLT: 0,
};

export const RetentionInitial: RetentionModel = {
  id: 0,
  cnpj: '',
  type: 2,
  effective: moment(new Date()).format('DD/MM/YYYY'),
  pis: 0,
  csll: 0,
  confins: 0,
  inss: 0,
  indINSS: 0,
  ir: 0,
  iss: 0,
  observation: '',
};

export const DepartmentInitial: DepartmentModel[] = [
  {
    id: 0,
    cnpj: '',
    name: '',
    alias: '',
    status: '',
  },
];

export const ManagerInitial: ManagerModel = {
  id: 0,
  name: '',
  alias: '',
  email: '',
  phone: '',
  status: '1',
  //approveTS: '0',
  cnpj: '',
  contactName: '',
  contactEmail: '',
};
