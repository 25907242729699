import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonModel } from '../../models/components/button.model';
import {
  FaArrowLeft,
  FaCalendarTimes,
  FaRegCalendarTimes,
  FaRegSave,
  FaSave,
} from 'react-icons/fa';
import { ToastService } from '../../services/toast.service';
import { useEffect, useRef, useState } from 'react';
import { Header } from '../../components/Header';
import { Formik } from 'formik';
import Fieldset from '../../components/Fieldset';
import { Col, Form, Row } from 'react-bootstrap';
import BaseService from '../../services/base.service';
import * as Yup from 'yup';
import { formMode, serviceControllers } from '../../util/enumerators';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import DadosBasicos from './base';
import RetencaoCliente from './retention/retention';
import {
  BasicClientInitial,
  ClientModel,
  DepartmentInitial,
  ManagerInitial,
  RetentionInitial,
} from '../../models/cliente.model';
import { clientMapSave, editMapping } from './client.mapping';
import { retentionDataMapping, retentionMapSave } from './retention/retention.mapping';
import { ClientRetentionService } from '../../services/client.retention.service';
import DepartmentClientList from './department';

export const ClientRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const { id, mode } = useParams<{ id?: string; mode?: string }>();

  const rootUrl = '/Cliente';
  const [clientId, setClientId] = useState(Number.parseInt(id));
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<ClientModel>({
    basic: BasicClientInitial,
    retention: RetentionInitial,
    department: DepartmentInitial,
    manager: ManagerInitial,
  });

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`${rootUrl}`);
      },
      text: 'Voltar',
      variant: 'outline-primary',
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: 'Voltar para a página anterior',
    },
    {
      onClick: () => {
        submitFormik();
      },
      text: 'Salvar',
      icon: (
        <>
          <FaRegSave className="fa-icon" /> <FaSave className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Salvar cliente / fornecedor',
      hide: mode === formMode.VIEW,
    },
  ];

  const initialize = async () => {
    setLoading(true);
    loadForEdit();
    setLoading(false);
  };

  const loadForEdit = async () => {
    if ((mode === formMode.REGISTER || mode === formMode.VIEW) && id) {
      const apiResponse: any = await BaseService.getById(
        serviceControllers.CLIENT,
        'NR_DOC_ID',
        id
      );

      if (apiResponse.return?.success) {
        if (apiResponse.result != null) {
          const data = apiResponse.result.dados[0];
          editMapping(data, setInitialValues);
        }
      }
      const retentionResponse: any = await ClientRetentionService.getById(
        serviceControllers.CLIENT_RETENTION,
        [
          {
            id: 'NR_DOC_ID',
            value: id,
          },
          {
            id: 'CD_RET_TP',
            value: 2,
          },
          {
            id: 'FL_STATUS',
            value: 1,
          },
        ]
      );
      if (retentionResponse.return?.success) {
        if (retentionResponse.result != null) {
          const data = retentionResponse.result.dados[0];
          retentionDataMapping(data, setInitialValues);
        }
      }
    }
  };

  const onSubmit = async (data: ClientModel) => {
    setLoading(true);
    var clientSaveObject = clientMapSave(data);

    await BaseService.insert(serviceControllers.CLIENT, clientSaveObject).then((response: any) => {
      if (response.return.success === true) {
        if (data.retention.cnpj === '') {
          navigate(rootUrl);
          ToastService.success('Dados salvos com sucesso!');
        }
      } else {
        ToastService.error('Erro ao salvar dados do colaborador.');
      }
    });
    if (data.retention.cnpj !== '') {
      var clientRetentionSaveObject = retentionMapSave(data);

      await BaseService.insert(serviceControllers.CLIENT_RETENTION, clientRetentionSaveObject).then(
        (response: any) => {
          if (response.return.success === true) {
            ToastService.success('Dados salvos com sucesso!');
            navigate(rootUrl);
          } else {
            ToastService.error('Erro ao salvar dados do colaborador.');
          }
        }
      );
    }
    setLoading(false);
  };

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  /*const handleConfirmModal = async () => {
    var delParams = {
      id: id,
    };

    await BaseService.del(serviceControllers.CLIENT, delParams).then((response: any) => {
      if (response.return.success === true) {
        navigate(rootUrl);
        ToastService.success('Cliente excluído com sucesso!');
      } else {
        ToastService.error('Erro ao excluir o cliente!');
      }
      setShowModal(false);
    });
  };*/

  const validationSchema = Yup.object().shape({
    basic: Yup.object().shape({
      cnpj: Yup.string().required('Campo obrigatório'),
      situation: Yup.number().required('Campo Obrigatório'),
      corporateName: Yup.string().required('Campo obrigatório'),
      alias: Yup.string().required('Campo obrigatório'),
    }),
    retention: Yup.object().shape({
      pis: Yup.number().required('Campo Obrigatório'),
      csll: Yup.number().required('Campo Obrigatório'),
      confins: Yup.number().required('Campo Obrigatório'),
      inss: Yup.number().required('Campo Obrigatório'),
      ir: Yup.number().required('Campo Obrigatório'),
      iss: Yup.number().required('Campo Obrigatório'),
    }),
  });

  useEffect(() => {
    // dispatch({
    //   type: 'LOAD_FILTERS',
    //   filtersList: [],
    // });

    // dispatch({
    //   type: 'SEARCH_FILTERS',
    //   filtersToSearch: [],
    // });
    initialize();
  }, []);

  return (
    <>
      <Header
        title="Cadastro de Cliente e Fornecedor"
        buttons={buttons}
        previousPage="Listagem de Clientes e Fornecedores"
        previousUrl={() => {
          navigate(`/Cliente/`);
        }}
      />
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            onSubmit(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setValues,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Col xs={8}>
                  <DadosBasicos
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Col>
                <Col xs={4}>
                  <RetencaoCliente
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    previousUrl={() => {
                      navigate(`/Cliente/`);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Fieldset legend="">
                    <DepartmentClientList idClient={clientId} />
                  </Fieldset>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
