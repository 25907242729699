import HttpService from './http.service';
import { ListRequest } from '../models/requestParams.model';

//Somente funções que estão no base controller
export const ClientRetentionService = {
  getById,
  getList,
  insert,
  del,
};

interface IValues {
  id: string;
  value: any;
}

async function getById(
  basePath: string,
  values: IValues[],
  endPointPath?: string
) {
  const withFilter = {
    filter: {
      field: [...values],
    },
  };

  let endPoint = endPointPath ? endPointPath : 'GetWithFilter';

  return HttpService.post(`${basePath}/${endPoint}`, withFilter);
}

async function getList(
  basePath: string,
  data: ListRequest,
  endPointPath?: string
) {
  let endPoint = endPointPath ? endPointPath : 'GetWithFilter';
  return HttpService.post(`${basePath}/${endPoint}`, data);
}

async function insert(basePath: string, data: any) {
  return HttpService.post(`${basePath}/Insert`, data);
}

async function del(basePath: string, data: any) {
  return HttpService.post(`${basePath}/Delete`, data);
}

export default ClientRetentionService;
