import HttpService from './http.service';
import { RequestClosingForEditParamsModel } from '../models/requestClosingForEdit.model';
import { ListRequest } from '../models/requestParams.model';
import { serviceControllers } from '../util/enumerators';
const basePath = serviceControllers.CLOSING;

export const ClosingService = {
  getById,
  getOptionsList,
  getHtml,
  getPDF,
  approveItems,
  reproveItems,
  sendAttachments,
};

async function getById(data: RequestClosingForEditParamsModel) {
  return HttpService.post(`${basePath}/GetForEdit`, data);
}

async function getOptionsList(data: ListRequest) {
  return HttpService.post(`${basePath}/GetOptionsFilter`, data);
}

async function getHtml(data: ListRequest) {
  return HttpService.post(`${basePath}/GetReport`, data);
}

async function getPDF(data: ListRequest) {
  return HttpService.post(`${basePath}/GetPDF`, data, { responseType: 'arraybuffer' });
}

async function approveItems(data) {
  return HttpService.post(`${basePath}/ApproveItems`, data);
}

async function reproveItems(data: ListRequest) {
  return HttpService.post(`${basePath}/ReproveItems`, data);
}

async function sendAttachments(data, reqParams: ListRequest) {
  const formData = new FormData();

  data.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  formData.append('reqParams', JSON.stringify(reqParams));

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return HttpService.post(`${basePath}/Attachment`, formData, config);
}

export default ClosingService;
