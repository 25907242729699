import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Image, Dropdown } from 'react-bootstrap';
import { FaBars, FaBell, FaRegUserCircle, FaRegBuilding } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FilterAutocomplete } from './filterAutocomplete/FilterAutocomplete';
import { ModalChangePassword } from './ModalChangePassword';

import { Menu as MenuModel } from '../models/menu.model';
import { StoreModel } from '../models/store.model';

import Logo from '../assets/logo-text.png';
import AuthService from '../services/auth.service';
import { menuMapping, returnCurrentEnterprise } from '../util';
import { ToastService } from '../services/toast.service';

interface PropsTopBar {
  hideControls?: boolean;
}

export const TopBar = ({ hideControls }: PropsTopBar) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showChangePassword, setShowChangePassword] = useState(false);
  const { menuCollapsed } = useSelector((state: MenuModel) => state);
  const authentication = useSelector((state: StoreModel) => state.authentication);
  const companyLogo = useSelector((state: StoreModel) => state.companyLogo);

  const handleCollapsedChange = () => {
    dispatch({ type: 'COLLAPSE_CHANGE', menuCollapsed: !menuCollapsed });
  };

  const handleSelectCompany = async (company) => {
    let requestParams = {
      User: (authentication.user = {
        ...authentication.user,
        enterpriseId: company?.cnpj,
      }),
    };

    try {
      const apiResponse: any = await AuthService.refreshToken(requestParams);

      if (apiResponse.message === 'success') {
        const menuList = menuMapping(apiResponse?.returnData);
        const enterprise = returnCurrentEnterprise(authentication);

        authentication.token = apiResponse.token;

        dispatch({
          type: 'SELECT_COMPANY',
          company: {
            companyId: company?.cnpj,
            companyName: company?.name,
            companyLogo: enterprise?.imgLogo,
            companyMenu: menuList,
          },
        });

        navigate('/HomeList');
      }
    } catch (error) {
      ToastService.error('Erro ao buscar informações da empresa selecionada!');
    }
  };

  const handleShowChangePassword = () => {
    setShowChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setShowChangePassword(false);
  };

  const Logout = () => {
    dispatch({
      type: 'LOGOUT',
    });
    navigate('/Home');
  };

  return (
    <>
      <Row className="top-bar" style={{ marginLeft: 0, marginRight: 0 }}>
        <Col xs={4} sm={3} md={2} lg={2} xl={3} className="d-flex align-items-center">
          <Col xs={3} sm={3} md={2} xl={3} className="align-items-center justify-content-center">
            {!hideControls && (
              <FaBars
                className="icon-menu"
                onClick={() => handleCollapsedChange()}
                id="btn-hide-controls"
              />
            )}
          </Col>
          <Col xs={9} sm={8} md={9} lg={6} xl={8}>
            {hideControls ? (
              <Image src={Logo} style={{ width: '150px', maxWidth: 'none' }} />
            ) : (
              <Link to={'/HomeList'} id="company-logo-link">
                <Image
                  src={`data:image/png;base64,${companyLogo}`}
                  style={{ width: '150px', maxWidth: 'none' }}
                />
              </Link>
            )}
          </Col>
        </Col>

        <Col xs={5} sm={7} md={6} lg={6} xl={7} className="pl-0">
          <FilterAutocomplete />
        </Col>

        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>

        {!hideControls && (
          <Col xs={2} sm={1} md={3} xl={1} className="d-flex justify-content-end">
            <Dropdown>
              <Dropdown.Toggle className="nav-link-top" id="dropdown-companies">
                <FaRegBuilding />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {authentication?.enterprises?.map((item, idx) => (
                  <Dropdown.Item key={idx} onClick={() => handleSelectCompany(item)}>
                    {item?.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <>
              <Dropdown>
                <Dropdown.Toggle className="nav-link-top" id="dropdown-notifications">
                  <FaBell />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Sem notificações no momento</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle className="nav-link-top" id="dropdown-user-options">
                  <FaRegUserCircle />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleShowChangePassword()}
                    id="btn-change-password"
                  >
                    Alterar Senha
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => Logout()} id="btn-change-logout">
                    {t('LOGOUT')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          </Col>
        )}
      </Row>

      <ModalChangePassword
        show={showChangePassword}
        handleClose={handleCloseChangePassword}
        id="modal-change-password"
      />
    </>
  );
};
