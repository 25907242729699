/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import '../../../scss/custom.css';
import { Header } from '../../../components/Header';
import Fieldset from '../../../components/Fieldset';

import { OrgaoEmissorModel } from '../../../models/orgaoEmissor.model';
import { UFModel } from '../../../models/UF.model';
import { TabelaConfiguracaoModel } from '../../../models/TabelaConfiguracao.model';
import { NacionalidadeModel } from '../../../models/nacionalidade.model';
import { BankModel } from '../../../models/banco.model';
import { ButtonModel } from '../../../models/components/button.model';
import { CollaboratorModel } from '../../../models/collaborator/colaborador.model';
import { ToastService } from '../../../services/toast.service';
import { useDispatch } from 'react-redux';
import { RegistryModel } from '../../../models/Registry.model';
import moment from 'moment';
import {
  FaArrowLeft,
  FaSave,
  FaRegSave,
  FaClipboard,
  FaRegClipboard,
  FaRegMoneyBillAlt,
  FaMoneyBillAlt,
} from 'react-icons/fa';
import { GrMailOption, GrMail } from 'react-icons/gr';

import 'react-datepicker/dist/react-datepicker.css';
import {
  IsNullOrEmpty,
  IsValidEmail,
  formValidateDate,
  formatCurrencyFromDb,
  formatDate,
  formatStringToDate,
} from '../../../util';
import CollaboratorTokenService from '../../../services/collaboratorToken.service';
import { getDomainListValues, loadDropDowns } from '../../../util/domainFunctions';
import { domainTypes, fieldTypes, formMode, serviceControllers } from '../../../util/enumerators';
import {
  CELLPHONE_MASK,
  CEP_MASK,
  CNPJ_MASK,
  CPF_MASK,
  CURRENCY_MASK,
  DATE_MASK,
  TABELA_COLABORADOR,
  TABELA_REGISTRO,
} from '../../../util/consts';
import BaseService from '../../../services/base.service';
import {
  collaboratorFieldsMap,
  collaboratorSaveMap,
  editingMap,
  registerFieldsMap,
  registerSaveMap,
  remunerationFieldsMap,
  remunerationSaveMap,
} from './columnMapping';
import { DynamicForm } from '../../../components/DynamicForm';
import { ColaboradorDadosModelDefault } from '../../../models/collaborator/colaboradorDados.model';
import { ColaboradorRegistroModelDefault } from '../../../models/collaborator/colaboradorRegistro.model';
import { ColaboradorRemuneracaoModelDefault } from '../../../models/collaborator/colaboradorRemuneracao.model';
import CollaboratorService from '../../../services/collaborator.service';
import { ListRequest, RequestParamsModel } from '../../../models/requestParams.model';
import RemunerationService from '../../../services/remuneration.service';
import * as Yup from 'yup';

export const ColaboradorRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams<{ token?: string }>();
  const { id, mode } = useParams<{ id?: string; mode?: string }>();
  const [isLoading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [registrySelected, setRegistrySelected] = useState(null);
  const [remunerationSelected, setRemunerationSelected] = useState(null);
  const [completeScreen, setCompleteScreen] = useState(true);
  const [isSelfRegister, setIsSelfRegister] = useState(false);
  const [hideButton, setHideButton] = useState(true);
  const [issuingAuthorities, setIssuingAuthorities] = useState<OrgaoEmissorModel[]>();
  const [UF, setUF] = useState<UFModel[]>();
  const [gender, setGender] = useState<TabelaConfiguracaoModel[]>();
  const [maritalStatus, setMaritalStatus] = useState<TabelaConfiguracaoModel[]>();
  const [nationalities, setNationalities] = useState<NacionalidadeModel[]>();
  const [banks, setBanks] = useState<BankModel[]>();
  const [accountTypes, setAccountTypes] = useState<TabelaConfiguracaoModel[]>();
  const [cities, setCities] = useState([]);
  const [registerCities, setRegisterCities] = useState([]);
  const [modalities, setModalities] = useState([]);
  const [lotacoes, setLotacoes] = useState([]);
  const [horaTrabalho, setHoraTrabalho] = useState([]);
  const [collectionCenters, setCollectionCenters] = useState([]);
  const [remunerations, setRemunerations] = useState([]);
  const [timesheet, setTimesheet] = useState([]);
  const [bancoHora, setBancoHora] = useState([]);
  const [registryDropdown, setRegistryDropdown] = useState<RegistryModel[]>([]); //Listagem de registros
  const [remunerationDropdown, setRemunerationDropdown] = useState([]);
  const [disableRegisterFields, setDisableRegisterFields] = useState(true);
  const [disableBeneficiary, setDisablebeneficiaryFields] = useState(true);
  const [disableRemunerationFields, setDisableRemunerationFields] = useState(true);
  const formRef = useRef();
  const rootUrl = '/Colaborador';

  const [initialValues, setInitialValues] = useState<CollaboratorModel>({
    colaborador: ColaboradorDadosModelDefault,
    registro: ColaboradorRegistroModelDefault,
    remuneracao: ColaboradorRemuneracaoModelDefault,
  });

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/Colaborador/`);
      },
      text: 'Voltar',
      variant: 'outline-primary',
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: 'Voltar para a página anterior',
      hide: token !== undefined,
    },
    {
      onClick: async () => {
        if (!formRef || !formRef.current) {
          return;
        }

        const values = formRef.current['values']['colaborador'];

        let cpf: string = values['cpf'];
        cpf = cpf?.replace(/\D/g, '');

        const name: string = values['name'];
        const email: string = values['email'];

        const requestParams = {
          cpf: cpf,
          name: name,
          email: email,
        };

        const apiResponse: any = await CollaboratorTokenService.sendToken(requestParams);

        if (apiResponse?.return?.success) {
          ToastService.success('Colaborador adicionado com sucesso!');

          setTimeout(() => {
            navigate(`/Colaborador/`);
          }, 1000);
        } else {
          ToastService.error('Erro ao adicionar Colaborador');
        }
      },
      text: 'Auto-Cadastro',
      variant: 'outline-primary',
      disabled: !isSelfRegister,
      icon: (
        <>
          <GrMailOption className="fa-icon" />
          <GrMail className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Enviar email de auto-cadastro',
      hide: id !== undefined || token !== undefined,
    },
    {
      onClick: () => {
        newRegistration();
      },
      text: 'Novo Registro',
      icon: (
        <>
          <FaRegClipboard className="fa-icon" />
          <FaClipboard className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Criar novo registro',
      hide: mode === formMode.VIEW || token !== undefined,
    },
    {
      onClick: async () => {
        await closeRegistration(initialValues);
      },
      text: 'Encerrar Registro',
      icon: (
        <>
          <FaRegClipboard className="fa-icon" />
          <FaClipboard className="fa-icon-hover fa-icon-color-red" />
        </>
      ),
      tooltip: 'Encerrar registro',
      hide: mode === formMode.VIEW || token !== undefined || hideButton,
    },
    {
      onClick: () => {
        newRemuneration();
      },
      text: 'Nova Remun.',
      icon: (
        <>
          <FaRegMoneyBillAlt className="fa-icon" />
          <FaMoneyBillAlt className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Criar nova remuneração',
      hide: mode === formMode.VIEW || token !== undefined,
    },
    {
      onClick: () => {
        submitFormik();
      },
      text: 'Salvar',
      icon: (
        <>
          <FaRegSave className="fa-icon" /> <FaSave className="fa-icon-hover" />
        </>
      ),
      tooltip: id ? 'Salvar alterações' : 'Salvar',
      hide: mode === formMode.VIEW,
    },
  ];

  const fieldList = [
    {
      type: fieldTypes.MASKED,
      field: 'cpf',
      label: 'CPF',
      mask: CPF_MASK,
      onChange: async (event, handleChange, setFieldValue, values) => {
        handleChange(event);

        setIsSelfRegister(
          verifySelfRegister(event.target.value, values.colaborador.name, values.colaborador.email)
        );
      },
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW || token || id,
    },
    {
      field: 'name',
      label: 'Nome',
      placeholder: 'Escreva o nome completo',
      onChange: async (event, handleChange, setFieldValue, values) => {
        handleChange(event);

        setIsSelfRegister(
          verifySelfRegister(values.colaborador.cpf, event.target.value, values.colaborador.email)
        );
      },
      lgSize: 4,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      field: 'email',
      label: 'Email',
      placeholder: 'Ex: exemplo@email.com',
      onChange: async (event, handleChange, setFieldValue, values) => {
        handleChange(event);

        setIsSelfRegister(
          verifySelfRegister(values.colaborador.cpf, values.colaborador.name, event.target.value)
        );
      },
      lgSize: 6,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      field: 'motherName',
      label: 'Nome da Mãe',
      placeholder: 'Nome completo da mãe',
      lgSize: 6,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      type: fieldTypes.MASKED,
      field: 'phone',
      label: 'Celular',
      mask: CELLPHONE_MASK,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      type: fieldTypes.MASKED,
      field: 'dtBirth',
      label: 'Nascimento',
      mask: DATE_MASK,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      field: 'rg',
      label: 'RG',
      placeholder: 'Número do documento',
      lgSize: 2,
      maxLength: 12,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      type: fieldTypes.SELECT,
      field: 'issuingAuthority',
      label: 'Órgão Emissor',
      optionsList: issuingAuthorities,
      lgSize: 4,
      disabled: isLoading || mode === formMode.VIEW,
      value: 'NR_ORG_ID',
      displayText: 'NM_ORG_DS',
    },
    {
      type: fieldTypes.SELECT,
      field: 'ufIssuingAuthority',
      label: 'UF Órgão Emissor',
      optionsList: UF,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
      value: 'NR_UF_ID',
      displayText: 'NR_UF_CD',
    },
    {
      type: fieldTypes.SELECT,
      field: 'nationality',
      label: 'Nacionalidade',
      optionsList: nationalities,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
      value: 'NR_NAC_ID',
      displayText: 'NM_NAC_DS',
    },
    {
      type: fieldTypes.SELECT,
      field: 'maritalStatus',
      label: 'Estado Civil',
      optionsList: maritalStatus,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      type: fieldTypes.SELECT,
      field: 'gender',
      label: 'Sexo',
      optionsList: gender,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      type: fieldTypes.MASKED,
      field: 'cep',
      label: 'CEP',
      onChange: (event, handleChange, setFieldValue) => {
        handleChange(event);

        handlePostalCodeChange(event.target.value.replace(/\D/g, ''), setFieldValue);
      },
      mask: CEP_MASK,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      field: 'neighborhood',
      label: 'Bairro',
      placeholder: 'Ex. Centro',
      lgSize: 4,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      type: fieldTypes.SELECT,
      field: 'state',
      label: 'UF',
      onChange: (event, handleChange, setFieldValue) => {
        handleChange(event);
        onUfChange(event.target.value);
        setFieldValue('colaborador.city', null);
      },
      optionsList: UF,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
      value: 'NR_UF_ID',
      displayText: 'NR_UF_CD',
    },
    {
      type: fieldTypes.SELECT,
      field: 'city',
      label: 'Cidade',
      optionsList: cities,
      lgSize: 4,
      disabled: isLoading || cities?.length === 0 || mode === formMode.VIEW,
      value: 'NR_CID_ID',
      displayText: 'NM_CID_DS',
    },
    {
      field: 'street',
      label: 'Logradouro',
      placeholder: 'Ex. Av. Paulista',
      lgSize: 6,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      field: 'streetNumber',
      label: 'Número',
      placeholder: 'Número da residência',
      lgSize: 2,
      maxLength: 6,
      disabled: isLoading || mode === formMode.VIEW,
      type: 'number',
    },
    {
      field: 'complement',
      label: 'Complemento',
      placeholder: 'Ex. Ap 105',
      lgSize: 4,
      maxLength: 20,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      type: fieldTypes.SELECT,
      field: 'accountType',
      label: 'Tipo de Conta',
      onChange: (event, handleChange, setFieldValue) => {
        handleChange(event);
        handleAccountTypeChange(event.target.value, setFieldValue);
      },
      optionsList: accountTypes,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      type: fieldTypes.SELECT,
      field: 'bank',
      label: 'Banco',
      optionsList: banks,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
      value: 'NR_BCO_ID',
      displayText: 'NM_BCO_DS',
    },
    {
      field: 'agency',
      label: 'Agência',
      lgSize: 2,
      maxLength: 4,
      disabled: isLoading || mode === formMode.VIEW,
      type: 'number',
    },
    {
      field: 'account',
      label: 'Conta',
      placeholder: 'Número',
      lgSize: 1,
      maxLength: 10,
      disabled: isLoading || mode === formMode.VIEW,
      type: 'number',
    },
    {
      field: 'digit',
      label: 'Dígito',
      lgSize: 1,
      maxLength: 1,
      disabled: isLoading || mode === formMode.VIEW,
      type: 'number',
    },
    {
      type: fieldTypes.MASKED,
      field: 'beneficiaryDocument',
      label: 'CPF do Titular',
      mask: CPF_MASK,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW || disableBeneficiary,
    },
    {
      field: 'beneficiaryName',
      label: 'Nome do Titular',
      lgSize: 2,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW || disableBeneficiary,
    },
  ];

  const registerFieldList = [
    {
      field: 'salesOrder',
      label: 'Pedido de Venda',
      placeholder: 'Número do Pedido de Venda',
      lgSize: 2,
      maxLength: 60,
      disabled: true,
    },
    {
      field: 'clientName',
      label: 'Nome do Cliente',
      lgSize: 4,
      maxLength: 60,
      disabled: true,
    },
    {
      type: fieldTypes.MASKED,
      field: 'clientId',
      label: 'CNPJ do Cliente',
      mask: CNPJ_MASK,
      lgSize: 3,
      disabled: true,
    },
    {
      type: fieldTypes.MASKED,
      field: 'registerDate',
      label: 'Data do Registro',
      mask: DATE_MASK,
      lgSize: 3,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
    },
    {
      type: fieldTypes.SELECT,
      field: 'modality',
      label: 'Modalidade',
      onChange: (event, handleChange, setFieldValue, values) => {
        handleChange(event);
        rumunerationCalc(values, event.target.value, setFieldValue);
      },
      optionsList: modalities,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
      value: 'ID_MOD_CTR',
      displayText: 'DS_MOD_CTR',
    },
    {
      type: fieldTypes.SELECT,
      field: 'capacity',
      label: 'Lotação',
      optionsList: lotacoes,
      lgSize: 4,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
      value: 'NR_LTC_ID',
      displayText: 'NM_LTC_DS',
    },
    {
      field: 'workPlace',
      label: 'Local de Trabalho',
      placeholder: 'Endereço do trabalho',
      lgSize: 3,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
    },
    {
      type: fieldTypes.SELECT,
      field: 'workSchedule',
      label: 'Horário de Trabalho',
      optionsList: horaTrabalho,
      lgSize: 3,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
      value: 'NR_HRT_ID',
      displayText: 'NM_HTR_DS',
    },
    {
      type: fieldTypes.SELECT,
      field: 'uf',
      label: 'UF',
      onChange: (event, handleChange, setFieldValue) => {
        handleChange(event);
        onUfChange(event.target.value, true);
        setFieldValue('registro.city', null);
      },
      optionsList: UF,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
      value: 'NR_UF_ID',
      displayText: 'NR_UF_CD',
    },
    {
      type: fieldTypes.SELECT,
      field: 'city',
      label: 'Cidade',
      optionsList: registerCities,
      lgSize: 4,
      disabled:
        isLoading ||
        registerCities?.length === 0 ||
        mode === formMode.VIEW ||
        disableRegisterFields,
      value: 'NR_CID_ID',
      displayText: 'NM_CID_DS',
    },
    {
      type: fieldTypes.SELECT,
      field: 'collectionCenter',
      label: 'Central de Captação',
      optionsList: collectionCenters,
      lgSize: 3,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      type: fieldTypes.SELECT,
      field: 'remuneration',
      label: 'Remuneração',
      optionsList: remunerations,
      lgSize: 3,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      type: fieldTypes.SELECT,
      field: 'timesheet',
      label: 'Timesheet',
      optionsList: timesheet,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      field: 'accessLogin',
      label: 'Login de Acesso',
      placeholder: 'Ex.: nome.sobrenome',
      lgSize: 4,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
    },
    {
      type: fieldTypes.SELECT,
      field: 'annualLeave',
      label: 'Banco de Horas',
      optionsList: bancoHora,
      lgSize: 3,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      field: 'expertise',
      label: 'Especialidade',
      lgSize: 3,
      maxLength: 30,
      disabled: isLoading || mode === formMode.VIEW || disableRegisterFields,
    },
  ];

  const createRemunerationField = (field, label, size = 2, disabled = false, onBlur = true) => ({
    type: fieldTypes.CURRENCY,
    field,
    label,
    mask: CURRENCY_MASK,
    onBlur: async (event, handleChange, setFieldValue, values) => {
      if (onBlur) {
        handleChange(event);
        rumunerationCalc(values, null, setFieldValue);
      }
    },
    lgSize: size,
    disabled: disabled || isLoading || mode === formMode.VIEW || disableRemunerationFields,
  });

  const remunerationFieldList = [
    {
      type: fieldTypes.MASKED,
      field: 'effectiveDate',
      label: 'Data da Vigência',
      mask: DATE_MASK,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW || disableRemunerationFields,
    },
    {
      field: 'monthBaseHours',
      label: 'Hora Base',
      lgSize: 2,
      maxLength: 3,
      disabled: isLoading || mode === formMode.VIEW || disableRemunerationFields,
      type: 'number',
    },
  ];

  const remunerationAreaFieldList = [
    createRemunerationField('salary', 'Salário Total', 4, true, false),
    createRemunerationField('clt', 'CLT', 4),
    createRemunerationField('da', 'DA', 4),
    createRemunerationField('pj', 'PJ', 4),
    createRemunerationField('flex', 'FLEX', 4),
    createRemunerationField('cooper', 'Cooperado', 4),
  ];

  const chargesAreaFieldList = [
    createRemunerationField('charges', 'Encargos', 4, true, false),
    createRemunerationField('ilatiCost', 'Custo ILATI', 4, true, false),
    createRemunerationField('rhCost', 'Custo RH', 4, true, false),
    createRemunerationField('indirectCost', 'Custo Indireto', 4),
    createRemunerationField('subsistenceAllowance', 'Ajuda de Custo', 4),
    createRemunerationField('totalCost', 'Custo Total', 4, true, false),
  ];

  const benefitsAreaFieldList = [
    createRemunerationField('healthCare', 'Assist. Médica'),
    createRemunerationField('dentalCare', 'Assist. Odontológica'),
    createRemunerationField('mealTicket', 'Vale Refeição'),
    createRemunerationField('foodStamps', 'Vale Alimentação'),
    createRemunerationField('fuelVoucher', 'Vale Combustível'),
    createRemunerationField('transportationVoucher', 'Vale Transporte'),
    createRemunerationField('plr', 'PLR', 3, true, false),
    createRemunerationField('lifeInsurance', 'Seguro de Vida', 3, true, false),
    createRemunerationField('otherbenefits', 'Outros Benefícios', 3),
    createRemunerationField('totalBenefits', 'Total de Benefícios', 3, true),
  ];

  const domainConfigurations = [
    {
      domain: domainTypes.ISSUING_AUTHORITY,
      setList: setIssuingAuthorities,
    },
    {
      domain: domainTypes.STATE,
      setList: setUF,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_COLABORADOR,
      column: 'NR_SEX_CD',
      setList: setGender,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_COLABORADOR,
      column: 'NR_CIV_CD',
      setList: setMaritalStatus,
    },
    {
      domain: domainTypes.NATIONALITY,
      setList: setNationalities,
    },
    {
      domain: domainTypes.BANK,
      setList: setBanks,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_COLABORADOR,
      column: 'IND_BPF_TP',
      setList: setAccountTypes,
    },
    {
      domain: domainTypes.MODALITY,
      setList: setModalities,
    },
    {
      domain: domainTypes.CAPACITY,
      setList: setLotacoes,
    },
    {
      domain: domainTypes.WORK_SCHEDULE,
      setList: setHoraTrabalho,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_REGISTRO,
      column: 'CD_CNL_CPT',
      setList: setCollectionCenters,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_REGISTRO,
      column: 'IND_TIP_RMN',
      setList: setRemunerations,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_REGISTRO,
      column: 'IND_PTO_TIP',
      setList: setTimesheet,
    },
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_REGISTRO,
      column: 'IND_PTO_BHR',
      setList: setBancoHora,
    },
  ];

  const initialize = async () => {
    setLoading(true);

    if (!token) await loadDropDowns(domainConfigurations);

    await loadForEdit();

    setLoading(false);
  };

  const loadForEdit = async () => {
    if (token) {
      checkToken();
    } else if ((mode === formMode.REGISTER || mode === formMode.VIEW) && id) {
      try {
        const apiResponse: any = await BaseService.getById(
          serviceControllers.COLLABORATOR,
          'NR_CLB_ID',
          id,
          '',
          ['COLABORADOR']
        );

        if (apiResponse.return?.success) {
          if (apiResponse.result != null) {
            const data = apiResponse.result.dados[0];
            const { NR_CLB_ID, NM_CLB_DS, DS_CLB_EMAIL, ID_LGR_UF } = data;

            onUfChange(ID_LGR_UF);
            editingMap(data, setInitialValues);

            //ativa botao de auto-cadastro se campos preenchidos
            const isSelfRegister = verifySelfRegister(NR_CLB_ID, NM_CLB_DS, DS_CLB_EMAIL);

            setIsSelfRegister(isSelfRegister);

            await loadRegistryDropDown();
          }
        }
      } catch (error) {
        ToastService.error('Erro ao consultar informações do Colaborador.');
      }
    }
  };

  const checkToken = async () => {
    try {
      setCompleteScreen(false);

      const apiResponse: any = await BaseService.getById(
        serviceControllers.COLLABORATOR_TOKEN,
        'DS_TKN_TOKEN',
        token,
        'GetData',
        ['COLABORADOR_TOKEN']
      );

      if (apiResponse?.return?.success) {
        const data = apiResponse.result.dados[0];

        let isExpired = new Date(data.DT_TKN_EXP) < new Date();

        if (isExpired) {
          navigate(`/Token/Expirado`);
          return;
        }

        await loadDropDowns(domainConfigurations, {
          idEnterprise: data['NR_EMP_ID'],
          idUser: data['ID_USR_INC'],
        });

        await onUfChange(data?.['ID_LGR_UF']);

        setDisablebeneficiaryFields(data?.['IND_BPF_TP'] !== 4);

        setInitialValues((prevValues) => ({
          ...prevValues,
          colaborador: collaboratorFieldsMap(data),
        }));
      } else {
        navigate(`/Token/Expirado`);
        return;
      }
      return;
    } catch (error) {
      navigate(`/Token/Expirado`);
      return;
    } finally {
      setLoading(false);
    }
  };

  const loadRegistryDropDown = async () => {
    try {
      const apiResponse: any = await BaseService.getById(
        serviceControllers.REGISTER,
        'NR_CLB_ID',
        id,
        '',
        ['REGISTRO']
      );

      if (apiResponse.return?.success) {
        const data = apiResponse.result.dados;
        const last = data.find((x) => !x.DT_REG_SDA);
        const selectedItem = last ? last : data[0];

        setHideButton(last ? false : true);
        setRegistrySelected(selectedItem.DT_REG_ENT);
        setRegistryDropdown(data);
        setDisableRegisterFields(true);

        onUfChange(selectedItem?.NF_UF_ID_LTR, true);

        setInitialValues((prevValues) => ({
          ...prevValues,
          registro: registerFieldsMap(selectedItem),
        }));

        await loadRemunaration(selectedItem.DT_REG_ENT);
      }
    } catch (error) {
      ToastService.error('Erro ao consultar informações de Registro.');
    }
  };

  const loadRemunaration = async (registry, validity = null) => {
    try {
      let flterParams: ListRequest = RequestParamsModel('REGISTRO_VALOR');
      flterParams.filter = {
        field: [
          {
            id: 'NR_CLB_ID',
            value: id,
          },
          {
            id: 'DT_REG_ENT',
            value: registry,
          },
          {
            id: 'DT_REG_VIG',
            value: new Date(formatStringToDate(validity)),
          },
        ],
      };

      const apiResponse: any = await BaseService.getList(
        serviceControllers.REMUNERATION,
        flterParams
      );

      const data = apiResponse?.result?.dados;

      if (data) {
        const { DT_REG_VIG_LISTA } = data[0];

        let remunerationDropDownItems;
        if (DT_REG_VIG_LISTA?.includes('|')) {
          remunerationDropDownItems = DT_REG_VIG_LISTA?.split('|');
        } else {
          remunerationDropDownItems = [DT_REG_VIG_LISTA];
        }

        const formattedDates = remunerationDropDownItems.map((dateString) => {
          let str = dateString.replace('-', '/');
          return formatDate(str);
        });

        setRemunerationSelected(validity ? validity : formattedDates[0]);
        setRemunerationDropdown(formattedDates);
      } else {
        setRemunerationSelected(null);
        setRemunerationDropdown(null);
      }

      setInitialValues((prevValues) => ({
        ...prevValues,
        remuneracao: remunerationFieldsMap(data ? data[0] : null),
      }));
    } catch (error) {
      ToastService.error('Erro ao consultar informações de remuneração.');
    }
  };

  const verifySelfRegister = (cpf: string, name: string, email: string): boolean => {
    if (!IsNullOrEmpty(cpf) && !IsNullOrEmpty(name) && !IsNullOrEmpty(email)) {
      if (IsValidEmail(email)) return true;
    }
    return false;
  };

  const onUfChange = async (idUF: any, registro: boolean = false) => {
    return await getDomainListValues(
      {
        domain: domainTypes.CITY,
        column: 'NR_UF_ID',
        value: idUF,
        setList: registro ? setRegisterCities : setCities,
      },
      token && {
        idEnterprise: initialValues.colaborador.idEnterprise,
        idUser: initialValues.colaborador.idUser,
      }
    );
  };

  const handlePostalCodeChange = async (data, setFieldValue) => {
    if (data.length === 8) {
      setLoading(true);
      try {
        const apiResponse: any = await CollaboratorService.getDataByPostalCode(data);

        if (apiResponse) {
          let uf = UF.find(
            (x) => x.NR_UF_CD.toUpperCase() === apiResponse?.stateCode?.toUpperCase()
          );

          let ufId = uf?.['NR_UF_ID'];
          let list = await onUfChange(ufId ?? '');

          let city = list?.find(
            (x) => x.NM_CID_DS.toUpperCase() === apiResponse?.city?.toUpperCase()
          );
          let cityId = city?.['NR_CID_ID'];

          setFieldValue('colaborador.state', ufId ?? '');
          setFieldValue('colaborador.city', cityId ?? '');
          setFieldValue('colaborador.neighborhood', apiResponse?.district ?? '');
          setFieldValue('colaborador.street', apiResponse?.street ?? '');
        }
      } catch (error) {
        ToastService.error('Erro ao consultar o CEP informado.');
      }

      setLoading(false);
    }
  };

  const handleAccountTypeChange = async (data, setFieldValue) => {
    if (data === '4') {
      setDisablebeneficiaryFields(false);
    } else {
      setDisablebeneficiaryFields(true);

      setFieldValue('colaborador.beneficiaryDocument', '');
      setFieldValue('colaborador.beneficiaryName', '');
    }
  };

  const onRegistryChange = async (data) => {
    setLoading(true);
    setDisableRegisterFields(true);

    if (data) {
      const item = registryDropdown.find((x) => x.DT_REG_ENT === data);

      onUfChange(item?.NF_UF_ID_LTR, true);

      setInitialValues((prevValues) => ({
        ...prevValues,
        registro: registerFieldsMap(item),
      }));

      await loadRemunaration(item.DT_REG_ENT);
    }

    setLoading(false);
  };

  const onRemunerationChange = async (data) => {
    setLoading(true);
    setDisableRemunerationFields(true);

    if (data) await loadRemunaration(registrySelected, data);

    setLoading(false);
  };

  const newRegistration = () => {
    setDisableRegisterFields(false);
    setHideButton(true);
    setRegistrySelected(undefined);
    setDisableRemunerationFields(false);
    setRemunerationDropdown([]);
    onUfChange(undefined, true);

    setInitialValues((prevValues) => ({
      ...prevValues,
      registro: ColaboradorRegistroModelDefault,
      remuneracao: ColaboradorRemuneracaoModelDefault,
    }));
  };

  const closeRegistration = async (data) => {
    try {
      var params = {
        id: 1,
        CollaboratorId: id,
        registerDate: moment(data.registro.registerDate, 'DD/MM/YYYY').toDate(),
        closeDate: moment().toDate(),
      };

      const apiResponse: any = await BaseService.insert(serviceControllers.REGISTER, params);

      if (apiResponse.return?.success) {
        ToastService.success('Registro encerrado com sucesso!');
        navigate(`${rootUrl}/${formMode.REGISTER}/${data.colaborador.idCollaborator}`);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        ToastService.error('Falha ao encerrar o registro!');
      }
    } catch (error) {
      ToastService.error('Falha ao encerrar o registro!');
    }
  };

  const newRemuneration = () => {
    if (!initialValues.registro.modality) {
      ToastService.warn('O campo Modalidade precisa estar preenchido!', {
        autoClose: 1500,
      });

      return;
    }

    setDisableRemunerationFields(false);
    setRemunerationSelected(undefined);

    setInitialValues((prevValues) => ({
      ...prevValues,
      remuneracao: ColaboradorRemuneracaoModelDefault,
    }));
  };

  const rumunerationCalc = async (data, selectedModality = null, setFieldValue = null) => {
    if (!data?.registro.modality && !selectedModality) {
      ToastService.warn('O campo Modalidade precisa estar preenchido!', {
        autoClose: 1500,
      });

      return;
    }

    if (!data.colaborador.cpf) {
      ToastService.warn('O campo CPF precisa estar preenchido!', {
        autoClose: 1500,
      });

      return;
    }

    try {
      var params = remunerationSaveMap(data, selectedModality);

      const apiResponse: any = await RemunerationService.getRumunerationCalc(params);

      if (apiResponse?.return?.success) {
        const data = apiResponse.result.dados[0];

        setFieldValue('remuneracao.salary', formatCurrencyFromDb(data?.['VL_CLB_TOT']));
        setFieldValue('remuneracao.clt', formatCurrencyFromDb(data?.['VL_CLB_SAL_CLT']));
        setFieldValue('remuneracao.da', formatCurrencyFromDb(data?.['VL_CLB_SAL_DA']));
        setFieldValue('remuneracao.pj', formatCurrencyFromDb(data?.['VL_CLB_SAL_PJ']));
        setFieldValue('remuneracao.flex', formatCurrencyFromDb(data?.['VL_CLB_SAL_FLX']));
        setFieldValue('remuneracao.cooper', formatCurrencyFromDb(data?.['VL_CLB_SAL_COOP']));
        setFieldValue('remuneracao.charges', formatCurrencyFromDb(data?.['VL_CLB_CST_CLT']));
        setFieldValue('remuneracao.ilatiCost', formatCurrencyFromDb(data?.['VL_CLB_CST_DA']));
        setFieldValue('remuneracao.rhCost', formatCurrencyFromDb(data?.['VL_CLB_CST_RH']));
        setFieldValue('remuneracao.indirectCost', formatCurrencyFromDb(data?.['VL_CLB_CST_IND']));
        setFieldValue(
          'remuneracao.subsistenceAllowance',
          formatCurrencyFromDb(data?.['VL_CLB_BNF_AJC'])
        );
        setFieldValue('remuneracao.totalCost', formatCurrencyFromDb(data?.['VL_CST_TOT']));
        setFieldValue('remuneracao.healthCare', formatCurrencyFromDb(data?.['VL_CLB_BNF_AM']));
        setFieldValue('remuneracao.dentalCare', formatCurrencyFromDb(data?.['VL_CLB_BNF_AO']));
        setFieldValue('remuneracao.mealTicket', formatCurrencyFromDb(data?.['VL_CLB_BNF_VR']));
        setFieldValue('remuneracao.foodStamps', formatCurrencyFromDb(data?.['VL_CLB_BNF_VA']));
        setFieldValue('remuneracao.fuelVoucher', formatCurrencyFromDb(data?.['VL_CLB_BNF_VC']));
        setFieldValue(
          'remuneracao.transportationVoucher',
          formatCurrencyFromDb(data?.['VL_CLB_BNF_VT'])
        );
        setFieldValue('remuneracao.plr', formatCurrencyFromDb(data?.['VL_CLB_BNF_PLR']));
        setFieldValue('remuneracao.lifeInsurance', formatCurrencyFromDb(data?.['VL_CLB_BNF_SV']));
        setFieldValue('remuneracao.otherbenefits', formatCurrencyFromDb(data?.['VL_CLB_BNF_OTR']));
        setFieldValue('remuneracao.totalBenefits', formatCurrencyFromDb(data?.['VL_BNF_TOT']));
      }
    } catch (error) {
      ToastService.error('Erro ao calcular informações de Remuneração');
    }
    setLoading(false);
  };

  const onSubmit = async (data: CollaboratorModel) => {
    setLoading(true);

    var collaboratorParams = collaboratorSaveMap(data, token, saved);

    try {
      await CollaboratorService.insert(collaboratorParams).then(async (response: any) => {
        if (response.return.success === true) {
          ToastService.success('Dados do Colaborador salvos com sucesso!');
          if (token) {
            navigate(`/Token/Sucesso`);
            return;
          }

          setSaved(true);
          if (data.registro.id === 0 && !disableRegisterFields) {
            var registerParams = registerSaveMap(data);

            //TODO: APAGAR APOS FINALIZACAO DA TELA
            try {
              await BaseService.insert(serviceControllers.REGISTER, registerParams).then(
                async (response: any) => {
                  if (response.return.success === true) {
                    ToastService.success('Registro salvo com sucesso!');
                    setDisableRegisterFields(true);

                    if (data.remuneracao.id === 0 && !disableRemunerationFields) {
                      var remunerationParams = remunerationSaveMap(data);

                      //TODO: APAGAR APOS FINALIZACAO DA TELA
                      try {
                        await BaseService.insert(
                          serviceControllers.REMUNERATION,
                          remunerationParams
                        ).then((response: any) => {
                          if (response.return.success === true) {
                            setDisableRemunerationFields(true);
                            ToastService.success('Remuneração salva com sucesso!');
                            navigate(
                              `${rootUrl}/${formMode.REGISTER}/${data.colaborador.cpf?.replace(
                                /[^\d]/g,
                                ''
                              )}`
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 500);
                          } else {
                            ToastService.error('Erro ao salvar novo Remuneração!');
                          }
                        });
                      } catch (error) {
                        ToastService.error(
                          'Erro na inserção da remuneração, após inserir corretamente o registro!'
                        );
                      }
                    }
                  } else {
                    setDisableRegisterFields(false);
                    ToastService.error('Erro ao salvar novo Registro!');
                  }
                }
              );
            } catch (error) {
              ToastService.error('Erro na inserção do registro!');
            }
          } else if (data.remuneracao.id === 0 && !disableRemunerationFields) {
            var remunerationParams = remunerationSaveMap(data);

            try {
              await BaseService.insert(serviceControllers.REMUNERATION, remunerationParams).then(
                (response: any) => {
                  if (response.return.success === true) {
                    setDisableRemunerationFields(true);
                    ToastService.success('Remuneração salva com sucesso!');
                    navigate(
                      `${rootUrl}/${formMode.REGISTER}/${data.colaborador.cpf?.replace(
                        /[^\d]/g,
                        ''
                      )}`
                    );
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  } else {
                    setDisableRemunerationFields(false);
                    ToastService.error('Erro ao salvar nova Remuneração!');
                  }
                }
              );
            } catch (error) {
              ToastService.error('Erro na inserção da remuneração!');
            }
          }
        } else {
          ToastService.error('Erro ao salvar dados do colaborador.');
        }
      });
    } catch (error) {
      ToastService.error('Erro ao salvar as informações. Verifique o preenchimento dos campos');
    }

    setLoading(false);
  };

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });

    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });

    initialize();
  }, []);

  const dateValidation = Yup.string().test('valid-date', 'Data inválida', formValidateDate);

  const validationSchema = Yup.object().shape({
    colaborador: Yup.object().shape({
      dtBirth: dateValidation.nullable(),
    }),

    registro: Yup.object().shape({
      registerDate: dateValidation.nullable(),
      accessLogin: Yup.string()
        .test('valid-login', 'Login inválido', function (value) {
          if (value) {
            const regex = /^[a-zA-Z]+[.][a-zA-Z]+$/;

            return regex.test(value);
          }
          return true;
        })
        .nullable(),
    }),
    remuneracao: Yup.object().shape({
      effectiveDate: dateValidation.nullable(),
    }),
  });

  return (
    <>
      <Header
        title="Gerenciamento de Colaborador"
        buttons={buttons}
        showBread={token ? false : true}
      />
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            onSubmit(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Fieldset legend="Dados do Colaborador">
                <Row className="align-items-center">
                  <DynamicForm
                    form="colaborador"
                    fieldList={fieldList}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    values={values}
                  />
                </Row>
              </Fieldset>

              {completeScreen && (
                <>
                  <Fieldset legend="Registro">
                    <Row>
                      <Col sm={12} md={6} lg={3}>
                        <Form.Group className={`form-group ${registrySelected ? 'selected' : ''}`}>
                          <Form.Select
                            id="registryList"
                            name="registryList"
                            required
                            disabled={isLoading || mode === formMode.VIEW}
                            onChange={(event) => {
                              setRegistrySelected(event.target.value);
                              onRegistryChange(event.target.value);
                            }}
                            value={registrySelected || ''}
                          >
                            <option value="" disabled={true}>
                              Selecione...
                            </option>
                            {registryDropdown?.map((registry, index, array) => {
                              return (
                                <option key={index} value={registry.DT_REG_ENT}>
                                  {registry.NM_APL_CLI_DS} {' - '} {registry.DS_MOD_CTR} {' - '}
                                  {moment(registry.DT_REG_ENT).format('DD/MM/YYYY')}
                                  {registry.DT_REG_SDA &&
                                    ' - ' + moment(registry.DT_REG_SDA).format('DD/MM/YYYY')}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Label
                            className={`label-input-form ${
                              !isLoading && mode !== formMode.VIEW ? 'enabled ' : ''
                            }${registrySelected ? 'selected' : ''}`}
                          >
                            Registro
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="align-items-center">
                      <DynamicForm
                        form="registro"
                        fieldList={registerFieldList}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        values={values}
                      />
                    </Row>
                  </Fieldset>

                  <Fieldset legend="Remuneração">
                    <Row>
                      <Col sm={12} md={6} lg={2}>
                        <Form.Group
                          className={`form-group ${remunerationSelected ? 'selected' : ''}`}
                        >
                          <Form.Select
                            id="remunerationList"
                            name="remunerationList"
                            required
                            disabled={isLoading || mode === formMode.VIEW}
                            onChange={(event) => {
                              setRemunerationSelected(event.target.value);
                              onRemunerationChange(event.target.value);
                            }}
                            value={remunerationSelected || ''}
                          >
                            <option value="" disabled={true}>
                              Selecione...
                            </option>
                            {remunerationDropdown?.map((remuneration, index, array) => {
                              return (
                                <option key={index} value={remuneration}>
                                  {remuneration}
                                </option>
                              );
                            })}
                          </Form.Select>
                          <Form.Label
                            className={`label-input-form ${
                              !isLoading && mode !== formMode.VIEW ? 'enabled ' : ''
                            }${remunerationSelected ? 'selected' : ''}`}
                          >
                            Remuneração
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <DynamicForm
                        form="remuneracao"
                        fieldList={remunerationFieldList}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        values={values}
                      />
                    </Row>

                    <Row>
                      <Col sm={12} md={12} lg={6}>
                        <Fieldset legend="Remuneração">
                          <Row>
                            <DynamicForm
                              form="remuneracao"
                              fieldList={remunerationAreaFieldList}
                              touched={touched}
                              errors={errors}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              values={values}
                            />
                          </Row>
                        </Fieldset>
                      </Col>
                      <Col sm={12} md={12} lg={6}>
                        <Fieldset legend="Encargos / Outros Custos">
                          <Row>
                            <DynamicForm
                              form="remuneracao"
                              fieldList={chargesAreaFieldList}
                              touched={touched}
                              errors={errors}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              values={values}
                            />
                          </Row>
                        </Fieldset>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <Fieldset legend="Benefícios">
                          <Row>
                            <DynamicForm
                              form="remuneracao"
                              fieldList={benefitsAreaFieldList}
                              touched={touched}
                              errors={errors}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              values={values}
                            />
                          </Row>
                        </Fieldset>
                      </Col>
                    </Row>
                  </Fieldset>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
