import { BasicClientModel, ClientModel, RetentionModel } from '../../models/cliente.model';
import { IsNullOrEmpty, formatCpfCnpj, removeFormatting } from '../../util';

export const editMapping = (data, setInitialValues) => {
  const basicData: BasicClientModel = {
    //idEnterprise: data["NR_EMP_ID"],
    //idUser: data["ID_USR_INC"],
    id: data['NR_DOC_ID'],
    cnpj: formatCpfCnpj(data['NR_DOC_ID']),
    type: data['NR_DOC_TP'],
    corporateName: data['NM_NOM_DS'],
    alias: data['NM_APL_DS'],
    situation: data['NR_SIT_CD'],
    clientChoice: data['CD_IND_CLI'],
    supplierChoice: data['CD_IND_FOR'],
    seller: data['NR_VEN_ID'],
    startRange1: data['PC_MVN_FX1_INI'],
    endRange1: data['PC_MVN_FX1_FIM'],
    startRange2: data['PC_MVN_FX2_INI'],
    endRange2: data['PC_MVN_FX2_FIM'],
    kickback: data['PC_VEN_TMC'],
    partCLT: data['PC_CLT_ALQ'],
    indPartCLT: data['IND_CLT_ALQ'],
  };

  setInitialValues((prev) => ({
    ...prev,
    basic: basicData,
  }));
};

export const clientMapSave = (data: ClientModel) => {
  const saveClientObj = {
    id: data.basic.id,
    cnpj: removeFormatting(data.basic.cnpj),
    type: 2,
    corporateName: data.basic.corporateName,
    situation: data.basic.situation,
    alias: data.basic.alias,
    clientChoice: 1, //data.basic.clientChoice[0] === 'on' ? 1 : 0,
    supplierChoice: 0, //data.basic.supplierChoice[0] === 'on' ? 1 : 0,
    seller: data.basic.seller,
    startRange1: data.basic.startRange1,
    endRange1: data.basic.endRange1,
    startRange2: data.basic.startRange2,
    endRange2: data.basic.endRange2,
    kickback: data.basic.kickback,
    partCLT: data.basic.partCLT,
    indPartCLT: IsNullOrEmpty(data.basic.partCLT) ? 0 : 1,
  };
  return saveClientObj;
};
