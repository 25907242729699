import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit, FaEdit, FaRegFileExcel, FaFileExcel } from 'react-icons/fa';

import { ButtonModel } from '../../models/components/button.model';
import { serviceControllers } from '../../util/enumerators';
import ListPageComponent from '../../components/ListPageComponent';
import { ExportFunctions } from '../../util/ExportFunctions';
import { ListRequest, RequestParamsModelDefault } from '../../models/requestParams.model';

export const VacationUserList = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [selectedVacation, setSelectedVacation] = useState<any>();
  const [dataset, setDataset] = useState<ListRequest>(RequestParamsModelDefault);

  const rootUrl = '/Ferias';
  const serviceController = serviceControllers.VACATION;
  const itemKey = 'RH_COLABORADOR_NR_CLB_ID';
  const legend = 'Listagem de Férias';

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`${rootUrl}/${selectedVacation}`);
      },
      text: 'Editar',
      disabled: !Number.isInteger(selectedVacation),
      icon: (
        <>
          <FaRegEdit className="fa-icon" />
          <FaEdit className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Editar férias selecionada',
    },
    {
      onClick: async () => {
        setLoading(true);

        dataset.result = null;
        dataset.parameter = null;

        await ExportFunctions.downloadExcel(serviceController, dataset);

        setLoading(false);
      },
      text: 'Exportar',
      disabled: isLoading,
      icon: (
        <>
          <FaRegFileExcel className="fa-icon" />
          <FaFileExcel className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Exportar dados para Excel',
    },
  ];

  return (
    <ListPageComponent
      idDataset="FERIAS"
      serviceController={serviceController}
      legend={legend}
      selectedItem={selectedVacation}
      setSelectedItem={setSelectedVacation}
      buttons={buttons}
      referenceColumn={itemKey}
      externalDataSet={dataset}
      setExternalDataSet={setDataset}
    />
  );
};
