import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const CurrencyInput = (props) => {
  const defaultMaskOptions = {
    prefix: 'R$',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 6,
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  return (
    <MaskedInput
      {...props}
      mask={currencyMask}
      placeholderChar={'\u2000'}
      showMask
      className="form-control form-control-sm"
    />
  );
};

export default CurrencyInput;
