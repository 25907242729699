import { useEffect, useRef, useState } from 'react';
import { Header } from '../../../components/Header';
import { Form, Formik } from 'formik';
import { FaRegSave, FaSave } from 'react-icons/fa';
import { fieldTypes, serviceControllers } from '../../../util/enumerators';
import { DynamicForm } from '../../../components/DynamicForm';
import * as Yup from 'yup';
import { IDepartForm, departmentMapSave } from './department.mapping';
import BaseService from '../../../services/base.service';
import { ToastService } from '../../../services/toast.service';
import { ListRequest, RequestParamsModel } from '../../../models/requestParams.model';
import ClientDepartmentService from '../../../services/client.department.service';

interface IDepartmentEdit {
  idClient: number;
  idDepart: number | boolean;
  refreshList: (datasource: ListRequest, filter: boolean) => void;
  handleClose: any;
}

const DepartmentEdit = ({ idClient, idDepart, refreshList, handleClose }: IDepartmentEdit) => {
  const formRef = useRef();
  const [initialValues, setInitialValues] = useState<IDepartForm>({
    department: {
      cnpj: idClient.toString(),
      id: 0,
      name: '',
      alias: '',
      status: '1',
    },
  });

  const handleSave = async (values: IDepartForm) => {
    var departSaveObject = departmentMapSave(values);

    await BaseService.insert(serviceControllers.CLIENT_DEPARTMENT, departSaveObject).then(
      (response: any) => {
        if (response.return.success === true) {
          ToastService.success('Dados salvos com sucesso!');
          refreshList(RequestParamsModel('DEPTO'), true);
          handleClose();
        } else {
          ToastService.error('Erro ao salvar dados do departamento.');
        }
      }
    );
  };

  const btnTools = [
    {
      onClick: () => {
        submitFormik();
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FaRegSave className="fa-icon" />
          <FaSave className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Salvar / Atualizar',
    },
  ];

  const statusOptions = [
    {
      value: '1',
      label: 'Ativo',
    },
    {
      value: '0',
      label: 'Inativo',
    },
  ];

  const fieldsForm = [
    {
      field: 'name',
      label: 'Nome',
      placeholder: 'Nome completo do departamento',
      lgSize: 12,
    },
    {
      field: 'alias',
      label: 'Apelido',
      placeholder: 'Apelido do departamento',
      lgSize: 12,
    },
    {
      type: fieldTypes.SELECT,
      field: 'status',
      label: 'Status',
      optionsList: statusOptions,
      lgSize: 12,
      value: 'value',
      displayText: 'label',
    },
  ];

  const validationSchema = Yup.object().shape({
    department: Yup.object().shape({
      name: Yup.string().required('Campo obrigatório'),
      alias: Yup.string().required('Campo obrigatório'),
      status: Yup.string().test('valid-status', 'Status Invalido', function (value) {
        return value === '1' || value === '0';
      }),
    }),
  });

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  useEffect(() => {
    const getDepartment = async () => {
      //if (idDepart !== true && idDepart !== undefined && idDepart) {
      if (typeof idDepart === 'number') {
        await ClientDepartmentService.getById(serviceControllers.CLIENT_DEPARTMENT, [
          {
            id: 'ID_CLIENTE_DEPTO',
            value: idDepart,
          },
          {
            id: 'NR_CLI_ID',
            value: idClient,
          },
        ]).then((response: any) => {
          if (response.result?.dados) {
            setInitialValues({
              department: {
                cnpj: response.result?.dados[0].NR_CLI_ID,
                id: response.result?.dados[0].ID_CLIENTE_DEPTO,
                name: response.result?.dados[0].NM_CDP_DS,
                alias: response.result?.dados[0].NM_CDP_APL,
                status: response.result?.dados[0].CD_STATUS ? '1' : '0',
              },
            });
          }
        });
      }
    };
    getDepartment();
  }, [idDepart, idClient]);

  return (
    <>
      <Header
        title="Cadastro de Cliente e Fornecedor"
        buttons={btnTools}
        previousPage="Listagem de Clientes e Fornecedores"
        previousUrl={() => {}}
        showBread={false}
      />
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleSave(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setValues,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <DynamicForm
                fieldList={fieldsForm}
                form="department"
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                values={values}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default DepartmentEdit;
