import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const Unauthorized = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <main>
            <section className="vh-100 d-flex align-items-center justify-content-center">
                <Container>
                    <Row>
                        <Col
                            xs={12}
                            className="text-center d-flex align-items-center justify-content-center"
                        >
                            <div>
                                <h1 className="text-primary mt-5">
                                    {t('UNATHORIZED.PAGE')}{' '}
                                    <span className="fw-bolder">
                                        {t('UNATHORIZED.UNATHORIZED')}
                                    </span>
                                </h1>
                                <p className="lead my-4">
                                    {t('UNATHORIZED.RESOURCE_UNATHORIZED')}
                                </p>
                                <Button
                                    variant="primary"
                                    className="animate-hover"
                                    onClick={() => {
                                        navigate('/HomeList');
                                    }}
                                >
                                    {t('UNATHORIZED.BACK_TO_HOME_PAGE')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
export default Unauthorized;