import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { format } from 'date-fns';
import { ListRequest } from '../models/requestParams.model';
import BaseService from '../services/base.service';

export const ExportFunctions = {
  downloadExcel,
  downloadPDF,
};

async function downloadExcel(basePath: string, dataset: ListRequest, idDataset = '') {
  let datasetTmp = {
    filter: { ...dataset.filter },
    dataset: { ...dataset.dataset },
  };

  let idx = datasetTmp.dataset?.data?.findIndex((d) => d.idDataset === idDataset);

  if (idx !== -1) {
    datasetTmp.dataset.data[idx].pageNumber = -1;
  } else {
    datasetTmp.dataset.data.forEach((dataset) => {
      dataset.pageNumber = -1;
    });
  }

  BaseService.getExcel(basePath, datasetTmp).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', formattedDate() + `${basePath}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
}

const exportPDF = async (htmlTable: string) => {
  const headerPDF = [];
  headerPDF.push(
    Array.from(document.getElementById(htmlTable).querySelectorAll('th')).map(
      (it: Node, idx: number) => ({
        id: `${it.textContent}.${idx}`,
        name: it.textContent,
        prompt: it.textContent,
        width: 65,
        align: 'center',
        padding: 0,
      })
    )
  );
  console.log('Cabeçalho', headerPDF);
  const dataTablePDF = parseHTMLTableElem(document.getElementById(htmlTable));
  console.log(dataTablePDF);
  var doc = new jsPDF({
    putOnlyUsedFonts: true,
    orientation: 'landscape',
    unit: 'pt',
    format: 'a0',
  });
  doc.setFontSize(9);
  doc.setFont('helvetica', 'normal', '400');
  doc.table(1, 1, dataTablePDF, headerPDF[0], { autoSize: true });
  doc.save('teste.pdf');
};

async function downloadPDF(htmlId: string) {
  //exportPDF(htmlId);
  const htmlImage = document.getElementById(htmlId);
  console.log(htmlImage.innerText);
  console.log(htmlImage.innerHTML);
  const imageHeight = htmlImage.clientHeight;
  const imageWidth = htmlImage.clientWidth;
  var doc = new jsPDF('l', 'mm', 'a0');
  const listFonts = doc.getFontList();
  const fontSize = doc.getFontSize();
  doc.setFontSize(9);
  doc.setFont('helvetica', 'normal', '400');

  // var pdfjs = document.getElementById(htmlId);
  //const pdf = new jsPDF('l', 'pt', 'a4');
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;

  const totalPages = Math.ceil(imageHeight / pageHeight);

  let y = 0;

  // for (let i = 0; i < totalPages; i++) {
  //   const canvas = await html2canvas(htmlImage, {
  //     //height: pageHeight,
  //     height: pageHeight,
  //     width: 10000,
  //     //y: -y + i * pageHeight,
  //   });
  //   const imgData = canvas.toDataURL('image/png');
  //   doc.addImage(imgData, 'PNG', 0, 0, 0, 0, undefined, 'FAST');
  //   if (i !== totalPages - 1) {
  //     doc.addPage();
  //   }
  // }

  // doc.save(formattedDate() + '_resumo_fechamento.pdf');

  // Convert HTML to PDF in JavaScript
  doc.html(htmlImage, {
    callback: async function (doc) {
      doc.save(formattedDate() + '_resumo_fechamento.pdf');
    },
    x: 10,
    y: 10,
    autoPaging: 'slice',
    width: htmlImage.clientWidth,
    windowWidth: htmlImage.clientWidth,
  });

  /*doc.html(pdfjs, {
    callback: function (doc) {
      doc.save(formattedDate() + '_resumo_fechamento2.pdf');
    },
    x: 20,
    y: 0,
    autoPaging: 'slice',
    margin: 40,
  });*/
}

function formattedDate() {
  const now = new Date();
  const customFormattedDate = format(now, 'yyyyMMddHHmmss');
  return customFormattedDate;
}

const testCell = () => {
  var generateData = function (amount) {
    var result = [];
    var data: any = {
      coin: '100',
      game_group: 'GameGroup',
      game_name: 'XPTO2',
      game_version: '25',
      machine: '20485861',
      vlt: '0',
    };
    for (var i = 0; i < amount; i += 1) {
      data.id = (i + 1).toString();
      result.push(Object.assign({}, data));
    }
    return result;
  };

  function createHeaders(keys) {
    var result = [];
    for (var i = 0; i < keys.length; i += 1) {
      result.push({
        id: keys[i],
        name: keys[i],
        prompt: keys[i],
        width: 65,
        align: 'center',
        padding: 0,
      });
    }
    return result;
  }

  var headers = createHeaders([
    'id',
    'coin',
    'game_group',
    'game_name',
    'game_version',
    'machine',
    'vlt',
  ]);

  var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'landscape' });
  doc.table(1, 1, generateData(100), headers, { autoSize: true });
  doc.save('teste.pdf');
};

const parseHTMLTableElem = (tableEl) => {
  const columns = Array.from(tableEl.querySelectorAll('th')).map((it: Node) => it.textContent);
  const rows: NodeList = tableEl.querySelectorAll('tbody > tr');
  return Array.from(rows).map((row: any) => {
    const cells: any = Array.from(row.querySelectorAll('td'));
    return columns.reduce((obj, col, idx) => {
      obj[col] = cells[idx].textContent;
      return obj;
    }, {});
  });
};
