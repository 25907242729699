import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaRegEdit,
  FaEdit,
  FaRegEye,
  FaEye,
  FaUserPlus,
  FaRegCalendarTimes,
  FaCalendarTimes,
} from 'react-icons/fa';

import { ButtonModel } from '../../models/components/button.model';

import { formMode, serviceControllers } from '../../util/enumerators';
import ListPageComponent from '../../components/ListPageComponent';

export const SalesOrderList = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedItemSalesOrder, setSelectedItemSalesOrder] = useState<any>();

  const rootUrl = '/PedidoVenda';
  const serviceController = serviceControllers.SALES_ORDER;
  const itemKey = 'NR_TMV_NU';
  const legend = 'Listagem de Pedidos de Venda';

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.VIEW}/${selectedItemSalesOrder}`);
      },
      text: 'Visualizar',
      disabled: !Number.isInteger(selectedItemSalesOrder),
      icon: (
        <>
          <FaRegEye className="fa-icon" />
          <FaEye className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Visualizar dados do pedido de venda',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}/${selectedItemSalesOrder}`);
      },
      text: 'Editar',
      disabled: !Number.isInteger(selectedItemSalesOrder),
      icon: (
        <>
          <FaRegEdit className="fa-icon" />
          <FaEdit className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Editar pedido de venda',
    },
    {
      onClick: () => {
        setShowModal(true);
      },
      text: 'Cancelar',
      disabled: !Number.isInteger(selectedItemSalesOrder),
      icon: (
        <>
          <FaRegCalendarTimes className="fa-icon" />
          <FaCalendarTimes className="fa-icon-hover fa-icon-color-red" />
        </>
      ),
      tooltip: 'Cancear pedido de venda',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}`);
      },
      text: 'Adicionar',
      icon: <FaUserPlus className="fa-icon-color-green" />,
      tooltip: 'Adicionar novo pedido de venda',
    },
  ];

  const handleDelParams = () => {
    var delParams = {
      id: selectedItemSalesOrder,
    };

    return delParams;
  };

  return (
    <ListPageComponent
      idDataset={'VENDA'}
      serviceController={serviceController}
      legend={legend}
      selectedItem={selectedItemSalesOrder}
      setSelectedItem={setSelectedItemSalesOrder}
      buttons={buttons}
      referenceColumn={itemKey}
      delParams={handleDelParams}
      showModal={showModal}
      setShowModal={setShowModal}
    />
  );
};
