import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ToastService = {
  success,
  error,
  info,
  warn,
  normal,
  dark,
};

const options: ToastOptions = {
  position: 'top-right',
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

function success(message: string) {
  toast.success(message, { ...options, toastId: 'success-toast' });
}

function normal(message: string) {
  toast(message, { ...options, toastId: 'default-toast' });
}

function dark(message: string) {
  toast.dark(message, { ...options, toastId: 'dark-toast' });
}

function error(message: string) {
  toast.error(message, { ...options, toastId: 'error-toast' });
}

function info(message: string, options: ToastOptions) {
  toast.info(message, { ...options, toastId: 'info-toast' });
}

function warn(message, options) {
  toast.warn(message, { ...options, toastId: 'warn-toast' });
}

export { ToastContainer, ToastService };
