import { useState } from 'react';
import ListPageComponent from '../../components/ListPageComponent';
import { formMode, serviceControllers } from '../../util/enumerators';
import { ButtonModel } from '../../models/components/button.model';
import { FaCalendarTimes, FaEdit, FaPlus, FaRegCalendarTimes, FaRegEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

export const ProfileList = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [showModal, setShowModal] = useState(false);

  const rootUrl = '/Perfil';
  const legend = 'Listagem de Perfis';
  const itemKey = 'ID_PERFIL';
  const serviceController = serviceControllers.PROFILE;
  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}`);
      },
      text: 'Adicionar',
      icon: <FaPlus className="fa-icon-color-green" />,
      tooltip: 'Adicionar novo perfil',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.EDIT}/${selectedItem}`);
      },
      text: 'Editar',
      disabled: !Number.isInteger(selectedItem),
      icon: (
        <>
          <FaRegEdit className="fa-icon" />
          <FaEdit className="fa-icon-hover fa-icon-color-green" />
        </>
      ),
      tooltip: 'Editar perfil selecionado',
    },
    {
      onClick: () => {
        setShowModal(true);
      },
      text: 'Excluir',
      disabled: !Number.isInteger(selectedItem),
      icon: (
        <>
          <FaRegCalendarTimes className="fa-icon" />
          <FaCalendarTimes className="fa-icon-hover fa-icon-color-red" />
        </>
      ),
      tooltip: 'Excluir perfil selecionado',
    },
  ];

  const handleDelParams = () => {
    return {
      id: selectedItem,
    };
  };

  return (
    <ListPageComponent
      idDataset={'PERFIL'}
      serviceController={serviceController}
      legend={legend}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      buttons={buttons}
      referenceColumn={itemKey}
      delParams={handleDelParams}
      deleteSuccessMessage={t('PROFILE_DELETE_SUCCESS')}
      showModal={showModal}
      setShowModal={setShowModal}
    />
  );
};
