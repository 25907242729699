export interface ColaboradorRemuneracaoModel {
  id: number;
  registerDate: any;
  effectiveDate?: any;
  monthBaseHours: number;
  salary?: any;
  clt?: any;
  da?: any;
  pj?: any;
  flex?: any;
  cooper?: any;
  charges?: any;
  ilatiCost?: any;
  rhCost?: any;
  indirectCost?: any;
  subsistenceAllowance?: any;
  totalCost?: any;
  healthCare?: any;
  dentalCare?: any;
  mealTicket?: any;
  foodStamps?: any;
  fuelVoucher?: any;
  transportationVoucher?: any;
  plr?: any;
  lifeInsurance?: any;
  otherbenefits?: any;
  totalBenefits?: any;
}

export const ColaboradorRemuneracaoModelDefault: ColaboradorRemuneracaoModel = {
  id: 0,
  registerDate: '',
  effectiveDate: '',
  monthBaseHours: 0,
  salary: 0,
  clt: 0,
  da: 0,
  pj: 0,
  flex: 0,
  cooper: 0,
  charges: 0,
  ilatiCost: 0,
  rhCost: 0,
  indirectCost: 0,
  subsistenceAllowance: 0,
  totalCost: 0,
  healthCare: 0,
  dentalCare: 0,
  mealTicket: 0,
  foodStamps: 0,
  fuelVoucher: 0,
  transportationVoucher: 0,
  plr: 0,
  lifeInsurance: 0,
  otherbenefits: 0,
  totalBenefits: 0,
};
