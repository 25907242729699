import { ListRequest } from '../models/requestParams.model';
import BaseService from '../services/base.service';
import DomainService from '../services/domain.service';

export const fetchMoreData = (dataset, getList, idDataset = '') => {
  let datasetTmp = {
    filter: { ...dataset.filter },
    dataset: { ...dataset.dataset },
  };

  let idx = datasetTmp.dataset?.data?.findIndex((d) => d.idDataset === idDataset);

  if (idx !== -1) {
    datasetTmp.dataset.data[idx].pageNumber = datasetTmp.dataset?.data[idx].pageNumber + 1;
  }

  getList(datasetTmp);
};

export const FilterAndListBuilder = async (
  serviceType,
  data,
  filters,
  setTableItems,
  tableItems,
  setDataset,
  setGridColumns,
  isFilter,
  dispatch,
  selectAll = null,
  setItensSelecionados = null
) => {
  const requestParams: ListRequest = filterParamsBuilder(data, filters);

  const apiResponse: any = await BaseService.getList(serviceType, requestParams);

  if (apiResponse?.return?.success) {
    const { data } = apiResponse.dataset;
    const searchFilters = apiResponse.filter.field;

    let columns = data[0].field?.filter((f) => f.showGrid === true);
    setGridColumns(columns);

    let options = searchFilters
      .filter((f) => f.showFilter === true)
      .map((campo) => ({
        ...campo,
        value: campo.description,
        defaultFilterValue: campo.value,
      }));

    let objectFilterDefault = options?.filter((x) => x.defaultFilterValue != null);

    if (!filters) {
      let newList = objectFilterDefault.map((element) => ({
        id: element?.id,
        filterType: element?.value,
        filterValue: element?.defaultFilterValue,
        filterMask: element?.mask,
        filterDataType: element?.type,
        isDefault: true,
      }));

      dispatch({
        type: 'SEARCH_FILTERS', //opcoes de filtro - @
        filtersToSearch: newList,
      });
    }

    dispatch({
      type: 'LOAD_FILTERS', // filtros selecionados
      filtersList: options,
    });

    dispatch({
      type: 'FILTER_OPTIONS', // filtros com multi-selecao
      optionsListFunction: (sprAuto: string, sprAutoSearch: string) => {
        return DomainService.getDomainListByProc(requestParams, sprAuto, sprAutoSearch);
      },
    });

    let itemsList = apiResponse.result.dados;

    if (!isFilter) itemsList = [...tableItems.items, ...itemsList];

    setTableItems({
      items: itemsList,
    });

    //TODO: Parametrizar colunas de comparação
    if (selectAll) {
      const selectedItems = apiResponse.result?.dados.map((item) => {
        return { NR_CLB_ID: item.NR_CLB_ID, DT_REG_ENT: item.DT_REG_ENT };
      });

      setItensSelecionados((prevFechamentosSelecionados) => [
        ...prevFechamentosSelecionados,
        ...selectedItems,
      ]);
    }

    setDataset(apiResponse);
  } else {
    setTableItems({ items: [] });
    setDataset(apiResponse);
  }
};

export const filterParamsBuilder = (data, filters) => {
  let requestParams: ListRequest = data;

  if (filters) {
    requestParams.filter.field = filters.map((filter) => ({
      id: filter.id,
      description: filter.filterType,
      value: filter.filterValue,
      type: 'texto',
      sprauto: filter?.sprauto,
    }));
  }

  return requestParams;
};

export const tableColumns = (data) => {
  if (data.dataset?.data[0]) {
    return data.dataset.data[0].field.filter((campo) => campo['showGrid']);
  } else {
    return [];
  }
};
