import { Col } from "react-bootstrap";
import NossoManifestoPng from "../../assets/images/banner_manifesto.png";
import TecnologiaPessoasJpg from "../../assets/images/tecnologia_e_pessoas.jpg";
import FabricaSoftwareJpg from "../../assets/images/fabrica_de_software_pb.jpg";
import RpaBackgroundJpg from "../../assets/images/rpa_background_pb.jpg";
import AppWebappJpg from "../../assets/images/app_webapp_background_pb.jpg";
import ELearningJpg from "../../assets/images/e_learning_pb.jpg";
import AjudamosSeuNegocioJpg from "../../assets/images/ajudamos_o_seu_negocio.jpg";

import SlideSlogan1Jpg from "../../assets/images/slide_slogan1.jpg";
import SlideSlogan2Jpg from "../../assets/images/slide_slogan2.jpg";
import SlideSlogan3Jpg from "../../assets/images/slide_slogan3.jpg";
import SlideSlogan4Jpg from "../../assets/images/slide_slogan4.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

const SwiperItem = ({ imgSrc, title, text, redirectUrl }) => {
  return (
    <div
      className="cover-background full-screen grid-item text-left"
      style={{
        backgroundImage: `url(${imgSrc})`,
        minHeight: "524px"
      }}
    >
      <div className="slide-hover-box">
        <div className="opacity-medium bg-black"></div>
        <figure className="position-absolute">
          <figcaption>
            <a href={redirectUrl}>
              <h6 className="font-weight-300 text-white margin-20px-bottom">
                {title}
              </h6>
            </a>
            <p className="text-white width-85 sm-width-100">{text}</p>
            <div
              className="separator-line-horrizontal-medium-light2 opacity5 margin-35px-top sm-margin-15px-top"
              style={{ backgroundColor: "#Ec1737" }}
            ></div>
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

const PageBody = () => {
  return (
    <>
      <section
        className="wow fadeIn cover-background sm-no-background-img bg-medium-light-gray"
        style={{ backgroundImage: `url(${NossoManifestoPng})` }}
      >
        <div className="container">
          <Col
            lg={6}
            sm={12}
            xs={12}
            className="pull-right wow fadeIn"
            style={{ fontSize: "14px" }}
          >
            <Col className="sm-text-center xs-no-padding-lr last-paragraph-no-margin margin-60px-bottom sm-margin-30px-bottom">
              <span className="text-medium text-deep-pink alt-font margin-10px-bottom display-inline-block"></span>
              <h5 className="alt-font text-extra-dark-gray font-weight-600">
                A tecnologia continua a mudar o mundo como conhecemos.
              </h5>
              <p className="width-90 sm-width-100">
                E para criar um impacto positivo e significativo, precisamos
                colocar as pessoas em primeiro lugar. Fazemos isso combinando a
                engenhosidade humana com tecnologias inovadoras para resolver
                problemas complexos e fornecer inovações para construir uma
                sociedade mais igualitária e inclusiva, o futuro é agora.
              </p>
            </Col>
            <Col
              sm={12}
              xs={12}
              lg={6}
              className="xs-margin-30px-bottom sm-margin-30px-bottom last-paragraph-no-margin xs-no-padding-lr sm-text-center"
            >
              <i className="fal fa-user-astronaut text-deep-pink icon-extra-medium margin-20px-bottom sm-margin-15px-bottom"></i>
              <div className="feature-content">
                <div className="alt-font font-weight-600 text-extra-dark-gray margin-5px-bottom">
                  Nossa tecnologia
                </div>
                <p className="width-80 md-width-90 sm-width-100">
                  Uma visão para TI que combina aplicações líquidas com
                  plataformas inteligentes e ecossistemas conectados.
                </p>
              </div>
            </Col>
            <Col
              sm={12}
              xs={12}
              lg={6}
              className="last-paragraph-no-margin xs-no-padding-lr sm-text-center"
            >
              <i className="fal fa-space-shuttle text-deep-pink icon-extra-medium margin-20px-bottom sm-margin-15px-bottom"></i>
              <div className="feature-content">
                <div className="alt-font font-weight-600 text-extra-dark-gray margin-5px-bottom">
                  O que entregamos?
                </div>
                <p className="width-80 md-width-90 sm-width-100">
                  Temos orgulho de ajudar a moldar a forma como empresas líderes
                  estruturam e gerenciam seus negócios.
                </p>
              </div>
            </Col>
          </Col>
        </div>
      </section>

      <section className="no-padding-bottom wow fadeIn">
        <div
          className="container-fluid"
          style={{ fontSize: "14px", paddingLeft: 0, paddingRight: 0 }}
        >
          <Col
            lg={5}
            md={7}
            sm={8}
            xs={12}
            className="center-col margin-five-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center"
          >
            <h5 className="alt-font text-extra-dark-gray font-weight-600 width-60 margin-lr-auto  md-width-80 xs-width-100">
              Serviços & Soluções
            </h5>
          </Col>

          <section className="no-padding height-100 bg-black">
            <div
              className="container-fluid"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div className="hover-option3">
                <Swiper
                  modules={[Autoplay, Pagination]}
                  spaceBetween={50}
                  slidesPerView={4}
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 3000,
                    pauseOnMouseEnter: true
                  }}
                  breakpoints={{
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 0
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 0
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 0
                    },
                    480: {
                      slidesPerView: 1,
                      spaceBetween: 0
                    }
                  }}
                >
                  <SwiperSlide>
                    <SwiperItem
                      imgSrc={TecnologiaPessoasJpg}
                      title="Tecnologia e Pessoas"
                      text="Nos diferenciamos no processo ágil na alocação de
                      profissionais especializados em TI."
                      redirectUrl="./solucoes/outsourcing/"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <SwiperItem
                      imgSrc={FabricaSoftwareJpg}
                      title="Fábrica de Software"
                      text="Soluções personalizadas para obter diferenciais
                      competitivos em seu negócio."
                      redirectUrl="./solucoes/solution_centre/"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <SwiperItem
                      imgSrc={RpaBackgroundJpg}
                      title="RPA"
                      text="Softwares robôs que automatizam os processos de negócios em toda a organização."
                      redirectUrl="./solucoes/rpa/"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <SwiperItem
                      imgSrc={AppWebappJpg}
                      title="Web App e Apps"
                      text="Tecnologias que se diferenciam na maneira que são desenvolvidas."
                      redirectUrl="web_app.html"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <SwiperItem
                      imgSrc={ELearningJpg}
                      title="E-learning"
                      text="Seus cursos disponíveis a qualquer hora e em qualquer lugar."
                      redirectUrl="single-project-page-01.html"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section className="wow fadeIn">
        <div className="container" style={{ fontSize: "14px" }}>
          <div className="equalize sm-equalize-auto">
            <Col
              sm={12}
              xs={12}
              lg={5}
              className="sm-center-col padding-five-right sm-margin-70px-bottom sm-text-center sm-padding-15px-lr xs-margin-50px-bottom wow fadeIn xs-text-center"
            >
              <p className="alt-font text-deep-pink margin-10px-bottom"></p>
              <h5 className="alt-font font-weight-600 text-extra-dark-gray">
                Inovamos com agilidade
              </h5>
              <p>
                Fazemos parceria para expandir seu negócio e dimensionar novas
                ofertas com agilidade, tudo em um ambiente altamente seguro.
                Nossos serviços ajudam a determinar a plataforma de tecnologia
                certa para permitir a transformação de seus negócios. Avaliamos
                suas necessidades e orientamos você através do processo com a
                estratégia tecnológica correta.
              </p>
            </Col>
            <Col lg={7} md={12} sm={12} xs={12} className="sm-no-padding-lr">
              <div className="padding-seven-lr pull-left sm-no-padding-lr col-2-nth">
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="margin-30px-bottom sm-text-center wow fadeInUp last-paragraph-no-margin"
                >
                  <em className="fal fa-lightbulb-on icon-extra-medium text-deep-pink margin-15px-bottom"></em>
                  <div className="alt-font text-extra-dark-gray font-weight-600 margin-5px-bottom">
                    Plataformas Inteligentes
                  </div>
                  <p>
                    Aproveitamos dados diversos internos e externos para gerar
                    um novo nível de conhecimento que permita que sua empresa
                    obtenha melhores resultados.
                  </p>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="margin-30px-bottom sm-text-center wow fadeInUp last-paragraph-no-margin"
                  data-wow-delay="0.2s"
                >
                  <i className="fal fa-pencil-ruler icon-extra-medium text-deep-pink margin-15px-bottom"></i>
                  <div className="alt-font text-extra-dark-gray font-weight-600 margin-5px-bottom">
                    Customização
                  </div>
                  <p>
                    Somos comprometidos em criar soluções inovadoras e
                    eficientes, incentivados pelo novo.
                  </p>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="wow fadeInUp xs-margin-30px-bottom sm-text-center last-paragraph-no-margin"
                  data-wow-delay="0.4s"
                >
                  <i className="fal fa-bullseye-arrow icon-extra-medium text-deep-pink margin-15px-bottom"></i>
                  <div className="alt-font text-extra-dark-gray font-weight-600 margin-5px-bottom">
                    Assertividade
                  </div>
                  <p>
                    Combinamos experiência e análise para adotar tecnologias
                    inteligentes e minimizar possíveis erros.
                  </p>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="wow fadeInUp last-paragraph-no-margin sm-text-center"
                  data-wow-delay="0.6s"
                >
                  <i className="fal fa-lock-alt icon-extra-medium text-deep-pink margin-15px-bottom"></i>
                  <div className="alt-font text-extra-dark-gray font-weight-600 margin-5px-bottom">
                    Ambientes seguros
                  </div>
                  <p>
                    Estamos continuamente inovando para proteger toda a cadeia
                    de valor do seu negócio, de ponta a ponta.
                  </p>
                </Col>
              </div>
            </Col>
          </div>
        </div>
      </section>

      <section className="wow fadeIn padding-50px-tb border-top border-color-extra-light-gray">
        <div className="container">
          <Col
            md={12}
            lg={8}
            className="md-margin-30px-bottom text-center text-lg-left wow fadeInDown"
            style={{ visibility: "visible", animationName: "fadeInDown" }}
          >
            <span className="alt-font text-extra-large text-extra-dark-gray margin-5px-top md-no-margin-top d-inline-block width-100">
              Procurando uma solução específica? Estamos aqui para ajudar.
            </span>
          </Col>
          <Col
            md={12}
            lg={4}
            className="text-center text-lg-left wow fadeInDow"
            style={{ visibility: "visible", animationName: "fadeInDown" }}
          >
            <a
              href="contato.html"
              className="btn btn-medium btn-rounded btn-transparent-dark-gray"
              data-wow-delay="0.4s"
            >
              Vamos conversar
              <i className="ti-arrow-right" aria-hidden="true"></i>
            </a>
          </Col>
        </div>
      </section>

      <section className="no-padding wow fadeIn bg-extra-dark-gray">
        <div className="container-fluid">
          <div className="equalize sm-equalize-auto">
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="cover-background sm-height-500px xs-height-350px wow fadeIn"
              style={{ backgroundImage: `url(${AjudamosSeuNegocioJpg})` }}
            >
              <div
                className="xs-height-400px"
                style={{ height: "580px" }}
              ></div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} className="wow fadeIn">
              <div
                className="padding-ten-all pull-left sm-no-padding-lr md-padding-50px-tb sm-padding-70px-tb"
                style={{ fontSize: "14px" }}
              >
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="margin-four-bottom md-margin-six-bottom sm-margin-40px-bottom xs-margin-30px-bottom xs-no-padding-lr"
                >
                  <h4 className="alt-font text-white sm-text-center sm-width-70 sm-margin-lr-auto sm-no-margin-bottom xs-width-100">
                    Ajudamos você a transformar seu negócio.
                  </h4>
                </Col>
                <div className="col-2-nth xs-text-center">
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className="margin-six-bottom sm-margin-30px-bottom xs-no-padding last-paragraph-no-margin"
                  >
                    <div className="text-light-gray margin-10px-bottom sm-margin-5px-bottom alt-font">
                      <span className="text-deep-pink margin-15px-right pull-left text-large xs-display-block xs-margin-lr-auto xs-width-100">
                        01.
                      </span>
                      Nosso objetivo...
                    </div>
                    <p>
                      É fornecer soluções mais eficientes, com mais benefícios e
                      vantagens através dos incríveis saltos tecnológicos.
                    </p>
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className="margin-six-bottom sm-margin-30px-bottom xs-no-padding last-paragraph-no-margin"
                  >
                    <div className="text-light-gray margin-10px-bottom sm-margin-5px-bottom alt-font">
                      <span className="text-deep-pink margin-15px-right pull-left text-large xs-display-block xs-margin-lr-auto xs-width-100">
                        02.
                      </span>
                      Inspiramos...
                    </div>
                    <p>
                      Nossos parceiros e clientes a buscarem soluções mais
                      inovadoras, estimulamos o setor a buscar mais qualidade e
                      criatividade.
                    </p>
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className="xs-margin-30px-bottom xs-no-padding last-paragraph-no-margin"
                  >
                    <div className="text-light-gray margin-10px-bottom sm-margin-5px-bottom alt-font">
                      <span className="text-deep-pink margin-15px-right pull-left text-large xs-display-block xs-margin-lr-auto xs-width-100">
                        03.
                      </span>
                      Valorizamos...
                    </div>
                    <p>
                      Cada contribuição individual para o nosso sucesso, e
                      motivamos as pessoas através da integridade e ética.
                    </p>
                  </Col>
                  <Col
                    lg={6}
                    md={12}
                    sm={12}
                    xs={12}
                    className="xs-no-padding last-paragraph-no-margin"
                  >
                    <div className="text-light-gray margin-10px-bottom sm-margin-5px-bottom alt-font">
                      <span className="text-deep-pink margin-15px-right pull-left text-large xs-display-block xs-margin-lr-auto xs-width-100">
                        04.
                      </span>
                      Reunimos...
                    </div>
                    <p>
                      O melhor do conhecimento, inteligência e habilidade para
                      aprimorar constantemente o trabalho e beneficiar nossos
                      clientes.
                    </p>
                  </Col>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </section>

      <section
        className="no-padding parallax xs-background-image-center cover-backgroun"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: `url(${SlideSlogan1Jpg})` }}
      >
        <div className="container-fluid one-third-screen position-relative md-height-600px sm-height-700px xs-height-450px">
          <div className="slider-typography">
            <div className="slider-text-middle-main"> </div>
          </div>
        </div>
      </section>

      <section
        className="no-padding parallax xs-background-image-center cover-backgroun"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: `url(${SlideSlogan2Jpg})` }}
      >
        <div className="container-fluid one-third-screen position-relative md-height-600px sm-height-700px xs-height-450px">
          <div className="slider-typography">
            <div className="slider-text-middle-main"> </div>
          </div>
        </div>
      </section>

      <section
        className="no-padding parallax xs-background-image-center cover-backgroun"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: `url(${SlideSlogan3Jpg})` }}
      >
        <div className="container-fluid one-third-screen position-relative md-height-600px sm-height-700px xs-height-450px">
          <div className="slider-typography">
            <div className="slider-text-middle-main"> </div>
          </div>
        </div>
      </section>

      <section
        className="no-padding parallax xs-background-image-center cover-backgroun"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: `url(${SlideSlogan4Jpg})` }}
      >
        <div className="container-fluid one-third-screen position-relative md-height-600px sm-height-700px xs-height-450px">
          <div className="slider-typography">
            <div className="slider-text-middle-main"> </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageBody;
