import React, { useState } from 'react';
import { CustomModal } from '../../../components/CustomModal';
import Fieldset from '../../../components/Fieldset';
import { TableComponent } from '../../../components/TableComponent';
import { ButtonModel } from '../../../models/components/button.model';
import { FiEdit2, FiEdit3, FiEye, FiTrash, FiTrash2 } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { Header } from '../../../components/Header';
import ManagerEdit from './manager.edit';
import ManagerList from './manager.list';
import { ListRequest } from '../../../models/requestParams.model';
import ClientDepartManagerService from '../../../services/client.depart.manager.service';
import { serviceControllers } from '../../../util/enumerators';

interface IDepartManagerList {
  idClient: number;
  idDepart: number | boolean;
  managerList: any;
  managerColumns: any;
  getManagerList?: () => void;
}
const DepartManagerList = ({
  idClient,
  idDepart,
  managerColumns,
  managerList,
  getManagerList,
}: IDepartManagerList) => {
  const [showManagerList, setShowManagerList] = useState(false);
  const [showManagerEdit, setShowManagerEdit] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedManager, setSelectedManager] = useState<any>();
  const [selectedItemManager, setSelectedItemManager] = useState<any>([]);

  // const selectManager = (param, index) => {
  //   setSelectedManager(selectedManager !== index ? index : null);
  //   setSelectedItemManager(selectedItemManager === param || param);
  // };

  const selectManager = (
    doc,
    index,
    e,
    ultimoSelecionado,
    setUltimoSelecionado,
    itensSelecionados,
    setItensSelecionados,
    referenceList
  ) => {
    const isItemInSelectedItems = (selectedItems, currentItem) => {
      return selectedItems.some(
        (selected) =>
          selected.DEPTOGESTOR_ID_CLIENTE_DEPTO_GESTOR ===
          currentItem.DEPTOGESTOR_ID_CLIENTE_DEPTO_GESTOR
      );
    };
    const item = referenceList.items[index];
    setSelectedManager(index);
    let selectedItems = [...itensSelecionados];
    if (isItemInSelectedItems(selectedItems, item)) {
      selectedItems = selectedItems.filter(
        (selected) =>
          selected.DEPTOGESTOR_ID_CLIENTE_DEPTO_GESTOR !== item.DEPTOGESTOR_ID_CLIENTE_DEPTO_GESTOR
      );
    } else {
      selectedItems = [
        {
          DEPTOGESTOR_ID_CLIENTE_DEPTO_GESTOR: item.DEPTOGESTOR_ID_CLIENTE_DEPTO_GESTOR,
          DEPTOGESTOR_ID_CLIENTE_GESTOR: item.DEPTOGESTOR_ID_CLIENTE_GESTOR,
        },
      ];
    }
    setSelectedItemManager(selectedItems);
  };

  const btnManager: ButtonModel[] = [
    {
      onClick: () => {
        setShowManagerList(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiEye className="fa-icon" />
          <FaEye className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Listagem dos Gestores',
    },
    {
      onClick: () => {
        setShowManagerEdit(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiEdit2 className="fa-icon" />
          <FiEdit3 className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Editar - Gestor',
      disabled: typeof selectedManager !== 'number',
    },
    {
      onClick: () => {
        setShowConfirmDelete(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiTrash className="fa-icon" />
          <FiTrash2 className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Excluir - Gestor do Departamento',
      disabled: typeof selectedManager !== 'number',
    },
  ];

  const handleDelDepartManager = async () => {
    var delParams = {
      id: selectedItemManager[0]?.DEPTOGESTOR_ID_CLIENTE_DEPTO_GESTOR,
      cnpj: idClient,
    };
    await ClientDepartManagerService.del(
      serviceControllers.CLIENT_DEPART_MANAGER,
      delParams
    ).finally(() => {
      setSelectedManager(null);
      setSelectedItemManager([]);
      setShowConfirmDelete(false);
      getManagerList();
    });
  };

  return (
    <>
      {/* {JSON.stringify(managerList)} */}
      <Header title={''} buttons={btnManager} showBread={false} />
      <Fieldset legend="Gestores do Departamento">
        {!(typeof idDepart === 'number') ? (
          <p style={{ fontSize: '14px', margin: 15, textAlign: 'center' }}>
            {' '}
            Escolha um departamento para visualizar os gestores...
          </p>
        ) : (
          <div
            style={{
              paddingTop: 13,
            }}
            className="height-fit-table"
          >
            <TableComponent
              infiniteScrollClassName="height-definition-timesheet"
              itemList={managerList}
              fetchMoreData={() => {}}
              totalItems={0}
              gridColumns={managerColumns}
              selectedItems={selectedItemManager}
              selectItem={selectManager}
              // comparisonColumn={'ID_CLIENTE_GESTOR'}
              referenceColumn={'DEPTOGESTOR_ID_CLIENTE_DEPTO_GESTOR'}
              orderable={false}
              useIndex={false}
            />
          </div>
        )}
      </Fieldset>
      <div style={{ display: 'flex', justifyContent: 'center' }} className="height-fit-table">
        <div className="width-fit-dialog">
          <CustomModal
            show={showManagerList}
            handleClose={() => setShowManagerList(false)}
            title="Gestor Disponíveis do Cliente"
            children={
              <ManagerList
                idClient={idClient}
                idDepart={idDepart}
                refresh={() => getManagerList()}
              />
            }
          />
        </div>
        <CustomModal
          show={showManagerEdit}
          handleClose={() => setShowManagerEdit(false)}
          title="Gestor"
          children={
            <ManagerEdit
              idClient={idClient}
              idDepart={idDepart}
              idManager={selectedItemManager[0]?.DEPTOGESTOR_ID_CLIENTE_GESTOR}
              refreshList={getManagerList}
              handleClose={() => setShowManagerEdit(false)}
            />
          }
        />
      </div>
      <ConfirmationModal
        title={'Deseja realmente excluir este registro?'}
        show={showConfirmDelete}
        handleClose={() => {
          setShowConfirmDelete(false);
        }}
        handleConfirm={() => {
          handleDelDepartManager();
        }}
        children={
          <p style={{ fontSize: '14px', color: 'red', marginBottom: 0 }}>
            Essa operação não poderá ser desfeita...
          </p>
        }
      />
    </>
  );
};

export default DepartManagerList;
