import { serviceControllers } from '../util/enumerators';
import HttpService from './http.service';
const basePath = serviceControllers.REMUNERATION;

export const RemunerationService = {
  getRumunerationCalc,
};

async function getRumunerationCalc(data: any) {
  return HttpService.post(`${basePath}/GetRemunerationCalc`, data);
}

export default RemunerationService;
