import moment from 'moment';
import { ClientModel, RetentionModel } from '../../../models/cliente.model';
import {
  IsNullOrZero,
  formatCpfCnpj,
  formatDate,
  formatDateTime,
  removeFormatting,
} from '../../../util';

export const retentionDataMapping = (data, setInitialValues) => {
  const retentionData: RetentionModel = {
    id: data['NR_DOC_ID'],
    cnpj: formatCpfCnpj(data['NR_DOC_ID']),
    type: data['CD_RET_TP'],
    effective: formatDate(data['DT_VIG_RET']),
    pis: data['PC_PIS_ALQ'],
    csll: data['PC_CSLL_ALQ'],
    confins: data['PC_COFINS_ALQ'],
    inss: data['PC_INSS_ALQ'],
    indINSS: data['PC_INSS_IND'],
    ir: data['PC_IR_ALQ'],
    iss: data['PC_ISS_ALQ'],
    observation: data['DS_OBS_RET'],
  };

  setInitialValues((prev) => ({
    ...prev,
    retention: retentionData,
  }));
};

export const retentionMapSave = (data: ClientModel) => {
  const saveClientObj = {
    id: data.retention.id,
    cnpj: removeFormatting(data.retention.cnpj),
    type: 2,
    effective: moment(data.retention.effective, 'DD/MM/YYYY').toDate(),
    pis: data.retention.pis,
    csll: data.retention.csll,
    confins: data.retention.confins,
    inss: data.retention.inss,
    indINSS: IsNullOrZero(data.retention.inss) ? 0 : 1,
    ir: data.retention.ir,
    iss: data.retention.iss,
    observation: data.retention.observation,
  };

  return saveClientObj;
};
