import { Link } from "react-router-dom";
import "./tokenPage.css";
import { FaCheck } from "react-icons/fa";
import { useEffect, useState } from "react";

export const SuccessTokenPage = () => {
  const [shouldShake, setShouldShake] = useState(false);

  useEffect(() => {
    const shakeTimeout = setTimeout(() => {
      setShouldShake(true);
    }, 100);

    return () => clearTimeout(shakeTimeout);
  }, []);

  return (
    <section className="token-return-page">
      <div className="token-return-content">
        <h4>Salvo com sucesso!</h4>
        <FaCheck
          className={`token-return-icon ${shouldShake ? "shake-once" : ""}`}
          style={{ fontSize: "60px", color: "forestgreen" }}
        />
        <br />
        <br />
        <br />
        <Link to="/" style={{ fontSize: "medium" }}>
          Página incial
        </Link>
      </div>
    </section>
  );
};
