import { Link } from "react-router-dom";
import "./tokenPage.css";
import { FaBan } from "react-icons/fa";
import { useEffect, useState } from "react";

export const ExpiredTokenPage = () => {
  const [shouldShake, setShouldShake] = useState(false);

  useEffect(() => {
    const shakeTimeout = setTimeout(() => {
      setShouldShake(true);
    }, 100);

    return () => clearTimeout(shakeTimeout);
  }, []);

  return (
    <section className="token-return-page">
      <div className="token-return-content">
        <h4>Esse token expirou!</h4>
        <FaBan
          className={`token-return-icon ${shouldShake ? "shake-once" : ""}`}
          style={{ fontSize: "60px", color: "red" }}
        />
        <br />
        <p style={{ fontSize: "medium" }}>
          Por favor, entre em contato com o Global System para soliciar um novo
          acesso.
        </p>
        <Link to="/" style={{ fontSize: "medium" }}>
          Página incial
        </Link>
      </div>
    </section>
  );
};
