import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Header } from "../../components/Header";
import Fieldset from "../../components/Fieldset";

import { ButtonModel } from "../../models/components/button.model";
import { FaArrowLeft } from "react-icons/fa";
import {
  ListRequest,
  RequestTimeSheetParamsModel,
  RequestTimeSheetParamsModelDefault
} from "../../models/requestTimeSheetParams.model";
import TimesheetService from "../../services/timesheet.service";
import React from "react";

export const TimeSheetAdditionalInformation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const forceUpdate = React.useState()[1].bind(null, {});
  const [isLoading, setLoading] = useState(false);
  const [fieldsInfo, setFieldsInfo] = useState({
    horarioTrabalho: "",
    uf: "",
    cidade: "",
    modalidade: "",
    tipoRemunaracao: "",
    dataDesligamento: "",
    qtdHorasExtras75: "",
    qtdHorasExtras100: "",
    qtdHorasTotal: "",
    qtdHorasSobreaviso: "",
    qtdHorasFalta: ""
  });

  // ---

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/Timesheet/`);
      },
      text: t("BACK"),
      variant: "outline-primary",
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: "Voltar para a página anterior"
    }
  ];

  const initialize = async () => {
    setLoading(true);

    await loadForEdit(RequestTimeSheetParamsModelDefault);

    setLoading(false);
  };

  const loadForEdit = async (data: any) => {
    // let requestTimeSheetParams: RequestTimeSheetParamsModel = data;
    // const apiResponse: any = await TimesheetService.get(requestTimeSheetParams);
    // if (apiResponse?.execucao?.retorno["@SUCESSO"] === "1") {
    //   forceUpdate();
    //   //Pega oa dados do primeiro dataset para poder preencher as informações adicionais
    //   setFieldsInfo({
    //     horarioTrabalho: apiResponse.resultList[0][0]["NM_HTR_DS"],
    //     uf: apiResponse.resultList[0][0]["NM_UF_DS"],
    //     cidade: apiResponse.resultList[0][0]["NM_CID_DS"],
    //     modalidade: apiResponse.resultList[0][0]["DS_MOD_CTR"],
    //     tipoRemunaracao: apiResponse.resultList[0][0]["IND_TIP_RMN_DS"],
    //     dataDesligamento: apiResponse.resultList[0][0]["DT_REG_SDA"],
    //     qtdHorasExtras75: apiResponse.resultList[0][0]["QT_PTO_HRE_FX1"],
    //     qtdHorasExtras100: apiResponse.resultList[0][0]["QT_PTO_HRE_FX2"],
    //     qtdHorasTotal: apiResponse.resultList[0][0]["QT_PTO_HRE"],
    //     qtdHorasSobreaviso: apiResponse.resultList[0][0]["MOC_COMP"],
    //     qtdHorasFalta: apiResponse.resultList[0][0]["QT_PTO_HRF"]
    //   });
    // }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <Header
        title={"Informações Complementares"}
        buttons={buttons}
        previousPage="Timesheet"
        previousUrl={() => {
          navigate(`/Timesheet/`);
        }}
      />
      <Fieldset legend="Informações Complementares">
        <Form className="m-2">
          <Row>
            <Col xl={4}>
              <Form.Group className="form-group">
                <Form.Control
                  id="colaborador"
                  size="sm"
                  name="colaborados"
                  type="text"
                  value={fieldsInfo.horarioTrabalho ?? "-"}
                  disabled={true}
                />
                <Form.Label>Horário de Trabalho</Form.Label>
              </Form.Group>
            </Col>
            <Col
              // xs={4} sm={6} md={8} lg={12}
              xl={2}
            >
              <Form.Group className="form-group">
                <Form.Control
                  id="situacao"
                  size="sm"
                  name="situacao"
                  type="text"
                  value={fieldsInfo.uf ?? "-"}
                  disabled={true}
                />
                <Form.Label>UF</Form.Label>
              </Form.Group>
            </Col>
            <Col xl={2}>
              <Form.Group className="form-group">
                <Form.Control
                  id="normais"
                  size="sm"
                  name="normais"
                  type="text"
                  value={fieldsInfo.cidade ?? "-"}
                  disabled={true}
                />
                <Form.Label>Cidade</Form.Label>
              </Form.Group>
            </Col>
            <Col
              // xs={4} sm={6} md={8} lg={12}
              xl={2}
            >
              <Form.Group className="form-group">
                <Form.Control
                  id="extra"
                  size="sm"
                  name="extra"
                  value={fieldsInfo.modalidade ?? "-"}
                  type="text"
                  disabled={true}
                />
                <Form.Label>Modalidade</Form.Label>
              </Form.Group>
            </Col>
            <Col xl={2}>
              <Form.Group className="form-group">
                <Form.Control
                  id="total"
                  size="sm"
                  name="total"
                  type="text"
                  value={fieldsInfo.tipoRemunaracao ?? "-"}
                  disabled={true}
                />
                <Form.Label>Tipo Remuneração</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xl={2}>
              <Form.Group className="form-group">
                <Form.Control
                  id="extra"
                  size="sm"
                  name="extra"
                  type="text"
                  value={fieldsInfo.dataDesligamento ?? "-"}
                  disabled={true}
                />
                <Form.Label>Data Desligamento</Form.Label>
              </Form.Group>
            </Col>

            <Col xl={2}>
              <Form.Group className="form-group">
                <Form.Control
                  id="extra"
                  size="sm"
                  name="extra"
                  type="text"
                  value={fieldsInfo.qtdHorasExtras75 ?? "-"}
                  disabled={true}
                />
                <Form.Label>Qtd. Horas Extras 75%</Form.Label>
              </Form.Group>
            </Col>
            <Col xl={2}>
              <Form.Group className="form-group">
                <Form.Control
                  id="total"
                  size="sm"
                  name="total"
                  type="text"
                  value={fieldsInfo.qtdHorasExtras100 ?? "-"}
                  disabled={true}
                />
                <Form.Label>Qtd. Horas Extras 100%</Form.Label>
              </Form.Group>
            </Col>
            <Col xl={2}>
              <Form.Group className="form-group">
                <Form.Control
                  id="normais"
                  size="sm"
                  name="normais"
                  type="text"
                  value={fieldsInfo.qtdHorasTotal ?? "-"}
                  disabled={true}
                />
                <Form.Label>Qtd. Horas Total</Form.Label>
              </Form.Group>
            </Col>

            <Col xl={2}>
              <Form.Group className="form-group">
                <Form.Control
                  id="extra"
                  size="sm"
                  name="extra"
                  type="text"
                  value={fieldsInfo.qtdHorasSobreaviso ?? "-"}
                  disabled={true}
                />
                <Form.Label>Qtd. Horas Sobreaviso</Form.Label>
              </Form.Group>
            </Col>

            <Col xl={2}>
              <Form.Group className="form-group">
                <Form.Control
                  id="extra"
                  size="sm"
                  name="extra"
                  type="text"
                  value={fieldsInfo.qtdHorasFalta ?? "-"}
                  disabled={true}
                />
                <Form.Label>Qtd. Horas Falta</Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Fieldset>
    </>
  );
};
