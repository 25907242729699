import moment from 'moment';
import { format } from 'date-fns';
import { DATETIME_FORMAT, TIME_FORMAT } from './consts';

export const IsNullOrEmpty = (str: string): boolean => {
  if (str === null) return true;
  if (str?.length === 0) return true;
  return false;
};

export const IsNullOrZero = (value: number): boolean => {
  if (value === null) return true;
  if (value === 0) return true;
  return false;
};

export const IsValidEmail = (email: string): boolean => {
  if (IsNullOrEmpty(email)) return false;
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  const matchArray = pattern.test(email);
  return matchArray;
};

export const formValidateDate = (value: any) => {
  if (value != null) {
    const parsedDate = moment(value, 'DD/MM/YYYY', true);
    const minDate = moment('02/01/1753', 'DD/MM/YYYY');
    const maxDate = moment('12/12/2500', 'DD/MM/YYYY');

    return parsedDate.isValid() && parsedDate.isAfter(minDate) && parsedDate.isBefore(maxDate);
  }

  return true;
};

export const formatDate = (inputString) => {
  if (inputString) {
    const date = new Date(inputString);
    const day = date.getDate()?.toString().padStart(2, '0');
    const month = (date.getMonth() + 1)?.toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
};

export const formatDateTime = (inputString) => {
  const date = new Date(inputString);
  return moment(date).format('DD/MM/YYYY - hh:mm:ss');
};

export const formatCep = (inputString) => {
  const cep = inputString?.toString().padStart(8, '0');
  return cep;
};

export const formatStringToDate = (date) => {
  return moment(date, 'DD/MM/YYYY').toDate();
};

export const formatCpfCnpj = (inputString) => {
  const CNPJ_REGEX = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
  const CPF_REGEX = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;

  let document = inputString?.toString().padStart(11, '0');
  let returnString;

  if (document?.length === 11) {
    returnString = document?.replace(CPF_REGEX, '$1.$2.$3-$4');
  } else {
    document = inputString?.toString().padStart(14, '0');
    returnString = document?.replace(CNPJ_REGEX, '$1.$2.$3/$4-$5');
  }

  return returnString;
};

// eslint-disable-next-line no-useless-escape
export const removeFormatting = (campoTexto: string) => campoTexto.replace(/(\.|\/|\-)/g, '');

export const removeTrailingSlash = (input) => {
  return input.endsWith('/') ? input.slice(0, -1) : input;
};

export const addLeadingSlash = (input) => {
  return !input.startsWith('/') ? `/${input}` : input;
};

export const timespanToHourMinute = (input: string) => {
  return moment(input, 'HH:mm:ss').format(TIME_FORMAT);
};

export const isDateTime = (input: string) => {
  return moment(input, DATETIME_FORMAT, true).isValid();
};

export const isTime = (input: string) => {
  return moment(input, TIME_FORMAT, true).isValid();
};

export const formatCurrencyFromDb = (data) => {
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(data);

  return formattedValue;
};

export const returnCurrentEnterprise = (data, currentCompany = null) => {
  let referenceCompany = data?.user?.enterpriseId;

  if (currentCompany != null && currentCompany !== data?.user?.enterpriseId) {
    referenceCompany = currentCompany;
  }

  let enterprise = data?.enterprises?.find((enterpise) => enterpise.cnpj === referenceCompany);

  return enterprise;
};

export const convertCurrencyToFloat = (data) => {
  const stringWithoutCurrency = data?.toString().replace('R$', '').trim();
  const floatValue = parseFloat(stringWithoutCurrency?.replace('.', '')?.replace(',', '.'));

  return floatValue;
};

export const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const menuMapping = (data) => {
  return data?.map((element) => {
    return {
      descriptionMenu: element?.['DS_MENU'],
      descriptionMenuParent: element?.['DS_MENU_PAI'],
      enterpriseCN: element?.['NR_EMP_ID'],
      id: element?.['ID_MENU'],
      image: element?.['DS_IMAGEM'],
      menuParentId: element?.['ID_MENU_PAI'] ?? 0,
      nameIdFieldFromTable: '',
      nameProcUpdate: '',
      ordem: element?.['DS_ORDEM'],
      perfilId: element?.['ID_PERFIL'],
      urlTarget: element?.['DS_URL'],
    };
  });
};

export const adjustIdName = (str) => {
  const adjustedId = removeAccents(str).toLowerCase().replace(/\s+/g, '-');
  return adjustedId;
};

export const dateMaskToMomentMask = (key) => {
  const masks = {
    'MM/yyyy': 'MM/YYYY',
    'dd/MM/yyyy': 'DD/MM/YYYY',
  };

  let result = key;
  if (masks[key]) result = masks[key];

  return result;
};

export const formatValue = (value, mask) => {
  let decimalPlaces = 2;

  if (mask) decimalPlaces = mask.split('#').length - 1;

  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

export const findParentsInTree = (node, searchForName) => {
  if (node.value === searchForName) {
    return [];
  }

  if (Array.isArray(node.children)) {
    for (var treeNode of node.children) {
      const childResult = findParentsInTree(treeNode, searchForName);

      if (Array.isArray(childResult)) {
        return [treeNode.value].concat(childResult);
      }
    }
  }
};

export const formattedDate = (): string => {
  const now = new Date();
  const customFormattedDate = format(now, 'yyyyMMddHHmmss');
  return customFormattedDate;
};
