export const domainTypes = {
  ANGENCY: 'Banco-Agencia',
  BANK: 'Banco',
  CAPACITY: 'Lotacao',
  CITY: 'Cidade',
  CIVIL_STATUS: 'Estado Civil',
  CONSULTANT: 'Consultor',
  FLAGLIST: 'FlagList',
  GENDER: 'Sexo',
  ISSUING_AUTHORITY: 'Orgao-Emissor',
  MODALITY: 'Modalidade-Contrato',
  NATIONALITY: 'Nacionalidade',
  STATE: 'UF',
  TYPE_CHECKING_ACCOUNT: 'Sexo',
  WORK_SCHEDULE: 'Horario-Trabalho',
  SALE: 'Vendas',
  SELLER: 'Vendedor',
};

export const fieldTypes = {
  TEXT: 'Text',
  MASKED: 'MaskedFormControl',
  SELECT: 'Select',
  CHECKBOX: 'CheckBox',
  CURRENCY: 'Currency',
};

export const formMode = {
  EDIT: 'Editar',
  REGISTER: 'Cadastro',
  VIEW: 'Consulta',
};

export const modalTypes = {
  EDIT: 'Editar',
  EXTRA_HOUR: 'Horas Adicionais',
  REPLICATE: 'Replicar Horas',
  DELETE: 'Remover apontamento',
  DELETE_EXTRA_HOUR: 'Remover hora extra',
};

export const serviceControllers = {
  CLIENT: '/Client',
  CLIENT_MANAGER: '/ClientManager',
  CLIENT_RETENTION: '/ClientRetention',
  CLIENT_DEPARTMENT: '/ClientDepartment',
  CLIENT_DEPART_MANAGER: '/ClientDepartManager',
  COLLABORATOR: '/Collaborator',
  COLLABORATOR_TOKEN: '/CollaboratorToken',
  CLOSING: '/Closing',
  DOMAIN: '/Domain',
  DOMAIN_OWN: '/DomainOwn',
  HOLIDAY: '/Holiday',
  MENU: '/Menu',
  USER: '/User',
  PROFILE: '/Profile',
  REGISTER: '/Register',
  REMUNERATION: '/Remuneration',
  TIMESHEET: '/Timesheet',
  VACATION: '/Vacation',
  SALES_ORDER: '/SalesOrder',
};

export const orderByDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
};
