import moment from 'moment';
import { formatCpfCnpj, dateMaskToMomentMask, formatValue } from '../../util';
import { Form } from 'react-bootstrap';

export const DynamicColumn = ({ gridColumns, data }) => {
  const format = 'DD/MM/YYYY';

  return (
    <>
      {gridColumns?.map((column, idx) => {
        const value = data[column.id];
        const type = column.type;
        const mask = column.mask;

        switch (type) {
          case 'data':
            return (
              <td style={{ padding: 6 }} key={idx}>
                {value == null
                  ? '-'
                  : moment(value).format(mask ? dateMaskToMomentMask(mask) : format)}
              </td>
            );

          case 'hora':
            return (
              <td style={{ padding: 6 }} key={idx}>
                {value == null ? '-' : moment(value, 'HH:mm:ss').format('HH:mm')}
              </td>
            );

          case 'valor':
            return (
              <td
                style={{
                  padding: 6,
                  textAlign: 'right',
                }}
                key={idx}
              >
                {value == null ? '-' : formatValue(value, mask)}
              </td>
            );

          case 'cpfcnpj':
            return (
              <td style={{ padding: 6 }} key={idx}>
                {value == null ? '-' : formatCpfCnpj(value)}
              </td>
            );

          case 'boolean':
            return (
              <td style={{ padding: 1 }}>
                <Form.Check
                  disabled
                  type="switch"
                  checked={value}
                  style={{ marginLeft: 20, position: 'relative' }}
                />
              </td>
            );
          default:
            return (
              <td
                style={{
                  padding: 6,
                  textAlign: type === 'inteiro' ? 'right' : 'left',
                }}
                key={idx}
              >
                {value == null ? '-' : value}
              </td>
            );
        }
      })}
    </>
  );
};
