import { ManagerModel } from '../../../models/cliente.model';
import { formatCpfCnpj } from '../../../util';

export interface IManagerForm {
  manager: ManagerModel;
}

export const managerDataMapping = (data, setInitialValues) => {
  const managerData: ManagerModel[] = data.map((item, idx) => ({
    id: item['ID_CLIENTE_DEPTO'],
    cnpj: formatCpfCnpj(item['NR_CLI_ID']),
    name: item['NM_CDP_DS'],
    alias: item['NM_CDP_APL'],

    status: item['CD_STATUS'],
  }));

  setInitialValues((prev) => {
    return {
      ...prev,
      manager: managerData,
    };
  });
};

export const managerMapSave = (data: IManagerForm) => {
  const saveDepartObj: ManagerModel = {
    id: data.manager.id,
    cnpj: data.manager.cnpj,
    name: data.manager.name?.toUpperCase(),
    alias: data.manager.alias?.toUpperCase(),
    status: data.manager.status,
    email: data.manager.email,
    phone: data.manager.phone,
    contactName: data.manager.contactName?.toUpperCase(),
    contactEmail: data.manager.contactEmail,
    //approveTS: data.manager.approveTS === '1' ? true : false,
  };

  return saveDepartObj;
};
