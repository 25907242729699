import 'bootstrap/dist/css/bootstrap.min.css';

import { Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RouteWithSidebar, RouteNoSidebar, RouteWithSiteHeader } from '../components/RouteWithBars';

import { ColaboradorList } from '../containers/colaborador/list';
import { ColaboradorRegister } from '../containers/colaborador/form/register';
import { SuccessTokenPage } from '../containers/colaborador/token/success';
import { ExpiredTokenPage } from '../containers/colaborador/token/expired';

import { MenuList } from '../containers/menu/list';
import { MenuRegister } from '../containers/menu/register';

import { UserList } from '../containers/user/list';
import { UserRegister } from '../containers/user/form/register';

import NotFound from '../containers/notFound';
import Login from '../containers/login/index';
import { HomeList } from '../containers/home/list';
import LoginLayoutRoute from '../containers/loginLayoutRoute';
import ForgotPassword from '../containers/forgotPassword';
import RecoverPassword from '../containers/recoverPassword';

import { StoreModel } from '../models/store.model';

// Novas telas traduzidas do PHP
import MainPage from '../containers/main/index';

//Novas telas do sistema
import { FechamentoList } from '../containers/fechamento/list';
import { ResumoFechamento } from '../containers/fechamento/resume';
import { FechamentoRegister } from '../containers/fechamento/register';

import { TimeSheetList } from '../containers/timesheet/list';
import { TimeSheetRegister } from '../containers/timesheet/register';
import { TimeSheetOvertime } from '../containers/timesheet/overtime';
import { TimeSheetAdditionalInformation } from '../containers/timesheet/additionalInformation';

import { HolidayList } from '../containers/holiday/list';
import { FeriadoRegister } from '../containers/holiday/register';

import { VacationUserList } from '../containers/vacation/list';
import { VacationList } from '../containers/vacation/vacationList';
import { VacationRegister } from '../containers/vacation/register';

import { SalesOrderList } from '../containers/salesOrder/list';
import { SalesOrderRegister } from '../containers/salesOrder/form/register';

import { ClientList } from '../containers/client/list';
import { ClientRegister } from '../containers/client/register';
import Unauthorized from '../containers/unauthorized';
import { addLeadingSlash, removeTrailingSlash } from '../util';
import { ProfileRegister } from '../containers/profile/register';
import { ProfileList } from '../containers/profile/list';

const authenticatedHomePage = '/homelist';

const SystemRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/404" element={<NotFound />} />
        <Route path="/401" element={<Unauthorized />} />
        <Route path="/" element={<MainPage />} />
        <Route path="/Home" element={<MainPage />} />
        <Route path="/ResetarSenha/:hash" element={<MainPage />} />
        <Route
          path="/token/:token"
          element={<RouteWithSiteHeader component={ColaboradorRegister} />}
        />

        <Route
          path="/token/Sucesso"
          element={<RouteWithSiteHeader component={SuccessTokenPage} />}
        />

        <Route
          path="/token/Expirado"
          element={<RouteWithSiteHeader component={ExpiredTokenPage} />}
        />

        <Route element={<PrivateRouteWithSidebar />}>
          <Route path={authenticatedHomePage} element={<RouteWithSidebar component={HomeList} />} />
          <Route path="/Usuario" element={<RouteWithSidebar component={UserList} />} />
          <Route path="/Usuario/Cadastro" element={<RouteWithSidebar component={UserRegister} />} />
          <Route
            path="/Usuario/:mode/:id"
            element={<RouteWithSidebar component={UserRegister} />}
          />
          <Route path="/Colaborador" element={<RouteWithSidebar component={ColaboradorList} />} />
          <Route
            path="/Colaborador/Cadastro"
            element={<RouteWithSidebar component={ColaboradorRegister} />}
          />
          <Route
            path="/Colaborador/:mode/:id"
            element={<RouteWithSidebar component={ColaboradorRegister} />}
          />
          <Route
            path="/Timesheet/Aprovacao"
            element={<RouteWithSidebar component={FechamentoList} />}
          />
          <Route
            path="/Timesheet/Resumo"
            element={<RouteWithSidebar component={ResumoFechamento} />}
          />
          <Route
            path="/Fechamento/Cadastro"
            element={<RouteWithSidebar component={FechamentoRegister} />}
          />
          <Route
            path="/Fechamento/Cadastro/:id"
            element={<RouteWithSidebar component={FechamentoRegister} />}
          />
          <Route path="/Menu" element={<RouteWithSidebar component={MenuList} />} />
          <Route path="/Menu/Cadastro" element={<RouteWithSidebar component={MenuRegister} />} />
          <Route
            path="/Menu/Cadastro/:id"
            element={<RouteWithSidebar component={MenuRegister} />}
          />
          <Route path="/Timesheet" element={<RouteWithSidebar component={TimeSheetList} />} />
          <Route
            path="/Timesheet/Replicar/"
            element={<RouteWithSidebar component={MenuRegister} />}
          />
          <Route
            path="/Timesheet/Cadastro/:dia"
            element={<RouteWithSidebar component={TimeSheetRegister} />}
          />
          <Route
            path="/Timesheet/Consulta/:userId"
            element={<RouteWithSidebar component={TimeSheetList} />}
          />
          <Route
            path="/Timesheet/CadastrarHoraExtra/"
            element={<RouteWithSidebar component={TimeSheetOvertime} />}
          />
          <Route
            path="/Timesheet/InformacaoAdicional/"
            element={<RouteWithSidebar component={TimeSheetAdditionalInformation} />}
          />
          <Route path="/Feriado" element={<RouteWithSidebar component={HolidayList} />} />
          <Route
            path="/Feriado/Cadastro"
            element={<RouteWithSidebar component={FeriadoRegister} />}
          />
          <Route path="/Ferias" element={<RouteWithSidebar component={VacationUserList} />} />
          <Route path="/Ferias/:userId" element={<RouteWithSidebar component={VacationList} />} />
          <Route
            path="/Ferias/Cadastro"
            element={<RouteWithSidebar component={VacationRegister} />}
          />
          <Route
            path="/Ferias/:mode/:id"
            element={<RouteWithSidebar component={VacationRegister} />}
          />
          <Route path="/PedidoVenda" element={<RouteWithSidebar component={SalesOrderList} />} />
          <Route
            path="/PedidoVenda/Cadastro"
            element={<RouteWithSidebar component={SalesOrderRegister} />}
          />
          <Route
            path="/PedidoVenda/:mode/:id"
            element={<RouteWithSidebar component={SalesOrderRegister} />}
          />

          <Route path="/Cliente" element={<RouteWithSidebar component={ClientList} />} />
          <Route
            path="/Cliente/Cadastro"
            element={<RouteWithSidebar component={ClientRegister} />}
          />
          <Route
            path="/Cliente/:mode/:id"
            element={<RouteWithSidebar component={ClientRegister} />}
          />

          <Route path="/Perfil" element={<RouteWithSidebar component={ProfileList} />} />

          <Route
            path="/Perfil/:mode/:id?"
            element={<RouteWithSidebar component={ProfileRegister} />}
          />
        </Route>

        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
};

const PrivateRouteWithSidebar = () => {
  const authentication = useSelector((state: StoreModel) => state.authentication);
  const companyMenu = useSelector((state: StoreModel) => state.companyMenu);

  const location = useLocation();
  const normalizedLocation = removeTrailingSlash(location.pathname.toLowerCase());

  if (!authentication?.token) {
    return <Navigate to="/" />;
  }

  let menuList = companyMenu;

  if (companyMenu === undefined || companyMenu.length === 0) menuList = authentication?.menu;

  if (
    normalizedLocation !== authenticatedHomePage &&
    !menuList?.some(
      (menu) =>
        menu.urlTarget !== null &&
        normalizedLocation.startsWith(
          addLeadingSlash(removeTrailingSlash(menu.urlTarget.toLowerCase()))
        )
    )
  ) {
    return <Navigate to="/401" />;
  }

  return <Outlet />;
};

const PrivateRouteNoSidebar = ({ component: Component, ...rest }) => {
  return <RouteNoSidebar component={Component} {...rest} />;
};

export default SystemRoutes;
