export interface ColaboradorDadosModel {
  id: number; //Apenas para distinção se vai chamar a proc de insert ou update
  idEnterprise: number;
  idUser: number;
  idCollaborator: number;
  cpf: string;
  name: string;
  rg: string;
  issuingAuthority?: string;
  ufIssuingAuthority?: number;
  nationality?: number;
  maritalStatus?: number;
  gender?: number;
  neighborhood?: string;
  state?: number;
  city?: number;
  bank?: number;
  agency?: number;
  accountType?: number;
  account?: string;
  digit?: string;
  beneficiaryDocument?: any;
  beneficiaryName?: string;
  dtBirth?: string;
  phone?: string;
  email?: string;
  motherName?: string;
  street?: string;
  streetNumber?: string;
  complement?: string;
  cep?: string;
}

export const ColaboradorDadosModelDefault: ColaboradorDadosModel = {
  id: 0,
  idEnterprise: 0,
  idUser: 0,
  idCollaborator: 0,
  cpf: "",
  name: "",
  rg: "",
  issuingAuthority: null,
  ufIssuingAuthority: null,
  nationality: null,
  maritalStatus: null,
  gender: null,
  neighborhood: null,
  state: null,
  city: null,
  bank: null,
  agency: null,
  accountType: null,
  account: null,
  digit: null,
  beneficiaryDocument: null,
  beneficiaryName: null,
  dtBirth: null,
  phone: null,
  email: null,
  motherName: null,
  street: null,
  streetNumber: null,
  complement: null,
  cep: null
};
