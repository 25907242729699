//TODO: Obsoleto!
export interface ColaboradorRegistroModel {
  id: number;
  salesOrder: any;
  clientName: any;
  clientId: any;
  registerDate?: any;
  modality: any;
  capacity: number;
  workPlace: any;
  workSchedule: number;
  uf: number;
  city: number;
  collectionCenter: any;
  remuneration: number;
  timesheet: number;
  accessLogin: any;
  annualLeave: any;
  expertise: any;
}

export const ColaboradorRegistroModelDefault: ColaboradorRegistroModel = {
  id: 0,
  salesOrder: "",
  clientName: "",
  clientId: "",
  registerDate: "",
  modality: "",
  capacity: 0,
  workPlace: "",
  workSchedule: 0,
  uf: 0,
  city: 0,
  collectionCenter: "",
  remuneration: 0,
  timesheet: 0,
  accessLogin: "",
  annualLeave: "",
  expertise: ""
};
