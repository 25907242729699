/* eslint-disable import/no-anonymous-default-export */
const INITIAL_STATE = {
  loading: false,
  menuCollapsed: true,
  companyName: null,
  companyLogo: null,
  companyMenu: [],
  companyId: null,
  filtersList: [],
  optionsListFunction: null,
  filtersToSearch: [],
  authentication: {
    token: null,
  },
  user: null,
  orderBy: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'COLLAPSE_CHANGE':
      return {
        ...state,
        menuCollapsed: action.menuCollapsed,
      };
    case 'AUTHENTICATE':
      const newAuthentication = action.authentication || state.authentication;
      const newCompanyName =
        action.authentication.enterprises?.[0]?.name?.[0]?.name || state.companyName;
      const newCompanyId = action.authentication.enterprises?.[0]?.id || state.companyId;

      return {
        ...state,
        authentication: newAuthentication,
        companyName: newCompanyName,
        companyId: newCompanyId,
      };
    case 'SELECT_COMPANY':
      return {
        ...state,
        companyId: action.company.companyId,
        companyName: action.company.companyName,
        companyLogo: action.company.companyLogo || state.companyLogo,
        companyMenu: action.company.companyMenu,
      };
    case 'LOGOUT':
      return INITIAL_STATE;
    case 'START_REDUX':
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          fullName: action.fullName,
        },
      };
    case 'LOAD_FILTERS':
      return {
        ...state,
        filtersList: action.filtersList,
      };
    case 'SEARCH_FILTERS':
      return {
        ...state,
        filtersToSearch: action.filtersToSearch,
      };
    case 'FILTER_OPTIONS':
      return {
        ...state,
        optionsListFunction: action.optionsListFunction,
      };
    case 'ORDER_BY':
      return {
        ...state,
        orderBy: action.orderBy,
      };
    default:
      return state;
  }
};
