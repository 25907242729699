import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import { Header } from "../../components/Header";
import Fieldset from "../../components/Fieldset";

import { ClosingService } from "../../services/closing.service";
import { CadastroService } from "../../services/cadastro.service";

import { ButtonModel } from "../../models/components/button.model";
import { FechamentoModel } from "../../models/fechamento.model";
import { ToastService } from "../../services/toast.service";
import { useDispatch, useSelector } from "react-redux";
import { StoreModel } from "../../models/store.model";
import { RequestClosingForEditParamsModel } from "../../models/requestClosingForEdit.model";
import RegistryService from "../../services/registry.service";
import { RegistryModel } from "../../models/Registry.model";
import { RequestRegistryParamsModel } from "../../models/requestRegistry.model";
import { CPF_MASK } from "../../util/consts";

export const FechamentoRegister = () => {
  const { authentication, companyId } = useSelector(
    (state: StoreModel) => state
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: string }>();
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [fechamentoBg, setFechamentoBg] = useState("");
  const [registrySelected, setRegistrySelected] = useState(null);

  // ---

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(-1);
      },
      text: t("BACK"),
      variant: "outline-primary"
    },
    {
      onClick: () => {
        submitFormik();
      },
      text: t("SAVE")
    }
  ];

  // ---

  const [registryDropdown, setRegistryDropdown] = useState<RegistryModel[]>([]);
  const formRef = useRef();

  // ---

  const [initialValues, setInitialValues] = useState<FechamentoModel>({
    fechamento: {
      fechamentoPK: "",
      DT_FLH_MAF: "",
      NM_CLB_DS: "",
      NR_CLB_ID: "",
      NM_LTC_APL: "",
      ID_Usuario: authentication.usuario.result.ID_USUARIO
    }
  });

  const initialize = async () => {
    setLoading(true);

    await loadForEdit();

    setLoading(false);
  };

  const loadForEdit = async () => {
    if (id != undefined || id != null) {
      const paramns: RequestClosingForEditParamsModel = {
        idEmpresa: companyId,
        idUsuario: authentication.usuario.result.ID_USUARIO,
        idFechamento: id,
        error: {}
      };

      const apiResponse: any = await ClosingService.getById(paramns);

      if (apiResponse.result != null) {
        let dateTimeISO = Date.parse(apiResponse.result.DT_CLB_NSC);
        let dateFormat = new Date(dateTimeISO)
          .toISOString()
          .replace(/T.*/, "")
          .split("-")
          .reverse()
          .join("/");

        apiResponse.result.DT_CLB_NSC = dateFormat;

        const paramnsRegistry: RequestRegistryParamsModel = {
          idEmpresa: companyId,
          idUsuario: authentication.usuario.result.ID_USUARIO,
          idColaborador: id,
          dtRegEnt: null,
          error: {}
        };
        const apiRegistryResponse: any = await RegistryService.getList(
          paramnsRegistry
        );
        let registry: RegistryModel = null;
        if (apiRegistryResponse.resultList) {
          registry = apiRegistryResponse.resultList[0];
          setRegistryDropdown(apiRegistryResponse.resultList);
          setRegistrySelected(registry.DT_REG_ENT);
        }

        setInitialValues({
          ...initialValues,
          fechamento: {
            ...apiResponse.result,
            NR_EMP_ID: companyId,
            ID_Usuario: authentication.usuario.result.ID_USUARIO
          }
        });
      }
    }
  };

  const create = async (data: any) => {
    CadastroService.save(data)
      .then((response: any) => {
        // -- setando a pk
        if (response.resultList != null && response.error != null) {
          response.resultList.forEach((message: any) => {
            if (message.fechamento !== undefined) {
              setInitialValues({
                ...data,
                fechamento: {
                  ...data.fechamento,
                  fechamentoPK: data.fechamento.cpfFechamento
                }
              });
            }
          });
        }

        // -- setando o toast
        if (response.error != null) {
          let errorList: string[] = [];

          response.error.forEach((message: any) => {
            if (message.fechamento != undefined) {
              setFechamentoBg("#FFF5EE");
              errorList.push(message.fechamento);
            }
          });

          ToastService.error(errorList.join(" - "));
        } else {
          ToastService.success("sucesso");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const update = async (data) => {
    CadastroService.save(data)
      .then((response: any) => {
        // console.log(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = async (data) => {
    if (Number(id)) {
      await update(data);
    } else {
      await create(data);
    }
  };

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  useEffect(() => {
    dispatch({
      type: "LOAD_FILTERS",
      filtersList: []
    });

    dispatch({
      type: "SEARCH_FILTERS",
      filtersToSearch: []
    });
    initialize();
  }, []);

  return (
    <>
      <Header title="Gerenciamento de Fechamento" buttons={buttons} />
      <hr className="mb-3" />
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          onSubmit(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setValues
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Fieldset legend="Dados do Fechamento" background={fechamentoBg}>
              <Row className="align-items-center">
                <Col sm={12} md={6} lg={2} className="mb-2 mt-2">
                  <Form.Group className="form-group">
                    <MaskedFormControl
                      type="text"
                      id="fechamento.NR_CLB_ID"
                      name="fechamento.NR_CLB_ID"
                      mask={CPF_MASK}
                      size="sm"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={
                        touched.fechamento?.NR_CLB_ID &&
                        !!errors.fechamento?.NR_CLB_ID
                      }
                      value={values.fechamento?.NR_CLB_ID}
                      maxLength={15}
                    />
                    <Form.Label>{t("CPF")}</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.fechamento?.NR_CLB_ID}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={8} className="mb-2 mt-2">
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      id="fechamento.NM_CLB_DS"
                      name="fechamento.NM_CLB_DS"
                      size="sm"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={
                        touched.fechamento?.NM_CLB_DS &&
                        !!errors.fechamento?.NM_CLB_DS
                      }
                      value={values.fechamento?.NM_CLB_DS}
                      maxLength={60}
                    />
                    <Form.Label>{t("Nome")}</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.fechamento?.NM_CLB_DS}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={2} className="mb-2 mt-2">
                  <Form.Group className="form-group">
                    <MaskedFormControl
                      type="text"
                      id="fechamento.DT_FLH_MAF"
                      name="fechamento.DT_FLH_MAF"
                      mask="11/11/1111"
                      size="sm"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={
                        touched.fechamento?.DT_FLH_MAF &&
                        !!errors.fechamento?.DT_FLH_MAF
                      }
                      value={values.fechamento?.DT_FLH_MAF}
                      maxLength={15}
                    />
                    <Form.Label>{t("Data")}</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.fechamento?.DT_FLH_MAF}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col sm={12} md={6} lg={2} className="mb-2 mt-2">
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      id="fechamento.NM_LTC_APL"
                      name="fechamento.NM_LTC_APL"
                      size="sm"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={
                        touched.fechamento?.NM_LTC_APL &&
                        !!errors.fechamento?.NM_LTC_APL
                      }
                      value={values.fechamento?.NM_LTC_APL}
                      maxLength={60}
                    />
                    <Form.Label>{t("Lotação")}</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.fechamento?.NM_LTC_APL}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Fieldset>
          </Form>
        )}
      </Formik>
    </>
  );
};
