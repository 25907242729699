import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaFilePdf,
  FaRegFilePdf,
  FaFileExcel,
  FaRegFileExcel,
  FaCheck,
  FaTimes,
  FaRegTrashAlt,
  FaTrashAlt,
  FaRegFileArchive,
  FaFileArchive,
  FaRegCalendarAlt,
  FaCalendarAlt,
} from 'react-icons/fa';
import appsettings from '../../configs/appsettings.json';
import moment from 'moment';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';

import { ExportFunctions } from '../../util/ExportFunctions';
import { CustomModal } from '../../components/CustomModal';
import Fieldset from '../../components/Fieldset';

import { ButtonModel } from '../../models/components/button.model';
import { ClosingService } from '../../services/closing.service';
import { ToastService } from '../../services/toast.service';

import { ListRequest, RequestParamsModel } from '../../models/requestParams.model';
import { formMode, serviceControllers } from '../../util/enumerators';
import ListPageComponent from '../../components/ListPageComponent';

export const FechamentoList = () => {
  const navigate = useNavigate();
  const [dataset, setDataset] = useState<ListRequest>(RequestParamsModel('FECHAMENTO'));
  const [closingList, setClosingList] = useState({ items: [] });

  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [ultimoSelecionado, setUltimoSelecionado] = useState();
  const [selecionarTodos, setSelecionarTodos] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [observacaoError, setObservacaoError] = useState(false);
  const [modalObservacao, setModalObservacao] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const fileInputRef = useRef(null);

  const rootUrl = '/Timesheet';
  const serviceController = serviceControllers.CLOSING;
  const itemKey = 'NR_CLB_ID';
  const legend = 'Listagem de Fechamento';

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.VIEW}/${itensSelecionados[0].NR_CLB_ID}`);
      },
      onAuxClick: () => {
        const route = `#${rootUrl}/${formMode.VIEW}/${itensSelecionados[0].NR_CLB_ID}`;
        window.open(route, '_blank');
        return;
      },
      onContextMenu: () => {
        const route = `#${rootUrl}/${formMode.VIEW}/${itensSelecionados[0].NR_CLB_ID}`;
        window.open(route, '_blank');
        return;
      },
      text: 'Timesheet',
      disabled: !(itensSelecionados?.length === 1),
      icon: (
        <>
          <FaRegCalendarAlt className="fa-icon" /> <FaCalendarAlt className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Acessar Timesheet',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/Resumo`, {
          state: {
            selectedItens: itensSelecionados,
          },
        });
      },
      text: 'Resumo',
      disabled: isLoading,
      icon: (
        <>
          <FaRegFilePdf className="fa-icon" /> <FaFilePdf className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Baixar Resumo do Faturamento',
    },
    {
      onClick: async () => {
        setLoading(true);

        dataset.result = null;
        dataset.parameter = null;

        await ExportFunctions.downloadExcel(serviceControllers.CLOSING, dataset);

        setLoading(false);
      },
      text: 'Exportar',
      disabled: isLoading,
      icon: (
        <>
          <FaRegFileExcel className="fa-icon" />
          <FaFileExcel className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Exportar dados para Excel',
    },
    {
      onClick: async () => {
        setShowModal(true);
        setIsApprove(true);
      },
      text: 'Aprovar',
      disabled: itensSelecionados?.length === 0 || isLoading,
      icon: <FaCheck className="fa-icon-color-green" />,
      tooltip: 'Aprovar itens selecionados',
    },
    {
      onClick: async () => {
        setShowModal(true);
        setIsApprove(false);
      },
      text: 'Reprovar',
      disabled: itensSelecionados?.length === 0 || isLoading,
      icon: <FaTimes className="fa-icon-color-red" />,
      tooltip: 'Reprovar itens selecionados',
    },
  ];

  const modalButtons: ButtonModel[] = [
    {
      onClick: () => {
        handleButtonClick();
      },
      text: 'Anexar Arquivos',
      icon: (
        <>
          <FaRegFileArchive className="fa-icon" /> <FaFileArchive className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Anexar Arquivos',
    },
    {
      onClick: async () => {
        if ((!modalObservacao || !modalObservacao.trim().length) && !isApprove) {
          setObservacaoError(true);
          return;
        }

        if (modalObservacao && !modalObservacao.trim().length && isApprove) {
          setObservacaoError(true);
          return;
        }

        setLoading(true);

        const requestParams: ListRequest = dataset;
        const apiResponse: any = await ClosingService.sendAttachments(selectedFiles, requestParams);

        let idAnexo = null;

        if (apiResponse?.return?.success === true) {
          idAnexo = apiResponse.result.dados[0].ID_ANX_CTRL;
        }

        await handleApprove(isApprove, idAnexo);

        setLoading(false);
      },
      text: isApprove ? 'Aprovar' : 'Reprovar',
      icon: isApprove ? (
        <FaCheck className="fa-icon-color-green" />
      ) : (
        <FaTimes className="fa-icon-color-red" />
      ),
      tooltip: (isApprove ? 'Aprovar' : 'Reprovar') + ' itens selecionados',
    },
  ];

  const handleApprove = async (isApprove: boolean = true, idAnexo: any) => {
    let requestParams: any = {
      dateOfRoll: closingList.items[0].DT_FLH_MAF,
      ids: itensSelecionados?.map((o) => {
        return o.NR_CLB_ID;
      }),
      observation: modalObservacao,
      idAttachment: idAnexo,
    };

    let apiResponse: any;

    apiResponse = isApprove
      ? await ClosingService.approveItems(requestParams)
      : await ClosingService.reproveItems(requestParams);

    if (apiResponse.return.success) {
      ToastService.success((isApprove ? 'Aprovação' : 'Reprovação') + ' realizada com sucesso!');
    }

    setItensSelecionados([]);
    setSelectedFiles([]);
    setModalObservacao('');
    setShowModal(false);
  };

  const selecionaFechamento = (
    doc,
    index,
    e,
    ultimoSelecionado,
    setUltimoSelecionado,
    selectedItems,
    setItensSelecionados,
    itemList
  ) => {
    if (e.shiftKey && ultimoSelecionado !== null) {
      const startIndex = Math.min(ultimoSelecionado, index);
      const endIndex = Math.max(ultimoSelecionado, index);
      const newSelecionados = [...selectedItems];

      for (let i = startIndex; i <= endIndex; i++) {
        const item = itemList.items[i];
        if (!newSelecionados.some((selected) => selected.NR_CLB_ID === item.NR_CLB_ID)) {
          newSelecionados.push({
            NR_CLB_ID: item.NR_CLB_ID,
          });
        }
      }
      setItensSelecionados(newSelecionados);
    } else {
      const item = itemList.items[index];

      const updatedSelecionados = selectedItems?.some(
        (selected) => selected.NR_CLB_ID === item.NR_CLB_ID
      )
        ? selectedItems.filter((selected) => selected.NR_CLB_ID !== item.NR_CLB_ID)
        : [...selectedItems, { NR_CLB_ID: item.NR_CLB_ID }];

      setItensSelecionados(updatedSelecionados);
      setUltimoSelecionado(index);
    }
  };

  const toggleSelecionarTodos = ({ target: { checked } }) => {
    setSelecionarTodos(checked);

    const selectedItems = checked
      ? closingList.items.map((item) => {
          return { NR_CLB_ID: item.NR_CLB_ID };
        })
      : [];

    setItensSelecionados(selectedItems);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFiles([]);
    setModalObservacao('');
    setObservacaoError(false);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const maxSize = appsettings.ATTACHMENT_MAX_SIZE;

    if (
      selectedFiles.length < appsettings.ATTACHMENT_MAX_QUANTITY &&
      files.length <= appsettings.ATTACHMENT_MAX_QUANTITY
    ) {
      let isValidSize = true;

      files.forEach((file: File) => {
        if (file.size > maxSize) {
          isValidSize = false;
        }
      });

      if (isValidSize) {
        let filesArray = [...selectedFiles, ...files];
        setSelectedFiles(filesArray);
      } else {
        ToastService.warn('O tamanho máximo do anexo é de ' + maxSize / (1024 * 1024) + 'MB', {
          autoClose: 1500,
        });
      }
    } else {
      ToastService.warn('Máximo de 10 anexos', {
        autoClose: 1500,
      });
    }
  };

  const handleRemoveFile = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const modalBody = () => {
    const uniqueDates = Array.from(
      new Set(closingList.items.map((item) => moment(item.DT_FLH_MAF).format('MM/YYYY')))
    );
    const distinctDatesString = uniqueDates.join(', ');

    return (
      <>
        <Row>
          <Col xl={12}>
            <Fieldset legend={'Dados da ' + (isApprove ? 'Aprovação' : 'Reprovação')}>
              <Form className="m-2">
                <Row>
                  <Col xl={6}>
                    <Form.Group className="form-group">
                      <Form.Control
                        id="mesAno"
                        size="sm"
                        name="mesAno"
                        type="text"
                        value={distinctDatesString}
                        disabled={true}
                        style={{
                          marginBottom: 10,
                        }}
                      />
                      <Form.Label>Mês/Ano</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6}>
                    <Form.Group className="form-group">
                      <Form.Control
                        id="qtdConsultores"
                        size="sm"
                        name="qtdConsultores"
                        type="text"
                        value={itensSelecionados?.length}
                        disabled={true}
                        style={{
                          marginBottom: 10,
                        }}
                      />
                      <Form.Label>Qtd. Consultores</Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl={12}>
                    <Form.Group className="form-group">
                      <Form.Control
                        id="observacao"
                        size="sm"
                        name="observacao"
                        type="text"
                        as="textarea"
                        rows={4}
                        value={modalObservacao}
                        onChange={(e) => {
                          setModalObservacao(e.target.value);
                          setObservacaoError(false);
                        }}
                        disabled={false}
                        required={!isApprove}
                        style={{
                          marginBottom: 10,
                        }}
                        placeholder={isApprove ? '(Opcional)' : '(Obrigatório)'}
                      />
                      <Form.Label>
                        Observação {!isApprove && ' *'}{' '}
                        {observacaoError && (
                          <a style={{ color: 'red', fontSize: '0.8rem' }}>Campo obrigatório</a>
                        )}
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Fieldset>
          </Col>
        </Row>

        <Row>
          <Col xl={12}>
            <Fieldset legend="Lista de Anexos" style={{ overflowX: 'auto'}}>
              <Form.Group style={{ textAlign: 'right' }}>
                <Form.Control
                  id="anexo"
                  size="sm"
                  name="anexo"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
              </Form.Group>
              <div
                style={{
                  maxHeight: '250px',
                  overflowY: 'auto',
                }}
              >
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr style={{ borderTopStyle: 'hidden' }}>
                      <th style={{ padding: 5 }}>Arquivo</th>
                      <th style={{ textAlign: 'center', padding: 5 }}>Excluir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedFiles.length > 0 ? (
                      selectedFiles?.map((file, index) => (
                        <>
                          <tr>
                            <td style={{ padding: 5 }}>{file.name}</td>
                            <td style={{ textAlign: 'center', padding: 5 }}>
                              <Button
                                size="sm"
                                variant={'danger'}
                                disabled={false}
                                onClick={() => handleRemoveFile(file.name)}
                                className="icon-button"
                                title={'Excluir arquivo'}
                              >
                                <div className="div-button" style={{ color: 'red' }}>
                                  <>
                                    <FaRegTrashAlt className="fa-icon" />{' '}
                                    <FaTrashAlt className="fa-icon-hover" />
                                  </>
                                </div>
                              </Button>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <tr>
                        <td style={{ padding: 5 }}> Nenhum anexo adicionado</td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Fieldset>
          </Col>
        </Row>
      </>
    );
  };
   
  useEffect(() => {
    setShowModal(false);
    setSelectedFiles([]);
    setModalObservacao('');
  }, []);

  return (
    <>
      <ListPageComponent
        idDataset={'FECHAMENTO'}
        serviceController={serviceController}
        legend={legend}
        customSelectFunction={selecionaFechamento}
        selectedItem={itensSelecionados}
        setSelectedItem={setItensSelecionados}
        buttons={buttons}
        referenceColumn={itemKey}
        selecionarTodos={selecionarTodos}
        toggleSelecionarTodos={toggleSelecionarTodos}
        setUltimoSelecionado={setUltimoSelecionado}
        ultimoSelecionado={ultimoSelecionado}
        externalDataList={closingList}
        setExternalDataList={setClosingList}
        externalDataSet={dataset}
        setExternalDataSet={setDataset}
      />
      <CustomModal
        show={showModal}
        title={(isApprove ? 'Aprovar' : 'Reprovar') + ' horas'}
        children={modalBody()}
        handleClose={handleCloseModal}
        buttons={modalButtons}
      />
    </>
  );
};
