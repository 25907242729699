import { Button, Modal } from 'react-bootstrap';
import { ButtonModel } from '../models/components/button.model';
import { FaArrowLeft } from 'react-icons/fa';
import { adjustIdName } from '../util';

interface IProps {
  title: string;
  show?: boolean;
  handleClose: any;
  children: any;
  footer?: any;
  buttons?: ButtonModel[];
  handleShow?: any;
  styleContent?: {};
}

export const CustomModal = (props: IProps) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} animation={false} onShow={props.handleShow}>
      <Modal.Header
        closeButton
        style={{ paddingBottom: 5, paddingTop: 10, backgroundColor: '#f2f3f8' }}
      >
        <Modal.Title style={{ fontSize: 'var(--default-font-size)', fontWeight: 700 }}>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          fontSize: 'var(--default-font-size)',
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: '#f2f3f8',
        }}
      >
        {props.children}
      </Modal.Body>
      <div className="modal-actions-div">
        <div>
          <Button
            size="sm"
            variant={'primary'}
            onClick={props.handleClose}
            className="icon-button"
            title={'Voltar para a tela anterior'}
          >
            <div className="div-button">
              <span className="fa-icon-button">
                <FaArrowLeft className="fa-icon-color-red" />
              </span>{' '}
              <span className="text-button">Voltar</span>
            </div>
          </Button>
        </div>
        <div style={{ paddingBottom: 10 }}>
          {props.buttons?.map((button: ButtonModel, idx) => {
            return (
              <>
                {button.icon != null ? (
                  <>
                    <Button
                      key={idx}
                      id={'btn-' + adjustIdName(button.text)}
                      size="sm"
                      variant={button.variant}
                      disabled={button.disabled}
                      onClick={button.onClick}
                      className="icon-button"
                      title={button.tooltip}
                    >
                      <div className="div-button" style={{ paddingLeft: 5, paddingRight: 5 }}>
                        <span className="fa-icon-button">{button.icon}</span>{' '}
                        <span className="text-button">{button.text}</span>
                      </div>
                    </Button>
                    &nbsp;&nbsp;
                  </>
                ) : (
                  <>
                    <Button
                      size="sm"
                      variant={button.variant}
                      disabled={button.disabled}
                      onClick={button.onClick}
                    >
                      {button.text}
                    </Button>
                    {'  '}
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
      {/* <Modal.Footer
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          backgroundColor: "#f2f3f8"
        }}
      >
        {props.footer}
      </Modal.Footer> */}
    </Modal>
  );
};
