import { useEffect } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { SideNav } from './SideNav';
import { TopBar } from './TopBar';

import PageHeader from '../containers/main/header';
import PageFooter from '../containers/main/footer';
import { StoreModel } from '../models/store.model';
import { returnCurrentEnterprise } from '../util';

export const RouteWithSidebar = ({ component: Component = null, ...rest }) => {
  const authentication = useSelector((state: StoreModel) => state.authentication);
  const companyId = useSelector((state: StoreModel) => state.companyId);

  useEffect(() => {
    const root = document.documentElement;

    let enterprise = returnCurrentEnterprise(authentication, companyId);

    root?.style.setProperty('--cor-label', enterprise?.labelColor || 'black');
    root?.style.setProperty('--cor-menu-label', enterprise?.labelMenuColor || '#aea9c3');
    root?.style.setProperty('--cor-menu-principal', enterprise?.primaryMenuColor || '#2c304d');
    root?.style.setProperty('--cor-menu-secundaria', enterprise?.secondaryMenuColor || '#2c304d');
    root?.style.setProperty('--cor-principal', enterprise?.primaryColor || '#2c304d');
    root?.style.setProperty('--cor-secundaria', enterprise?.secondaryColor || '#2c304d');
    root?.style.setProperty(
      '--cor-principal-background',
      enterprise?.secondaryColor + '50' || '#2c304d50'
    );
    root?.style.setProperty('--primary', enterprise?.primaryColor || '#2c304d');
    root?.style.setProperty('--secondary', enterprise?.secondaryColor || '#2c304d');
    root?.style.setProperty('--default-font-size', '11px');
  }, [companyId]);

  return (
    <Container fluid className="container-geral">
      <TopBar />
      <Col className="d-flex bg-content-geral" sm={12} xl={12}>
        <SideNav />
        <div className="content-geral">
          <Component />
        </div>
      </Col>
    </Container>
  );
};

export const RouteNoSidebar = ({ component: Component = null, ...rest }) => {
  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty('--cor-label', 'black');
    root?.style.setProperty('--cor-menu-label', '#aea9c3');
    root?.style.setProperty('--cor-menu-principal', '#2c304d');
    root?.style.setProperty('--cor-menu-secundaria', '#2c304d');
    root?.style.setProperty('--cor-principal', '#2c304d');
    root?.style.setProperty('--cor-secundaria', '#2c304d');
    root?.style.setProperty('--cor-principal-background', '#2c304d50');
    root?.style.setProperty('--primary', '#2c304d');
    root?.style.setProperty('--secondary', '#2c304d');
    root?.style.setProperty('--default-font-size', '11px');
  }, []);

  return (
    <Container fluid className="container-geral">
      <TopBar hideControls={true} />
      <Col className="d-flex bg-content-geral" sm={12} xl={12}>
        <div className="content-geral">
          <Component />
        </div>
      </Col>
    </Container>
  );
};

export const RouteWithSiteHeader = ({ component: Component = null, ...rest }) => {
  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty('--cor-label', 'black');
    root?.style.setProperty('--cor-menu-label', '#aea9c3');
    root?.style.setProperty('--cor-menu-principal', '#2c304d');
    root?.style.setProperty('--cor-menu-secundaria', '#2c304d');
    root?.style.setProperty('--cor-principal', '#2c304d');
    root?.style.setProperty('--cor-secundaria', '#2c304d');
    root?.style.setProperty('--cor-principal-background', '#2c304d50');
    root?.style.setProperty('--primary', '#2c304d');
    root?.style.setProperty('--secondary', '#2c304d');
    root?.style.setProperty('--default-font-size', '11px');
  }, []);

  return (
    <Container
      fluid
      className="container-geral"
      style={{ paddingTop: 100, backgroundColor: 'black' }}
    >
      <PageHeader isResetingPassword={false} />
      <Col className="d-flex bg-content-geral" sm={12} xl={12}>
        <div className="content-geral" style={{ height: '100%', padding: 0 }}>
          <div style={{ padding: 30 }}>
            <Component />
          </div>
          <PageFooter />
        </div>
      </Col>
    </Container>
  );
};
