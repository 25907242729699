export interface RequestTimeSheetParamsModel {
  execucao: ListRequest;
}
export interface ListRequest {
  parametro: any;

  retorno: boolean;

  pesquisa: any;

  paginacao: any;
}

export const RequestTimeSheetParamsModelDefault: RequestTimeSheetParamsModel = {
  execucao: {
    parametro: { NR_EMP_ID: 3204529000115, ID_USUARIO: 3 },
    retorno: true,
    pesquisa: {
      campo: []
    },
    paginacao: {
      registros: {
        total: 0
      },
      pagina: {
        numero: 1,
        tamanho: 50
      },
      ordem: {
        campo: "DT_FLH_MAF",
        direcao: "DESC"
      }
    }
  }
};

// export interface RequestTimeSheetParamsModel {
//   idColaborador: number;
//   idEmpresa: number;
//   idUsuario: number;
//   dtRegistroEntrada: string;
//   dtFolhaFechamento: string;
//   parametro: any;
//   erro: any;
// }

// export const RequestTimeSheetParamsModelDefault: RequestTimeSheetParamsModel = {
//   idColaborador: 4871212521,
//   idEmpresa: 3204529000115,
//   idUsuario: 1426,
//   dtRegistroEntrada: "",
//   dtFolhaFechamento: "",
//   parametro: {
//     retorno: false,
//     pesquisa: {
//       nome: "",
//       documento: ""
//     },
//     pagina: {
//       records: {
//         count: 0
//       },
//       page: {
//         number: 0,
//         size: 0
//       },
//       order: {
//         field: 0,
//         direction: 0
//       }
//     }
//   },
//   erro: {}
// };
