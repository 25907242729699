import React, { useEffect, useRef, useState } from 'react';
import { IManagerForm, managerMapSave } from './manager.mapping';
import { ManagerInitial } from '../../../models/cliente.model';
import { FaRegSave, FaSave } from 'react-icons/fa';
import { fieldTypes, serviceControllers } from '../../../util/enumerators';
import { Header } from '../../../components/Header';
import { Form, Formik } from 'formik';
import { DynamicForm } from '../../../components/DynamicForm';
import ClientDepartManagerService from '../../../services/client.depart.manager.service';
import BaseService from '../../../services/base.service';
import { ToastService } from '../../../services/toast.service';
import { CELLPHONE_MASK } from '../../../util/consts';

interface IManagerEdit {
  idDepart: number | boolean;
  idClient: number | boolean;
  idManager: number | boolean;
  refreshList?: () => void;
  handleClose?: any;
  mode?: 'add' | 'edit' | 'view';
}

const ManagerEdit = ({
  idClient,
  idDepart,
  idManager,
  refreshList,
  handleClose,
  mode,
}: IManagerEdit) => {
  const formRef = useRef();
  const [initialValues, setInitialValues] = useState<IManagerForm>({ manager: ManagerInitial });

  const handleSave = async (values: IManagerForm) => {
    var managerSaveObject = managerMapSave(values);

    await BaseService.insert(serviceControllers.CLIENT_MANAGER, managerSaveObject).then(
      (response: any) => {
        if (response.return.success === true) {
          ToastService.success('Dados salvos com sucesso!');
          refreshList();
          handleClose();
        } else {
          ToastService.error('Erro ao salvar dados do departamento.');
        }
      }
    );
  };

  const btnTools = [
    {
      onClick: () => {
        submitFormik();
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FaRegSave className="fa-icon" />
          <FaSave className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Salvar / Atualizar',
    },
  ];

  const statusOptions = [
    {
      value: '1',
      label: 'ATIVO',
    },
    {
      value: '0',
      label: 'INATIVO',
    },
  ];

  const fieldsForm = [
    {
      field: 'name',
      label: 'Nome',
      placeholder: 'Nome completo do gestor',
      lgSize: 12,
      styleField: {
        textTransform: 'uppercase',
      },
      disabled: mode === 'view',
    },
    {
      field: 'alias',
      label: 'Apelido',
      placeholder: 'Apelido do gestor',
      lgSize: 12,
      styleField: {
        textTransform: 'uppercase',
      },
      disabled: mode === 'view',
    },
    {
      field: 'email',
      label: 'Email',
      placeholder: 'Email do gestor',
      lgSize: 12,
      disabled: mode === 'view',
    },
    {
      field: 'phone',
      label: 'Telefone',
      type: fieldTypes.MASKED,
      mask: CELLPHONE_MASK,
      placeholder: 'Telefone do gestor',
      lgSize: 6,
      disabled: mode === 'view',
    },
    {
      field: 'status',
      label: 'Status',
      type: fieldTypes.SELECT,
      optionsList: statusOptions,
      lgSize: 6,
      value: 'value',
      displayText: 'label',
      disabled: mode === 'view',
    },
    {
      field: 'contactName',
      label: 'Nome do Contato - Gestor',
      placeholder: 'Nome do contato com o gestor',
      lgSize: 12,
      disabled: mode === 'view',
    },
    {
      field: 'contactEmail',
      label: 'Email do Contato - Gestor',
      placeholder: 'Email do contato com o gestor',
      lgSize: 12,
      disabled: mode === 'view',
    },
  ];

  /*const validationSchema = Yup.object().shape({
    department: Yup.object().shape({
      name: Yup.string().required('Campo obrigatório'),
      alias: Yup.string().required('Campo obrigatório'),
      status: Yup.string().test('valid-status', 'Status Invalido', function (value) {
        return value === '1' || value === '0';
      }),
    }),
  });*/

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  useEffect(() => {
    const getManager = async () => {
      //if (typeof idDepart === 'number' && typeof idManager === 'number') {
      await ClientDepartManagerService.getById(serviceControllers.CLIENT_MANAGER, [
        {
          id: 'ID_CLIENTE_DEPTO',
          value: typeof idDepart === 'number' ? idDepart : 0,
        },
        {
          id: 'NR_CLI_ID',
          value: idClient,
        },
        {
          id: 'ID_CLIENTE_GESTOR',
          value: typeof idManager === 'number' ? idManager : 0,
        },
      ]).then((response: any) => {
        if (response.result?.dados) {
          setInitialValues({
            manager: {
              id: response.result?.dados[0].ID_CLIENTE_GESTOR,
              cnpj: idClient.toString(),
              name: response.result?.dados[0].NM_CGS_DS,
              alias: response.result?.dados[0].NM_CGS_APL,
              email: response.result?.dados[0].NM_CGS_EMAIL,
              phone: response.result?.dados[0].NR_CGS_TEL,
              contactName: response.result?.dados[0].NM_CGC_CON,
              contactEmail: response.result?.dados[0].NM_CGC_CON_EMAIL,
              status: response.result?.dados[0].CD_STATUS ? '1' : '0',
              //approveTS: response.result?.dados[0].IND_APR_TSH === 1 ? '1' : '0',
            },
          });
        }
      });
      //}
    };
    setInitialValues((prev) => ({
      manager: {
        ...prev.manager,
        cnpj: idClient.toString(),
      },
    }));
    getManager();
  }, [idDepart, idClient, idManager]);

  return (
    <>
      <Header
        title="Cadastro de Gestores"
        buttons={btnTools}
        previousPage="Listagem de Gestores"
        previousUrl={() => {}}
        showBread={false}
      />
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleSave(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setValues,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <DynamicForm
                fieldList={fieldsForm}
                form="manager"
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                values={values}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ManagerEdit;
