import { serviceControllers } from "../util/enumerators";
import HttpService from "./http.service";
const basePath = serviceControllers.COLLABORATOR;

export const CollaboratorService = {
  insert,
  getDataByPostalCode
};

//Está sendo usado pois há a necessidade de um endpoint que permita salvar sem estar logado
async function insert(data: any) {
  return HttpService.post(`${basePath}/Save`, data);
}

async function getDataByPostalCode(cep: string) {
  return HttpService.get(`/PostalCode?postalCode=${cep}`);
}

export default CollaboratorService;
