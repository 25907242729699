// export interface RequestMenuParamsModel {
//   idEmpresa: any;
//   idUsuario: any;
//   idPerfil: any;
//   idMenu: any;
//   idMenuPai: any;
//   status: boolean;
//   descricao: any;
//   error: any;
// }

export interface RequestMenuParamsModel {
  execucao: ListRequest;
}
export interface ListRequest {
  parametro: any;

  retorno: boolean;

  pesquisa: any;

  paginacao: any;
}

export const RequestMenuParamsModelDefault: RequestMenuParamsModel = {
  // idEmpresa: 3204529000115,
  // status: true,
  // idUsuario: 0,
  // idPerfil: 0,
  // idMenu: 0,
  // idMenuPai: 0,
  // descricao: "",
  // error: {
  //   erroR_NUMBER: 0,
  //   erroR_SEVERITY: 0,
  //   erroR_STATE: 0,
  //   erroR_PROCEDURE: "",
  //   erroR_LINE: 0,
  //   erroR_MESSAGE: ""
  // }
  execucao: {
    parametro: { NR_EMP_ID: 3204529000115, ID_USUARIO: 3 },
    retorno: true,
    pesquisa: {
      campo: []
    },
    paginacao: {
      registros: {
        total: 0
      },
      pagina: {
        numero: 1,
        tamanho: 50
      },
      ordem: {
        campo: "NM_CLB_DS",
        direcao: "ASC"
      }
    }
  }
};
