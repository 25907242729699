import HttpService from "./http.service";
import { RequestRegistryParamsModel } from "../models/requestRegistry.model";
const basePath = "/Registry";

export const RegistryService = {
  getList
};

async function getList(data: RequestRegistryParamsModel) {
  return HttpService.post(`${basePath}/GetRegistryWithFilter`, data);
}

export default RegistryService;
