import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaRegEdit,
  FaEdit,
  FaRegEye,
  FaEye,
  FaUserPlus,
  FaFileExcel,
  FaRegFileExcel,
} from 'react-icons/fa';
import { ButtonModel } from '../../models/components/button.model';
import { formMode, serviceControllers } from '../../util/enumerators';
import ListPageComponent from '../../components/ListPageComponent';
import { ExportFunctions } from '../../util/ExportFunctions';
import { RequestParamsModel } from '../../models/requestParams.model';

export const UserList = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const rootUrl = '/Usuario';
  const serviceController = serviceControllers.USER;
  const itemKey = 'ID_USUARIO';
  const legend = 'Listagem de Usuários';

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.VIEW}/${selectedItem}`);
      },
      text: 'Visualizar',
      disabled: !Number.isInteger(selectedItem),
      icon: (
        <>
          <FaRegEye className="fa-icon" />
          <FaEye className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Visualizar dados do usuário selecionado',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}/${selectedItem}`);
      },
      text: 'Editar',
      disabled: !Number.isInteger(selectedItem),
      icon: (
        <>
          <FaRegEdit className="fa-icon" />
          <FaEdit className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Editar usuário selecionado',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}`);
      },
      text: 'Novo',
      disabled: false,
      icon: <FaUserPlus className="fa-icon-color-green" />,
      tooltip: 'Adicionar novo usuário',
    },
    {
      onClick: async () => {
        setLoading(true);

        await ExportFunctions.downloadExcel(serviceController, RequestParamsModel('USUARIO'));

        setLoading(false);
      },
      text: 'Exportar',
      disabled: isLoading,
      icon: (
        <>
          <FaRegFileExcel className="fa-icon" />
          <FaFileExcel className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Exportar dados para Excel',
    },
  ];

  return (
    <ListPageComponent
      idDataset="USUARIO"
      serviceController={serviceController}
      legend={legend}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      buttons={buttons}
      referenceColumn={itemKey}
    />
  );
};
