import { DepartmentModel } from '../../../models/cliente.model';
import { formatCpfCnpj, removeFormatting } from '../../../util';
export interface IDepartForm {
  department: DepartmentModel;
}

export const departmentDataMapping = (data, setInitialValues) => {
  const departmentData: DepartmentModel[] = data.map((item, idx) => ({
    id: item['ID_CLIENTE_DEPTO'],
    cnpj: formatCpfCnpj(item['NR_CLI_ID']),
    name: item['NM_CDP_DS'],
    alias: item['NM_CDP_APL'],
    status: item['CD_STATUS'],
  }));

  setInitialValues((prev) => {
    return {
      ...prev,
      department: departmentData,
    };
  });
};

export const departmentMapSave = (data: IDepartForm) => {
  const saveDepartObj: DepartmentModel = {
    id: data.department.id,
    cnpj: data.department.cnpj,
    name: data.department.name,
    alias: data.department.alias,
    status: data.department.status,
  };

  return saveDepartObj;
};
