import { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { TableComponent } from '../../../components/TableComponent';
import { ListRequest, RequestParamsModel } from '../../../models/requestParams.model';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RetentionModel } from '../../../models/cliente.model';
import BaseService from '../../../services/base.service';
import { serviceControllers } from '../../../util/enumerators';
import { tableColumns } from '../../../util/listFunctions';
import ClientRetentionService from '../../../services/client.retention.service';
import { retentionDataMapping } from './retention.mapping';
import { formatCpfCnpj, formatDate } from '../../../util';
import { Table } from 'react-bootstrap';
import { DynamicHeader } from '../../../components/dynamicTable/DynamicHeader';
import { FaCheck } from 'react-icons/fa';
import { DynamicColumn } from '../../../components/dynamicTable/DynamicColumn';
import { EmptyTable } from '../../../components/EmptyTable';

interface IRetentionHistory {
  idClient: string;
}
const RetentionHistory = ({ idClient }: IRetentionHistory) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters = useSelector((state: any) => state.filtersToSearch);
  const [isLoading, setIsLoading] = useState(false);
  const [retentions, setRetentions] = useState<any[]>([]);
  const [dataset, setDataset] = useState<ListRequest>(RequestParamsModel('RETENCAO'));
  const [gridColumns, setGridColumns] = useState([]);

  const loadPageData = async () => {
    if (idClient !== undefined || idClient != null) {
      setIsLoading(true);
      const apiResponse: any = await ClientRetentionService.getById(
        serviceControllers.CLIENT_RETENTION,
        [
          {
            id: 'NR_DOC_ID',
            value: idClient,
          },
          {
            id: 'CD_RET_TP',
            value: 2,
          },
          {
            id: 'FL_STATUS',
            value: -1,
          },
        ]
      );

      if (apiResponse.result != null) {
        let columns = tableColumns(apiResponse);
        const data = apiResponse.result?.dados;

        setGridColumns(columns);
        setRetentions(data);
      }

      setIsLoading(false);
    }
  };
  const initialize = async () => {
    setIsLoading(true);
    await loadPageData();
    setIsLoading(false);
  };

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });

    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });

    initialize();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {retentions.length > 0 ? (
        <Table striped bordered hover size="sm">
          <DynamicHeader
            gridColumns={gridColumns}
            selecionarTodos={() => {}}
            toggleSelecionarTodos={() => {}}
          />

          <tbody>
            {retentions.map((i, index) => (
              <tr
                style={{
                  backgroundColor: 'var(--cor-principal-background)',
                }}
                key={index}
                onClick={() => {}}
                onDoubleClick={() => {}}
              >
                <td
                  style={{
                    padding: 0,
                    width: 5,
                  }}
                />
                <DynamicColumn
                  key={index}
                  gridColumns={gridColumns}
                  data={i}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTable />
      )}
    </>
  );
};

export default RetentionHistory;
