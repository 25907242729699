import Fieldset from '../../components/Fieldset';
import { Row } from 'react-bootstrap';
import { domainTypes, fieldTypes } from '../../util/enumerators';
import { CNPJ_MASK, TABELA_CLIENTE_FORNECEDOR } from '../../util/consts';
import { DynamicForm } from '../../components/DynamicForm';
import { loadDropDowns } from '../../util/domainFunctions';
import { useEffect, useState } from 'react';

interface IDadoBasicos {
  touched: any;
  errors: any;
  handleChange: any;
  setFieldValue: any;
  handleBlur: any;
  values: any;
}

const DadosBasicos = ({
  touched,
  errors,
  handleChange,
  setFieldValue,
  handleBlur,
  values,
}: IDadoBasicos) => {
  const [optionSituation, setOptionSituation] = useState([]);
  const [optionSeller, setOptionSeller] = useState([]);

  const basicFields = [
    {
      type: fieldTypes.MASKED,
      field: 'cnpj',
      label: 'CNPJ',
      mask: CNPJ_MASK,
      lgSize: 6,
    },
    {
      type: fieldTypes.SELECT,
      field: 'situation',
      label: 'Situação',
      optionsList: optionSituation,
      lgSize: 6,
      value: 'NM_KEY',
      displayText: 'DS_VALUE',
    },
    {
      field: 'corporateName',
      label: 'Razão Social',
      lgSize: 12,
    },
    {
      field: 'alias',
      label: 'Apelido',
      lgSize: 5,
    },
    {
      type: fieldTypes.SELECT,
      field: 'seller',
      label: 'Vendedor',
      optionsList: optionSeller,
      lgSize: 7,
      value: 'NR_VEN_ID',
      displayText: 'NM_VEN_DS',
    },
    // {
    //   type: fieldTypes.CHECKBOX,
    //   field: 'clientChoice',
    //   label: 'Cliente',
    //   lgSize: 1,
    //   style: {
    //     marginTop: '18px',
    //   },
    //   styleLabel: {
    //     marginTop: '3px',
    //   },
    // },
    // {
    //   type: fieldTypes.CHECKBOX,
    //   field: 'supplierChoice',
    //   label: 'Fornecedor',
    //   lgSize: 1,
    //   style: {
    //     marginTop: '18px',
    //   },
    //   styleLabel: {
    //     marginTop: '3px',
    //   },
    // },
  ];
  const marginFields = [
    {
      type: 'number',
      field: 'startRange1',
      label: 'Inicio - Faixa 1',
      lgSize: 3,
      step: '.01',
    },
    {
      type: 'number',
      field: 'endRange1',
      label: 'Final - Faixa 1',
      lgSize: 3,
      step: '.01',
    },
    {
      type: 'number',
      field: 'startRange2',
      label: 'Inicio - Faixa 2',
      lgSize: 3,
      step: '.01',
    },
    {
      type: 'number',
      field: 'endRange2',
      label: 'Final - Faixa 2',
      lgSize: 3,
      step: '.01',
    },
    {
      type: 'number',
      field: 'kickback',
      label: '% Comissão',
      lgSize: 6,
      step: '.01',
    },
    {
      type: 'number',
      field: 'partCLT',
      label: 'Aliq. CLT',
      lgSize: 6,
      step: '.01',
    },
  ];

  const domainLists = [
    {
      domain: domainTypes.FLAGLIST,
      table: TABELA_CLIENTE_FORNECEDOR,
      column: 'NR_SIT_CD',
      setList: setOptionSituation,
    },
    {
      domain: domainTypes.SELLER,
      setList: setOptionSeller,
    },
  ];

  const initialize = async () => {
    await loadDropDowns(domainLists);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <Fieldset legend="Dados Básicos">
      <Row style={{ margin: '10px 8px 0 8px' }}>
        <DynamicForm
          form="basic"
          fieldList={basicFields}
          touched={touched}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          values={values}
        />
      </Row>
      <Row style={{ margin: '10px 8px 0px 8px' }}>
        <Fieldset legend="Margens" style={{ paddingTop: '10px' }}>
          <DynamicForm
            form="basic"
            fieldList={marginFields}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            values={values}
          />
        </Fieldset>
      </Row>
    </Fieldset>
  );
};

export default DadosBasicos;
