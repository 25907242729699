import React, { useState } from 'react';
import Fieldset from '../../../components/Fieldset';
import { Header } from '../../../components/Header';
import { ButtonModel } from '../../../models/components/button.model';
import { FiDelete, FiEdit2, FiEdit3, FiEye, FiPlus } from 'react-icons/fi';
import { DynamicForm } from '../../../components/DynamicForm';
import { fieldTypes } from '../../../util/enumerators';
import { DATE_MASK } from '../../../util/consts';
import { Loading } from '../../../components/Loading';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import RetentionHistory from './retention.history';
import RetentionModal from './retention.modal';
import { RetentionInitial } from '../../../models/cliente.model';
import { FaPlus, FaRegEye } from 'react-icons/fa';

interface IRetentionClient {
  previousUrl: () => any | undefined;
  touched: any;
  errors: any;
  handleChange: any;
  setFieldValue: any;
  handleBlur: any;
  values: any;
}

const RetentionClient = (props: IRetentionClient) => {
  const [showModal, setShowModal] = useState(false);
  const [edition, setEdition] = useState(false);
  const retentionFields = [
    {
      type: fieldTypes.MASKED,
      field: 'effective',
      label: 'Vigência',
      mask: DATE_MASK,
      lgSize: 12,
      disabled: true,
    },
    {
      type: 'number',
      field: 'pis',
      label: 'PIS',
      onChange: props.handleChange,
      lgSize: 6,
      disabled: !edition,
      step: '.01',
    },
    {
      type: 'number',
      field: 'csll',
      label: 'CSLL',
      onChange: props.handleChange,
      lgSize: 6,
      disabled: !edition,
      step: '.01',
    },
    {
      type: 'number',
      field: 'confins',
      label: 'COFINS',
      onChange: props.handleChange,
      lgSize: 6,
      disabled: !edition,
      step: '.01',
    },
    {
      type: 'number',
      field: 'inss',
      label: 'INSS',
      onChange: props.handleChange,
      lgSize: 6,
      disabled: !edition,
      step: '.01',
    },
    {
      type: 'number',
      field: 'ir',
      label: 'IR',
      onChange: props.handleChange,
      lgSize: 6,
      disabled: !edition,
      step: '.01',
    },
    {
      type: 'number',
      field: 'iss',
      label: 'ISS',
      onChange: props.handleChange,
      lgSize: 6,
      disabled: !edition,
      step: '.01',
    },
    {
      field: 'observation',
      label: 'Observação',
      onChange: props.handleChange,
      lgSize: 12,
      disabled: !edition,
    },
  ];
  const btnRetention: ButtonModel[] = [
    {
      onClick: () => {
        setShowModal(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiEye className="fa-icon" />
          <FaRegEye className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),

      tooltip: 'Histórico - Retenções',
    },
    {
      onClick: () => {
        props.setFieldValue('retention', RetentionInitial);
        props.setFieldValue('retention.cnpj', props.values.basic.cnpj);
        setEdition(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiPlus className="fa-icon" />
          <FaPlus className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Adicionar - Retenções',
    },
    {
      onClick: () => {
        setEdition(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiEdit2 className="fa-icon" />
          <FiEdit3 className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Editar - Retenções',
    },
  ];

  return (
    <>
      <Fieldset legend="Retenções">
        <Header
          title="Cadastro de Cliente e Fornecedor"
          buttons={btnRetention}
          previousPage="Listagem de Clientes e Fornecedores"
          previousUrl={props.previousUrl}
          showBread={false}
        />
        {/* {JSON.stringify(props.values)} */}
        <DynamicForm
          form="retention"
          fieldList={retentionFields}
          touched={props.touched}
          errors={props.errors}
          handleChange={props.handleChange}
          setFieldValue={props.setFieldValue}
          handleBlur={props.handleBlur}
          values={props.values}
        />
      </Fieldset>
      <RetentionModal
        title={'LISTA DE RETENÇÕES'}
        show={showModal}
        handleClose={() => {
          setShowModal((prev) => !prev);
        }}
        handleConfirm={null}
        children={<RetentionHistory idClient={props.values?.retention?.id} />}
        style={{
          width: 'fit-content',
        }}
      />
    </>
  );
};

export default RetentionClient;
