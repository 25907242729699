import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row, ListGroup } from 'react-bootstrap';

import { Menu as MenuModel } from '../../models/menu.model';
import { StoreModel } from '../../models/store.model';

export const HomeList = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [interactiveMenu, setInteractiveMenu] = useState([]);

  const authentication = useSelector((state: StoreModel) => state.authentication);
  const companyMenu = useSelector((state: StoreModel) => state.companyMenu);

  useEffect((): any => {
    let menuList = companyMenu;

    if (companyMenu === undefined || companyMenu.length === 0) {
      menuList = authentication?.menu;
    }

    const menuInteractive = menuList?.filter(
      (item) => item.menuParentId === 0 && item.descriptionMenu === 'Menu Interativo'
    );

    const reduceMenu = menuInteractive?.map((itemParent) => {
      let submenu = [];

      menuList?.map((item) => {
        if (itemParent.id === item.menuParentId) submenu.push(item);
      });

      return {
        ...itemParent,
        submenu: submenu,
      };
    });

    setInteractiveMenu(reduceMenu[0]?.submenu);
  }, [companyMenu]);

  useEffect(() => {
    dispatch({ type: 'LOAD_FILTERS', filtersList: [] });
    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });
  }, [dispatch]);

  return (
    <Row>
      <Col xl={12}>
        <h3>Navegação</h3>
        <ListGroup horizontal className="list-home">
          {interactiveMenu?.map((item: MenuModel, idx) => (
            <ListGroup.Item
              className="item-list-home"
              key={idx}
              onClick={() => navigate(`/${item?.urlTarget}`)}
            >
              <i className={`fa ${item?.image}`}></i>
              {item?.descriptionMenu}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>
    </Row>
  );
};
