import { Button, Modal } from 'react-bootstrap';
import { FaCheck, FaTimes } from 'react-icons/fa';

interface IProps {
  title: string;
  show?: boolean;
  handleClose: any;
  handleConfirm: any;
  children: any;
  style?: any;
}

export const RetentionModal = (props: IProps) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Modal.Header
        closeButton
        style={{
          paddingBottom: 5,
          paddingTop: 10,
          backgroundColor: '#f2f3f8',
        }}
      >
        <Modal.Title style={{ fontSize: 'var(--default-font-size)', fontWeight: 700 }}>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          fontSize: 'var(--default-font-size)',
          paddingTop: 20,
          paddingBottom: 20,
          backgroundColor: '#f2f3f8',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {props.children}
      </Modal.Body>
      <div className="modal-actions-div" style={{ justifyContent: 'end' }}>
        <div style={{ paddingBottom: 10 }}>
          <>
            {props.handleConfirm !== null && (
              <Button
                size="sm"
                onClick={props.handleConfirm}
                className="icon-button"
                title={'Confirmar ação'}
              >
                <div className="div-button" style={{ paddingLeft: 5, paddingRight: 5 }}>
                  <span className="fa-icon-button">
                    <FaCheck className="fa-icon-color-green" />
                  </span>
                </div>
              </Button>
            )}
            &nbsp;&nbsp;
          </>
          <>
            <Button
              size="sm"
              onClick={props.handleClose}
              className="icon-button"
              title={'Cancelar ação'}
            >
              <div className="div-button" style={{ paddingLeft: 5, paddingRight: 5 }}>
                <span className="fa-icon-button">
                  <FaTimes className="fa-icon-color-red" />
                </span>
              </div>
            </Button>
            &nbsp;&nbsp;
          </>
        </div>
      </div>
    </Modal>
  );
};

export default RetentionModal;
