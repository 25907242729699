export const EmptyTable = () => {
  return (
    <div
      style={{
        paddingTop: 30,
        justifyContent: "center",
        display: "flex"
      }}
    >
      Nenhum item foi encontrado
    </div>
  );
};
