import { ListRequest } from '../models/requestParams.model';
import { serviceControllers } from '../util/enumerators';
import HttpService from './http.service';
const basePath = serviceControllers.DOMAIN;

export const DomainService = {
  getDomainList,
  getValidValues,
  getDomainListByProc,
};

async function getDomainList(domain: ListRequest) {
  return HttpService.post(`${basePath}/GetDomainList`, domain);
}

async function getDomainListByProc(
  domain: ListRequest,
  sprAuto: string,
  sprAutoSearch: string = null
) {
  let queryURL = `sprAuto=${sprAuto}`;
  if (sprAutoSearch) {
    queryURL += `&sprAutoSearch=${sprAutoSearch}`;
  }
  return HttpService.post(`${basePath}/GetDomainListByProc?${queryURL}`, domain);
}

async function getValidValues() {
  return HttpService.get(`${basePath}/ValidDatasetValues`);
}

export default DomainService;
