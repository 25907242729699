import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  FaRegEdit,
  FaEdit,
  FaRegEye,
  FaEye,
  FaUserPlus,
  FaRegCalendarTimes,
  FaCalendarTimes,
} from 'react-icons/fa';

import { Loading } from '../../components/Loading';
import { ButtonModel } from '../../models/components/button.model';

import { ListRequest, RequestParamsModel } from '../../models/requestParams.model';

import { BaseService } from '../../services/base.service';
import { ListComponent } from '../../components/ListComponent';
import { FilterAndListBuilder, fetchMoreData } from '../../util/listFunctions';
import { formMode, serviceControllers } from '../../util/enumerators';
import { ConfirmationModal } from '../../components/ConfirmationModal';

export const ClientList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rootUrl = '/Cliente';
  const filters = useSelector((state: any) => state.filtersToSearch);
  const [showModal, setShowModal] = useState(false);
  const [clientList, setClientList] = useState({ items: [] });
  const [selectedClient, setSelectedClient] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>([]);

  const [dataset, setDataset] = useState<ListRequest>(RequestParamsModel('CLIENTE_FORNECEDOR'));
  const [isLoading, setLoading] = useState(false);
  const [gridColumns, setGridColumns] = useState([]);

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.VIEW}/${selectedItem}`);
      },
      text: 'Visualizar',
      disabled: !(typeof selectedItem === 'number'),
      icon: (
        <>
          <FaRegEye className="fa-icon" />
          <FaEye className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Visualizar dados do período de férias selecionado',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}/${selectedItem}`);
      },
      text: 'Editar',
      disabled: !(typeof selectedItem === 'number'),
      icon: (
        <>
          <FaRegEdit className="fa-icon" />
          <FaEdit className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Editar cliente/fornecedor selecionado',
    },
    {
      onClick: () => {
        setShowModal(true);
      },
      text: 'Excluir',
      disabled: !(typeof selectedItem === 'number'),
      icon: (
        <>
          <FaRegCalendarTimes className="fa-icon" />
          <FaCalendarTimes className="fa-icon-hover fa-icon-color-red" />
        </>
      ),
      tooltip: 'Excluir férias selecionada',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}`);
      },
      text: 'Adicionar',
      icon: <FaUserPlus className="fa-icon-color-green" />,
      tooltip: 'Adicionar novo cliente/fornecedor',
    },
  ];

  const getList = useCallback(
    async (data: any, isFilter: boolean = false) => {
      FilterAndListBuilder(
        serviceControllers.CLIENT,
        data,
        filters,
        setClientList,
        clientList,
        setDataset,
        setGridColumns,
        isFilter,
        dispatch
      );
    },
    [clientList.items, dataset, filters, dispatch]
  );

  useEffect(() => {
    setSelectedClient(null);
    setSelectedItem(null);
    getList(RequestParamsModel('CLIENTE_FORNECEDOR'), true);
  }, [filters]);

  useEffect(() => {
    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });
  }, []);

  const selectClient = (param, index) => {
    setSelectedClient(selectedClient !== index ? index : null);
    setSelectedItem(selectedItem === param || param);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmModal = async () => {
    /*var selectedItem = clientList.items.find((v) => v.NR_DOC_ID === selectedItem);

    var delParams = {
      id: selectedItem['NR_DOC_ID'],
    };*/
    var delParams = {
      id: selectedItem,
    };
    await BaseService.del(serviceControllers.CLIENT, delParams).finally(() => {
      setSelectedClient(null);
      setSelectedItem(null);
      setShowModal(false);
      getList(RequestParamsModel('CLIENTE_FORNECEDOR'), true);
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <ListComponent
        idDataset="CLIENTE_FORNECEDOR"
        legend="Listagem de Clientes e Fornecedores"
        itemList={clientList}
        fetchMoreData={() => {
          fetchMoreData(dataset, getList);
        }}
        dataset={dataset}
        gridColumns={gridColumns}
        buttons={buttons}
        selectItem={selectClient}
        selectedItems={selectedItem}
        referenceColumn="NR_DOC_ID"
        useIndex={false}
      />
      <ConfirmationModal
        title={'Deseja realmente excluir este item?'}
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
        children={''}
      />
    </>
  );
};
