import { serviceControllers } from "../util/enumerators";
import HttpService from "./http.service";
const basePath = serviceControllers.COLLABORATOR;

export const CadastroService = {
  save
};

async function save(data: any) {
  return HttpService.post(`${basePath}/Save`, data);
}

export default CadastroService;
