import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { modalTypes } from '../../util/enumerators';
import { format, parseISO } from 'date-fns';
import moment from 'moment';

import {
  FaCheck,
  FaRegEdit,
  FaEdit,
  FaRegCalendarPlus,
  FaCalendarPlus,
  FaRegListAlt,
  FaListAlt,
  FaRegTrashAlt,
  FaTrashAlt,
  FaArrowLeft,
  FaArrowRight,
  FaRegArrowAltCircleRight,
  FaRegArrowAltCircleLeft,
} from 'react-icons/fa';
import { Header } from '../../components/Header';
import { Loading } from '../../components/Loading';
import Fieldset from '../../components/Fieldset';

import { ButtonModel } from '../../models/components/button.model';

import TimesheetService from '../../services/timesheet.service';
import { ToastService } from '../../services/toast.service';
import { TableComponent } from '../../components/TableComponent';
import { CustomModal } from '../../components/CustomModal';
import { t } from 'i18next';
import { TimeKeepingModel } from '../../models/timesheet/timekeeping.model';
import { isDateTime, isTime, timespanToHourMinute } from '../../util';
import { DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from '../../util/consts';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { DynamicForm } from '../../components/DynamicForm';
import { RequestTimeSheetParamsModel } from '../../models/requestTimeSheetParams.model';
import ClosingService from '../../services/closing.service';

export const TimeSheetList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId?: string }>();
  const date = new Date();

  const filters = useSelector((state: any) => state.filtersToSearch);
  const [timeSheetList, setTimeSheetList] = useState({ items: [] });
  const [timeSheetListExtra, setTimeSheetListExtra] = useState({ items: [] });
  const [timeSheetListOnNotice, setTimeSheetListOnNotice] = useState({
    items: [],
  });

  const [itensNormaisSelecionados, setItensNormaisSelecionados] = useState([]);
  const [itensExtraSelecionados, setItensExtraSelecionados] = useState([]);
  const [ultimoSelecionado, setUltimoSelecionado] = useState();
  const [ultimoExtraSelecionado, setUltimoExtraSelecionado] = useState();
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [gridColumns, setGridColumns] = useState([]);
  const [gridColumnsExtra, setGridColumnsExtra] = useState([]);
  const [gridColumnsOnNotice, setGridColumnsOnNotice] = useState([]);
  const [currentYear, setCurrentYear] = useState(date.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(date.getMonth() + 1);

  const [validationErrors, setValidationErrors] = useState({
    HourEnt: null,
    HourExitLunch: null,
    HourEntLunch: null,
    HourExit: null,
  });

  const [fieldsInfo, setFieldsInfo] = useState({
    timesheet: {
      colaborador: '',
      cpf: '',
      apontamento: '',
      status: '',
      dataDesligamento: '',
      horarioTrabalho: '',
      registro: '',
      qtdHorasSobreaviso: '',
      normais: '',
      extras: '',
      total: '',
      uteis: '',
      apontadas: '',
      percentApontadas: '',
      diferenca: '',
      faltantes: '',
      aPagar: '',
      faltas: '',
      heAd: '',
      zero: '',
      cem: '',
      adcNoturno: '',
      modalidade: '',
      remuneracao: '',
      horaBase: '',
      aFaturar: '',
    },
  });

  const [timekeeping, setTimekeeping] = useState<TimeKeepingModel>({
    HourEnt: '09:00',
    HourExitLunch: '12:00',
    HourEntLunch: '13:00',
    HourExit: '18:00',
    DateOfMark: `${currentYear}-${currentMonth}-01`,
  });

  const GetMonthDescription = () => {
    const month = new Intl.DateTimeFormat('pt-BR', { month: 'long' })
      .format(new Date(`${currentYear}-${currentMonth}-05`))
      .replace(/^\w/, (c) => c.toUpperCase());

    return month;
  };

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        setModalTitle(modalTypes.REPLICATE);
        setModalType(modalTypes.REPLICATE);
        setShowModal(true);
      },
      text: modalTypes.REPLICATE,
      disabled: isLoading,
      icon: (
        <>
          <FaRegListAlt className="fa-icon" />
          <FaListAlt className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Replicar horas para o mês',
      hide: userId ? true : false,
    },
    {
      onClick: () => {
        setModalTitle(modalTypes.EDIT);
        setModalType(modalTypes.EDIT);
        setShowModal(true);
        let register = timeSheetList.items.find(
          (x) => x.DT_PTO_DIA === itensNormaisSelecionados[0].DT_PTO_DIA
        );
        handleEditItem(register);
      },
      text: modalTypes.EDIT,
      disabled: itensNormaisSelecionados.length !== 1 || isLoading,
      icon: (
        <>
          <FaRegEdit className="fa-icon" /> <FaEdit className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Editar item selecionado',
      hide: userId ? true : false,
    },
    {
      onClick: () => {
        setModalTitle(modalTypes.EXTRA_HOUR);
        setModalType(modalTypes.EXTRA_HOUR);
        setShowModal(true);
      },
      text: modalTypes.EXTRA_HOUR,
      disabled: isLoading,
      icon: (
        <>
          <FaRegCalendarPlus className="fa-icon" />
          <FaCalendarPlus className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Lançar horas adicionais',
      hide: userId ? true : false,
    },
    {
      onClick: async () => {
        setLoading(true);

        const register = timeSheetList.items.find(
          (x) => x.DT_PTO_DIA === itensNormaisSelecionados[0].DT_PTO_DIA
        );

        let requestParams: any = {
          dateOfRoll: register.DT_FLH_MAF,
          ids: [register?.NR_CLB_ID]
        };
    
        let apiResponse: any = await ClosingService.approveItems(requestParams);

        if (apiResponse.return.success) {
          ToastService.success(t('TIMESHEET_APPROVAL_SOLICITATION_SUCCESS'));
        }
    
        setItensNormaisSelecionados([]);
        setLoading(false);
      },
      text: 'Solicitar Aprovação',
      disabled: timeSheetList.items.length == 0 || isLoading,
      icon: <FaCheck className="fa-icon-color-green" />,
      tooltip: 'Enviar registros para aprovação',
      hide: userId ? true : false,
    },
    {
      onClick: async () => {
        if (itensNormaisSelecionados.length) {
          setModalTitle(modalTypes.DELETE);
          setModalType(modalTypes.DELETE);
        } else if (itensExtraSelecionados.length) {
          setModalTitle(modalTypes.DELETE_EXTRA_HOUR);
          setModalType(modalTypes.DELETE_EXTRA_HOUR);
        }

        setShowModal(true);
      },
      text: 'Excluir',
      disabled: (!itensNormaisSelecionados.length && !itensExtraSelecionados.length) || isLoading,
      icon: (
        <>
          <FaRegTrashAlt className="fa-icon" />
          <FaTrashAlt className="fa-icon-hover" />
        </>
      ),
      tooltip:
        itensNormaisSelecionados.length > 0
          ? 'Excluir itens selecionados'
          : 'Excluir item selecionado',
      hide: userId ? true : false,
    },
    {
      onClick: async () => {
        dispatch({
          type: 'SEARCH_FILTERS',
          filtersToSearch: [],
        });
        navigate(`/Timesheet/Aprovacao`);
      },
      text: 'Voltar',
      variant: 'outline-primary',
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: 'Voltar para a página anterior',
      hide: userId ? false : true,
    },
  ];

  const modalButtons: ButtonModel[] = [
    {
      onClick: async () => {
        setLoading(true);
        setValidationErrors({
          HourEnt: null,
          HourExitLunch: null,
          HourEntLunch: null,
          HourExit: null,
        });

        const modal = timekeepModalRules.find((x) => x.key === modalType);

        if (modal?.isValid(timekeeping)) {
          await modal.handleSubmit(timekeeping);
        } else {
          ToastService.warn('Existem campos inválidos', { autoClose: 1000 });
        }

        setLoading(false);
      },
      text: 'Salvar',
      icon: <FaCheck className="fa-icon-color-green" />,
      tooltip: 'Salvar Registro',
    },
  ];

  const centeredButtons: ButtonModel[] = [
    {
      onClick: async () => {
        setLoading(true);

        let newMonth = currentMonth - 1;
        if (newMonth === 0) {
          newMonth = 12;

          setCurrentYear(currentYear - 1);
        }

        setCurrentMonth(newMonth);
        setLoading(false);
      },
      text: 'Mês anterior',
      disabled: isLoading,
      icon: (
        <>
          <FaArrowLeft className="fa-icon" />
          <FaRegArrowAltCircleLeft className="fa-icon-hover" />
        </>
      ),
    },
    {
      onClick: async () => {},
      text: `${GetMonthDescription()}/${currentYear}`,
      disabled: true,
      variant: 'outline-primary',
    },
    {
      onClick: async () => {
        setLoading(true);

        let newMonth = currentMonth + 1;

        if (newMonth === 13) {
          newMonth = 1;
          setCurrentYear(currentYear + 1);
        }

        setCurrentMonth(newMonth);
        setLoading(false);
      },
      text: 'Próximo mês',
      disabled: isLoading,
      icon: (
        <>
          <FaArrowRight className="fa-icon" />
          <FaRegArrowAltCircleRight className="fa-icon-hover" />
        </>
      ),
    },
  ];

  const createField = (field, label, size = 1) => ({
    field,
    label,
    lgSize: size,
    disabled: true,
  });

  const fieldList = [
    createField('cpf', 'CPF'),
    createField('colaborador', 'Colaborador', 2),
    createField('registro', 'Registro'),
    createField('dataDesligamento', 'Saída'),
    createField('modalidade', 'Modalidade'),
    createField('remuneracao', 'Remuneração'),
    createField('apontamento', 'Apontamento'),
    createField('horaBase', 'Hora Base'),
    createField('horarioTrabalho', 'Horário de Trabalho', 2),
    createField('status', 'Status'),
    createField('uteis', 'Úteis'),
    createField('apontadas', 'Apontadas'),
    createField('percentApontadas', '% Aprontado'),
    createField('faltas', 'Faltas'),
    createField('heAd', 'HE + AD'),
    createField('zero', '0%'),
    createField('cem', '100%'),
    createField('adcNoturno', 'Ad. Noturno'),
    createField('qtdHorasSobreaviso', 'Sobreaviso'),
    createField('diferenca', 'Diferença'),
    createField('aPagar', 'A Pagar'),
    createField('aFaturar', 'A Faturar'),
  ];

  const getList = useCallback(
    async (year?: number, month?: number) => {
      let dataTest = {
        DateOfTimeSheet: `${year}-${month}-01`,
      };

      let apiResponse: any = userId
        ? await TimesheetService.getTimeSheetById(userId, dataTest)
        : await TimesheetService.getTimeSheet(dataTest);

      if (apiResponse?.return?.success) {
        let columns = filterColumnsByExibeGrid(apiResponse, 1);
        let columnsExtra = filterColumnsByExibeGrid(apiResponse, 2);
        let columnsOnNotice = filterColumnsByExibeGrid(apiResponse, 3);

        setGridColumns(columns);
        setGridColumnsExtra(columnsExtra);
        setGridColumnsOnNotice(columnsOnNotice);

        const num1 = parseFloat(apiResponse.result.dados[0]['TOT_HRA_MES']); //verificar, pois não retorna diretamente pela proc
        const num2 = parseFloat(apiResponse.result.dados[0]['TOT_HRA_PTO']);
        let resultPercApont = '0';
        if (!isNaN(num1) && !isNaN(num2) && num2 !== 0) {
          resultPercApont = (num1 / num2).toFixed(2);
        }

        //Pega os dados do primeiro dataset para poder preencher as informações do resumo
        setFieldsInfo({
          timesheet: {
            cpf: apiResponse.result.dados[0]['NR_CLB_ID'],
            colaborador: apiResponse.result.dados[0]['NM_CLB_DS'],
            registro: format(parseISO(apiResponse.result.dados[0]['DT_FLH_MAF']), 'MM/yyyy'),
            dataDesligamento: apiResponse.result.dados[0]['DT_REG_SDA'] ?? '-',
            modalidade: apiResponse.result.dados[0]['APL_MOD_CTR'] ?? '-',
            remuneracao: apiResponse.result.dados[0]['DS_TIP_RMN'] ?? '-',
            apontamento: apiResponse.result.dados[0]['DS_PTO_TIP'] ?? '-',
            horaBase: apiResponse.result.dados[0]['NR_HBS_QTD'] ?? '-',
            horarioTrabalho: apiResponse.result.dados[0]['NM_HTR_DS'] ?? '-',
            status: apiResponse.result.dados[0]['APL_TSH_STA'] ?? '-', 
            uteis: apiResponse.result.dados[0]['TOT_HRA_MES'] ?? '-',
            apontadas: apiResponse.result.dados[0]['TOT_HRA_PTO'] ?? '-',
            percentApontadas: resultPercApont.toString(),
            faltas: apiResponse.result.dados[0]['TOT_PTO_HRF'] ?? '-',
            heAd: (
              parseFloat(apiResponse.result.dados[0]['TOT_PTO_HRE']) + //verificar se é apenas o TOT_PTO_HRE ou se não retorna diretamente pela proc
              parseFloat(apiResponse.result.dados[0]['TOT_PTO_ADN'])
            )
              .toFixed(2)
              .toString(),
            zero: apiResponse.result.dados[0]['TOT_PTO_HRE_FX1'] ?? '-', //confirmar
            cem: apiResponse.result.dados[0]['TOT_PTO_HRE_FX2'] ?? '-', //confirmar
            adcNoturno: apiResponse.result.dados[0]['TOT_PTO_ADN'] ?? '-',
            qtdHorasSobreaviso: apiResponse.result.dados[0]['TOT_PTO_SAV'] ?? '-',
            diferenca: apiResponse.result.dados[0]['TOT_PTO_DIF'] ?? '-',
            aPagar: apiResponse.result.dados[0]['TOT_HRA_PAG'] ?? '-',
            aFaturar: apiResponse.result.dados[0]['TOT_HRA_FAT'] ?? '-',

            normais: apiResponse.result.dados[0]['QT_PTO_HRN'] ?? '-',
            extras: apiResponse.result.dados[0]['TOT_PTO_HRE'] ?? '-',
            total: apiResponse.result.dados[0]['TOT_HRA_MES'] ?? '-',
            faltantes: apiResponse.result.dados[0]['TOT_PTO_HRF'] ?? '-',
          },
        });

        setTimeSheetList({
          items: apiResponse.result.dados1,
        });

        setTimeSheetListExtra({
          items: apiResponse.result.dados2,
        });

        setTimeSheetListOnNotice({
          items: apiResponse.result.dados3,
        });
      } else {
        setGridColumns([]);
        setGridColumnsExtra([]);
        setGridColumnsOnNotice([]);
        setTimeSheetList({
          items: [],
        });

        setTimeSheetListExtra({
          items: [],
        });

        setTimeSheetListOnNotice({
          items: [],
        });
      }
    },
    [filters, dispatch, timeSheetList.items]
  );

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });
    setItensNormaisSelecionados([]);
    setItensExtraSelecionados([]);
    getList(currentYear, currentMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, currentYear, currentMonth]);

  const isItemInSelectedItems = (selectedItems, currentItem) => {
    return selectedItems.some((selected) => selected.DT_PTO_DIA === currentItem.DT_PTO_DIA);
  };

  const filterColumnsByExibeGrid = (data, index) => {
    if (data.dataset?.data[index]) {
      return data.dataset.data[index].field.filter((campo) => campo['showGrid']);
    } else {
      return [];
    }
  };

  const selectTimeListRow = (
    doc,
    index,
    e,
    ultimoSelecionado,
    setUltimoSelecionado,
    itensSelecionados,
    setItensSelecionados,
    referenceList
  ) => {
    const item = referenceList.items[index];
    let selectedItems = [...itensSelecionados];

    if (isItemInSelectedItems(selectedItems, item)) {
      selectedItems = selectedItems.filter((selected) => selected.DT_PTO_DIA !== item.DT_PTO_DIA);
    } else {
      selectedItems = [...selectedItems, { DT_PTO_DIA: item?.DT_PTO_DIA }];
    }

    setUltimoSelecionado(index);
    setItensExtraSelecionados([]);
    setItensSelecionados(selectedItems.length > 0 ? [selectedItems[selectedItems.length - 1]] : []);
  };

  const selectExtraListRow = (
    doc,
    index,
    e,
    ultimoSelecionado,
    setUltimoSelecionado,
    itensSelecionados,
    setItensSelecionados,
    referenceList
  ) => {
    const item = referenceList.items[index];
    let selectedItems = [...itensSelecionados];

    if (isItemInSelectedItems(selectedItems, item)) {
      selectedItems = selectedItems.filter((selected) => selected.DT_PTO_DIA !== item.DT_PTO_DIA);
    } else {
      selectedItems = [...selectedItems, { DT_PTO_DIA: item?.DT_PTO_DIA }];
    }

    setItensNormaisSelecionados([]);
    setUltimoExtraSelecionado(index);
    setItensExtraSelecionados(
      selectedItems.length > 0 ? [selectedItems[selectedItems.length - 1]] : []
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTimekeeping({
      HourEnt: null,
      HourExitLunch: null,
      HourEntLunch: null,
      HourExit: null,
      DateOfMark: `${currentYear}-${currentMonth}-01`,
    });
  };

  const handleEditItem = (item) => {
    setModalTitle(
      (prevTitle) => modalTypes.EDIT + ' Dia ' + moment(item.DT_PTO_DIA).format(DATE_FORMAT)
    );
    setModalType(modalTypes.EDIT);

    setTimekeeping((prevTimekeeping) => ({
      ...prevTimekeeping,
      HourEnt: timespanToHourMinute(item.HR_PTO_ETR),
      HourExitLunch: timespanToHourMinute(item.HR_PTO_SAM),
      HourEntLunch: timespanToHourMinute(item.HR_PTO_EAM),
      HourExit: timespanToHourMinute(item.HR_PTO_STR),
      DateOfMark: item.DT_PTO_DIA,
    }));

    setShowModal(true);
  };

  const handleInputChange = (field, value) => {
    setValidationErrors({
      HourEnt: null,
      HourExitLunch: null,
      HourEntLunch: null,
      HourExit: null,
    });

    setTimekeeping((prevTimekeeping) => ({
      ...prevTimekeeping,
      [field]: value,
    }));
  };

  const timekeepModalRules = [
    {
      key: modalTypes.REPLICATE,
      showLunchTimeInput: true,
      entryMask: '11:11',
      exitMask: '11:11',
      exitLunch: '11:11',
      entryLunch: '11:11',
      isValid: (timekeeping: TimeKeepingModel) => {
        let isValid = true;

        if (!isTime(timekeeping.HourEnt)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourEnt: 'Formato inválido (HH:MM)',
          }));
        }

        if (!isTime(timekeeping.HourExitLunch)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourExitLunch: 'Formato inválido (HH:MM)',
          }));
        }

        if (!isTime(timekeeping.HourEntLunch)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourEntLunch: 'Formato inválido (HH:MM)',
          }));
        }

        if (!isTime(timekeeping.HourExit)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourExit: 'Formato inválido (HH:MM)',
          }));
        }

        return isValid;
      },
      handleSubmit: async (timekeeping: TimeKeepingModel) => {
        const request = { ...timekeeping };
        request.DateOfMark = `${currentYear}-${currentMonth}-01`;

        return await TimesheetService.insert(request).then((response) => {
          if (response['return'] && response['return']['success']) {
            ToastService.success(t('TIMESHEET_UPDATE_SUCCESS'));
            setShowModal(false);
            return getList(currentYear, currentMonth);
          }
        });
      },
    },
    {
      key: modalTypes.EDIT,
      showLunchTimeInput: true,
      entryMask: '11:11',
      exitMask: '11:11',
      exitLunch: '11:11',
      entryLunch: '11:11',
      isValid: (timekeeping: TimeKeepingModel) => {
        let isValid = true;

        if (!isTime(timekeeping.HourEnt)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourEnt: 'Formato inválido (HH:MM)',
          }));
        }

        if (!isTime(timekeeping.HourExitLunch)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourExitLunch: 'Formato inválido (HH:MM)',
          }));
        }

        if (!isTime(timekeeping.HourEntLunch)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourEntLunch: 'Formato inválido (HH:MM)',
          }));
        }

        if (!isTime(timekeeping.HourExit)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourExit: 'Formato inválido (HH:MM)',
          }));
        }

        return isValid;
      },
      handleSubmit: async (timekeeping: TimeKeepingModel) => {
        return await TimesheetService.update(timekeeping).then((response) => {
          if (response['return'] && response['return']['success']) {
            ToastService.success(t('TIMESHEET_UPDATE_SUCCESS'));
            setShowModal(false);
            return getList(currentYear, currentMonth);
          }
        });
      },
    },
    {
      key: modalTypes.EXTRA_HOUR,
      showLunchTimeInput: false,
      entryMask: '11/11/1111 11:11',
      exitMask: '11:11',
      isValid: (timekeeping: TimeKeepingModel) => {
        let isValid = true;

        if (!isDateTime(timekeeping.HourEnt)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourEnt: 'Formato inválido (DD/MM/AAAA HH:MM)',
          }));
        }

        if (!isTime(timekeeping.HourExit)) {
          isValid = false;
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            HourExit: 'Formato inválido (HH:MM)',
          }));
        }

        return isValid;
      },
      handleSubmit: async (timekeeping: TimeKeepingModel) => {
        const request = { ...timekeeping };
        request.DateOfMark = moment(timekeeping.HourEnt, DATE_FORMAT).format('YYYY-MM-DD');
        request.HourEnt = moment(timekeeping.HourEnt, DATETIME_FORMAT).format(TIME_FORMAT);

        return await TimesheetService.insertExtra(request).then((response) => {
          if (response['return'] && response['return']['success']) {
            ToastService.success(t('TIMESHEET_UPDATE_SUCCESS'));
            setShowModal(false);
            return getList(currentYear, currentMonth);
          }
        });
      },
      onShow: () => {
        setTimekeeping({
          HourEnt: null,
          HourExitLunch: null,
          HourEntLunch: null,
          HourExit: null,
          DateOfMark: null,
        });
      },
    },
    {
      key: modalTypes.DELETE,
      handleSubmit: async () => {
        setLoading(true);
        const item = itensNormaisSelecionados[0];

        const request = {
          DateOfMark: item.DT_PTO_DIA,
        };

        return await TimesheetService.remove(request)
          .then((response: any) => {
            if (response?.return?.success) {
              ToastService.success(t('TIMESHEET_DELETE_SUCCESS'));
              setShowModal(false);
              setItensNormaisSelecionados([]);
              return getList(currentYear, currentMonth);
            }
          })
          .finally(() => {
            setShowModal(false);
            setLoading(false);
          });
      },
    },
    {
      key: modalTypes.DELETE_EXTRA_HOUR,
      handleSubmit: async () => {
        setLoading(true);
        const item = timeSheetListExtra.items.find(
          (x) => x.DT_PTO_DIA === itensExtraSelecionados[0].DT_PTO_DIA
        );

        const request = {
          DateOfMark: item.DT_PTO_DIA,
          HourEnt: moment(item.HR_PTO_ETR, 'HH:mm:ss').format(TIME_FORMAT),
          HourExit: moment(item.HR_PTO_STR, 'HH:mm:ss').format(TIME_FORMAT),
        };

        return await TimesheetService.removeExtra(request)
          .then((response: any) => {
            if (response?.return?.success) {
              ToastService.success(t('TIMESHEET_DELETE_SUCCESS'));
              setShowModal(false);
              setItensExtraSelecionados([]);
              return getList(currentYear, currentMonth);
            }
          })
          .finally(() => {
            setShowModal(false);
            setLoading(false);
          });
      },
    },
  ];

  const modalInfo = timekeepModalRules.find((x) => x.key === modalType);

  const modalBody = () => {
    return (
      <Row>
        <Col xl={12}>
          <Fieldset legend={modalTitle}>
            <Form className="m-2">
              <Row>
                <Col xl={6}>
                  <Form.Group className="form-group">
                    <MaskedFormControl
                      type="text"
                      id="HrPontoEntrada"
                      name="HrPontoEntrada"
                      mask={modalInfo?.entryMask}
                      size="sm"
                      required
                      value={timekeeping.HourEnt}
                      maxLength={5}
                      style={{
                        marginBottom: 10,
                      }}
                      onChange={({ target }) => handleInputChange('HourEnt', target.value)}
                      className={validationErrors['HourEnt'] && 'red-outline'}
                    />
                    {modalType === modalTypes.EXTRA_HOUR ? (
                      <Form.Label>Data Hora Entrada</Form.Label>
                    ) : (
                      <Form.Label>Hora Entrada</Form.Label>
                    )}
                  </Form.Group>
                </Col>

                {modalInfo?.showLunchTimeInput && (
                  <>
                    <Col xl={6}>
                      <Form.Group className="form-group">
                        <MaskedFormControl
                          type="text"
                          id="HrSaidaAlmoco"
                          name="HrSaidaAlmoco"
                          mask={modalInfo?.exitLunch}
                          size="sm"
                          required
                          value={timekeeping.HourExitLunch}
                          maxLength={5}
                          style={{
                            marginBottom: 10,
                          }}
                          onChange={({ target }) =>
                            handleInputChange('HourExitLunch', target.value)
                          }
                          className={validationErrors['HourExitLunch'] && 'red-outline'}
                        />
                        <Form.Label>Hora Saída Almoço</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="form-group">
                        <MaskedFormControl
                          type="text"
                          id="HrEntradaAlmoco"
                          name="HrEntradaAlmoco"
                          mask={modalInfo?.entryLunch}
                          size="sm"
                          required
                          value={timekeeping.HourEntLunch}
                          maxLength={5}
                          style={{
                            marginBottom: 10,
                          }}
                          onChange={({ target }) => handleInputChange('HourEntLunch', target.value)}
                          className={validationErrors['HourEntLunch'] && 'red-outline'}
                        />
                        <Form.Label>Hora Retorno Almoço</Form.Label>
                      </Form.Group>
                    </Col>
                  </>
                )}

                <Col xl={6}>
                  <Form.Group className="form-group">
                    <MaskedFormControl
                      type="text"
                      id="HrPontoSaida"
                      name="HrPontoSaida"
                      mask={modalInfo?.exitMask}
                      size="sm"
                      required
                      value={timekeeping.HourExit}
                      maxLength={5}
                      style={{
                        marginBottom: 10,
                      }}
                      onChange={({ target }) => handleInputChange('HourExit', target.value)}
                      className={validationErrors['HourExit'] && 'red-outline'}
                    />
                    <Form.Label>Hora Saída</Form.Label>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Fieldset>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {isLoading && <Loading />}
      <Header
        title="Timesheet"
        buttons={buttons}
        middleButtons={centeredButtons}
        previousPage={userId ? 'Listagem de Fechamento' : null}
        previousUrl={() => {
          navigate(`/Timesheet/Aprovacao`);
        }}
      />
      <Fieldset legend={'Apontamento de Horas: ' + GetMonthDescription() + ' de ' + currentYear}>
        <div>
          <Fieldset legend="Resumo">
            <Form className="m-2">
              <Row>
                <DynamicForm form="timesheet" fieldList={fieldList} values={fieldsInfo} />
              </Row>
            </Form>
          </Fieldset>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Col xl={12} style={{ padding: 0 }}>
            <Fieldset legend="Informações do Ponto">
              <div
                className="height-definition-timesheet"
                style={{
                  overflow: 'hidden',
                }}
              >
                <TableComponent
                  infiniteScrollClassName="height-definition-timesheet"
                  itemList={timeSheetList}
                  fetchMoreData={null}
                  totalItems={0}
                  gridColumns={gridColumns}
                  selectedItems={itensNormaisSelecionados}
                  selectItem={selectTimeListRow}
                  editItem={(e, index) => {
                    let register = timeSheetList.items[index];
                    handleEditItem(register);
                  }}
                  setUltimoSelecionado={setUltimoSelecionado}
                  setItensSelecionados={setItensNormaisSelecionados}
                  ultimoSelecionado={ultimoSelecionado}
                  referenceColumn="DT_PTO_DIA"
                  orderable={false}
                />
              </div>
            </Fieldset>
          </Col>
        </div>

        <Row
          style={{
            padding: 8,
          }}
        >
          <Col xl={6} xs={12}>
            <Fieldset legend="Horas Adicionais">
              <div
                className="height-definition-timesheet"
                style={{
                  overflow: 'hidden',
                }}
              >
                <TableComponent
                  infiniteScrollClassName="height-definition-timesheet"
                  itemList={timeSheetListExtra}
                  fetchMoreData={null}
                  totalItems={0}
                  gridColumns={gridColumnsExtra}
                  selectedItems={itensExtraSelecionados}
                  selectItem={selectExtraListRow}
                  setUltimoSelecionado={setUltimoExtraSelecionado}
                  setItensSelecionados={setItensExtraSelecionados}
                  ultimoSelecionado={ultimoExtraSelecionado}
                  isRed={true}
                  referenceColumn="DT_PTO_DIA"
                  orderable={false}
                />
              </div>
            </Fieldset>
          </Col>

          <Col xl={6} xs={12}>
            <Fieldset legend="Horas Sobreaviso">
              <div
                className="height-definition-timesheet"
                style={{
                  overflow: 'hidden',
                }}
              >
                <TableComponent
                  infiniteScrollClassName="height-definition-timesheet"
                  itemList={timeSheetListOnNotice}
                  fetchMoreData={null}
                  totalItems={0}
                  gridColumns={gridColumnsOnNotice}
                  selectedItems={itensExtraSelecionados}
                  selectItem={selectExtraListRow}
                  setUltimoSelecionado={setUltimoExtraSelecionado}
                  setItensSelecionados={setItensExtraSelecionados}
                  ultimoSelecionado={ultimoExtraSelecionado}
                  isRed={true}
                  referenceColumn="DT_PTO_DIA"
                  orderable={false}
                />
              </div>
            </Fieldset>
          </Col>
        </Row>
      </Fieldset>
      <CustomModal
        show={
          showModal && modalType !== modalTypes.DELETE && modalType !== modalTypes.DELETE_EXTRA_HOUR
        }
        title={'Lançamento de Horas'}
        children={modalBody()}
        handleClose={handleCloseModal}
        buttons={modalButtons}
        handleShow={modalInfo?.onShow}
      />

      <ConfirmationModal
        title={'Deseja realmente excluir este apontamento?'}
        show={
          showModal &&
          (modalType === modalTypes.DELETE || modalType === modalTypes.DELETE_EXTRA_HOUR)
        }
        handleClose={handleCloseModal}
        handleConfirm={modalInfo?.handleSubmit}
        children={''}
      />
    </>
  );
};
