import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  FaFilePdf,
  FaRegFilePdf,
  FaFileAlt,
  FaRegFileAlt,
  FaArrowLeft,
  FaRegCopy,
  FaCopy,
} from 'react-icons/fa';

import { Header } from '../../components/Header';
import Fieldset from '../../components/Fieldset';
import { ExportFunctions } from '../../util/ExportFunctions';
import { ButtonModel } from '../../models/components/button.model';

import { ClosingService } from '../../services/closing.service';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Loading } from '../../components/Loading';
import '../../scss/custom.css';
import { ToastService } from '../../services/toast.service';
import {
  ListRequest,
  RequestParamsModelDefault,
  RequestParamsModel,
} from '../../models/requestParams.model';
import { formattedDate } from '../../util';
import axios from 'axios';
import { store } from '../../store';

export const ResumoFechamento = () => {
  const location = useLocation();
  let selectedItens = location.state.selectedItens;

  const navigate = useNavigate();
  const returnHTML = useRef(null);
  const dispatch = useDispatch();

  const [innerHtml, setInnerHtml] = useState('');
  const [isLoading, setLoading] = useState(false);

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/Timesheet/Aprovacao`);
      },
      text: 'Voltar',
      icon: (
        <>
          <FaArrowLeft className="fa-icon-color-red" />
        </>
      ),
      tooltip: 'Voltar para a página anterior',
    },
    {
      onClick: async () => {
        setLoading(true);
        // await ClosingService.getPDF(RequestParamsModel('FECHAMENTO')).then((resp) => {
        //   alert(JSON.stringify(resp));
        // });
        const getPDF = async (data: any) => {
          ClosingService.getPDF(data).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${formattedDate()}_resumo_fechamento.pdf`);
            document.body.appendChild(link);
            link.click();
          });
        };
        setLoading(true);
        getPDF(RequestParamsModel('FECHAMENTO'));
        setLoading(false);
      },
      text: 'Gerar PDF',
      icon: (
        <>
          <FaRegFilePdf className="fa-icon" /> <FaFilePdf className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Baixar Resumo do Faturamento',
    },
    {
      onClick: () => {
        const printContents = document.getElementById('innerHtml_content').innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        window.location.reload();
      },
      text: 'Imprimir',
      icon: (
        <>
          <FaRegFileAlt className="fa-icon" />
          <FaFileAlt className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Compartilhar itens selecionados',
    },
    {
      onClick: () => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = returnHTML.current.innerHTML;
        document.body.appendChild(tempElement);
        const range = document.createRange();
        range.selectNodeContents(tempElement);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        document.body.removeChild(tempElement);
        ToastService.info('Copiado para a área de transferência', null);
      },
      text: 'Copiar',
      icon: (
        <>
          <FaRegCopy className="fa-icon" />
          <FaCopy className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Copiar itens',
    },
  ];

  const getList = async (data: any) => {
    let requestClosingParams: ListRequest = data;
    setLoading(true);

    const apiResponse: any = await ClosingService.getHtml(requestClosingParams);
    const { HTML } = apiResponse.result.dados[0];

    setInnerHtml(HTML);
    setLoading(false);
  };

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });

    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });
    getList(RequestParamsModel('FECHAMENTO'));
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <Header
        title="Resumo de Fechamento"
        buttons={buttons}
        previousPage="Listagem de Fechamento"
        previousUrl={() => {
          navigate(`/Timesheet/Aprovacao`);
        }}
      />
      <Fieldset legend="Resumo de Fechamento">
        <div style={{ paddingTop: 13 }}>
          <InfiniteScroll
            dataLength={null}
            next={null}
            loader={<b>Carregando...</b>}
            height="100%"
            hasMore={false}
          >
            <div
              ref={returnHTML}
              id="innerHtml_content"
              dangerouslySetInnerHTML={{ __html: innerHtml }}
            />
          </InfiniteScroll>
        </div>
      </Fieldset>
    </>
  );
};
