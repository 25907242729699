import React from 'react';
import Fieldset from './Fieldset';
import { TableComponent } from './TableComponent';
import { Header } from './Header';
import { Footer } from './Footer';
import { OrderByModel } from '../models/orderby.model';
// import { fetchMoreData } from "../util/listFunctions";
import { Breadcrumb } from 'react-bootstrap';

interface ListComponentProps {
  idDataset: string;
  legend: string;
  itemList: {
    items: any[];
  };
  fetchMoreData: () => void;
  dataset: any;
  gridColumns: any[];
  buttons: any[];
  style?: React.CSSProperties;
  selectedItems?: any[] | any | null;
  selecionarTodos?: any;
  selectItem: (
    itemId: number,
    index: number,
    e: React.MouseEvent,
    lastSelected: number,
    setUltimoSelecionado: any,
    itensSelecionados: any[],
    setItensSelecionados: any,
    referenceList: {
      items: any[];
    }
  ) => void;
  editItem?: (e, index: number) => void;
  toggleSelecionarTodos?: any;
  ultimoSelecionado?: number;
  setUltimoSelecionado?: any;
  setItensSelecionados?: any;
  referenceColumn?: string;
  useIndex?: boolean;
  orderable?: boolean;
  order?: OrderByModel;
  breadcrumb?: boolean;
  onColumnOrdered?: (column, direction) => void;
}

/**

ListComponent

@param {string} legend - A legenda do componente.
@param {object} itemList - Os itens da lista.
@param {function} fetchMoreData - Função para buscar mais dados.
@param {object} dataset - Lista de itens.
@param {array} gridColumns - As colunas da tabela.
@param {array} buttons - Array com os botão a serem exibidos na listagem.
@param {object} style - OPCIONAL - O estilo do componente.
@param {array|object|null} selectedItems - OPCIONAL - Os itens selecionados (pode ser um array ou um item único).
@param {function} selecionarTodos - OPCIONAL - Função para selecionar todos os itens.
@param {function} selectItem - Função para selecionar um item.
@param {function} editItem - Função para editar um item.
@param {function} toggleSelecionarTodos - OPCIONAL - Função para alternar a seleção de todos os itens.
@param {number} ultimoSelecionado - Classe para o componente InfiniteScroll.
@param {string|null} referenceColumn - OPCIONAL - Coluna usada para poder ser comparada nas funções de seleção.
@param {boolean} useIndex - OPCIONAL - Variável para poder definir se vai usar o index ou não na lógica de seleção dos itens.
@param {boolean} orderable - OPCIONAL - Define se mostrará ou não os ícones para ordenar a tabela
@param {OrderByModel} order - OPCIONAL - objeto contendo informações sobre a ordenação atual
@param {function} onColumnOrdered - OPCIONAL - Função de callback que é executada quando a tabela é ordenada
*/

export const ListComponent: React.FC<ListComponentProps> = ({
  idDataset,
  legend,
  itemList,
  fetchMoreData,
  dataset,
  gridColumns,
  buttons,
  breadcrumb = true,
  selectedItems = null,
  selecionarTodos = null,
  selectItem,
  editItem,
  toggleSelecionarTodos = null,
  ultimoSelecionado = null,
  setUltimoSelecionado = null,
  setItensSelecionados = null,
  referenceColumn = null,
  useIndex = null,
  orderable = true,
  order = null,
  onColumnOrdered = null,
}) => {
  const getTotalLenghtByDataset = () => {
    let idx = dataset.dataset?.data?.findIndex((d) => d.idDataset === idDataset);
    if (idx !== undefined && idx !== -1) {
      return dataset.dataset.data[idx].records;
    }
    return 0;
  };

  return (
    <>
      <Header title={legend} buttons={buttons} showBread={breadcrumb} />
      <Fieldset legend={legend}>
        <div
          style={{
            paddingTop: 13,
          }}
        >
          <TableComponent
            infiniteScrollClassName=""
            itemList={itemList}
            fetchMoreData={fetchMoreData}
            totalItems={getTotalLenghtByDataset()}
            gridColumns={gridColumns}
            selectedItems={selectedItems}
            selecionarTodos={selecionarTodos}
            selectItem={selectItem}
            editItem={editItem}
            toggleSelecionarTodos={toggleSelecionarTodos}
            ultimoSelecionado={ultimoSelecionado}
            setUltimoSelecionado={setUltimoSelecionado}
            setItensSelecionados={setItensSelecionados}
            referenceColumn={referenceColumn}
            useIndex={useIndex}
            orderable={orderable}
            order={order}
            onColumnOrdered={onColumnOrdered}
          />
        </div>
      </Fieldset>
      <Footer
        current={itemList.items?.length}
        total={getTotalLenghtByDataset()}
        selectedCount={selectedItems?.length}
      />
    </>
  );
};
