import { useState } from "react";
import PageHeader from "./header";
import PageBody from "./body";
import PageFooter from "./footer";

import AjudamosJpg from "../../assets/images/ajudamos_o_seu_negocio.jpg";
import OutSourcingJpg from "../../assets/images/home_outsourcing.jpg";
import HomeRpaJpg from "../../assets/images/home_rpa.jpg";
import HomeFabricaJpg from "../../assets/images/home_fabrica_de_software.jpg";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const SliderSection = () => {
  const [activeColumn, setActiveColumn] = useState("manifesto");

  const handleOptionHover = (columnName) => {
    setActiveColumn(columnName);
  };

  const handleMenuLeave = () => {
    setActiveColumn("manifesto");
  };

  return (
    <section
      className="wow fadeIn no-padding"
      style={{ visibility: "visible", animationName: "fadeIn", paddingTop: 0 }}
    >
      <div
        className="container-fluid"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Col md={12} style={{ padding: 0 }}>
          <ul className="blog-header-style1 equalize  sm-equalize-auto">
            <li
              className={`blog-banner cover-background sm-margin-15px-bottom sm-height-450px ${
                activeColumn === "manifesto" ? "blog-column-active" : ""
              }`}
              style={{
                height: "600px",
                listStyle: "none",
                backgroundImage: `url(${AjudamosJpg})`
              }}
              onMouseEnter={() => handleOptionHover("manifesto")}
            >
              <SliderItem
                title="Somos a Global System"
                subtitle="Nosso manifesto"
                buttonLink="http://www.gsoftware.com.br/solucoes/outsourcing/"
              />
            </li>
            <li
              className={`blog-banner cover-background sm-margin-15px-bottom sm-height-450px ${
                activeColumn === "outsourcing" ? "blog-column-active" : ""
              }`}
              style={{
                height: "600px",
                listStyle: "none",
                backgroundImage: `url(${OutSourcingJpg})`
              }}
              onMouseEnter={() => handleOptionHover("outsourcing")}
            >
              <SliderItem
                title="Pessoas e Teconologia"
                subtitle="Processo ágil na alocação de profissionais"
                buttonLink="http://www.gsoftware.com.br/solucoes/outsourcing/"
              />
            </li>
            <li
              className={`blog-banner cover-background sm-margin-15px-bottom sm-height-450px ${
                activeColumn === "rpa" ? "blog-column-active" : ""
              }`}
              style={{
                height: "600px",
                listStyle: "none",
                backgroundImage: `url(${HomeRpaJpg})`
              }}
              onMouseEnter={() => handleOptionHover("rpa")}
            >
              <SliderItem
                title="Robotic Process Automation"
                subtitle="RPA - O futuro dos negócios baseados em robotização"
                buttonLink="http://www.gsoftware.com.br/solucoes/rpa/"
              />
            </li>
            <li
              className={`blog-banner cover-background sm-margin-15px-bottom sm-height-450px ${
                activeColumn === "solution centre" ? "blog-column-active" : ""
              }`}
              style={{
                height: "600px",
                listStyle: "none",
                backgroundImage: `url(${HomeFabricaJpg})`
              }}
              onMouseEnter={() => handleOptionHover("solution centre")}
            >
              <SliderItem
                title="Solution Centre"
                subtitle="Estrutura para desenvolvimento de código"
                buttonLink="http://www.gsoftware.com.br/nosso-manifesto/"
              />
            </li>
          </ul>
        </Col>
      </div>
    </section>
  );
};

const SliderItem = ({ title, subtitle, buttonLink }) => {
  return (
    <>
      <div className="opacity-medium bg-extra-dark-gray"></div>
      <figure>
        <figcaption style={{ paddingBottom: "30px" }}>
          <a
            href={buttonLink}
            className="text-white text-extra-small text-uppercase alt-font font-weight-600 margin-10px-bottom display-inline-block"
          >
            {title}
          </a>
          <a
            href={buttonLink}
            className="text-extra-large display-block text-white alt-font width-200px md-width-180px sm-width-100 sm-margin-seven-bottom xs-width-100"
            style={{ marginBottom: "50px" }}
          >
            {subtitle}
          </a>
          <a
            href={buttonLink}
            className="btn btn-very-small btn-white font-weight-300"
          >
            Saiba mais
          </a>
        </figcaption>
      </figure>
    </>
  );
};

const Home = () => {
  const location = useLocation();
  const isResetingPassword = location.pathname
    .toLowerCase()
    .startsWith('/reset');
    
  return (
    <>
      <PageHeader isResetingPassword={isResetingPassword}/>
      <SliderSection />
      <PageBody />
      <PageFooter />
    </>
  );
};

export default Home;
