import { formatCpfCnpj, formatDate, formatStringToDate } from "../../../util";

export const editingMap = (data, setInitialValues) => {
  const salesOrderData = {
    client: data["NM_CLI_DS"],
    cnpj: formatCpfCnpj(data["NR_CLI_ID"]),
    seller: data["NR_VEN_ID"],
    status: data["NM_USR_INC_STA"],
    number: data["NR_TMV_NU"],
    date: formatDate(data["DT_TMV_PED"]),
    area: data["NM_CDP_DS"],
    manager: data["NM_CGS_DS"],
    invoicingBusinessDays: data["CD_FAT_UTL"],
    closingTypeBilling: data["IND_FAT_TP"],
    from: data["NU_CHR_INI"],
    to: data["NU_CHR_FIM"],
    consultant: data["NM_CLB_DS"], //TODO: trazer id pela proc
    cpf: formatCpfCnpj(data["NR_CLB_ID"]),
    modality: data["ID_MOD_CTR"],
    startDate: formatDate(data["DT_CLB_INI"]),
    endDate: formatDate(data["DT_CLB_FIM"]),
    monthlyHours: data["NR_HBS_QTD"],
    remuneration: data["IND_TIP_RMN"],
    timesheet: data["IND_PTO_TIP"],
    schedule: data["NM_HTR_DS"],
    state: data["NR_UF_ID_LTR"],
    city: data["NR_CID_ID_LTR"],
    expertise: data["NM_CLB_ESP"],
    workPlace: data["NM_LTR_DS"],
    poll: data["CD_CNL_CPT"],
    salesChannel: data["CD_CNL_VND"],
    observation: data["DS_FER_OBS"]
  };

  const salesValuesData = valueFieldsMap(data);

  setInitialValues({
    salesOrder: salesOrderData,
    salesValues: salesValuesData
  });
};

export const saveMap = (data) => {
  const saveObject = {
    client: data.salesOrderData.client,
    cnpj: data.salesOrderData.cnpj,
    seller: data.salesOrderData.seller,
    status: data.salesOrderData.status,
    number: data.salesOrderData.number,
    date: formatStringToDate(data.salesOrderData.date),
    area: data.salesOrderData.area,
    manager: data.salesOrderData.manager,
    invoicingBusinessDays: data.salesOrderData.invoicingBusinessDays,
    closingTypeBilling: data.salesOrderData.closingTypeBilling,
    from: data.salesOrderData.from,
    to: data.salesOrderData.to,
    consultant: data.salesOrderData.consultant, //TODO: trazer id pela proc
    cpf: data.salesOrderData.cpf,
    modality: data.salesOrderData.modality,
    startDate: formatStringToDate(data.salesOrderData.startDate),
    endDate: formatStringToDate(data.salesOrderData.endDate),
    monthlyHours: data.salesOrderData.monthlyHours,
    remuneration: data.salesOrderData.remuneration,
    timesheet: data.salesOrderData.timesheet,
    schedule: data.salesOrderData.schedule,
    state: data.salesOrderData.state,
    city: data.salesOrderData.city,
    expertise: data.salesOrderData.expertise,
    workPlace: data.salesOrderData.workPlace,
    poll: data.salesOrderData.poll,
    salesChannel: data.salesOrderData.salesChannel,
    observation: data.salesOrderData.observation
  };
  return saveObject;
};

export const valueFieldsMap = (data) => {
  const salesValuesData = {
    purchasePriceInput: data["VL_TM_VCO_TOT"],
    purchasePriceCalc: data["VL_TM_VCO_TOT_HR"],
    cltInput: data["VL_TM_VCO_CLT"],
    cltPriceCalc: data["PC_TM_VCO_CLT"],
    daInput: data["VL_TM_VCO_DA"],
    daCalc: data["PC_TM_VCO_DA"],
    pjInput: data["VL_TM_VCO_PJ"],
    pjCalc: data["PC_TM_VCO_PJ"],
    flexInput: data["VL_TM_VCO_FLX"],
    flexCalc: data["PC_TM_VCO_FLX"],
    cooperInput: data["VL_TM_VCO_COOP"],
    cooperCalc: data["PC_TM_VCO_COOP"],
    subsistenceAllowanceInput: data["VL_TM_VCO_DES"],
    cltChargesInput: data["VL_TM_CST_CLT"],
    cltChargesPriceCalc: data["PC_TM_CST_CLT"],
    quotasInput: data["VL_TM_CST_DA"],
    quotasCalc: data["PC_TM_CST_DA"],
    rhInput: data["VL_TM_CST_RH"],
    rhCalc: data["PC_TM_CST_RH"],
    equipmentInput: data["VL_TM_CST_MAT"],
    equipmentCalc: data["PC_TM_CST_MAT"],
    indirectCostInput: data["VL_TM_CST_IND"],
    indirectCostCalc: data["PC_TM_CST_IND"],
    overhaedInput: data["VL_TM_CST_FIN"],
    overhaedCalc: data["PC_TM_CST_FIN"],
    cooperCostInput: data["VL_TM_CST_COOP"],
    cooperCostCalc: data["PC_TM_CST_COOP"],
    lifeInsuranceInput: data["VL_TM_BNF_SV"],
    lifeInsuranceCalc: data["PC_TM_BNF_SV"],
    healthCareInput: data["VL_TM_BNF_AM"],
    healthCareCalc: data["PC_TM_BNF_AM"],
    dentalCareInput: data["VL_TM_BNF_AO"],
    dentalCareCalc: data["PC_TM_BNF_AO"],
    mealTicketInput: data["VL_TM_BNF_VR"],
    mealTicketCalc: data["PC_TM_BNF_VR"],
    foodStampsInput: data["VL_TM_BNF_VA"],
    foodStampsCalc: data["PC_TM_BNF_VA"],
    fuelVoucherInput: data["VL_TM_BNF_VC"],
    fuelVoucherCalc: data["PC_TM_BNF_VC"],
    charteredInput: data["VL_TM_BNF_VT"],
    charteredCalc: data["PC_TM_BNF_VT"],
    plrInput: data["VL_TM_BNF_PLR"],
    plrCalc: data["PC_TM_BNF_PLR"],
    otherBenefitsInput: data["VL_TM_BNF_OTR"],
    otherbenefitsCalc: data["PC_TM_BNF_OTR"],
    sellPriceInput: data["VL_TM_VVD_TOT"],
    sellPriceCalc: data["VL_TM_VVD_TOT_HR"],
    sellValueInput: data["VL_TM_VVD_CLB"],
    sellValueCalc: data["PC_TM_VVD_CLB"],
    equipmentSellInput: data["VL_TM_VVD_MAT"],
    equipmentSellCalc: data["PC_TM_VVD_MAT"],
    taxesInput: data["VL_TM_CST_IMP"],
    taxesCalc: data["PC_TM_CST_IMP"],
    profitInput: data["VL_TM_LCR_OPE"],
    profitCalc: data["PC_TM_LCR_OPE"],
    comissionInput: data["VL_TM_COM_VND"],
    comissionCalc: data["PC_TM_COM_VND"],
    presumedProfitInput: data["VL_TM_LPR_VND"],
    presumedProfitCalc: data["PC_TM_LPR_VND"],
    totalSaleValueInput: data["VL_TM_TOT_CMP"],
    totalSaleValueCalc: data["PC_TM_TOT_CMP"],
    totalTaxesValueInput: data["VL_TM_TOT_CST"],
    totalTaxesValueCalc: data["PC_TM_TOT_CST"],
    totalBenefitslValueInput: data["VL_TM_TOT_BNF"],
    totalBenefitsValueCalc: data["PC_TM_TOT_BNF"],
    observation: data["DS_VAL_OBS"]
  };

  return salesValuesData;
};
