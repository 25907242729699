import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Row } from 'react-bootstrap';
import { Header } from '../../../components/Header';
import { ButtonModel } from '../../../models/components/button.model';
import { FaArrowLeft, FaSave, FaRegSave } from 'react-icons/fa';
import { Formik } from 'formik';
import Fieldset from '../../../components/Fieldset';
import { UserModel } from '../../../models/user.model';
import { UFModel } from '../../../models/UF.model';
import { useDispatch } from 'react-redux';
import { getDomainListValues } from '../../../util/domainFunctions';
import { domainTypes, fieldTypes, formMode, serviceControllers } from '../../../util/enumerators';
import { ListRequest, RequestParamsModel } from '../../../models/requestParams.model';
import { DynamicForm } from '../../../components/DynamicForm';
import BaseService from '../../../services/base.service';
import { CPF_MASK } from '../../../util/consts';
import { IsNullOrEmpty } from '../../../util';
import { ToastService } from '../../../services/toast.service';
import { onUfChange } from '../../../util/formFunctions';

export const UserRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, mode } = useParams<{ id?: string; mode?: string }>();
  const [isLoading, setLoading] = useState(false);
  const formRef = useRef();

  const [UF, setUF] = useState<UFModel[]>();
  const [cities, setCities] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [domains, setDomains] = useState([]);
  const [status, setStatus] = useState([
    { ID_STATUS: true, DS_STATUS: 'Ativo' },
    { ID_STATUS: false, DS_STATUS: 'Inativo' },
  ]);

  const [initialValues, setInitialValues] = useState<UserModel>({
    user: {
      id: undefined,
      username: '',
      password: '',
      enterpriseId: undefined,
      name: '',
      email: '',
      photo: '',
      cpf: '',
      city: '',
      state: '',
      status: true,
      changePassword: false,
      notificationVersion: false,
      userCreated: undefined,
      userUpdated: undefined,
    },
  });

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/Usuario/`);
      },
      text: 'Voltar',
      variant: 'outline-primary',
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: 'Voltar para a página anterior',
    },
    {
      onClick: () => {
        submitFormik();
      },
      text: 'Salvar',
      disabled: false,
      hide: mode === formMode.VIEW,
      icon: (
        <>
          <FaRegSave className="fa-icon" /> <FaSave className="fa-icon-hover" />
        </>
      ),
      tooltip: id ? 'Salvar alterações' : 'Salvar registro',
    },
  ];

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  const onSubmit = async (data) => {
    await saveUser(data);
  };

  const domainConfigurations = [
    {
      domain: domainTypes.STATE,
      setList: setUF,
    },
  ];

  const fieldList = [
    {
      type: fieldTypes.MASKED,
      field: 'cpf',
      label: 'CPF',
      mask: CPF_MASK,
      lgSize: 2,
      disabled: id != null || isLoading || mode === formMode.VIEW,
    },
    {
      field: 'name',
      label: 'Nome',
      placeholder: 'Escreva o nome completo',
      lgSize: 4,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      field: 'username',
      label: 'Login',
      placeholder: 'Nome do login',
      lgSize: 6,
      maxLength: 60,
      disabled: id != null || isLoading || mode === formMode.VIEW,
    },
    {
      field: 'email',
      label: 'Email',
      placeholder: 'Ex: exemplo@email.com',
      lgSize: 6,
      maxLength: 60,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      type: fieldTypes.SELECT,
      field: 'state',
      label: 'UF',
      onChange: (event, handleChange, setFieldValue) => {
        handleChange(event);
        onUfChange(event.target.value, setCities);
        setFieldValue('user.city', null);
      },
      optionsList: UF,
      lgSize: 2,
      disabled: isLoading || mode === formMode.VIEW,
      value: 'NR_UF_ID',
      displayText: 'NR_UF_CD',
    },
    {
      type: fieldTypes.SELECT,
      field: 'city',
      label: 'Cidade',
      optionsList: cities,
      lgSize: 4,
      disabled: isLoading || cities?.length === 0 || mode === formMode.VIEW,
      value: 'NR_CID_ID',
      displayText: 'NM_CID_DS',
    },
    {
      type: fieldTypes.SELECT,
      field: 'profile',
      label: 'Perfil',
      optionsList: profiles,
      lgSize: 3,
      disabled: isLoading || profiles?.length === 0 || mode === formMode.VIEW,
      value: 'ID_PERFIL',
      displayText: 'DS_PERFIL',
    },
    {
      type: fieldTypes.SELECT,
      field: 'domain',
      label: 'Domínio',
      optionsList: domains,
      lgSize: 3,
      disabled: isLoading || domains?.length === 0 || mode === formMode.VIEW,
      value: 'ID_DOMINIO',
      displayText: 'DS_DOMINIO',
    },
    {
      type: fieldTypes.SELECT,
      field: 'status',
      label: 'Status',
      optionsList: status,
      includeSelectOption: false,
      value: 'ID_STATUS',
      displayText: 'DS_STATUS',
      lgSize: 3,
      disabled: isLoading || mode === formMode.VIEW,
    },
    {
      type: fieldTypes.CHECKBOX,
      field: 'changePassword',
      label: 'Mudar Senha',
      lgSize: 3,
      disabled: isLoading || mode === formMode.VIEW,
    },
  ];

  const loadDropDowns = async () => {
    setLoading(true);

    for (const config of domainConfigurations) {
      await getDomainListValues(config);
    }

    let requestParams: ListRequest = RequestParamsModel('USUARIO');

    const profilesResponse: any = await BaseService.getList(
      serviceControllers.PROFILE,
      requestParams
    );

    if (profilesResponse?.return?.success) {
      let itemsList = profilesResponse.result.dados;
      setProfiles(itemsList);
    }

    const domainsResponse: any = await BaseService.getList(
      serviceControllers.DOMAIN_OWN,
      requestParams
    );

    if (domainsResponse?.return?.success) {
      let itemsList = domainsResponse.result.dados;
      setDomains(itemsList);
    }
    setLoading(false);
  };

  const saveUser = async (data) => {
    setLoading(true);

    const params = {
      id: Number(id) ? parseInt(id) : undefined,
      cpf: !IsNullOrEmpty(data.user?.cpf) ? parseInt(data.user?.cpf.replace(/[^\d]/g, '')) : 0,
      name: data.user?.name.replace(/\s{2,}/g, ' '),
      username: data.user?.username,
      email: data.user?.email,
      state: data.user?.state ? parseInt(data.user?.state) : null,
      city: data.user?.city !== 0 ? data.user?.city : null,
      profile: data.user?.profile ? parseInt(data.user?.profile) : null,
      domain: data.user?.domain ? parseInt(data.user?.domain) : null,
      status: data.user?.status,
      changePassword: data.user?.changePassword,
      costCenterId: undefined,
    };

    try {
      BaseService.insert(serviceControllers.USER, params).then((response: any) => {
        setLoading(false);
        if (response.return.success === true) {
          if (Number(id)) {
            ToastService.success('Usuário atualizado com sucesso!');
          } else {
            ToastService.success('Usuário cadastrado com sucesso!');
          }
          navigate(`/Usuario/`);
        }
      });
    } catch (error) {
      ToastService.error('Erro ao salvar dados do Usuário!');
    }
  };

  const initialize = async () => {
    setLoading(true);

    await Promise.all([loadDropDowns(), loadForEdit()]);

    setLoading(false);
  };

  const loadForEdit = async () => {
    if (id !== undefined || id != null) {
      const apiResponse: any = await BaseService.getById(
        serviceControllers.USER,
        'ID_UsuarioEdicao',
        id
      );

      if (apiResponse?.return?.success) {
        if (apiResponse.result != null) {
          const data = apiResponse?.result?.dados[0];
          const cpf = data?.['DS_CPF'];
          const cpfToString = cpf?.toString().padStart(11, '0');
          await onUfChange(data?.['ID_UF'], setCities);

          setInitialValues((prevValues) => ({
            ...prevValues,
            user: {
              ...data,
              id: id,
              cpf: cpfToString,
              name: data?.['DS_NOME'],
              username: data?.['DS_LOGIN'],
              email: data?.['DS_EMAIL'],
              state: data?.['ID_UF'],
              city: data?.['NR_CID_ID'],
              profile: data?.['ID_PERFIL'],
              domain: data?.['ID_DOMINIO'],
              status: data?.['CD_STATUS'],
              changePassword: data?.['FL_MUDAR_SENHA'],
            },
          }));
        }
      }
    }
  };

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });

    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });

    initialize();
  }, []);

  return (
    <>
      <Header title="Gerenciamento de Usuário" buttons={buttons} />
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            onSubmit(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setValues,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Fieldset legend="Cadastro Usuário">
                <Row className="align-items-center" style={{ paddingTop: 10 }}>
                  <DynamicForm
                    form="user"
                    fieldList={fieldList}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    values={values}
                  />
                </Row>
              </Fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
