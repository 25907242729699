import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegEdit, FaEdit, FaRegEye, FaEye, FaUserPlus } from 'react-icons/fa';
import { ButtonModel } from '../../models/components/button.model';
import { formMode, serviceControllers } from '../../util/enumerators';
import ListPageComponent from '../../components/ListPageComponent';

export const ColaboradorList = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<any>();

  const rootUrl = '/Colaborador';
  const serviceController = serviceControllers.COLLABORATOR;
  const itemKey = 'NR_CLB_ID';
  const legend = 'Listagem de Colaboradores';

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.VIEW}/${selectedItem}`);
      },
      text: 'Visualizar',
      disabled: !Number.isInteger(selectedItem),
      icon: (
        <>
          <FaRegEye className="fa-icon" />
          <FaEye className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Visualizar dados do colaborador selecionado',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}/${selectedItem}`);
      },
      text: 'Editar',
      disabled: !Number.isInteger(selectedItem),
      icon: (
        <>
          <FaRegEdit className="fa-icon" />
          <FaEdit className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Editar colaborador selecionado',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}`);
      },
      text: 'Adicionar',
      icon: <FaUserPlus className="fa-icon-color-green" />,
      tooltip: 'Adicionar novo colaborador',
    },
  ];

  return (
    <ListPageComponent
      idDataset={'COLABORADOR'}
      serviceController={serviceController}
      legend={legend}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      buttons={buttons}
      referenceColumn={itemKey}
    />
  );
};
