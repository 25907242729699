export const CPF_MASK = '111.111.111-11';
export const CNPJ_MASK = '11.111.111/1111-11';
export const DATE_MASK = '11/11/1111';
export const MONTH_YEAR_MASK = '11/1111';
export const TIME_MASK = '11:11';
export const CELLPHONE_MASK = '(11) 11111-1111';
export const CEP_MASK = '11.111-111';
export const CURRENCY_MASK = '111.111,11';
export const TABELA_COLABORADOR = 'RH_COLABORADOR';
export const TABELA_REGISTRO = 'RH_REGISTRO';
export const TABELA_CLIENTE_FORNECEDOR = 'CF_CLIENTE_FORNECEDOR';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
