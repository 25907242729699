import { useCallback, useEffect, useState } from 'react';
import { serviceControllers } from '../../../util/enumerators';
import Fieldset from '../../../components/Fieldset';
import { TableComponent } from '../../../components/TableComponent';
import { FaEye, FaUserCog, FaUserEdit, FaUserPlus, FaUsers } from 'react-icons/fa';
import { FiEye, FiUserPlus } from 'react-icons/fi';
import { LiaUserCogSolid, LiaUserEditSolid } from 'react-icons/lia';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { Header } from '../../../components/Header';
import { CustomModal } from '../../../components/CustomModal';
import ManagerEdit from './manager.edit';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { useDispatch } from 'react-redux';
import { ListRequest, RequestParamsModel } from '../../../models/requestParams.model';
import { FilterAndListBuilder } from '../../../util/listFunctions';
import { Form } from 'react-bootstrap';
import { ClientDepartmentService } from './../../../services/client.department.service';
import { ToastService } from '../../../services/toast.service';
import { ClientDepartManagerService } from './../../../services/client.depart.manager.service';
import { DepartManagerModel } from '../../../models/cliente.model';

interface IManagerList {
  idClient: number;
  idDepart?: number | boolean;
  refresh?: () => void;
}

const ManagerList = ({ idClient, idDepart, refresh }: IManagerList) => {
  const dispatch = useDispatch();
  const [managers, setManagers] = useState({ items: [] });
  const [managerColumns, setManagerColumns] = useState([]);
  const [modeForm, setModeForm] = useState<'add' | 'edit' | 'view'>('add');
  const [showManagerEdit, setShowManagerEdit] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [managerSelected, setManagerSelected] = useState<number | null>(null);
  const [managerIndex, setManagerIndex] = useState<number | null>(null);
  const [departName, setDepartName] = useState<string | null>(null);
  const [dataset, setDataset] = useState<ListRequest>(RequestParamsModel('CLIENTE_GESTOR'));
  const [filterValue, setFilterValue] = useState('-1');
  const [filterList, setFilterList] = useState([
    {
      id: 'NR_CLI_ID',
      filterValue: idClient,
    },
    {
      id: 'ID_CLIENTE_DEPTO',
      filterValue: idDepart,
    },
    {
      id: 'CD_TP_FILTRO',
      filterValue: typeof idDepart !== 'number' ? -1 : 0,
    },
  ]);
  const btnManagerList = [
    {
      onClick: () => {
        setModeForm('view');
        setShowManagerEdit(true);
        //submitFormik();
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiEye className="fa-icon" />
          <FaEye className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Visualizar Registro',
      disabled: typeof managerSelected !== 'number',
    },
    {
      onClick: () => {
        setShowManagerEdit(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiUserPlus className="fa-icon" />
          <FaUserPlus className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Incluir Novo Gestor',
      disabled: typeof managerSelected === 'number',
    },
    {
      onClick: () => {
        setShowManagerEdit(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <LiaUserEditSolid className="fa-icon" />
          <FaUserEdit className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Editar Gestor',
      disabled: typeof managerSelected !== 'number',
    },
    {
      onClick: () => {
        handleAddManager();
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <AiOutlineUsergroupAdd className="fa-icon" />
          <FaUsers className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Inclui Gestor no Departamento',
      disabled: typeof managerSelected !== 'number',
    },
  ];

  const handleAddManager = async () => {
    if (typeof idDepart !== 'number') {
      ToastService.error('Selecione um departamento para pode incluir um gestor!');
      return;
    }
    const departManagerObj: DepartManagerModel = {
      id: 0,
      department: idDepart,
      cnpj: idClient,
      manager: managerSelected,
      status: 1,
    };
    const apiResponse: any = await ClientDepartManagerService.insert(
      serviceControllers.CLIENT_DEPART_MANAGER,
      departManagerObj
    );
    if (apiResponse.return?.success) {
      ToastService.success(`Gestor incluído ao departamento ${departName} com sucesso!`);
      setManagerIndex(null);
      setManagerSelected(null);
      //getManagers(RequestParamsModel('CLIENTE_GESTOR'), true);
      refresh();
    }
  };

  const selectManager = (param, index) => {
    setManagerIndex(managerIndex !== index ? index : null);
    setManagerSelected(managerSelected === param || param);
  };

  const getManagers = useCallback(
    async (data: any, isFilter: boolean = false) => {
      FilterAndListBuilder(
        serviceControllers.CLIENT_MANAGER,
        data,
        filterList,
        setManagers,
        managers,
        setDataset,
        setManagerColumns,
        isFilter,
        dispatch
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [managers.items, dataset, filterList, dispatch]
  );

  const handleFilterValue = (value: string) => {
    const newFilter = [
      {
        id: 'NR_CLI_ID',
        filterValue: idClient,
      },
      {
        id: 'ID_CLIENTE_DEPTO',
        filterValue: idDepart,
      },
      {
        id: 'CD_TP_FILTRO',
        filterValue: Number.parseInt(value),
      },
    ];
    setFilterList(newFilter);
    setFilterValue(value);
  };

  const getDepartmentName = async () => {
    const apiResponse: any = await ClientDepartmentService.getById(
      serviceControllers.CLIENT_DEPARTMENT,
      [
        { id: 'ID_CLIENTE_DEPTO', value: idDepart },
        {
          id: 'NR_CLI_ID',
          value: idClient,
        },
      ]
    );
    if (apiResponse.return?.success) {
      if (apiResponse.result != null) {
        setDepartName(apiResponse.result?.dados[0].NM_CDP_DS);
      }
    }
  };

  useEffect(() => {
    //getManagers();
    getManagers(RequestParamsModel('CLIENTE_GESTOR'), true);
  }, [filterList]);

  useEffect(() => {
    getManagers(RequestParamsModel('CLIENTE_GESTOR'), true);
    if (typeof idDepart === 'number') {
      getDepartmentName();
    }
  }, []);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ marginTop: '15px', minWidth: '300px' }}>
          <Form.Check
            inline
            label="Todos"
            name="group1"
            type={'radio'}
            id={`inline-1`}
            value={filterValue}
            onChange={({ target }) => handleFilterValue('-1')}
            defaultChecked={typeof idDepart !== 'number'}
          />
          <Form.Check
            inline
            label="Selecionados"
            name="group1"
            type={'radio'}
            id={`inline-2`}
            value={filterValue}
            onChange={({ target }) => handleFilterValue('0')}
            defaultChecked={typeof idDepart === 'number'}
          />
          <Form.Check
            inline
            label="Disponíveis"
            name="group1"
            type={'radio'}
            id={`inline-3`}
            value={filterValue}
            onChange={({ target }) => handleFilterValue('1')}
          />
        </div>
        <Header title={''} buttons={btnManagerList} showBread={false} />
      </div>
      <Fieldset legend={'Gestores Cadastrados'}>
        <div
          style={{
            paddingTop: 13,
          }}
          className="height-fit-table"
        >
          {departName && (
            <p>
              <b>Departamento Selecionado: </b>
              {departName}
            </p>
          )}
          <TableComponent
            infiniteScrollClassName="height-definition-timesheet"
            itemList={managers}
            fetchMoreData={() => {}}
            totalItems={0}
            gridColumns={managerColumns}
            selectedItems={managerSelected}
            selectItem={selectManager}
            referenceColumn={'ID_CLIENTE_GESTOR'}
            orderable={false}
          />
        </div>
      </Fieldset>
      <CustomModal
        show={showManagerEdit}
        handleClose={() => setShowManagerEdit(false)}
        title="Gestor"
        children={
          <ManagerEdit
            idClient={idClient}
            idDepart={null}
            idManager={managerSelected}
            refreshList={() => getManagers(RequestParamsModel('CLIENTE_GESTOR'), true)}
            //refreshList={() => getManagers()}
            handleClose={() => setShowManagerEdit(false)}
            mode={modeForm}
          />
        }
      />
      <ConfirmationModal
        title={'Deseja realmente excluir este registro?'}
        show={showConfirmDelete}
        handleClose={() => {
          setShowConfirmDelete(false);
        }}
        handleConfirm={() => {
          //handleConfirmModal();
          setShowConfirmDelete(false);
        }}
        children={
          <p style={{ fontSize: '14px', color: 'red', marginBottom: 0 }}>
            Essa operação não poderá ser desfeita...
          </p>
        }
      />
    </>
  );
};

export default ManagerList;
