import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import { Header } from "../../components/Header";

import {
  RequestMenuParamsModel,
  RequestMenuParamsModelDefault
} from "../../models/requestMenu.model";

import { MenuService } from "../../services/menu.service";
import moment from "moment";
import { FaCheck } from "react-icons/fa";
import Fieldset from "../../components/Fieldset";
import { ButtonModel } from "../../models/components/button.model";

export const MenuList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters = useSelector((state: any) => state.filtersToSearch);
  const style = {
    height: 30,
    margin: 6,
    padding: 8,
    cursor: "pointer"
  };

  const [menuList, setmenuList] = useState({ items: [] });

  const { t } = useTranslation();

  const [dataset, setDataset] = useState<RequestMenuParamsModel>(
    RequestMenuParamsModelDefault
  );
  const [menuSelecionado, setMenuSelecionado] = useState<any>();
  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/Menu/Consulta/${menuSelecionado}`);
      },
      text: t("VIEW"),
      variant: "outline-primary",
      disabled: !Number.isInteger(menuSelecionado)
    },
    {
      onClick: () => {
        navigate(`/Menu/Cadastro/${menuSelecionado}`);
      },
      text: t("EDIT"),
      variant: "outline-primary",
      disabled: !Number.isInteger(menuSelecionado)
    },
    {
      onClick: () => {
        navigate("/Menu/Cadastro");
      },
      text: t("NEW"),
      variant: "primary",
      disabled: false
    }
  ];

  const getList = useCallback(
    async (data: any, isFilter: boolean = false) => {
      let requestMenuParams: RequestMenuParamsModel = data;

      if (filters) {
        requestMenuParams.execucao.pesquisa.campo = [];
        filters.map((filter) => {
          requestMenuParams.execucao.pesquisa.campo.push({
            id: filter.id,
            dsc: filter.filterType,
            value: filter.filterValue,
            tipo: "texto",
            sprauto: ""
          });
        });
      }

      const apiResponse: any = await MenuService.getList(requestMenuParams);

      if (apiResponse?.execucao?.retorno["@SUCESSO"] === "1") {
        if (!isFilter) {
          setmenuList({
            items: [...menuList.items, ...apiResponse.resultList]
          });
        } else {
          setmenuList({
            items: apiResponse.resultList
          });
        }

        let options = [];
        apiResponse.execucao.pesquisa.CAMPO.map((campo) => {
          options.push({
            id: campo["@id"],
            value: campo["@dsc"],
            tipo: campo["@tipo"],
            sprauto: campo["@sprauto"]
          });
        });

        dispatch({ type: "LOAD_FILTERS", filtersList: options });

        setDataset({
          ...dataset,
          execucao: {
            ...dataset.execucao,
            paginacao: apiResponse.execucao.paginacao
          }
        });
      }
    },
    [menuList.items, dataset, filters, dispatch]
  );

  const fetchMoreData = () => {
    let datasetTmp = {
      ...dataset,
      execucao: {
        ...dataset.execucao,
        paginacao: {
          ...dataset.execucao.paginacao,
          pagina: {
            ...dataset.execucao.paginacao.pagina,
            numero: dataset.execucao.paginacao.pagina.numero + 1
          }
        }
      }
    };
    getList(datasetTmp);
  };

  useEffect(() => {
    setMenuSelecionado(null);
    getList(RequestMenuParamsModelDefault, true);
  }, [filters]);

  const selecionaMenu = (index: number) => {
    if (menuSelecionado === index) {
      setMenuSelecionado(null);
    } else {
      setMenuSelecionado(index);
    }
  };

  return (
    <>
      <Header title="Listagem de Menu" buttons={buttons} />

      <Fieldset legend="Listagem de Menu">
        <InfiniteScroll
          dataLength={menuList.items.length}
          next={fetchMoreData}
          hasMore={
            menuList.items.length < dataset.execucao.paginacao?.records?.total
          }
          loader={<b>Carregando...</b>}
          height="100%"
        >
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Ordem</th>
                <th>Descrição</th>
                <th>Menu Pai</th>
                <th>URL</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {menuList.items.map((i, index) => (
                <tr
                  className="text-center"
                  style={{
                    ...style,
                    backgroundColor:
                      i.NR_CLB_ID === menuSelecionado
                        ? "var(--cor-principal-background)"
                        : ""
                  }}
                  key={index}
                  onClick={() => {
                    selecionaMenu(i.NR_CLB_ID);
                  }}
                >
                  <td> {i.DS_ORDEM == null ? "-" : i.DS_ORDEM}</td>
                  <td>
                    {" "}
                    {i.descriptionMenu == null ? "-" : i.descriptionMenu}
                  </td>
                  <td>
                    {" "}
                    {i.DESCRICAO_MENU_PAI == null
                      ? "-"
                      : i.DESCRICAO_MENU_PAI}{" "}
                  </td>
                  <td> {i.urlTarget == null ? "-" : i.urlTarget}</td>

                  <td>
                    {i.FL_STATUS ? (
                      <FaCheck className="text-success" />
                    ) : (
                      "Inativo"
                    )}
                  </td>

                  <td>
                    {i.DT_REG_ENT !== null && i.DT_REG_SDA === null ? (
                      <FaCheck className="text-success" />
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      </Fieldset>

      {/* <InfiniteScroll
        dataLength={menuList.items.length}
        next={fetchMoreData}
        hasMore={
          menuList.items.length < 100 //dataset.execucao.paginacao?.records?.total
        }
        loader={<b>Carregando...</b>}
        height="100%"
      >
        <Table striped bordered hover size="sm">
          <thead>
            <tr className="text-center">
              <th>Ordem</th>
              <th>Descrição</th>
              <th>Menu Pai</th>
              <th>URL</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {menuList.items.map((i, index) => (
              <tr className="text-center" style={style} key={index}>
                <td> {i.DS_ORDEM == null ? "-" : i.DS_ORDEM}</td>
                <td> {i.descriptionMenu == null ? "-" : i.descriptionMenu}</td>
                <td>
                  {i.DESCRICAO_MENU_PAI == null ? "-" : i.DESCRICAO_MENU_PAI}
                </td>
                <td> {i.urlTarget == null ? "-" : i.urlTarget}</td>
                <td> {i.FL_STATUS ? "Ativo" : "Inativo"}</td>
                <td> - </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </InfiniteScroll> */}
    </>
  );
};
