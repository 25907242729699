import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Modal, Col, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";

import { ToastService } from "../services/toast.service";
import AuthService from "../services/auth.service";

export const ModalChangePassword = (props: any) => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const initialValues: any = {
    password: "",
    newPassword: "",
    passwordConfirmation: ""
  };

  const schema = yup.object().shape({
    password: yup.string().required(t("REQUIRED")),
    newPassword: yup
      .string()
      .required(t("REQUIRED"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        t("PASSWORD_STRENGTH")
      ),
    passwordConfirmation: yup
      .string()
      .required(t("REQUIRED"))
      .oneOf([yup.ref("newPassword"), null], "As senhas não correspondem")
  });

  const handleChangePesonalData = (password, newPassword) => {
    setLoading(true);

    const params = {
      currentPassword: password,
      newPassword: newPassword
    };

    AuthService.changePassword(params)
      .then((response: any) => {
        if (response?.return?.success) {
          ToastService.success(t("PASSWORD_CHANGE_SUCCESS"));

          if (typeof props.handleClose !== "undefined") {
            props.handleClose();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      animation={false}
      size="lg"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values) => {
          handleChangePesonalData(values.password, values.newPassword);
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t("CHANGE_PASSWORD")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xs={4} sm={4}>
                  <Form.Group>
                    <Form.Label>{t("CURRENT_PASSWORD")}</Form.Label>
                    <Form.Control
                      id="password"
                      name="password"
                      type="password"
                      value={values.password}
                      isInvalid={!!touched.password && !!errors.password}
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={4} sm={4}>
                  <Form.Group>
                    <Form.Label>{t("NEW_PASSWORD")}</Form.Label>
                    <Form.Control
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      maxLength={15}
                      value={values.newPassword}
                      isInvalid={!!touched.newPassword && !!errors.newPassword}
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.newPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={4} sm={4}>
                  <Form.Group>
                    <Form.Label>{t("CONFIRM_PASSWORD")}</Form.Label>
                    <Form.Control
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type="password"
                      value={values.passwordConfirmation}
                      isInvalid={
                        !!touched.passwordConfirmation &&
                        !!errors.passwordConfirmation
                      }
                      maxLength={15}
                      onChange={handleChange}
                      disabled={isLoading}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.passwordConfirmation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={props.handleClose}>
                {t("CANCEL")}
              </Button>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("SAVE")}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
