import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaRegCalendarPlus,
  FaCalendarPlus,
  FaRegCalendarTimes,
  FaCalendarTimes,
  FaFileExcel,
  FaRegFileExcel,
} from 'react-icons/fa';

import { ButtonModel } from '../../models/components/button.model';
import { formMode, serviceControllers } from '../../util/enumerators';
import ListPageComponent from '../../components/ListPageComponent';
import { ExportFunctions } from '../../util/ExportFunctions';
import { RequestParamsModelDefault } from '../../models/requestParams.model';

export const HolidayList = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [holidayList, setHolidayList] = useState({ items: [] });
  const [selectedHoliday, setSelectedHoliday] = useState<any>();
  const [selectedItemHoliday, setSelectedItemHoliday] = useState<any>();
  const [isLoading, setLoading] = useState(false);

  const rootUrl = '/Feriado';
  const serviceController = serviceControllers.HOLIDAY;
  const itemKey = 'DT_FER_DIA';
  const legend = 'Listagem de Feriados';

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        setShowModal(true);
      },
      text: 'Excluir',
      disabled: selectedHoliday === true || selectedHoliday === null ? true : false,
      icon: (
        <>
          <FaRegCalendarTimes className="fa-icon" />
          <FaCalendarTimes className="fa-icon-hover fa-icon-color-red" />
        </>
      ),
      tooltip: 'Excluir feriado',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}`);
      },
      text: 'Adicionar',
      icon: (
        <>
          <FaRegCalendarPlus className="fa-icon" />
          <FaCalendarPlus className="fa-icon-hover fa-icon-color-green" />
        </>
      ),
      tooltip: 'Adicionar novo feriado',
    },
    {
      onClick: async () => {
        setLoading(true);

        await ExportFunctions.downloadExcel(serviceController, RequestParamsModelDefault);

        setLoading(false);
      },
      text: 'Exportar',
      disabled: isLoading,
      icon: (
        <>
          <FaRegFileExcel className="fa-icon" />
          <FaFileExcel className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Exportar dados para Excel',
    },
  ];

  const selectHoliday = (param, index) => {
    setSelectedHoliday(selectedHoliday !== index ? index : null);
    setSelectedItemHoliday(selectedItemHoliday === param || param);
  };

  const handleDelParams = () => {
    var selectedItem = holidayList.items.find(
      (holiday) => holiday.DT_FER_DIA === selectedItemHoliday
    );

    var delParams = {
      date: selectedItem['DT_FER_DIA'],
      city: selectedItem['NR_CID_ID'],
      state: selectedItem['NR_UF_ID'],
    };

    return delParams;
  };

  return (
    <ListPageComponent
      idDataset={'FERIADO'}
      serviceController={serviceController}
      legend={legend}
      customSelectFunction={selectHoliday}
      selectedItem={selectedHoliday}
      setSelectedItem={setSelectedHoliday}
      buttons={buttons}
      referenceColumn={itemKey}
      useIndex={true}
      delParams={handleDelParams}
      showModal={showModal}
      setShowModal={setShowModal}
      externalDataList={holidayList}
      setExternalDataList={setHolidayList}
    />
  );
};
