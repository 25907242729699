export interface SalesOrderDataModel {
  client: string;
  cnpj: string;
  seller: number;
  status: any;
  number: any;
  date: string;
  area: string;
  manager: string;
  invoicingBusinessDays: number;
  closingTypeBilling: number;
  from: string;
  to: string;
  consultant: number;
  cpf: string;
  modality: string;
  startDate: string;
  endDate: string;
  monthlyHours: string;
  remuneration: number;
  timesheet: number;
  schedule: string;
  state: number;
  city: number;
  expertise: string;
  workPlace: string;
  poll: number;
  salesChannel: number;
  observation: string;
}

export const SalesOrderDataModelDefault: SalesOrderDataModel = {
  client: "",
  cnpj: "",
  seller: 0,
  status: "",
  number: "",
  date: "",
  area: "",
  manager: "",
  invoicingBusinessDays: 0,
  closingTypeBilling: 0,
  from: "",
  to: "",
  consultant: 0,
  cpf: "",
  modality: "",
  startDate: "",
  endDate: "",
  monthlyHours: "",
  remuneration: 0,
  timesheet: 0,
  schedule: "",
  state: 0,
  city: 0,
  expertise: "",
  workPlace: "",
  poll: 0,
  salesChannel: 0,
  observation: ""
};
