import { orderByDirections } from '../../util/enumerators';
import { OrderByArrow } from '../OrderByArrow';

export const DynamicHeader = ({
  gridColumns,
  selecionarTodos = null,
  toggleSelecionarTodos = null,
  orderable = true,
  order = null,
  onColumnOrdered = null,
}) => {
  return (
    <thead>
      <tr style={{ borderTopStyle: 'hidden' }} key={Math.random() * 10}>
        {selecionarTodos != null ? (
          <th style={{ width: 30 }} className="table-header" title="Selecionar Todos">
            <input type="checkbox" checked={selecionarTodos} onChange={toggleSelecionarTodos} />
          </th>
        ) : (
          <th style={{ width: 30, minWidth: 30 }} className="table-header"></th>
        )}
        {gridColumns?.map((i) => (
          <th
            key={Math.random()}
            className="table-header"
            title={i.description}
            onClick={() => {
              if (i.orderable && orderable) {
                onColumnOrdered(
                  i.id,
                  order?.direction === orderByDirections.ASC
                    ? orderByDirections.DESC
                    : orderByDirections.ASC
                );
              }
            }}
          >
            <span>{i.header}</span>

            {orderable && (
              <OrderByArrow
                orderedColumn={order?.column}
                direction={order?.direction}
                targetColumn={i}
              />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};
