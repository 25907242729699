export interface ValuesDataModel {
  purchasePriceInput: string;
  purchasePriceCalc: string;

  cltInput: string;
  cltPriceCalc: string;

  daInput: string;
  daCalc: string;

  pjInput: string;
  pjCalc: string;

  flexInput: string;
  flexCalc: string;

  cooperInput: string;
  cooperCalc: string;

  subsistenceAllowanceInput: string;

  cltChargesInput: string;
  cltChargesPriceCalc: string;

  quotasInput: string;
  quotasCalc: string;

  rhInput: string;
  rhCalc: string;

  equipmentInput: string;
  equipmentCalc: string;

  indirectCostInput: string;
  indirectCostCalc: string;

  overhaedInput: string;
  overhaedCalc: string;

  cooperCostInput: string;
  cooperCostCalc: string;

  lifeInsuranceInput: string;
  lifeInsuranceCalc: string;

  healthCareInput: string;
  healthCareCalc: string;

  dentalCareInput: string;
  dentalCareCalc: string;

  mealTicketInput: string;
  mealTicketCalc: string;

  foodStampsInput: string;
  foodStampsCalc: string;

  fuelVoucherInput: string;
  fuelVoucherCalc: string;

  charteredInput: string;
  charteredCalc: string;

  plrInput: string;
  plrCalc: string;

  otherBenefitsInput: string;
  otherbenefitsCalc: string;

  sellPriceInput: string;
  sellPriceCalc: string;

  sellValueInput: string;
  sellValueCalc: string;

  equipmentSellInput: string;
  equipmentSellCalc: string;

  taxesInput: string;
  taxesCalc: string;

  profitInput: string;
  profitCalc: string;

  comissionInput: string;
  comissionCalc: string;

  presumedProfitInput: string;
  presumedProfitCalc: string;

  totalSaleValueInput: string;
  totalSaleValueCalc: string;

  totalTaxesValueInput: string;
  totalTaxesValueCalc: string;

  totalBenefitslValueInput: string;
  totalBenefitsValueCalc: string;

  observation: string;
}

export const ValuesDataModelDefault: ValuesDataModel = {
  purchasePriceInput: "",
  purchasePriceCalc: "",
  cltInput: "",
  cltPriceCalc: "",
  daInput: "",
  daCalc: "",
  pjInput: "",
  pjCalc: "",
  flexInput: "",
  flexCalc: "",
  cooperInput: "",
  cooperCalc: "",
  subsistenceAllowanceInput: "",
  cltChargesInput: "",
  cltChargesPriceCalc: "",
  quotasInput: "",
  quotasCalc: "",
  rhInput: "",
  rhCalc: "",
  equipmentInput: "",
  equipmentCalc: "",
  indirectCostInput: "",
  indirectCostCalc: "",
  overhaedInput: "",
  overhaedCalc: "",
  cooperCostInput: "",
  cooperCostCalc: "",
  lifeInsuranceInput: "",
  lifeInsuranceCalc: "",
  healthCareInput: "",
  healthCareCalc: "",
  dentalCareInput: "",
  dentalCareCalc: "",
  mealTicketInput: "",
  mealTicketCalc: "",
  foodStampsInput: "",
  foodStampsCalc: "",
  fuelVoucherInput: "",
  fuelVoucherCalc: "",
  charteredInput: "",
  charteredCalc: "",
  plrInput: "",
  plrCalc: "",
  otherBenefitsInput: "",
  otherbenefitsCalc: "",
  sellPriceInput: "",
  sellPriceCalc: "",
  sellValueInput: "",
  sellValueCalc: "",
  equipmentSellInput: "",
  equipmentSellCalc: "",
  taxesInput: "",
  taxesCalc: "",
  profitInput: "",
  profitCalc: "",
  comissionInput: "",
  comissionCalc: "",
  presumedProfitInput: "",
  presumedProfitCalc: "",
  totalSaleValueInput: "",
  totalSaleValueCalc: "",
  totalTaxesValueInput: "",
  totalTaxesValueCalc: "",
  totalBenefitslValueInput: "",
  totalBenefitsValueCalc: "",
  observation: ""
};
