import HttpService from './http.service';
import { RequestMenuParamsModel } from '../models/requestMenu.model';
import { serviceControllers } from '../util/enumerators';
const basePath = serviceControllers.MENU;

export const MenuService = {
  getList,
  getChildrens,
  insert,
  update,
};

async function getList(data: RequestMenuParamsModel) {
  return HttpService.post(`${basePath}/GetWithFilter`, data);
}

async function getChildrens(data: RequestMenuParamsModel) {
  return HttpService.post(`${basePath}/GetChildrens`, data);
}

async function insert(data) {
  return HttpService.post(`${basePath}`, data);
}

async function update(data) {
  return HttpService.put(basePath, data);
}

export default MenuService;
