import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { List, arrayMove } from "react-movable";

import { Formik } from "formik";
import * as yup from "yup";

import { Header } from "../../components/Header";

import { Menu as MenuModel } from "../../models/menu.model";
import { MenuService } from "../../services/menu.service";

import {
  RequestMenuParamsModel,
  RequestMenuParamsModelDefault
} from "../../models/requestMenu.model";
import _ from "lodash";
import Fieldset from "../../components/Fieldset";

const tableStyles = {
  background: "#eaebec",
  borderSpacing: 0
};

export const MenuRegister = () => {
  const { pathname } = useLocation();
  const { id } = useParams<{ id?: string }>();

  const [isLoading, setLoading] = useState(false);
  const [menus, setMenus] = useState<MenuModel[]>();

  const { t } = useTranslation();
  const formRef = useRef();

  const labelTitle = id === undefined ? "Novo Menu" : "Editar Menu";

  const [menuChildrens, setMenuChildrens] = useState([]);

  const [initialValues, setInitialValues] = useState({
    descricao: "",
    url: "",
    icone: "",
    ordem: 0,
    idMenuPai: undefined
  });

  const schema = yup.object().shape({
    descricao: yup.string().required(t("REQUIRED")),
    url: yup.string().required(t("REQUIRED")),
    icone: yup.string().required(t("REQUIRED")),
    ordem: yup.number().required(t("REQUIRED")),
    idMenuPai: yup.number().required(t("REQUIRED"))
  });

  const initialize = async () => {
    setLoading(true);

    const apiResponse: any = await MenuService.getList(
      RequestMenuParamsModelDefault
    );
    setMenus(apiResponse.resultList);

    setLoading(false);
  };

  const create = async (data) => {
    const response: any = await MenuService.insert(data);
  };
  const update = async (data) => {
    const response: any = await MenuService.update(data);
  };

  const onMenuPaiChange = async (data) => {
    setLoading(true);

    if (data != 0) {
      let requesParanms: RequestMenuParamsModel = RequestMenuParamsModelDefault;
      //requesParanms.idMenuPai = data;

      const apiResponse: any = await MenuService.getChildrens(requesParanms);

      if (apiResponse.resultList.length > 0) {
        setMenuChildrens(apiResponse.resultList);
      } else {
        setMenuChildrens([]);
        // "o menu selecionado nao possui filhos"
      }
    } else {
      setMenuChildrens([]);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    if (Number(id)) {
      await update(data);
    } else {
      await create(data);
    }
  };

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <Header title={labelTitle} />

      <hr className="mb-4" />

      <Fieldset legend="Colaborador">
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values, actions) => {
            onSubmit(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setValues
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-4">
                <Col sm={12} md={6} lg={3}>
                  <Form.Group>
                    <Form.Label>{t("Descricao")}</Form.Label>
                    <Form.Control
                      required
                      size="sm"
                      type="text"
                      id="descricao"
                      name="descricao"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={touched.descricao && !!errors.descricao}
                      value={values.descricao}
                      maxLength={60}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.descricao}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <Form.Group>
                    <Form.Label>{t("URL")}</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      id="url"
                      name="url"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={touched.url && !!errors.url}
                      value={values.url}
                      maxLength={60}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.url}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <Form.Group>
                    <Form.Label>{t("Icone")}</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      id="icone"
                      name="icone"
                      required
                      placeholder={t("Icone")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={touched.icone && !!errors.icone}
                      value={values.icone}
                      maxLength={60}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.icone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <Form.Group>
                    <Form.Label>{t("Menu Pai")}</Form.Label>
                    <Form.Select
                      size="sm"
                      id="idMenuPai"
                      name="idMenuPai"
                      isInvalid={touched.idMenuPai && !!errors.idMenuPai}
                      disabled={isLoading}
                      onChange={(event) => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          idMenuPai: event.target.value
                        }));

                        onMenuPaiChange(event.target.value);
                      }}
                      value={values.idMenuPai}
                      aria-label={t("Menu Pai")}
                    >
                      <option value="0">{t("Selecione...")}</option>
                      {_.map(menus, (m: MenuModel) => {
                        return (
                          <option key={m.id} value={m.id}>
                            {m.descriptionMenu} - {m.menuParentId}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.idMenuPai}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col sm={12} md={6} lg={3}>
                  {menuChildrens.length > 0 && (
                    <Fieldset legend="Filhos">
                      <List
                        values={menuChildrens}
                        onChange={({ oldIndex, newIndex }) =>
                          setMenuChildrens(
                            arrayMove(menuChildrens, oldIndex, newIndex)
                          )
                        }
                        renderList={({ children, props, isDragged }) => (
                          <table
                            style={{
                              ...tableStyles,
                              cursor: isDragged ? "grabbing" : undefined
                            }}
                          >
                            <thead>
                              <tr>
                                <th className="thStyles">Ordem</th>
                                <th className="thStyles">Descrição</th>
                              </tr>
                            </thead>
                            <tbody {...props}>{children}</tbody>
                          </table>
                        )}
                        renderItem={({
                          value,
                          props,
                          isDragged,
                          isSelected
                        }) => {
                          const row = (
                            <tr
                              {...props}
                              style={{
                                ...props.style,
                                cursor: isDragged ? "grabbing" : "grab",
                                backgroundColor:
                                  isDragged || isSelected ? "#EEE" : "#fafafa"
                              }}
                            >
                              <td className="tdStyles">{value.dsOrdem}</td>
                              <td className="tdStyles">{value.dsMenu}</td>
                            </tr>
                          );
                          return isDragged ? (
                            <table style={{ ...props.style, borderSpacing: 0 }}>
                              <tbody>{row}</tbody>
                            </table>
                          ) : (
                            row
                          );
                        }}
                      />
                    </Fieldset>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Fieldset>
    </>
  );
};
