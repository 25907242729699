import { UserCredentialsModel } from '../models/userCredentials';
import { ResponseRequestModel, ResultRequestModel } from '../models/resultRequest.model';
import { RecoveryPasswordModel } from '../models/recoveryPassword.model';
import HttpService from './http.service';
import { ResultLogin } from '../models/resultLogin.model';
import { serviceControllers } from '../util/enumerators';
import { ResetPasswordModel } from '../models/ResetPasswordModel';

const basePath = serviceControllers.USER;

export const AuthService = {
  login,
  changePassword,
  forgotPassword,
  refreshToken,
  recoveryPassword,
  isResetPasswordHashValid,
  resetPassword,
};

async function login(data: UserCredentialsModel): Promise<ResultLogin> {
  return HttpService.post(`${basePath}/login`, data);
}

async function changePassword(data: object): Promise<ResultRequestModel> {
  return HttpService.put(`${basePath}/ChangePassword`, JSON.stringify(data));
}

async function forgotPassword(data: string): Promise<ResultRequestModel> {
  return HttpService.post(`${basePath}/ResetPassword`, data);
}

async function refreshToken(data: any): Promise<ResultLogin> {
  return HttpService.post(`${basePath}/RefreshToken`, data);
}

async function recoveryPassword(data: RecoveryPasswordModel): Promise<ResultRequestModel> {
  return HttpService.post(`${basePath}/RecoveryPassword`, data);
}

async function isResetPasswordHashValid(hash: string): Promise<ResponseRequestModel> {
  return HttpService.get(`${basePath}/IsResetPasswordHashValid`, {
    params: {
      hash: hash,
    },
  });
}

async function resetPassword(data: ResetPasswordModel): Promise<ResultRequestModel> {
  return HttpService.put(`${basePath}/ConcludeResetPassword`, JSON.stringify(data));
}

export default AuthService;
