import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { fieldTypes, formMode, serviceControllers } from '../../util/enumerators';

import {
  FaRegEdit,
  FaEdit,
  FaRegEye,
  FaEye,
  FaRegCalendarTimes,
  FaCalendarTimes,
  FaArrowLeft,
  FaRegCalendarPlus,
  FaCalendarPlus,
} from 'react-icons/fa';
import { Header } from '../../components/Header';
import { Loading } from '../../components/Loading';
import Fieldset from '../../components/Fieldset';

import { ButtonModel } from '../../models/components/button.model';

import { ToastService } from '../../services/toast.service';
import { TableComponent } from '../../components/TableComponent';
import BaseService from '../../services/base.service';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { CPF_MASK, DATE_MASK } from '../../util/consts';
import { DynamicForm } from '../../components/DynamicForm';
import { format, parseISO } from 'date-fns';
import { Formik } from 'formik';
import { formatCpfCnpj } from '../../util';

export const VacationList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId?: string }>();
  const [showModal, setShowModal] = useState(false);
  const [vacationList, setVacationList] = useState({ items: [] });
  const [isLoading, setLoading] = useState(false);
  const [gridColumns, setGridColumns] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>();
  const formRef = useRef();

  const [fieldsInfo, setFieldsInfo] = useState({
    vacation: {
      name: '',
      cpf: '',
      modality: '',
      admissionDate: '',
    },
  });

  const rootUrl = '/Ferias';
  const serviceController = serviceControllers.VACATION;
  const itemKey = 'NR_FER_ID';

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/Ferias/`);
      },
      text: 'Voltar',
      variant: 'outline-primary',
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: 'Voltar para a página anterior',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.VIEW}/${selectedItem}`);
      },
      text: 'Visualizar',
      disabled: !Number.isInteger(selectedItem),
      icon: (
        <>
          <FaRegEye className="fa-icon" />
          <FaEye className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Visualizar dados do período de férias selecionado',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.EDIT}/${selectedItem}`);
      },
      text: 'Editar',
      disabled: !Number.isInteger(selectedItem),
      icon: (
        <>
          <FaRegEdit className="fa-icon" />
          <FaEdit className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Editar férias selecionada',
    },
    {
      onClick: () => {
        setShowModal(true);
      },
      text: 'Excluir',
      disabled: !Number.isInteger(selectedItem),
      icon: (
        <>
          <FaRegCalendarTimes className="fa-icon" />
          <FaCalendarTimes className="fa-icon-hover fa-icon-color-red" />
        </>
      ),
      tooltip: 'Excluir férias selecionada',
    },
    {
      onClick: () => {
        navigate(`${rootUrl}/${formMode.REGISTER}/${userId}`);
      },
      text: 'Adicionar',
      icon: (
        <>
          <FaRegCalendarPlus className="fa-icon" />
          <FaCalendarPlus className="fa-icon-hover" />
        </>
      ),
      tooltip: 'Adicionar novo período de férias',
    },
  ];

  const fieldList = [
    {
      type: fieldTypes.MASKED,
      field: 'cpf',
      label: 'CPF',
      mask: CPF_MASK,
      lgSize: 2,
      maxLength: 14,
      disabled: true,
    },
    {
      field: 'name',
      label: 'Nome do Colaborador',
      placeholder: 'Escreva o nome do Colaborador',
      lgSize: 3,
      maxLength: 60,
      disabled: true,
    },
    {
      field: 'modality',
      label: 'Modalidade',
      placeholder: 'Escreva a modalidade de contratação',
      lgSize: 3,
      maxLength: 60,
      disabled: true,
    },
    {
      type: fieldTypes.MASKED,
      field: 'admissionDate',
      label: 'Data de Admissão',
      mask: DATE_MASK,
      lgSize: 2,
      maxLength: 10,
      disabled: true,
    },
  ];

  const tableColumns = (data) => {
    if (data.dataset?.data[0]) {
      return data.dataset.data[1].field.filter((campo) => campo['showGrid']);
    } else {
      return [];
    }
  };

  const loadPageData = async () => {
    if (userId !== undefined || userId != null) {
      setLoading(true);

      const apiResponse: any = await BaseService.getById(serviceController, 'NR_CLB_ID', userId);

      if (apiResponse.result != null) {
        let columns = tableColumns(apiResponse);
        const data = apiResponse.result.dados[0];

        setGridColumns(columns);

        setFieldsInfo((prevValues) => ({
          ...prevValues,
          vacation: {
            cpf: formatCpfCnpj(data['RH_COLABORADOR_NR_CLB_ID']),
            name: data['RH_COLABORADOR_NM_CLB_DS'],
            admissionDate: format(parseISO(data['RH_REGISTRO_DT_REG_ENT']), 'dd/MM/yyyy'),
            modality: data['WP_MODALIDADE_CONTRATACAO_DS_MOD_CTR'],
          },
        }));

        setVacationList({
          items: apiResponse.result.dados1,
        });
      }

      setLoading(false);
    }
  };

  const initialize = async () => {
    setLoading(true);

    await loadPageData();

    setLoading(false);
  };

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });

    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });

    initialize();
  }, []);

  const selectItem = (index: number) => {
    setSelectedItem(selectedItem === index || index);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmModal = async () => {
    var selected = vacationList.items.find((v) => v.NR_FER_ID === selectedItem);

    var delParams = {
      id: selected[itemKey],
    };

    await BaseService.del(serviceController, delParams).then((response: any) => {
      if (response.return.success === true) {
        setSelectedItem(null);
        setShowModal(false);
        ToastService.success('Excluído com sucesso!');
        window.location.reload();
      } else {
        ToastService.error('Erro ao excluir!');
      }
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <Header
        title="Férias"
        buttons={buttons}
        previousPage={'Listagem de Férias'}
        previousUrl={() => {
          navigate(`/Ferias`);
        }}
      />
      <Fieldset legend={'Registros de : ' + fieldsInfo.vacation.name}>
        <div>
          <Formik
            enableReinitialize={true}
            innerRef={formRef}
            initialValues={fieldsInfo}
            onSubmit={(values) => {}}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Fieldset legend="Resumo">
                  <Row className="align-items-center" style={{ paddingTop: 10 }}>
                    <DynamicForm
                      form="vacation"
                      fieldList={fieldList}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      values={values}
                    />
                  </Row>
                </Fieldset>
              </Form>
            )}
          </Formik>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Col xl={12} style={{ padding: 0 }}>
            <Fieldset legend="Listagem de Férias">
              <div
                className="height-definition-timesheet"
                style={{
                  overflow: 'hidden',
                }}
              >
                <TableComponent
                  infiniteScrollClassName="height-definition-timesheet"
                  itemList={vacationList}
                  fetchMoreData={null}
                  totalItems={0}
                  gridColumns={gridColumns}
                  selectedItems={selectedItem}
                  selectItem={selectItem}
                  referenceColumn={itemKey}
                  orderable={false}
                />
              </div>
            </Fieldset>
          </Col>
        </div>
      </Fieldset>
      <ConfirmationModal
        title={'Deseja realmente excluir este item?'}
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
        children={''}
      />
    </>
  );
};
