import { useCallback, useEffect, useState } from 'react';
import { ButtonModel } from '../../../models/components/button.model';
import { FiEdit2, FiEdit3, FiPlus, FiTrash, FiTrash2 } from 'react-icons/fi';
import { Header } from '../../../components/Header';
import { FaPlus } from 'react-icons/fa';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import DepartmentTable from './department.table';
import { FilterAndListBuilder, tableColumns } from '../../../util/listFunctions';
import { ListRequest, RequestParamsModel } from '../../../models/requestParams.model';
import BaseService from '../../../services/base.service';
import { serviceControllers } from '../../../util/enumerators';
import { ListComponent } from '../../../components/ListComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DepartmentEdit from './department.edit';
import RetentionModal from '../retention/retention.modal';
import { CustomModal } from '../../../components/CustomModal';
import { TableComponent } from '../../../components/TableComponent';
import Fieldset from '../../../components/Fieldset';
import { ToastService } from '../../../services/toast.service';
import { Col, Row } from 'react-bootstrap';
import ClientDepartmentService from '../../../services/client.department.service';
import DepartManagerList from '../manager/depart.manager.list.';

interface IDepartmentClientList {
  idClient: number;
}

const DepartmentClientList = (props: IDepartmentClientList) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [columnsDepart, setColumnsDepart] = useState([]);
  const [columnsManager, setColumnsManager] = useState([]);
  const [dataDepart, setDataDepart] = useState<ListRequest>(RequestParamsModel('DEPTO'));
  const [departments, setDepartments] = useState({ items: [] });
  const [dataManager, setDataManager] = useState<ListRequest>(RequestParamsModel('DEPTO_GESTOR'));
  const [managers, setManagers] = useState({ items: [] });
  const [showModal, setShowModal] = useState(false);
  const [clientList, setClientList] = useState({ items: [] });
  const [selectedDepart, setSelectedDepart] = useState<any>();
  const [selectedItemDepart, setSelectedItemDepart] = useState<any>();
  const [selectedManager, setSelectedManager] = useState<any>();
  const [selectedItemManager, setSelectedItemManager] = useState<any>();

  // const filters = useSelector((state: any) => state.filtersToSearch);
  const filterDepart = {
    field: [
      {
        id: 'NR_CLI_ID',
        filterValue: props.idClient,
      },
    ],
  };
  const filterManager = {
    field: [
      {
        id: 'NR_CLI_ID',
        filterValue: props.idClient,
      },
      {
        id: 'ID_CLIENTE_DEPTO',
        filterValue: selectedItemDepart,
      },
    ],
  };

  const btnDepartment: ButtonModel[] = [
    {
      onClick: () => {
        setShowFormEdit(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiPlus className="fa-icon" />
          <FaPlus className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Adicionar - Departamento',
    },
    {
      onClick: () => {
        alert(`selectedDepart - ${selectedDepart}`);
        alert(`selectedItemDepart - ${selectedItemDepart}`);
        setShowFormEdit(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiEdit2 className="fa-icon" />
          <FiEdit3 className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Editar - Departamento',
      disabled: selectedDepart === null,
    },
    {
      onClick: () => {
        setShowConfirmation(true);
      },
      text: '',
      variant: 'outline-primary',
      icon: (
        <>
          <FiTrash className="fa-icon" />
          <FiTrash2 className="fa-icon-hover fa-icon-color-blue" />
        </>
      ),
      tooltip: 'Excluir - Departamento',
      disabled: selectedDepart === null,
    },
  ];

  const getDepartList = useCallback(
    async (data: any, isFilter: boolean = false) => {
      FilterAndListBuilder(
        serviceControllers.CLIENT_DEPARTMENT,
        data,
        filterDepart.field,
        setDepartments,
        clientList,
        setDataDepart,
        setColumnsDepart,
        isFilter,
        dispatch
      );
    },
    [departments.items, dataDepart, filterDepart, dispatch]
  );
  const getManagerList = useCallback(
    async (data: any, isFilter: boolean = false) => {
      FilterAndListBuilder(
        serviceControllers.CLIENT_DEPART_MANAGER,
        data,
        filterManager.field,
        setManagers,
        clientList,
        setDataManager,
        setColumnsManager,
        isFilter,
        dispatch
      );
    },
    [managers.items, dataManager, filterManager, dispatch]
  );

  const selectDepart = (param, index) => {
    setSelectedDepart(selectedDepart !== index ? index : null);
    setSelectedItemDepart(selectedItemDepart === param || param);
    setSelectedItemManager(null);
  };

  const selectManager = (param, index) => {
    setSelectedManager(selectedManager !== index ? index : null);
    setSelectedItemManager(selectedItemManager === param || param);
  };

  const handleConfirmModal = async () => {
    var delParams = {
      id: selectedItemDepart,
      cnpj: props.idClient,
    };

    await BaseService.del(serviceControllers.CLIENT_DEPARTMENT, delParams).then((response: any) => {
      if (response.return.success === true) {
        ToastService.success('Departamento do cliente removido com sucesso!');
        getDepartList(RequestParamsModel('DEPTO'), true);
      } else {
        ToastService.error('Erro ao excluir departamento do cliente!');
      }
      setShowModal(false);
    });
  };

  useEffect(() => {
    setSelectedDepart(null);
    setSelectedItemDepart(null);
    getDepartList(RequestParamsModel('DEPTO'), true);
  }, []);

  useEffect(() => {
    setSelectedItemManager(null);
    setSelectedManager(null);
    if (typeof selectedItemDepart === 'number') {
      getManagerList(RequestParamsModel('DEPTO_GESTOR'), true);
    } else setManagers({ items: [] });
    //getManagerList(RequestParamsModel('DEPTO_GESTOR'), true);
  }, [selectedItemDepart]);

  return (
    <>
      <Row>
        <Col xs={5}>
          {/* {JSON.stringify(managers)} */}
          <Header title={''} buttons={btnDepartment} showBread={false} />

          <Fieldset legend={'Departamentos Vinculados'}>
            <div
              style={{
                paddingTop: 13,
              }}
              className="height-fit-table"
            >
              <TableComponent
                infiniteScrollClassName="height-definition-timesheet"
                itemList={departments}
                fetchMoreData={() => {}}
                totalItems={0}
                gridColumns={columnsDepart}
                selectedItems={selectedItemDepart}
                selectItem={selectDepart}
                referenceColumn={'ID_CLIENTE_DEPTO'}
                orderable={false}
              />
            </div>
          </Fieldset>
        </Col>
        <Col xs={7}>
          <DepartManagerList
            idClient={props.idClient}
            idDepart={selectedItemDepart}
            managerList={managers}
            managerColumns={columnsManager}
            getManagerList={() => getManagerList(RequestParamsModel('DEPTO_GESTOR'), true)}
          />
        </Col>
      </Row>
      <CustomModal
        title={'Edição de Departamento'}
        show={showFormEdit}
        handleClose={() => setShowFormEdit(false)}
        children={
          <DepartmentEdit
            idClient={props.idClient}
            idDepart={selectedItemDepart}
            refreshList={getDepartList}
            handleClose={() => setShowFormEdit(false)}
          />
        }
      />
      <ConfirmationModal
        title={'Deseja realmente excluir este registro?'}
        show={showConfirmation}
        handleClose={() => {
          setShowConfirmation(false);
        }}
        handleConfirm={() => {
          handleConfirmModal();
          setShowConfirmation(false);
        }}
        children={
          <p style={{ fontSize: '14px', color: 'red', marginBottom: 0 }}>
            Essa operação não poderá ser desfeita...
          </p>
        }
      />
    </>
  );
};

export default DepartmentClientList;
