import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import MaskedFormControl from 'react-bootstrap-maskedinput';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import * as yup from 'yup';

import { Header } from '../../components/Header';
import Fieldset from '../../components/Fieldset';

import { ClosingService } from '../../services/closing.service';
import { CadastroService } from '../../services/cadastro.service';

import { ButtonModel } from '../../models/components/button.model';
import { FechamentoModel } from '../../models/fechamento.model';
import { ToastService } from '../../services/toast.service';
import { useDispatch, useSelector } from 'react-redux';
import { StoreModel } from '../../models/store.model';
import { RequestClosingForEditParamsModel } from '../../models/requestClosingForEdit.model';
import RegistryService from '../../services/registry.service';
import { RegistryModel } from '../../models/Registry.model';
import { RequestRegistryParamsModel } from '../../models/requestRegistry.model';
import moment from 'moment';
import { FaArrowLeft, FaRegSave, FaSave } from 'react-icons/fa';

export const TimeSheetRegister = () => {
  const { authentication, companyId } = useSelector((state: StoreModel) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { selectedDate } = useParams<{ selectedDate?: string }>();
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [fechamentoBg, setFechamentoBg] = useState('');
  const [registroBg, setRegistroBg] = useState('');
  const [registrySelected, setRegistrySelected] = useState(null);

  const date = new Date();
  const month = new Intl.DateTimeFormat('pt-BR', { month: 'long' })
    .format(date)
    .replace(/^\w/, (c) => c.toUpperCase());
  const year = date.getFullYear();

  // ---

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/Timesheet/`);
      },
      text: t('BACK'),
      variant: 'outline-primary',
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: 'Voltar para a página anterior',
    },
    {
      onClick: () => {
        submitFormik();
      },
      text: t('SAVE'),
      icon: (
        <>
          <FaRegSave className="fa-icon" /> <FaSave className="fa-icon-hover" />
        </>
      ),
      tooltip: selectedDate ? 'Salvar alterações' : 'Replicar horas',
    },
  ];

  // ---

  const [registryDropdown, setRegistryDropdown] = useState<RegistryModel[]>([]);
  const formRef = useRef();

  // ---

  const [initialValues, setInitialValues] = useState<FechamentoModel>({
    fechamento: {
      fechamentoPK: '',
      DT_FLH_MAF: '',
      NM_CLB_DS: '',
      NR_CLB_ID: '',
      NM_LTC_APL: '',
      ID_Usuario: authentication.usuario.ID_USUARIO,
    },
  });

  const initialize = async () => {
    setLoading(true);

    await loadForEdit();

    setLoading(false);
  };

  const loadForEdit = async () => {
    if (selectedDate != undefined || selectedDate != null) {
      // const paramns: RequestClosingForEditParamsModel = {
      //   idEmpresa: companyId,
      //   idUsuario: authentication.usuario.ID_USUARIO,
      //   idFechamento: id,
      //   error: {}
      // };
      // const apiResponse: any = await ClosingService.getById(paramns);
      // if (apiResponse.result != null) {
      //   let dateTimeISO = Date.parse(apiResponse.result.DT_CLB_NSC);
      //   let dateFormat = new Date(dateTimeISO)
      //     .toISOString()
      //     .replace(/T.*/, "")
      //     .split("-")
      //     .reverse()
      //     .join("/");
      //   apiResponse.result.DT_CLB_NSC = dateFormat;
      //   const paramnsRegistry: RequestRegistryParamsModel = {
      //     idEmpresa: companyId,
      //     idUsuario: authentication.usuario.result.ID_USUARIO,
      //     idColaborador: id,
      //     dtRegEnt: null,
      //     error: {}
      //   };
      //   const apiRegistryResponse: any = await RegistryService.getList(
      //     paramnsRegistry
      //   );
      //   let registry: RegistryModel = null;
      //   if (apiRegistryResponse.resultList) {
      //     registry = apiRegistryResponse.resultList[0];
      //     setRegistryDropdown(apiRegistryResponse.resultList);
      //     setRegistrySelected(registry.DT_REG_ENT);
      //   }
      //   setInitialValues({
      //     ...initialValues,
      //     fechamento: {
      //       ...apiResponse.result,
      //       NR_EMP_ID: companyId,
      //       ID_Usuario: authentication.usuario.result.ID_USUARIO
      //     }
      //   });
      // }
    }
  };

  const create = async (data: any) => {
    CadastroService.save(data)
      .then((response: any) => {
        // -- setando a pk
        if (response.resultList != null && response.error != null) {
          response.resultList.forEach((message: any) => {
            if (message.fechamento !== undefined) {
              setInitialValues({
                ...data,
                fechamento: {
                  ...data.fechamento,
                  fechamentoPK: data.fechamento.cpfFechamento,
                },
              });
            }
          });
        }

        // -- setando o toast
        if (response.error != null) {
          let errorList: string[] = [];

          response.error.forEach((message: any) => {
            if (message.fechamento != undefined) {
              setFechamentoBg('#FFF5EE');
              errorList.push(message.fechamento);
            }
          });

          ToastService.error(errorList.join(' - '));
        } else {
          ToastService.success('sucesso');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const update = async (data) => {
    CadastroService.save(data)
      .then((response: any) => {
        // console.log(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = async (data) => {
    if (selectedDate !== undefined || selectedDate != null) {
      await update(data);
    } else {
      await create(data);
    }
  };

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });

    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });
    initialize();
  }, []);

  return (
    <>
      <Header
        title={
          selectedDate != null || selectedDate != undefined
            ? 'Editar ' + selectedDate
            : 'Replicar horas'
        }
        buttons={buttons}
        previousPage="Timesheet"
        previousUrl={() => {
          navigate(`/Timesheet/`);
        }}
      />
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          onSubmit(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setValues,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Fieldset legend={month + ' de ' + year} background={fechamentoBg}>
              <Row className="align-items-center">
                <Col sm={12} md={6} lg={3} className="mb-2 mt-2">
                  <Form.Group className="form-group">
                    <MaskedFormControl
                      type="text"
                      id="fechamento.NR_CLB_ID"
                      name="fechamento.NR_CLB_ID"
                      mask="11:11"
                      size="sm"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={touched.fechamento?.NR_CLB_ID && !!errors.fechamento?.NR_CLB_ID}
                      value={values.fechamento?.NR_CLB_ID || '09:00'}
                      maxLength={5}
                    />
                    <Form.Label className="label-input-form">Entrada</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.fechamento?.NR_CLB_ID}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={3} className="mb-2 mt-2">
                  <Form.Group className="form-group">
                    <MaskedFormControl
                      type="text"
                      id="fechamento.NM_CLB_DS"
                      name="fechamento.NM_CLB_DS"
                      mask="11:11"
                      size="sm"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={touched.fechamento?.NM_CLB_DS && !!errors.fechamento?.NM_CLB_DS}
                      value={values.fechamento?.NM_CLB_DS || '12:00'}
                      maxLength={5}
                    />
                    <Form.Label className="label-input-form">Almoço</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.fechamento?.NM_CLB_DS}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={3} className="mb-2 mt-2">
                  <Form.Group className="form-group">
                    <MaskedFormControl
                      type="text"
                      id="fechamento.DT_FLH_MAF"
                      name="fechamento.DT_FLH_MAF"
                      mask="11:11"
                      size="sm"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={touched.fechamento?.DT_FLH_MAF && !!errors.fechamento?.DT_FLH_MAF}
                      value={values.fechamento?.DT_FLH_MAF || '13:00'}
                      maxLength={5}
                    />
                    <Form.Label className="label-input-form">Retorno</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.fechamento?.DT_FLH_MAF}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col sm={12} md={6} lg={3} className="mb-2 mt-2">
                  <Form.Group className="form-group">
                    <MaskedFormControl
                      type="text"
                      id="fechamento.NM_LTC_APL"
                      name="fechamento.NM_LTC_APL"
                      mask="11:11"
                      size="sm"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={touched.fechamento?.NM_LTC_APL && !!errors.fechamento?.NM_LTC_APL}
                      value={values.fechamento?.NM_LTC_APL || '18:00'}
                      maxLength={5}
                    />
                    <Form.Label className="label-input-form">Saída</Form.Label>

                    <Form.Control.Feedback type="invalid">
                      {errors.fechamento?.NM_LTC_APL}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="align-items-center">
                <Col sm={12} md={12} lg={12} className="mb-2 mt-2">
                  <Form.Group className="form-group">
                    <Form.Control
                      type="text"
                      id="fechamento.NM_LTC_APL"
                      name="fechamento.NM_LTC_APL"
                      size="sm"
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isLoading}
                      isInvalid={touched.fechamento?.NM_LTC_APL && !!errors.fechamento?.NM_LTC_APL}
                      value={values.fechamento?.NM_LTC_APL}
                      maxLength={60}
                    />
                    <Form.Label className="label-input-form">Descrição / Projeto</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      {errors.fechamento?.NM_LTC_APL}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Fieldset>
          </Form>
        )}
      </Formik>
    </>
  );
};
