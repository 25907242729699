import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { fieldTypes, formMode, serviceControllers } from '../../util/enumerators';
import * as Yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import Fieldset from '../../components/Fieldset';
import { DynamicForm } from '../../components/DynamicForm';
import { ButtonModel } from '../../models/components/button.model';
import { FaArrowLeft, FaRegSave, FaSave } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import BaseService from '../../services/base.service';
import ProfileService from '../../services/profile.service';
import { ToastService } from '../../services/toast.service';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { t } from 'i18next';
import { findParentsInTree } from '../../util';

export const ProfileRegister = () => {
  const { id, mode } = useParams<{ id?: string; mode?: string }>();
  const [isLoading, setLoading] = useState(false);
  const [tree, setTree] = useState<any>([]);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();

  const treeValuePrepend = {
    component: 'c',
    menu: 'm',
  };

  useEffect(() => {
    dispatch({
      type: 'LOAD_FILTERS',
      filtersList: [],
    });

    dispatch({
      type: 'SEARCH_FILTERS',
      filtersToSearch: [],
    });
    initialize();
  }, [id]);

  const loadForEdit = async () => {
    try {
      const apiResponse: any = await BaseService.getById(
        serviceControllers.PROFILE,
        'ID_PERFIL',
        id,
        '',
        ['PERFIL']
      );

      if (apiResponse.return?.success && apiResponse.result != null) {
        const data = apiResponse.result.dados[0];
        return data;
      }
    } catch (error) {
      ToastService.error(t('PROFILE_LOAD_FAILED'));
      return null;
    }
  };

  const loadMenus = async (profileId?: number) => {
    try {
      const apiResponse: any = await BaseService.getList(
        serviceControllers.PROFILE,
        {
          filter: !profileId
            ? {}
            : {
                field: [
                  {
                    id: 'ID_PERFIL',
                    value: profileId,
                  },
                ],
              },
          parameter: {},
        },
        'GetMenus'
      );

      if (!apiResponse.return?.success || apiResponse.result == null) {
        return [];
      }

      return apiResponse.result.dados;
    } catch (error) {
      ToastService.error(t('PROFILE_LOAD_FAILED'));
      return [];
    }
  };

  const loadComponents = async (loadedMenus) => {
    try {
      const apiResponse: any = await BaseService.getList(
        serviceControllers.PROFILE,
        {
          filter: {},
          parameter: {},
        },
        'GetComponents'
      );

      let data = [];
      if (apiResponse.return?.success && apiResponse.result !== null) {
        data = apiResponse.result.dados;
      }

      const expanded = [];
      const rootMenus = loadedMenus
        .filter((x) => x.ID_MENU_PAI === null)
        .map((root) => {
          let value = treeValuePrepend.menu + root['ID_MENU'];
          expanded.push(value);

          return {
            label: root['DS_MENU'],
            value: value,
            type: 'menu',
            children: [
              ...loadedMenus
                .filter((x) => x.ID_MENU_PAI === root['ID_MENU'])
                .map((children) => {
                  value = treeValuePrepend.menu + children['ID_MENU'];
                  expanded.push(value);

                  return {
                    value: value,
                    label: children['DS_MENU'],
                    children: data
                      .filter((x) => x.ID_MENU === children['ID_MENU'])
                      .map((component) => {
                        value = treeValuePrepend.component + component['ID_MENU_COMPONENTE'];
                        expanded.push(value);

                        return {
                          value: value,
                          label: component['DS_COMPONENTE'],
                        };
                      }),
                  };
                }),
              ...data
                .filter((x) => x.ID_MENU === root['ID_MENU'])
                .map((component) => {
                  value = treeValuePrepend.component + component['ID_MENU_COMPONENTE'];
                  expanded.push(value);
                  return {
                    value: value,
                    label: component['DS_COMPONENTE'],
                  };
                }),
            ],
          };
        });

      setTree(rootMenus);
      setExpanded(expanded);

      return data;
    } catch (error) {
      ToastService.error(t('PROFILE_LOAD_FAILED'));
      return [];
    }
  };

  const loadComponentsForProfile = async (loadedComponents) => {
    try {
      const apiResponse: any = await BaseService.getList(
        serviceControllers.PROFILE,
        {
          filter: {
            field: [
              {
                id: 'ID_PERFIL',
                value: id,
              },
            ],
          },
          parameter: {},
        },
        'GetComponentsByProfileId'
      );

      if (!apiResponse.return?.success || apiResponse.result == null) {
        return [];
      }

      return apiResponse.result.dados;
    } catch (error) {
      ToastService.error(t('PROFILE_LOAD_FAILED'));
      return [];
    }
  };

  const initialize = async () => {
    setLoading(true);

    const loadedMenus = await loadMenus();
    const loadedComponents = await loadComponents(loadedMenus);

    if (mode === formMode.EDIT && id) {
      const profile = await loadForEdit();
      const selectedMenus = await loadMenus(parseInt(id));
      const selectedComponents = await loadComponentsForProfile(loadedComponents);

      setInitialValues((prevValues) => ({
        ...prevValues,
        profile: {
          ...prevValues.profile,
          name: profile['DS_PERFIL'],
          active: profile['FL_STATUS'],
          menus: selectedMenus.map((x) => {
            return {
              id: x['ID_MENU'],
              PerfilId: x['ID_PERFIL'],
              ProfileMenuId: x['ID_PERFIL_MENU'],
            };
          }),
          components: selectedComponents.map((x) => {
            return {
              id: x['ID_MENU_COMPONENTE'],
              profileId: x['ID_PERFIL'],
              ProfileComponentId: x['ID_PERFIL_COMPONENTE'],
            };
          }),
        },
      }));

      setChecked([
        ...checked,
        ...selectedMenus.map((x) => treeValuePrepend.menu + x.ID_MENU),
        ...selectedComponents.map(
          (x) => treeValuePrepend.component + x.ID_MENU_COMPONENTE.toString()
        ),
      ]);
    }

    setLoading(false);
  };

  const saveProfile = async (data) => {
    setLoading(true);

    const params = {
      profile: {
        description: data.profile?.name,
        status: data.profile?.active,
      },
      menus: getParentMenus(checked),
      components: checked
        .filter((x) => x.startsWith(treeValuePrepend.component))
        .map((item) => {
          return {
            id: item.substring(1),
          };
        }),
    };

    ProfileService.insert(params).then((response: any) => {
      setLoading(false);
      if (response.return.success === true) {
        ToastService.success('Perfil cadastrado com sucesso!');
        navigate(`/Perfil/`);
      }
    });
  };

  const editProfile = async (data) => {
    setLoading(true);
    const params = {
      profile: {
        id: id,
        description: data.profile?.name,
        status: data.profile?.active,
      },
      menus: getParentMenus(checked),
      components: checked
        .filter((x) => x.startsWith(treeValuePrepend.component))
        .map((item) => {
          return {
            id: item.substring(1),
            profileId: id,
          };
        }),
      storedMenus: data.profile?.menus,
      storedComponents: data.profile?.components,
    };

    ProfileService.update(params).then((response: any) => {
      setLoading(false);
      if (response.return.success === true) {
        ToastService.success('Perfil atualizado com sucesso!');
        navigate(`/Perfil/`);
      }
    });
  };

  const getParentMenus = (checkedItems: Array<string>) => {
    const selectedMenus = [];
    
    checkedItems.forEach(item => {
      const paths = findParentsInTree({children: tree}, item);
      
      paths.filter(x => x.startsWith(treeValuePrepend.menu)).forEach(path => {
        const normalizedValue = parseInt(path.substring(1));

        if(!selectedMenus.some(x=> x.id === normalizedValue)) {
          selectedMenus.push({
            id: normalizedValue,
            perfilId: id
          });
        }
      });
    });

    return selectedMenus;
  }

  const [initialValues, setInitialValues] = useState<any>({
    profile: {
      name: '',
      active: false,
      menus: [],
      components: [],
    },
  });

  const fieldList = [
    {
      field: 'name',
      label: 'Nome',
      placeholder: 'Escreva o nome do perfil',
      lgSize: 3,
      maxLength: 100,
      required: true,
    },
    {
      type: fieldTypes.CHECKBOX,
      field: 'active',
      label: 'Ativo',
      lgSize: 3,
    },
  ];

  const validationSchema = Yup.object().shape({
    profile: Yup.object().shape({
      name: Yup.string().trim().required('Campo obrigatório'),
    }),
  });

  const buttons: ButtonModel[] = [
    {
      onClick: () => {
        navigate(`/Perfil/`);
      },
      text: 'Voltar',
      variant: 'outline-primary',
      icon: <FaArrowLeft className="fa-icon-color-red" />,
      tooltip: 'Voltar para a página anterior',
    },
    {
      onClick: () => {
        submitFormik();
      },
      text: 'Salvar',
      disabled: false,
      hide: mode === formMode.VIEW,
      icon: (
        <>
          <FaRegSave className="fa-icon" />
          <FaSave className="fa-icon-hover" />
        </>
      ),
      tooltip: id ? 'Salvar alterações' : 'Salvar registro',
    },
  ];

  const submitFormik = () => {
    if (formRef && formRef.current) {
      let current: any = formRef.current;
      current.handleSubmit();
    }
  };

  const onSubmit = async (data) => {
    (await mode) === formMode.REGISTER ? saveProfile(data) : editProfile(data);
  };

  return (
    <>
      <Header
        title="Cadastro de Perfil"
        buttons={buttons}
        previousPage="Listagem de Perfil"
        previousUrl={() => {
          navigate(`/Perfil/`);
        }}
      />
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            onSubmit(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Fieldset legend="Dados do Perfil">
                <Row className="align-items-center" style={{ paddingTop: 10 }}>
                  <DynamicForm
                    form="profile"
                    fieldList={fieldList}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    values={values}
                  />
                </Row>

                <Row>
                  <Col xs={12}>
                    <CheckboxTree
                      showNodeIcon={false}
                      nodes={tree}
                      checked={checked}
                      expanded={expanded}
                      checkModel="all"
                      noCascade={false}                    
                      onlyLeafCheckboxes={false}
                      onCheck={(checked) => {
                        setChecked(checked);
                      }}
                      onExpand={(expanded) => setExpanded(expanded)}
                    />
                  </Col>
                </Row>
              </Fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
