import HttpService from "./http.service";
import { RequestTimeSheetParamsModel } from "../models/requestTimeSheetParams.model";
import { ListRequest } from "../models/requestParams.model";
import { serviceControllers } from "../util/enumerators";
import { TimeKeepingModel } from "../models/timesheet/timekeeping.model";
const basePath = serviceControllers.TIMESHEET;

export const TimesheetService = {
  insert,
  update,
  remove,
  removeExtra,
  removeSobreaviso,
  getAprovacao,
  getExtra,
  getRelatorio,
  getRelatorioFechamentoResumo,
  alterarTimeSheet,
  aprovarTimeSheet,
  calcularValorTotal,
  insertExtra,
  insertNotificacao,
  insertSobreaviso,
  insertStatus,
  getTimeSheetById,
  getTimeSheet
};

async function insert(data) {
  return HttpService.post(`${basePath}/AddTimeSheetReplicate`, data);
}

async function update(data) {
  return HttpService.post(`${basePath}/AddTimeSheet`, data);
}

async function remove(data) {
  return HttpService.post(`${basePath}/Delete`, data);
}

async function removeExtra(data) {
  return HttpService.post(`${basePath}/DeleteTimeSheetExtraHour`, data);
}

async function removeSobreaviso(data) {
  return HttpService.post(`${basePath}/DeleteSobreaviso`, data);
}

async function getTimeSheetById(id, data) {
  return HttpService.post(`${basePath}/GetTimeSheet/${id}`, data);
}

async function getTimeSheet(data) {
  return HttpService.post(`${basePath}/GetTimeSheet`, data);
}

async function getAprovacao(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/GetAprovacao`, data);
}

async function getExtra(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/GetExtra`, data);
}

async function getRelatorio(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/GetRelatorio`, data);
}

async function getRelatorioFechamentoResumo(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/GetRelatorioFechamentoResumo`, data);
}

async function alterarTimeSheet(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/GetHeaderWithFilter`, data);
}

async function aprovarTimeSheet(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/AprovarTimeSheet`, data);
}

async function calcularValorTotal(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/CalcularValorTotal`, data);
}

async function insertExtra(data: TimeKeepingModel) {
  return HttpService.post(`${basePath}/AddTimeSheetExtraHour`, data);
}

async function insertNotificacao(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/InsertNotificacao`, data);
}

async function insertSobreaviso(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/InsertSobreaviso`, data);
}

async function insertStatus(data: RequestTimeSheetParamsModel) {
  return HttpService.post(`${basePath}/InsertStatus`, data);
}

export default TimesheetService;
