import { CollaboratorModel } from '../../../models/collaborator/colaborador.model';
import {
  convertCurrencyToFloat,
  formatCep,
  formatCpfCnpj,
  formatCurrencyFromDb,
  formatDate,
  formatStringToDate,
} from '../../../util';

export const editingMap = (data, setInitialValues) => {
  const collaboratorData = collaboratorFieldsMap(data);
  const registerData = registerFieldsMap(data);
  const remunerationData = remunerationFieldsMap(data);

  setInitialValues({
    colaborador: collaboratorData,
    registro: registerData,
    remuneracao: remunerationData,
  });
};

export const collaboratorFieldsMap = (data) => {
  const collaboratorData = {
    id: 1,
    idEnterprise: data['NR_EMP_ID'],
    idUser: data['ID_USR_INC'],
    idCollaborator: data['NR_CLB_ID'],
    cpf: formatCpfCnpj(data['NR_CLB_ID']),
    name: data['NM_CLB_DS'],
    email: data['DS_CLB_EMAIL'],
    rg: data['NR_RG_NUM'],
    issuingAuthority: data['ID_RG_ORG'],
    ufIssuingAuthority: data['ID_RG_UF'],
    nationality: data['NR_NAC_ID'],
    maritalStatus: data['NR_CIV_CD'],
    gender: data['NR_SEX_CD'],
    neighborhood: data['NM_LGR_BAI'],
    state: data['ID_LGR_UF'],
    city: data['ID_LGR_CID'],
    bank: data['NR_BPF_ID'],
    agency: data['NR_BPF_AG'],
    accountType: data['IND_BPF_TP'],
    account: data['NR_BPF_CC'],
    digit: data['NR_BPF_DG'],
    beneficiaryDocument: formatCpfCnpj(data?.['NR_BPF_TDC']),
    beneficiaryName: data?.['NM_BPF_TDS'],
    dtBirth: formatDate(data?.['DT_CLB_NSC']),
    phone: data['NR_CEL_NUM'],
    motherName: data['NM_MAE_DS'],
    street: data['NM_LGR_DS'],
    streetNumber: data['NR_LGR_NUM'],
    complement: data['NM_LGR_CPL'],
    cep: formatCep(data['NR_LGR_CEP']),
  };

  return collaboratorData;
};

export const collaboratorSaveMap = (data, token = null, saved = false) => {
  const saveObject = {
    token: token,
    id: saved ? 1 : data.colaborador.id, //Apenas para distinção se vai chamar a proc de insert ou update
    idEnterprise: data.colaborador.idEnterprise,
    idUser: data.colaborador.idUser,
    idCollaborator: data.colaborador.cpf?.replace(/[^\d]/g, ''),
    cpf: data.colaborador.cpf,
    name: data.colaborador.name,
    email: data.colaborador.email,
    rg: data.colaborador.rg,
    issuingAuthority: data.colaborador.issuingAuthority,
    ufIssuingAuthority: parseInt(data.colaborador.ufIssuingAuthority),
    nationality: parseInt(data.colaborador.nationality),
    maritalStatus: parseInt(data.colaborador.maritalStatus),
    gender: parseInt(data.colaborador.gender),
    neighborhood: data.colaborador.neighborhood,
    state: parseInt(data.colaborador.state),
    city: parseInt(data.colaborador.city),
    bank: parseInt(data.colaborador.bank),
    agency: parseInt(data.colaborador.agency),
    accountType: parseInt(data.colaborador.accountType),
    account: parseInt(data.colaborador.account),
    digit: parseInt(data.colaborador.digit),
    beneficiaryDocument: data.colaborador.beneficiaryDocument?.replace(/[^\d]/g, ''),
    beneficiaryName: data.colaborador.beneficiaryName,
    dtBirth: formatStringToDate(data.colaborador.dtBirth),
    phone: data.colaborador.phone,
    motherName: data.colaborador.motherName,
    street: data.colaborador.street,
    streetNumber: data.colaborador.streetNumber,
    complement: data.colaborador.complement,
    cep: data.colaborador.cep?.replace(/[^\d]/g, ''),
  };
  return saveObject;
};

export const registerFieldsMap = (data) => {
  const registerValuesData = {
    id: 1,
    collaboratorId: data['NR_CLB_ID'],
    salesOrder: data?.['NR_TMV_NU'],
    clientName: data?.['VL_TM_VCO_TOT_HR'],
    clientId: formatCpfCnpj(data?.['NR_EMP_ID']),
    registerDate: formatDate(data?.['DT_REG_ENT']),
    modality: data?.['ID_MOD_CTR'],
    capacity: data?.['NR_LTC_ID'],
    workPlace: data?.['NM_LTR_DS'],
    workSchedule: data?.['NR_HRT_ID'],
    uf: data?.['NF_UF_ID_LTR'],
    city: data?.['NF_CID_ID_LTR'],
    collectionCenter: data['CD_CNL_CPT']?.toString(),
    remuneration: data?.['IND_TIP_RMN'],
    timesheet: data?.['IND_PTO_TIP'],
    accessLogin: data?.['DS_CLB_LOGIN'],
    annualLeave: data?.['IND_PTO_BHR']?.toString(),
    expertise: data?.['NM_CLB_ESP'],
  };

  return registerValuesData;
};

export const registerSaveMap = (data: CollaboratorModel) => {
  const saveObject = {
    id: data.registro.id, //Apenas para distinção se vai chamar a proc de insert ou update
    collaboratorId: data.colaborador.cpf?.replace(/[^\d]/g, ''),
    salesOrder: data.registro.salesOrder,
    clientName: data.registro.clientName,
    clientId: data.registro.clientId,
    registerDate: formatStringToDate(data.registro.registerDate),
    modality: data.registro.modality,
    capacity: data.registro.capacity,
    workPlace: data.registro.workPlace,
    workSchedule: data.registro.workSchedule,
    uf: data.registro.uf,
    city: data.registro.city,
    collectionCenter: parseInt(data.registro.collectionCenter),
    remuneration: data.registro.remuneration,
    timesheet: data.registro.timesheet,
    accessLogin: data.registro.accessLogin,
    annualLeave: parseInt(data.registro.annualLeave),
    expertise: data.registro.expertise,
  };
  return saveObject;
};

export const remunerationFieldsMap = (data) => {
  const remunerationValuesData = {
    id: 1,
    registerDate: formatDate(data?.['DT_REG_ENT']),
    effectiveDate: formatDate(data?.['DT_REG_VIG']),
    monthBaseHours: data?.['NR_HBS_QTD'] ? data?.['NR_HBS_QTD'] : '',
    salary: formatCurrencyFromDb(data?.['VL_CLB_TOT']),
    clt: formatCurrencyFromDb(data?.['VL_CLB_SAL_CLT']),
    da: formatCurrencyFromDb(data?.['VL_CLB_SAL_DA']),
    pj: formatCurrencyFromDb(data?.['VL_CLB_SAL_PJ']),
    flex: formatCurrencyFromDb(data?.['VL_CLB_SAL_FLX']),
    cooper: formatCurrencyFromDb(data?.['VL_CLB_SAL_COOP']),
    charges: formatCurrencyFromDb(data?.['VL_CLB_CST_CLT']),
    ilatiCost: formatCurrencyFromDb(data?.['VL_CLB_CST_DA']),
    rhCost: formatCurrencyFromDb(data?.['VL_CLB_CST_RH']),
    indirectCost: formatCurrencyFromDb(data?.['VL_CLB_CST_IND']),
    subsistenceAllowance: formatCurrencyFromDb(data?.['VL_CLB_BNF_AJC']),
    totalCost: formatCurrencyFromDb(data?.['VL_CST_TOT']),
    healthCare: formatCurrencyFromDb(data?.['VL_CLB_BNF_AM']),
    dentalCare: formatCurrencyFromDb(data?.['VL_CLB_BNF_AO']),
    mealTicket: formatCurrencyFromDb(data?.['VL_CLB_BNF_VR']),
    foodStamps: formatCurrencyFromDb(data?.['VL_CLB_BNF_VA']),
    fuelVoucher: formatCurrencyFromDb(data?.['VL_CLB_BNF_VC']),
    transportationVoucher: formatCurrencyFromDb(data?.['VL_CLB_BNF_VT']),
    plr: formatCurrencyFromDb(data?.['VL_CLB_BNF_PLR']),
    lifeInsurance: formatCurrencyFromDb(data?.['VL_CLB_BNF_SV']),
    otherbenefits: formatCurrencyFromDb(data?.['VL_CLB_BNF_OTR']),
    totalBenefits: formatCurrencyFromDb(data?.['VL_BNF_TOT']),
  };

  return remunerationValuesData;
};

export const remunerationSaveMap = (data: CollaboratorModel, modality = null) => {
  const saveObject = {
    id: data.remuneracao.id, //Apenas para distinção se vai chamar a proc de insert ou update
    modality: modality ?? data.registro.modality, //Apenas para distinção se vai chamar a proc de insert ou update
    collaboratorId: data.colaborador.cpf?.replace(/[^\d]/g, ''),
    registerDate: formatStringToDate(data.registro.registerDate),
    effectiveDate: formatStringToDate(data.remuneracao.effectiveDate),
    monthBaseHours: data.remuneracao.monthBaseHours,
    salary: convertCurrencyToFloat(data.remuneracao.salary),
    clt: convertCurrencyToFloat(data.remuneracao.clt),
    da: convertCurrencyToFloat(data.remuneracao.da),
    pj: convertCurrencyToFloat(data.remuneracao.pj),
    flex: convertCurrencyToFloat(data.remuneracao.flex),
    cooper: convertCurrencyToFloat(data.remuneracao.cooper),
    charges: convertCurrencyToFloat(data.remuneracao.charges),
    ilatiCost: convertCurrencyToFloat(data.remuneracao.ilatiCost),
    rhCost: convertCurrencyToFloat(data.remuneracao.rhCost),
    indirectCost: convertCurrencyToFloat(data.remuneracao.indirectCost),
    subsistenceAllowance: convertCurrencyToFloat(data.remuneracao.subsistenceAllowance),
    totalCost: convertCurrencyToFloat(data.remuneracao.totalCost),
    healthCare: convertCurrencyToFloat(data.remuneracao.healthCare),
    dentalCare: convertCurrencyToFloat(data.remuneracao.dentalCare),
    mealTicket: convertCurrencyToFloat(data.remuneracao.mealTicket),
    foodStamps: convertCurrencyToFloat(data.remuneracao.foodStamps),
    fuelVoucher: convertCurrencyToFloat(data.remuneracao.fuelVoucher),
    transportationVoucher: convertCurrencyToFloat(data.remuneracao.transportationVoucher),
    plr: convertCurrencyToFloat(data.remuneracao.plr),
    lifeInsurance: convertCurrencyToFloat(data.remuneracao.lifeInsurance),
    otherbenefits: convertCurrencyToFloat(data.remuneracao.otherbenefits),
    totalBenefits: convertCurrencyToFloat(data.remuneracao.totalBenefits),
  };
  return saveObject;
};
