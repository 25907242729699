import React, { useCallback, useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FaSearch, FaTimes, FaListUl } from "react-icons/fa";
import { Badge, Form, InputGroup, Dropdown } from "react-bootstrap";
import { ToastService } from "../../services/toast.service";
import { StoreModel } from "../../models/store.model";
import MaskedFormControl from "react-bootstrap-maskedinput";
import Select from "react-select";
import filterTypes from "./FilterEnumerator";
import { formatStringToDate } from "../../util";
import { MONTH_YEAR_MASK } from "../../util/consts";

export const FilterAutocomplete = () => {
  const dispatch = useDispatch();

  const refInput = useRef(null);
  const dropdownRef = useRef(null);
  const badgesDivRef = useRef(null);
  const inputGroupRef = useRef(null);
  const filterButtonDivRef = useRef(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const [showBadges, setShowBadges] = useState(true);
  const [showFiltersDiv, setShowFiltersDiv] = useState(false);
  const [tempStyle, setTempStyle] = useState({ marginTop: "5px" });

  const [options, setOptions] = useState([]);
  const [hasSuggestions, setHasSuggestions] = useState<Boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<any>({});

  const [filterValue, setFilterValue] = useState<any>("");
  const [refValue, setRefValue] = useState<any>({});

  const [focusedFilterIndex, setFocusedFilterIndex] = useState(-1);

  const appliedFilter = useSelector(
    (state: StoreModel) => state.filtersToSearch
  );

  const optionsListFunction = useSelector(
    (state: StoreModel) => state.optionsListFunction
  );
  const filtersList = useSelector((state: StoreModel) => state.filtersList);
  const [filteredFiltersList, setFilteredFiltersList] = useState(filtersList);

  const forceUpdate = React.useState()[1].bind(null, {});

  const showFiltersColumnStyle = {
    display: "flex",
    marginTop: "5px",
    flexDirection: "column",
    alignItems: "flex-start",
    border: "1px solid rgba(0,0,0,.15)",
    borderRadius: "4px",
    boxShadow: "0 6px 12px rgba(0,0,0,.175)",
    padding: "5px"
  };

  const showFilters = () => {
    setShowFiltersDiv(true);
    setTempStyle(showFiltersColumnStyle);
    setShowBadges(true);
  };

  const handleResize = () => {
    const { offsetWidth: divInputWidth = 0 } = inputGroupRef?.current || {};
    const { offsetWidth: divFilterWidth = 0, offsetParent } =
      badgesDivRef?.current || {};

    setShowFiltersDiv(false);

    if (offsetParent !== null) {
      setTempStyle({ marginTop: "5px" });
      setShowBadges(divInputWidth >= divFilterWidth);
    } else {
      setShowBadges(true);
      setTempStyle({ marginTop: "-60px" });
    }
  };

  const handleSearchButton = () => {
    const toastOptions = { autoClose: 1000 };
    if (!selectedFilter?.value) {
      ToastService.warn("Selecione um filtro", toastOptions);
      return;
    }

    const { type: filterDataType, mask: filterMask } = refValue;
    let parsedDate: any = "";
    let filterDisplayValue = "";
    let filterValue = filterTypes.DOMINIO
      ? refValue.value
      : refValue.value.trim();

    if (
      !refValue.value ||
      (filterDataType === filterTypes.DOMINIO && filterValue.length < 1)
    ) {
      ToastService.warn("Valor do filtro em branco", toastOptions);
      return;
    }

    const alreadyExists = appliedFilter?.find(
      (item) => item.id === selectedFilter?.id
    );

    const validationRegex =
      selectedFilter?.regexValue && new RegExp(selectedFilter?.regexValue);

    const validation = !validationRegex || validationRegex.test(filterValue);

    if (!validation) {
      ToastService.warn(selectedFilter?.messageValidation, toastOptions);
      return;
    }

    switch (filterDataType) {
      case filterTypes.INTEIRO:
        filterValue = filterValue.replace(/\D/g, "");
        break;
      case filterTypes.DATA:
        if (filterValue.split("/").length === 2) {
          const [month, year] = filterValue.split("/");
          filterValue = `${parseInt(year)}-${month}-01`;
          filterDisplayValue = `${month}/${parseInt(year)}`;
          parsedDate = new Date(
            formatStringToDate(`01/${month}/${parseInt(year)}`)
          );
        } else {
          const [day, month, year] = filterValue.split("/");
          filterValue = `${parseInt(year)}-${month}-${day}`;
          filterDisplayValue = `${day}/${month}/${parseInt(year)}`;
          parsedDate = new Date(formatStringToDate(filterDisplayValue));
        }

        if (isNaN(parsedDate.getTime())) {
          ToastService.warn("Data inválida", toastOptions);
          return;
        }
        break;
      case filterTypes.DOMINIO:
        filterValue = Array.isArray(filterValue)
          ? filterValue.join("|")
          : filterValue.toString();
        break;
    }

    const newFilter = {
      id: selectedFilter?.id,
      filterType: selectedFilter?.value,
      filterValue,
      filterDisplayValue,
      filterMask,
      filterDataType,
      filterDomainOptions: options
    };

    const newFiltersList = alreadyExists
      ? appliedFilter.map((item) =>
          item.id === alreadyExists.id ? newFilter : item
        )
      : [...appliedFilter, newFilter];

    dispatch({ type: "SEARCH_FILTERS", filtersToSearch: newFiltersList });

    try {
      refInput.current.value = "";
    } catch (error) {}

    refValue.value = "";
    setSelectedFilter((prevState) => ({}));
  };

  const handleSetFilter = async (item, ref) => {
    setSelectedFilter(item);
    setHasSuggestions(false);

    try {
      ref.current.value = "";
      ref.current.campoFiltro = item.value;
      ref.current.mask = item.mask;
      ref.current.type = item.type;
      ref.current.maxLength =
        item.maxLength != null ? parseInt(item.maxLength) : 100;

      ref.current.focus();
    } catch (error) {}

    refValue.value = "";
    refValue.campoFiltro = item.value;
    refValue.mask = item.mask;
    refValue.type = item.type;
    refValue.maxLength =
      item.maxLength != null ? parseInt(item.maxLength) : 100;

    if (item.type === filterTypes.DOMINIO) {
      if (item.sprAuto && item.sprAuto !== "") {
        let response = await optionsListFunction(
          item.sprAuto,
          item.sprAutoSearch
        );
        let fieldsOption: string[] = item.sprAutoFields.split("|");
        if (fieldsOption.length === 2) {
          if (response.return?.success) {
            setOptions(
              response?.result?.dados?.map((opt) => {
                return {
                  id: opt[fieldsOption[0]],
                  value: opt[fieldsOption[1]]
                };
              })
            );
          } else {
            setOptions([]);
          }
        } else {
          setOptions([]);
        }
      } else if (item.valueDomain) {
        let options = item?.valueDomain;
        setOptions(options);
      } else {
        setOptions([]);
      }
    }
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.startsWith("@")) {
        setHasSuggestions(true);

        if (dropdownRef.current.ariaExpanded !== "true")
          dropdownRef.current.click();

        const filteredList = filtersList?.filter((x) =>
          x.value
            ?.toUpperCase()
            .includes(refInput?.current?.value?.toUpperCase())
        );

        setFilteredFiltersList(filteredList);
        forceUpdate();
      } else {
        refValue.value = event.target.value;
        setFilterValue(event.target.value);
        setHasSuggestions(false);
      }
    },
    [setHasSuggestions]
  );

  const handleSelect = useCallback((selected) => {
    if (selectedFilter.component === "multiselect") {
      const selectedValues = selected?.map((option) => option.value);
      refValue.value = selectedValues;
      setFilterValue(selectedValues);
    } else {
      refValue.value = selected.value;
      setFilterValue(selected.value);
    }
  }, []);

  const handleClean = () => {
    dispatch({
      type: "SEARCH_FILTERS",
      filtersToSearch: []
    });

    refInput.current.value = "";
  };

  const handleDeleteFilter = (item) => {
    if (!item.isDefault) {
      dispatch({
        type: "SEARCH_FILTERS",
        filtersToSearch: [
          ...appliedFilter.filter((itemFilter) => item !== itemFilter)
        ]
      });
    }
  };

  const handleKeyUp = (event) => {
    if (
      event.keyCode === 13 &&
      refValue.value !== "" &&
      refInput?.current?.value !== ""
    ) {
      handleSearchButton();
    }
  };

  const handleClickFilter = () => {
    setHasSuggestions(true);
    dropdownRef.current.click();
  };

  const handleKeyDown = (event) => {
    const { keyCode } = event;

    switch (keyCode) {
      case 9:
      case 40:
        event.preventDefault();
        setFocusedFilterIndex(
          (prevIndex) => (prevIndex + 1) % filteredFiltersList.length
        );
        break;
      case 38:
        event.preventDefault();
        setFocusedFilterIndex(
          (prevIndex) =>
            (prevIndex - 1 + filteredFiltersList.length) %
            filteredFiltersList.length
        );
        break;
      case 13:
        if (hasSuggestions) {
          event.preventDefault();
          const focusedItem = filteredFiltersList[focusedFilterIndex];
          handleSetFilter(focusedItem, refInput);
        } else {
          handleSearchButton();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        badgesDivRef.current &&
        !badgesDivRef.current.contains(event.target) &&
        showFiltersDiv &&
        showBadges &&
        !filterButtonDivRef.current.contains(event.target)
      ) {
        setTempStyle({ marginTop: "-60px" });
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showFiltersDiv]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setFilteredFiltersList(filtersList);
    forceUpdate();
  }, [hasSuggestions]);

  useEffect(() => {
    if (filtersList?.length > 0) {
      handleResize();

      let objectFilterDefault = filtersList?.filter(
        (x) => x.defaultFilterValue != null
      );

      if (appliedFilter?.length === 0) {
        for (let i = 0; i < objectFilterDefault?.length; i++) {
          const element = objectFilterDefault[i];
          let displayValue = null;

          if (element?.type === filterTypes.DATA) {
            const [year, month, day] = element?.defaultFilterValue.split("-");

            displayValue =
              element?.mask === MONTH_YEAR_MASK
                ? `${month}/${year}`
                : `${day}/${month}/${year}`;
          }

          appliedFilter.push({
            id: element?.id,
            filterType: element?.value,
            filterValue: element?.defaultFilterValue,
            filterDisplayValue: displayValue,
            filterMask: element?.mask,
            filterDataType: element?.type,
            filterDomainOptions: element?.valueDomain,
            isDefault: true
          });
        }
      }
    }

    setSelectedFilter({});
    forceUpdate();
  }, [filtersList]);

  useEffect(() => {
    const filteredList = filtersList?.filter((x) =>
      x.value?.toUpperCase().includes(refInput?.current?.value?.toUpperCase())
    );

    setFilteredFiltersList(filteredList);
  }, [refInput?.current?.value, filtersList]);

  return (
    filtersList?.length > 0 && (
      <div className="filter-autocomplete">
        <InputGroup size="sm" style={{ display: "flex" }} ref={inputGroupRef}>
          {selectedFilter?.value !== "" && selectedFilter?.value !== undefined && (
            <InputGroup.Text
              id="basic-addon1"
              style={{
                width: "fit-content",
                cursor: "pointer",
                fontWeight: 600
              }}
              onClick={handleClickFilter}
            >
              {selectedFilter.value}
            </InputGroup.Text>
          )}
          {selectedFilter?.type === filterTypes.DOMINIO ? (
            <Select
              className="select-items"
              classNamePrefix="inner-class-select"
              isMulti={selectedFilter.component === "multiselect"}
              placeholder={"Selecione..."}
              options={options.map((option) => ({
                label: option.value,
                value: option.id
              }))}
              onKeyDown={handleKeyDown}
              onChange={handleSelect}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100%",
                  maxHeight: "26px",
                  overflow: "auto"
                }),

                multiValue: (provided) => ({
                  ...provided,
                  maxHeight: "30px",
                  overflow: "auto"
                })
              }}
            />
          ) : (
            <Form.Control
              id="filterInput"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              placeholder={
                selectedFilter?.value === "" ||
                selectedFilter?.value === undefined
                  ? "Digite @ para exibir os filtros"
                  : selectedFilter?.placeholder
              }
              ref={refInput}
              style={{ width: "auto", borderColor: "lightgray" }}
              mask={selectedFilter?.mask}
              onChange={handleChange}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              as={selectedFilter?.mask ? MaskedFormControl : "input"}
            />
          )}

          <InputGroup.Text className="ico-search" onClick={handleSearchButton}>
            <FaSearch />
          </InputGroup.Text>
        </InputGroup>
        <Dropdown>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-toggle-top"
            ref={dropdownRef}
          ></Dropdown.Toggle>
          {hasSuggestions && (
            <Dropdown.Menu ref={dropdownMenuRef}>
              {filteredFiltersList?.map((item, idx) => {
                return (
                  <Dropdown.Item
                    key={idx}
                    onClick={() => handleSetFilter(item, refInput)}
                    className={idx === focusedFilterIndex ? "focused" : ""}
                  >
                    {item.value}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          )}
        </Dropdown>

        {appliedFilter?.length > 0 && (
          <div
            className={`list-tags ${showBadges ? "visible" : "hidden"}`}
            ref={badgesDivRef}
            style={{ ...tempStyle, backgroundColor: "#FFFFFF", zIndex: 9 }}
          >
            {appliedFilter?.map((item, idx) => {
              let label = "";
              let labelHover = "";

              if (
                item.filterMask !== "" &&
                item.filterDataType === filterTypes.INTEIRO
              ) {
                let returnLabel = "";
                let aux = 0;
                let valueArray = item.filterValue?.split("");

                for (let i = 0; i < item.filterMask?.length; i++) {
                  const element = item.filterMask[i];

                  if (element !== "." && element !== "/" && element !== "-") {
                    returnLabel += valueArray[aux];
                    aux++;
                  } else {
                    returnLabel += element;
                  }
                }

                label = `${item.filterType}: ${
                  returnLabel.length > 15
                    ? returnLabel.substring(0, 15) + "..."
                    : returnLabel
                }`;

                labelHover = `${item.filterType}: ${returnLabel}`;
              } else if (item.filterDataType === filterTypes.DOMINIO) {
                if (item.filterValue[0] !== undefined) {
                  let filterValues = [];
                  let displayValue = "";

                  if (item.filterValue.indexOf("|") === -1) {
                    const option = item.filterDomainOptions.find(
                      (opt) => opt.id.toString() === item.filterValue.toString()
                    );
                    filterValues = [item.filterValue];
                    displayValue = option.value;
                  } else {
                    filterValues = item.filterValue
                      .split("|")
                      .map((value) => parseInt(value, 10));

                    displayValue = filterValues
                      .map((value) => {
                        const option = options.find(
                          (opt) => opt.id.toString() === value.toString()
                        );
                        return option ? option.value : value;
                      })
                      .join(", ");
                  }

                  if (filterValues.length === 1) {
                    label = `${item.filterType}: ${
                      displayValue?.length > 15
                        ? displayValue.substring(0, 15) + "..."
                        : displayValue
                    }`;
                  } else {
                    label = `${item.filterType}: (${filterValues.length}) selecionados`;
                  }

                  labelHover = `${item.filterType}: ${displayValue}`;
                }
              } else {
                let displayValue = item.filterDisplayValue || item.filterValue;
                label = `${item.filterType}: ${
                  displayValue.length > 15
                    ? displayValue.substring(0, 15) + "..."
                    : displayValue
                }`;

                labelHover = `${item.filterType}: ${displayValue}`;
              }

              return (
                <Badge
                  key={idx}
                  bg="primary"
                  onClick={() => handleDeleteFilter(item)}
                  title={labelHover}
                  style={{ marginTop: showFiltersDiv ? "3px" : "0" }}
                >
                  {label}
                  <FaTimes />
                </Badge>
              );
            })}
            <Badge
              bg="secondary"
              onClick={handleClean}
              style={{ marginTop: showFiltersDiv ? "3px" : "0" }}
            >
              <FaTimes /> Limpar
            </Badge>
          </div>
        )}
        {appliedFilter?.length > 0 && (
          <div className="list-tags" ref={filterButtonDivRef}>
            <Badge
              bg="primary"
              className="show-filters-button visible"
              onClick={showFilters}
            >
              <FaListUl /> &nbsp; Exibir Filtros
            </Badge>
          </div>
        )}
      </div>
    )
  );
};
